import React from 'react';
import { Modal } from 'react-bootstrap';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import './status.scss';
import { transactionDate } from '../../../Util/functions';

interface IStatusModalProps {
  show: boolean;
  handleClose: () => void;
  onDoneClick: () => void;
  transactionData: {
    recipient: {
      account_holder_name: string;
      bank_name: string;
    };
    sender: {
      name: string;
      bank_name: string;
    };
    createdAt: string;
    currency: {
      from: {
        symbol: string;
        amount: number;
      };
      to: {
        symbol: string;
        amount: number;
      };
    };
    swift_copy: {
      _id: string;
      field: string;
      location: string;
    } | null;
    status:
      | 'COMPLETED_BY_USER'
      | 'AMOUNT_RECEIVED'
      | 'AMOUNT_TRANSFERRED'
      | 'CANCELLED'
      | 'AMOUNT_CREDITED';
  };
  title: string;
}

const StatusModal = ({
  show,
  handleClose,
  title,
  transactionData,
}: IStatusModalProps) => {
  // console.log(transactionData);
  const percent = () => {
    if (transactionData.status === 'COMPLETED_BY_USER') {
      return 0;
    }

    if (transactionData.status === 'AMOUNT_RECEIVED') {
      return 34;
    }

    if (transactionData.status === 'AMOUNT_TRANSFERRED') {
      return 68;
    }

    if (transactionData.status === 'AMOUNT_CREDITED') {
      return 100;
    }

    return 0;
  };

  return (
    <Modal show={show} onHide={handleClose} className="modal-transaction">
      <Modal.Header closeButton>
        <h5 className="modal-title" id="staticBackdropLabel">
          {title}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <h6 className="text-left">{title}</h6>
        {transactionData.status !== 'CANCELLED' ? (
          <div
            className="bar-container pb-5"
            style={{ width: '90%', margin: 'auto' }}>
            <ProgressBar percent={percent()}>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    <h5 className="progressBarText">COMPLETED BY USER</h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    <h5 className="progressBarText">AMOUNT RECEIVED BY US</h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    <h5 className="progressBarText">
                      AMOUNT TRANSFERRED BY US
                    </h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    <h5 className="progressBarText w-25">AMOUNT CREDITED</h5>
                  </div>
                )}
              </Step>
            </ProgressBar>
          </div>
        ) : (
          <h5>Transaction Cancelled</h5>
        )}

        <div>
          <div className="detail-container d-flex justify-content-around">
            <div>
              <h6 className="info-text">Name of Recipient</h6>
              <h5 className="data-text">
                {transactionData.recipient.account_holder_name}
              </h5>

              <h6 className="info-text">Bank Name of Recipient</h6>
              <h5 className="data-text">
                {transactionData.recipient.bank_name}
              </h5>

              <h6 className="info-text">Amount Transferring</h6>
              <h5 className="data-text">
                ₹{transactionData.currency.from.amount}
              </h5>

              <h6 className="info-text">Amount Receiving</h6>
              <h5>£{transactionData.currency.to.amount.toFixed(2)}</h5>
            </div>

            <div>
              <h6 className="info-text">Sender Name</h6>
              <h5 className="data-text">{transactionData.sender.name}</h5>

              <h6 className="info-text">Bank Name of Sender</h6>
              <h5 className="data-text">{transactionData.sender.bank_name}</h5>

              <h6 className="info-text">Date Created</h6>
              <h5>{transactionDate(new Date(transactionData.createdAt))}</h5>
            </div>
          </div>
        </div>

        {transactionData.swift_copy ? (
          <div className="d-flex justify-content-center mb-3 mt-2 btn-wrapper">
            <a
              href={`${
                process.env.REACT_APP_API_URL
              }/doc/${transactionData.swift_copy?.location.split('/').pop()}`}
              target="_blank"
              style={{ textDecoration: 'none' }}
              rel="noreferrer">
              <button className="p-4 login-btn next-btn d-flex justify-content-center align-items-center">
                Download Swift Copy
              </button>
            </a>
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default StatusModal;
