import React, { useEffect, useState } from 'react';
import TravelIco from '../../../Assets/Images/travel-and-tourism.svg';
import './order-status.scss';
import { Link } from 'react-router-dom';
import MultiProgressBar from '../../../Common/Pages/multiProgressBar/multi-progress-bar';
import AllAnswers from '../all-answers/all-answers';
import Header from '../../../Common/Pages/header/header';
import NavBar from '../../../Common/Pages/NavBar/nav-bar';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Giffgaff from '../../../Assets/Images/giffgaff-sim.svg';
import LebaraSim from '../../../Assets/Images/lebara-sim.svg';
import VoxiSim from '../../../Assets/Images/voxi-sim.svg';
import ScooterIco from '../../../Assets/Images/scooter.svg';
import SimIco from '../../../Assets/Images/sim-ico.svg';
import processTimeIco from '../../../Assets/Images/processing-time.svg';
import moment from 'moment';

const OrderStatusComponent = () => {
  const [orderStatus, setOrderStatus] = useState('pending');
  const [shippingAddress, setShippingAddress] = useState('');
  const [paymentStatus, setPaymentStatus] = useState();
  const [deliveryType, setDeliveryType] = useState('');
  const [providerName, setProviderName] = useState('');
  const [orderDate, setOrderDate] = useState('');
  const [orderId, setOrderId] = useState();
  const [receiptUrl, setReceiptUrl] = useState();
  const maxDate = new Date();
  const sim = useSelector((state) => state.sim);
  const orderStatusId = useSelector(
    (state) => state.sim?.clientSecretId?.data?._id,
  );
  const user = useSelector((state) => state.user);
  const token = user.user?.tokens?.access;
  maxDate.setFullYear(maxDate.getFullYear() - 6);
  let axiosToken = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token,
    },
  };
  useEffect(() => {
    (async () => {
      try {
        const orderStatus = await axios.get(
          `${process.env.REACT_APP_API_URL}/sim/order/${orderStatusId}`,
          axiosToken,
        );
        setShippingAddress(
          `${orderStatus.data.data.user_address.city}, ${orderStatus.data.data.user_address.country}`,
        );
        setProviderName(orderStatus?.data.data?.sim_provider?.provider_name);
        setDeliveryType(orderStatus?.data.data?.delivery_type);
        setPaymentStatus(orderStatus?.data?.data?.payment?.amount_charged);
        setOrderStatus(orderStatus.data.data.status);
        setOrderId(orderStatus.data.data._id);
        const orderedDate = orderStatus.data.data.createdAt;
        const formatChange = moment(orderedDate).format('DD-MMM-YYYY');
        setOrderDate(formatChange);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const handleInvoice = async (id) => {
    try {
      const getAllOrder = await axios.get(
        `${process.env.REACT_APP_API_URL}/sim/order/${orderId}/receipt`,
        axiosToken,
      );
      setReceiptUrl(getAllOrder.data.receipt_url);
      window.open(getAllOrder.data.receipt_url, '_blank');
    } catch (err) {
      console.log('err: ', err);
    }
  };
  return (
    <div className="container-fluid">
      <Header />
      <div className="row">
        <div className="col-3 ps-0 sidebar-container">
          <NavBar />
        </div>
        <div
          id="order-status-wrapper"
          className="px-3 px-sm-5 col-12 col-sm-9 mx-auto right-wrapper">
          <h1 className="mb-3"> My Orders </h1>
          <div className="col-12 px-sm-5 px-2 pb-5 order-status-content">
            <div className="row inner-order-details pb-2 pt-4 mx-0 px-2">
              <div className="col-sm-12 col-12 ps-0 order-number-sec">
                <h2>
                  Order no. <strong>:4565756</strong>
                </h2>
              </div>
            </div>
            <div className="row inner-order-details pb-2 pt-4 mx-0">
              <div className="col-sm-4 col-12 ps-0 order-number-sec d-flex aling-items-center px-3 px-sm-0">
                <div className="pe-4">
                  <img
                    src={
                      providerName == 'Voxi'
                        ? VoxiSim
                        : providerName == 'Lebara'
                        ? LebaraSim
                        : Giffgaff
                    }
                    style={{ width: '60px' }}
                    alt="sim-img"
                  />
                </div>
                <div>
                  <h2>{providerName} SIM</h2>
                  <h3>
                    <strong>
                      {deliveryType === 'standard_delivery'
                        ? 'Normal '
                        : 'Express '}
                      Delivery
                    </strong>
                    <br />
                    {sim.deliveryType == 'express_delivery' ? (
                      <p
                        style={{
                          fontStyle: 'italic',
                          fontSize: '12px',
                          fontWeight: '500',
                        }}>
                        {sim.expressDeliveryDate} - {sim.expressDeliveryEndDate}
                      </p>
                    ) : (
                      <p
                        style={{
                          fontStyle: 'italic',
                          fontSize: '12px',
                          fontWeight: '500',
                        }}>
                        {sim.freeDeliveryStartDate} - {sim.freeDeliveryEndDate}
                      </p>
                    )}
                  </h3>
                </div>
              </div>
              <div className="col-sm-8 col-12 ps-sm-0 pt-4 pt-sm-0 ps-sm-3 order-details d-flex align-items-center justify-content-sm-end justify-content-start">
                <ul className="ps-0 row w-100">
                  <li className="col-sm-4 col-6">
                    <h5> Payment Made </h5>
                    <h6>
                      ₹
                      {Math.round(
                        paymentStatus ? paymentStatus / 100 ?? '0.00' : 0,
                      ).toFixed(2)}
                    </h6>
                  </li>
                  <li className="col-sm-4 col-6">
                    <h5> Order Date </h5>
                    <h6> {orderDate} </h6>
                  </li>
                  <li className="col-sm-4 col-6 pt-sm-0 pt-4">
                    <h5> Shipping to </h5>
                    <h6> {shippingAddress} </h6>
                  </li>
                </ul>
              </div>
            </div>
            {paymentStatus && (
              <div className="btn-wrapper text-sm-end text-center mt-3">
                <button className="invoice-btn" onClick={() => handleInvoice()}>
                  VIEW INVOICE
                </button>
              </div>
            )}

            <div className="col-12 order-progress-bar ps-0 pt-4 d-flex align-items-center justify-content-center">
              <img
                src={
                  orderStatus == 'pending' || orderStatus == 'processing'
                    ? processTimeIco
                    : orderStatus == 'dispatched'
                    ? ScooterIco
                    : SimIco
                }
                alt="scooter-ico"
                style={{ width: '40px' }}
              />
              {orderStatus == 'pending' || orderStatus == 'processing' ? (
                <h2 className="mb-0 ms-2">We are processing your order</h2>
              ) : orderStatus == 'dispatched' ? (
                <h2 className="mb-0 ms-2"> Your order has been picked up </h2>
              ) : (
                <h2 className="mb-0 ms-2"> SIM has been delivered </h2>
              )}
            </div>
            <MultiProgressBar orderStatus={orderStatus} />
          </div>
          <AllAnswers />
        </div>
      </div>
    </div>
  );
};

export default OrderStatusComponent;
