import React, { useEffect, useState } from 'react';
import Calculator from '../../../Common/Pages/calculator/calculator';
import BankIco from '../../../Assets/Images/bank.svg';
import BankBlueIco from '../../../Assets/Images/bank-blue.svg';
import EduSchoolIco from '../../../Assets/Images/educational-school.svg';
import MoneyTransferIco from '../../../Assets/Images/money-transfer.svg';
import './bank-transfer.scss';
import Accordion from 'react-bootstrap/Accordion';
import FlagIco from '../../../Assets/Images/flag-ico.svg';
import UserIco from '../../../Assets/Images/user.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setTemp } from '../../../global/features/user';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function BankTransfer(props) {
  const [selectedReceipient, setSelectedReceipient] = useState();
  const payNowSelected = useSelector((state) => state.data.payNowSendMoney);
  const whoWantPay = useSelector((state) => state.user.whoWantPay);
  const bankDetails = useSelector((state) => state.data.bankDetails);
  const [key, setKey] = useState('home');
  const payNowId = useSelector((state) => state.data.payNowSendMoney);
  const finalAmount = useSelector((state) => state.calculator.from.amount);
  var today = new Date(),
    time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  const dispatch = useDispatch();
  const transaction_reference_number = useSelector(
    (state) => state.user.temp.transaction_reference_number,
  );
  const selectRecepient = (data) => {
    setSelectedReceipient(data);
  };
  const getRadioVal = (val) => {
    props.setSelectedOwn(val.target.value);
  };
  return (
    <>
      {props.transferDetails ? (
        <div id="bank-transfer-wrapper" className="row mt-4">
          <div className="col-sm-7 col-12 pe-sm-4 mb-4 mb-sm-0">
            {!payNowSelected || payNowSelected.length == 0 ? (
              <>
                {props.receipientPayVal === 'own' ? (
                  <div className="row h-100 mx-4 mx-sm-0">
                    <div className="col-12 h-100 own-accordion-wrap">
                      <div className="d-flex align-items-center pt-3 pb-4">
                        <img
                          src={EduSchoolIco}
                          alt="bank-ico"
                          className="pe-4"
                        />
                        <h2 className="mb-0"> Own Details</h2>
                      </div>
                      {props.listofReceipient.map((data) => {
                        // let str = data.account_number;
                        // let accountLastNumber = str.slice(str.length - 4);
                        // let accountNum = data.account_number;
                        // let account_number = accountNum.replace(
                        //   /.(?=.{4,}$)/g,
                        //   'X',
                        // );
                        return (
                          <>
                            <Accordion
                              defaultActiveKey={data._id}
                              className="mb-4">
                              <Accordion.Item eventKey="1" className="mb-3">
                                <div className="d-flex align-items-center">
                                  <div className="row w-100 p-3">
                                    <div className="col-sm-6 col-8 accordion-left-sec d-flex align-items-center">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault2"
                                        value={data._id}
                                        // checked={data._id}
                                        onChange={getRadioVal}
                                      />
                                      <img
                                        src={UserIco}
                                        alt="user-ico"
                                        className="userIco mx-3"
                                      />
                                      <div>
                                        <p className="mb-0">{data.type}</p>
                                        <h6>{data.account_holder_name}</h6>
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-4 accordion-right-sec d-flex align-items-center justify-content-end pe-4">
                                      <img
                                        src={FlagIco}
                                        alt="flag-ico"
                                        className="me-2"
                                      />
                                    </div>
                                  </div>
                                  <Accordion.Header></Accordion.Header>
                                </div>
                                <Accordion.Body className="p-1">
                                  <div
                                    className="accordion"
                                    id="accordionExample">
                                    <div className="accordion-item">
                                      <div className="accordion-body row">
                                        <div className="col-sm-4 col-12 mb-sm-4 mb-2">
                                          <h4>Full Name</h4>
                                          <h5>{data.account_holder_name}</h5>
                                        </div>
                                        <div className="col-sm-3 col-12 mb-sm-4 mb-2">
                                          <h4>Bank Name</h4>
                                          <h5>{data.bank_name}</h5>
                                        </div>
                                        <div className="col-sm-5 col-12 mb-sm-4 mb-2">
                                          <h4>Swift Code</h4>
                                          <h5>{data.swift_code}</h5>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                          <h4>
                                            {data.type === 'own'
                                              ? 'Your'
                                              : data.type}{' '}
                                            Address
                                          </h4>
                                          <h5>{data.bank_address}</h5>
                                        </div>
                                        <div className="col-sm-3 col-12">
                                          <h4>Type</h4>
                                          <h5>{data.type}</h5>
                                        </div>
                                        <div className="col-sm-5 col-12">
                                          <h4>IBAN Number</h4>
                                          <h5>{data.iban}</h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
            {/* University Details */}
            {payNowSelected ? (
              <>
                {props.receipientPayVal === 'university' ||
                payNowId.length != 0 ||
                props.receipientPayVal === 'rent' ? (
                  <div className="left-sec h-100 px-sm-5 px-sm-4">
                    {props.listofReceipient.map((data) => {
                      return (
                        <>
                          <div className="d-none d-sm-block">
                            <div className="d-flex align-items-center pt-5">
                              <img
                                src={EduSchoolIco}
                                alt="bank-ico"
                                className="pe-4"
                              />
                              <h2>{data.type} Details</h2>
                            </div>
                            <div className="row mt-5 mx-0 bank-details-wrapper">
                              <div className="col-sm-6 col-12 mb-4 ps-0">
                                <h6> Name of Recipient </h6>
                                <h5> {data.account_holder_name} </h5>
                              </div>
                              <div className="col-sm-6 col-12 ps-0">
                                <h6> Bank Name of Recipient </h6>
                                <h5> {data.bank_name} </h5>
                              </div>
                              {/* Refactor */}
                              {data.account_number ? (
                                <div className="col-sm-6 col-12 mb-4 ps-0">
                                  <h6> Account Number of Recipient </h6>
                                  <h5> {data.account_number} </h5>
                                </div>
                              ) : (
                                ''
                              )}
                              <div className="col-sm-6 col-12 ps-0 mb-4">
                                <h6 style={{ textTransform: 'capitalize' }}>
                                  {' '}
                                  {data.type} Address{' '}
                                </h6>
                                <h5> {data.bank_address} </h5>
                              </div>
                              {data.sort_code ? (
                                <div className="col-sm-6 col-12 ps-0 mb-4">
                                  <h6> SORT Code </h6>
                                  <h5> {data.sort_code} </h5>
                                </div>
                              ) : (
                                ''
                              )}
                              <div className="col-sm-6 col-12 ps-0 mb-4">
                                <h6> IBAN </h6>
                                <h5> {data.iban} </h5>
                              </div>
                              <div className="col-sm-6 col-12 ps-0">
                                <h6> SWIFT Code </h6>
                                <h5> {data.swift_code} </h5>
                              </div>
                              <div className="col-12 ps-0 mt-4">
                                <div className="form-floating mb-4">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    value={transaction_reference_number}
                                    onChange={(e) =>
                                      dispatch(
                                        setTemp({
                                          key: 'transaction_reference_number',
                                          value: e.target.value,
                                        }),
                                      )
                                    }
                                    placeholder="Transaction Reference Number"
                                  />
                                  <label htmlFor="floatingInput">
                                    Transaction Reference Number
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mobile-tabs-ui d-sm-none">
                            <Tabs
                              id="controlled-tab-example"
                              activeKey={key}
                              onSelect={(k) => setKey(k)}
                              className="mb-sm-3 mb-2 ps-sm-3 ps-1">
                              <Tab eventKey="home" title="BANK BENEFICIARY">
                                <div
                                  className="m-4"
                                  style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                  }}>
                                  <div className="d-flex align-items-center pt-5 p-4">
                                    <img
                                      src={EduSchoolIco}
                                      alt="bank-ico"
                                      className="pe-4"
                                    />
                                    <h2>{data.type} Details</h2>
                                  </div>
                                  <div className="row mt-5 mx-0 bank-details-wrapper px-4">
                                    <div className="col-sm-6 col-12 mb-4 ps-0">
                                      <h6> Name of Recipient </h6>
                                      <h5> {data.account_holder_name} </h5>
                                    </div>
                                    <div className="col-sm-6 col-12 ps-0">
                                      <h6> Bank Name of Recipient </h6>
                                      <h5> {data.bank_name} </h5>
                                    </div>
                                    {/* Refactor */}
                                    {data.account_number ? (
                                      <div className="col-sm-6 col-12 mb-4 ps-0">
                                        <h6> Account Number of Recipient </h6>
                                        <h5> {data.account_number} </h5>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <div className="col-sm-6 col-12 ps-0 mb-4">
                                      <h6
                                        style={{ textTransform: 'capitalize' }}>
                                        {' '}
                                        {data.type} Address{' '}
                                      </h6>
                                      <h5> {data.bank_address} </h5>
                                    </div>
                                    {data.sort_code ? (
                                      <div className="col-sm-6 col-12 ps-0 mb-4">
                                        <h6> SORT Code </h6>
                                        <h5> {data.sort_code} </h5>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <div className="col-sm-6 col-12 ps-0 mb-4">
                                      <h6> IBAN </h6>
                                      <h5> {data.iban} </h5>
                                    </div>
                                    <div className="col-sm-6 col-12 ps-0">
                                      <h6> SWIFT Code </h6>
                                      <h5> {data.swift_code} </h5>
                                    </div>
                                    <div className="col-12 ps-0 mt-4">
                                      <div className="form-floating mb-4">
                                        <input
                                          type="email"
                                          className="form-control"
                                          id="floatingInput"
                                          value={transaction_reference_number}
                                          onChange={(e) =>
                                            dispatch(
                                              setTemp({
                                                key: 'transaction_reference_number',
                                                value: e.target.value,
                                              }),
                                            )
                                          }
                                          placeholder="Transaction Reference Number"
                                        />
                                        <label htmlFor="floatingInput">
                                          Transaction Reference Number
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey="profile" title="MONEY TRANSFER">
                                <div className="p-4">
                                  <Calculator />
                                </div>
                              </Tab>
                            </Tabs>
                          </div>
                        </>
                      );
                    })}{' '}
                    {/* <div className="h-75 d-flex align-items-center justify-content-center">
                      //{' '}
                      <div className="text-center">
                        // <h4> University Details Not availabe! </h4>
                        // <Link to="/receipients"> Please Add </Link>
                        //{' '}
                      </div>
                      //{' '}
                    </div> */}
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                {props.receipientPayVal === 'university' ||
                props.receipientPayVal === 'rent' ? (
                  <div className="left-sec add-bank-details h-100 px-sm-5 px-sm-3">
                    {props.listofReceipient.length != 0 ? (
                      <>
                        {props.listofReceipient.map((data) => {
                          return (
                            <>
                              <div className="d-none d-sm-block">
                                <div className="d-flex align-items-center pt-5">
                                  <img
                                    src={EduSchoolIco}
                                    alt="bank-ico"
                                    className="pe-4"
                                  />
                                  <h2>{data.type} Details</h2>
                                </div>
                                <div className="row mt-5 mx-0 bank-details-wrapper">
                                  <div className="col-sm-6 col-12 mb-4 ps-0">
                                    <h6> Name of Recipient </h6>
                                    <h5> {data.account_holder_name} </h5>
                                  </div>
                                  <div className="col-sm-6 col-12 ps-0">
                                    <h6> Bank Name of Recipient </h6>
                                    <h5> {data.bank_name} </h5>
                                  </div>
                                  {data.account_number ? (
                                    <div className="col-sm-6 col-12 mb-4 ps-0">
                                      <h6> Account Number of Recipient </h6>
                                      <h5> {data.account_number} </h5>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div className="col-sm-6 col-12 ps-0 mb-4">
                                    <h6 style={{ textTransform: 'capitalize' }}>
                                      {' '}
                                      {data.type} Address{' '}
                                    </h6>
                                    <h5> {data.bank_address} </h5>
                                  </div>
                                  {data.sort_code ? (
                                    <div className="col-sm-6 col-12 ps-0 mb-4">
                                      <h6> SORT Code </h6>
                                      <h5> {data.sort_code} </h5>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div className="col-sm-6 col-12 ps-0 mb-4">
                                    <h6> IBAN </h6>
                                    <h5> {data.iban} </h5>
                                  </div>
                                  <div className="col-sm-6 col-12 ps-0">
                                    <h6> SWIFT Code </h6>
                                    <h5> {data.swift_code} </h5>
                                  </div>
                                  <div className="col-12 ps-0 mt-4">
                                    <div className="form-floating mb-4">
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Transaction Reference Number"
                                      />
                                      <label htmlFor="floatingInput">
                                        Transaction Reference Number
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mobile-tabs-ui d-sm-none">
                                <Tabs
                                  id="controlled-tab-example"
                                  activeKey={key}
                                  onSelect={(k) => setKey(k)}
                                  className="mb-3 ps-3">
                                  <Tab
                                    eventKey="home"
                                    title={`${data.type} Details`}>
                                    <div
                                      className="mx-4 my-4"
                                      style={{
                                        border: '1px solid #ccc',
                                        borderRadius: '10px',
                                      }}>
                                      <div className="d-flex align-items-center pt-5 px-3">
                                        <img
                                          src={EduSchoolIco}
                                          alt="bank-ico"
                                          className="pe-4"
                                        />
                                        <h2>{data.type} Details</h2>
                                      </div>
                                      <div className="row mt-5 mx-0 bank-details-wrapper px-3">
                                        <div className="col-sm-6 col-12 mb-4 ps-0">
                                          <h6> Name of Recipient </h6>
                                          <h5> {data.account_holder_name} </h5>
                                        </div>
                                        <div className="col-sm-6 col-12 ps-0">
                                          <h6> Bank Name of Recipient </h6>
                                          <h5> {data.bank_name} </h5>
                                        </div>
                                        {data.account_number ? (
                                          <div className="col-sm-6 col-12 mb-4 ps-0">
                                            <h6>
                                              {' '}
                                              Account Number of Recipient{' '}
                                            </h6>
                                            <h5> {data.account_number} </h5>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                        <div className="col-sm-6 col-12 ps-0 mb-4">
                                          <h6
                                            style={{
                                              textTransform: 'capitalize',
                                            }}>
                                            {' '}
                                            {data.type} Address{' '}
                                          </h6>
                                          <h5> {data.bank_address} </h5>
                                        </div>
                                        {data.sort_code ? (
                                          <div className="col-sm-6 col-12 ps-0 mb-4">
                                            <h6> SORT Code </h6>
                                            <h5> {data.sort_code} </h5>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                        <div className="col-sm-6 col-12 ps-0 mb-4">
                                          <h6> IBAN </h6>
                                          <h5> {data.iban} </h5>
                                        </div>
                                        <div className="col-sm-6 col-12 ps-0">
                                          <h6> SWIFT Code </h6>
                                          <h5> {data.swift_code} </h5>
                                        </div>
                                        <div className="col-12 ps-0 mt-4">
                                          <div className="form-floating mb-4">
                                            <input
                                              type="email"
                                              className="form-control"
                                              id="floatingInput"
                                              placeholder="Transaction Reference Number"
                                            />
                                            <label htmlFor="floatingInput">
                                              Transaction Reference Number
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab>
                                  <Tab
                                    eventKey="profile"
                                    title="MONEY TRANSFER">
                                    <div className="p-4">
                                      <Calculator />
                                    </div>
                                  </Tab>
                                </Tabs>
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <div className="h-75 d-flex align-items-center justify-content-center">
                        <div className="text-center">
                          <h4> University Details Not availabe! </h4>
                          <Link to="/receipients"> Please Add </Link>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
          <div className="col-sm-5 col-12 d-sm-block d-none">
            <Calculator />
          </div>
        </div>
      ) : props.confirmation ? (
        <div id="bank-transfer-wrapper" className="row mt-4">
          <div className="col-sm-7 col-12 pe-sm-4 mb-4 mb-sm-0 d-none d-sm-block">
            <div className="left-sec h-100 px-sm-5 px-3">
              {props.listofReceipient.map((data) => {
                if (props.selectedOwn === data._id) {
                  return (
                    <div
                      className="row mt-5 mx-0 bank-details-wrapper"
                      key={data._id}>
                      <div className="col-12 col-sm-7 ps-sm-0">
                        <div className="col-12 ps-0 mb-4">
                          <h6> Name of Receipient </h6>
                          <h5> {data.account_holder_name} </h5>
                        </div>
                        <div className="col-12 ps-0 mb-4">
                          <h6> Bank Name of Receipient </h6>
                          <h5> {data.bank_name} </h5>
                        </div>
                        <div className="col-12 ps-0 mb-4">
                          <h6> IBAN </h6>
                          <h5> {data.iban} </h5>
                        </div>
                        <div className="col-12 ps-0 mb-4">
                          <h6> Amount Transferring </h6>
                          <h5> {finalAmount} </h5>
                        </div>
                        <div className="col-12 ps-0">
                          <h6> Time Taken to Transfer </h6>
                          <h5> 1 working day </h5>
                        </div>
                      </div>
                      <div className="col-5 d-none d-sm-block">
                        <img
                          src={MoneyTransferIco}
                          alt="money-transfer-ico"
                          className="money-img"
                        />
                      </div>
                    </div>
                  );
                }
              })}
              <div className="row mt-4">
                <div className="col-12 d-flex align-items-start transfer-bottom-para">
                  <h6 className="w-50 pt-1">
                    {props.timeVal ? (
                      <>
                        <strong>Rate is guaranteed</strong>
                        <br /> for 1hr
                      </>
                    ) : (
                      <strong className="pt-2">
                        Rate will be booked the next working day
                      </strong>
                    )}
                  </h6>
                  <p className="ps-4">
                    {props.timeVal
                      ? 'Money should arrive ideally in 24 hours (if transfer is completed before 2pm IST) and maximum in 2 working days.'
                      : 'We will book the rate for you and send you information on your WhatsApp. Money will be credited 24-72 hours from then.'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-5 col-12 d-none d-sm-block">
            <Calculator />
          </div>

          <div className="mobile-tabs-ui d-sm-none">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3 ps-3">
              <Tab eventKey="home" title="DETAILS CONFIRMATION">
                <div className="left-sec h-100 px-sm-5 px-3 mx-4">
                  {props.listofReceipient.map((data) => {
                    if (props.selectedOwn === data._id) {
                      return (
                        <div
                          className="row mt-5 mx-0 bank-details-wrapper"
                          key={data._id}>
                          <div className="col-12 col-sm-7 ps-sm-0">
                            <div className="col-12 ps-0 mb-4">
                              <h6> Name of Receipient </h6>
                              <h5> {data.account_holder_name} </h5>
                            </div>
                            <div className="col-12 ps-0 mb-4">
                              <h6> Bank Name of Receipient </h6>
                              <h5> {data.bank_name} </h5>
                            </div>
                            <div className="col-12 ps-0 mb-4">
                              <h6> IBAN </h6>
                              <h5> {data.iban} </h5>
                            </div>
                            <div className="col-12 ps-0 mb-4">
                              <h6> Amount Transferring </h6>
                              <h5> {finalAmount} </h5>
                            </div>
                            <div className="col-12 ps-0">
                              <h6> Time Taken to Transfer </h6>
                              <h5> 1 working day </h5>
                            </div>
                          </div>
                          <div className="col-5 d-none d-sm-block">
                            <img
                              src={MoneyTransferIco}
                              alt="money-transfer-ico"
                              className="money-img"
                            />
                          </div>
                        </div>
                      );
                    }
                  })}
                  <div className="row mt-4">
                    <div className="col-12 d-flex align-items-start transfer-bottom-para">
                      <h6 className="w-50">
                        {' '}
                        <strong>Rate is guaranteed</strong>
                        <br /> for 1hr{' '}
                      </h6>
                      <p className="ps-4">
                        Money should arrive ideally in 24 hours (if transfer is
                        completed before 2pm IST) and maximum in 2 working days.
                      </p>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile" title="MONEY TRANSFER">
                <div className="p-4">
                  <Calculator />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      ) : (
        <div id="bank-transfer-wrapper" className="row mt-4">
          <div className="mobile-tabs-ui d-sm-none">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3 ps-3">
              <Tab eventKey="home" title="BANK BENEFICIARY">
                <div className="col-sm-7 col-12 pe-sm-4 mb-4 mb-sm-0 p-4">
                  <div className="left-sec h-100 px-sm-5 px-3">
                    <div className="d-flex align-items-center pt-5 justify-content-start">
                      {bankDetails != null && (
                        <>
                          <img
                            src={BankBlueIco}
                            alt="bank-ico"
                            className="pe-4"
                          />
                          <h2>
                            Please add our bank details
                            <br /> as beneficiary for transfer
                          </h2>
                        </>
                      )}
                    </div>
                    {bankDetails != null ? (
                      <div className="row mt-5 mx-0 bank-details-wrapper">
                        <div className="col-5 ps-sm-0 mb-3">
                          <h6 className="mb-0 mb-sm-1"> Bank Name </h6>
                          <h5> {bankDetails.bank_name} </h5>
                        </div>
                        <div className="col-7 ps-sm-0 mb-3">
                          <h6 className="mb-0 mb-sm-1"> Account Name </h6>
                          <h5> {bankDetails.account_name} </h5>
                        </div>
                        <div className="col-5 mb-sm-4 mb-2 ps-sm-0">
                          <h6 className="mb-0 mb-sm-1"> Bank Branch </h6>
                          <h5> {bankDetails.branch} </h5>
                        </div>
                        <div className="col-7 ps-0">
                          <h6 className="mb-0 mb-sm-1"> Account Number </h6>
                          <h5> {bankDetails.account_number} </h5>
                        </div>
                        <div className="col-12 mt-3 mt-sm-0 ps-sm-0">
                          <h6 className="mb-0 mb-sm-1"> IFSC Code </h6>
                          <h5> {bankDetails.ifsc_code} </h5>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center">
                        <h2 className="mb-3">
                          Please contact customer care for bank detail.
                        </h2>
                        <a
                          href="https://wa.me/message/KVW64HQKH7SID1"
                          target="_blank"
                          className="btn-redirect"
                          rel="noreferrer">
                          Click Here
                        </a>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12 left-footer-wrapper">
                        <h6> Notes </h6>
                        <ul>
                          <li>
                            In certain online banking platform it can take upto
                            24 hours before a new beneficiary is added.
                          </li>
                          <li>
                            Our banking partner is regulated by{' '}
                            <a
                              href="https://m.rbi.org.in/scripts/bs_viewcontent.aspx?Id=3657"
                              target="_blank"
                              rel="noreferrer">
                              RBI.
                            </a>{' '}
                            Check their reviews. Your money is safe with us!
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile" title="MONEY TRANSFER">
                <div className="p-4">
                  <Calculator />
                </div>
              </Tab>
            </Tabs>
          </div>
          <div className="d-none d-sm-flex">
            <div className="col-sm-7 col-12 pe-sm-4 mb-4 mb-sm-0">
              <div className="left-sec h-100 px-sm-5 px-3">
                <div className="d-flex align-items-center pt-5 justify-content-start">
                  {bankDetails != null && (
                    <>
                      <img src={BankBlueIco} alt="bank-ico" className="pe-4" />
                      <h2>
                        Please add our bank details
                        <br /> as beneficiary for transfer
                      </h2>
                    </>
                  )}
                </div>
                {bankDetails != null ? (
                  <div className="row mt-5 mx-0 bank-details-wrapper">
                    <div className="col-5 ps-sm-0 mb-3">
                      <h6 className="mb-0 mb-sm-1"> Bank Name </h6>
                      <h5> {bankDetails.bank_name} </h5>
                    </div>
                    <div className="col-7 ps-sm-0 mb-3">
                      <h6 className="mb-0 mb-sm-1"> Account Name </h6>
                      <h5> {bankDetails.account_name} </h5>
                    </div>
                    <div className="col-5 mb-sm-4 mb-2 ps-sm-0">
                      <h6 className="mb-0 mb-sm-1"> Bank Branch </h6>
                      <h5> {bankDetails.branch} </h5>
                    </div>
                    <div className="col-7 ps-0">
                      <h6 className="mb-0 mb-sm-1"> Account Number </h6>
                      <h5> {bankDetails.account_number} </h5>
                    </div>
                    <div className="col-12 mt-3 mt-sm-0 ps-sm-0">
                      <h6 className="mb-0 mb-sm-1"> IFSC Code </h6>
                      <h5> {bankDetails.ifsc_code} </h5>
                    </div>
                  </div>
                ) : (
                  <div className="text-center">
                    <h2 className="mb-3">
                      Please contact customer care for bank detail.
                    </h2>
                    <a
                      href="https://wa.me/message/KVW64HQKH7SID1"
                      target="_blank"
                      className="btn-redirect"
                      rel="noreferrer">
                      Click Here
                    </a>
                  </div>
                )}
                <div className="row">
                  <div className="col-12 left-footer-wrapper">
                    <h6> Notes </h6>
                    <ul>
                      <li>
                        In certain online banking platform it can take upto 24
                        hours before a new beneficiary is added.
                      </li>
                      <li>
                        Our banking partner is regulated by{' '}
                        <a
                          href="https://m.rbi.org.in/scripts/bs_viewcontent.aspx?Id=3657"
                          target="_blank"
                          rel="noreferrer">
                          RBI.
                        </a>{' '}
                        Check their reviews. Your money is safe with us!
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-5 col-12">
              <Calculator />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BankTransfer;
