import React, { useState } from 'react';
import './signup-form.scss';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
interface SignupFormProps {
  values: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    phone: string;
    birthDate: string;
    country: string;
    address: string;
    city: string;
    state: string;
    zip: string;
  };
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  handleBlur: (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  handlePhoneNumber: (e: string) => void;
  handleZipBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  touched: { [field: string]: boolean };
  errors: { [field: string]: string };
}

const maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() - 6);

function SignupForm({
  values,
  handleChange,
  handleBlur,
  handleZipBlur,
  handlePhoneNumber,
  touched,
  errors,
}: SignupFormProps) {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center pt-4">
          <div className="col-11 col-sm-6 text-center">
            <h1 className="pb-4 page-heading mb-2"> Account Information </h1>
            <div className="row">
              <h3 className="text-start mb-3 text-mandatory-field">
                {' '}
                Note: All fields are{' '}
                <strong className="text-danger">MANDATORY</strong>{' '}
              </h3>
              <div className="col-12 col-sm-6">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="First Name"
                  />
                  <label htmlFor="firstName">
                    First Name{' '}
                    <span>
                      (as per passport)<span style={{ color: 'red' }}> *</span>
                    </span>
                  </label>
                  {touched.firstName && errors.firstName ? (
                    <p className="error-message">{errors.firstName}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Last Name"
                  />
                  <label htmlFor="lastName">
                    Last Name{' '}
                    <span>
                      (as per passport)<span style={{ color: 'red' }}> *</span>
                    </span>
                  </label>
                  {touched.lastName && errors.lastName ? (
                    <p className="error-message">{errors.lastName}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating mb-4">
                  <input
                    className="form-control"
                    id="email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Email Address"
                  />
                  <label htmlFor="email">
                    Enter Email Address<span style={{ color: 'red' }}> *</span>
                  </label>
                  {touched.email && errors.email ? (
                    <p className="error-message">{errors.email}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Password"
                  />
                  <label htmlFor="password">
                    Password<span style={{ color: 'red' }}> *</span>
                  </label>
                  {touched.password && errors.password ? (
                    <p className="error-message">{errors.password}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Retype Password"
                  />
                  <label htmlFor="confirmPassword">
                    Retype Password<span style={{ color: 'red' }}> *</span>
                  </label>
                  {touched.confirmPassword && errors.confirmPassword ? (
                    <p className="error-message">{errors.confirmPassword}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <PhoneInput
                  international
                  id="phone"
                  name="phone"
                  defaultCountry="IN"
                  countryCallingCodeEditable={false}
                  value={values.phone}
                  placeholder="Phone Number *"
                  onChange={handlePhoneNumber}
                  onBlur={handleBlur}
                />
                <div className="form-floating mb-4">
                  {/* <input
                    className="form-control"
                    id="phone"
                    name="phone"
                    type="text"
                    value={values.phone}
                    placeholder="Phone Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  /> */}
                  {/* <label htmlFor="phone">Enter Phone Number</label> */}
                  {touched.phone && errors.phone ? (
                    <p className="error-message">{errors.phone}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating mb-4">
                  <input
                    className="form-control"
                    id="birthDate"
                    name="birthDate"
                    type="date"
                    value={values.birthDate}
                    max={maxDate.toISOString().split('T')[0]}
                    placeholder="Date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="birthDate">
                    Date of Birth <span style={{ color: 'red' }}>*</span>
                  </label>
                  {touched.birthDate && errors.birthDate ? (
                    <p className="error-message">{errors.birthDate}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-6">
                <div className="form-floating">
                  <select
                    className="form-select"
                    id="country"
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                    aria-label="Floating label select example">
                    <option value="" selected disabled hidden>
                      Select Country
                    </option>
                    <option value="India">India</option>
                  </select>
                  <label htmlFor="country">
                    Country <span style={{ color: 'red' }}>*</span>
                  </label>
                  {touched.country && errors.country ? (
                    <p className="error-message">{errors.country}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-6">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    name="zip"
                    value={values.zip}
                    onChange={handleChange}
                    onBlur={handleZipBlur}
                    placeholder="Zip Code"
                  />
                  <label htmlFor="zip">
                    Zip Code <span style={{ color: 'red' }}>*</span>
                  </label>
                  {touched.zip && errors.zip ? (
                    <p className="error-message">{errors.zip}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-6">
                <div className="form-floating">
                  <input
                    className="form-control"
                    type="text"
                    id="state"
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="State"
                  />
                  <label htmlFor="state">
                    State <span style={{ color: 'red' }}>*</span>
                  </label>
                  {touched.state && errors.state ? (
                    <p className="error-message">{errors.state}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-6">
                <div className="form-floating">
                  <input
                    className="form-control"
                    type="text"
                    id="city"
                    name="city"
                    placeholder="City"
                    value={values.city}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <label htmlFor="city">
                    City <span style={{ color: 'red' }}>*</span>
                  </label>
                  {touched.city && errors.city ? (
                    <p className="error-message">{errors.city}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="form-floating mb-4">
                  <input
                    className="form-control"
                    id="address"
                    name="address"
                    type="text"
                    value={values.address}
                    placeholder="Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="birthDate">
                    Enter Address <span style={{ color: 'red' }}>*</span>
                  </label>
                  {touched.address && errors.address ? (
                    <p className="error-message">{errors.address}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignupForm;
