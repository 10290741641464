/* eslint-disable no-redeclare */
import React, { useState, useEffect } from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const MultiProgressBar = (props) => {
  const [stepsActive, setStepsActive] = useState('step1');
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  if (props.accountVerification) {
    var stepPercentage = 0;
    if (props.currentStep === 1) {
      stepPercentage = 0;
    } else if (props.currentStep === 2) {
      stepPercentage = 50;
    } else if (props.currentStep === 3) {
      stepPercentage = 100;
    } else {
      stepPercentage = 0;
    }
  } else if (props.sendMoneyBar) {
    var stepPercentage = 0;
    if (props.currentStep === 1) {
      stepPercentage = 0;
    } else if (props.currentStep === 2) {
      stepPercentage = 20;
    } else if (props.currentStep === 3) {
      stepPercentage = 40;
    } else if (props.currentStep === 4) {
      stepPercentage = 60;
    } else if (props.currentStep === 5) {
      stepPercentage = 80;
    } else if (props.currentStep === 6) {
      stepPercentage = 100;
    } else {
      stepPercentage = 0;
    }
  } else if (props.orderStatus) {
    var stepPercentage = 0;
    if (props.orderStatus == 'pending' || props.orderStatus == 'processing') {
      stepPercentage = 0;
    } else if (props.orderStatus === 'dispatched') {
      stepPercentage = 50;
    } else if (props.orderStatus === 'delivered') {
      stepPercentage = 100;
    } else {
      stepPercentage = 0;
    }
  } else {
    var stepPercentage = 0;
    if (props.currentStep === 1) {
      stepPercentage = 0;
    } else if (props.currentStep === 2) {
      stepPercentage = 34;
    } else if (props.currentStep === 3) {
      stepPercentage = 67;
    } else if (props.currentStep === 4) {
      stepPercentage = 100;
    } else {
      stepPercentage = 0;
    }
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);
  return (
    <>
      <div className="container py-sm-5 pt-3 pb-5">
        <div className="col-10 col-sm-8 mx-auto">
          {props.accountVerification ? (
            <ProgressBar percent={stepPercentage}>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    {props.currentStep === 1 &&
                      windowDimensions.width < 576 && (
                        <h5 className="progressBarText"> SENDER </h5>
                      )}
                    <h5 className="progressBarText d-none d-sm-block">
                      {' '}
                      SENDER{' '}
                    </h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    {props.currentStep === 2 &&
                      windowDimensions.width < 576 && (
                        <h5 className="progressBarText"> UPLOAD DOC </h5>
                      )}
                    <h5 className="progressBarText d-none d-sm-block">
                      {' '}
                      UPLOAD DOC{' '}
                    </h5>
                  </div>
                )}
              </Step>
              {/* <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    <h5 className="progressBarText"> CONFIRMATION </h5>
                  </div>
                )}
              </Step> */}
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    {props.currentStep === 3 &&
                      windowDimensions.width < 576 && (
                        <h5 className="progressBarText"> COMPLETE </h5>
                      )}
                    <h5 className="progressBarText d-none d-sm-block">
                      {' '}
                      COMPLETE{' '}
                    </h5>
                  </div>
                )}
              </Step>
            </ProgressBar>
          ) : props.sendMoneyBar ? (
            <ProgressBar percent={stepPercentage}>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    {props.currentStep === 1 &&
                      windowDimensions.width < 576 && (
                        <h5 className="progressBarText"> RECEIPIENT </h5>
                      )}
                    <h5 className="progressBarText d-none d-sm-block">
                      {' '}
                      RECEIPIENT{' '}
                    </h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    {props.currentStep === 2 &&
                      windowDimensions.width < 576 && (
                        <h5 className="progressBarText"> SENDER </h5>
                      )}
                    <h5 className="progressBarText d-none d-sm-block">
                      {' '}
                      SENDER{' '}
                    </h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    {props.currentStep === 3 &&
                      windowDimensions.width < 576 && (
                        <h5 className="progressBarText"> BANK DETAILS </h5>
                      )}
                    <h5 className="progressBarText d-none d-sm-block">
                      {' '}
                      BANK DETAILS{' '}
                    </h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    {props.currentStep === 4 &&
                      windowDimensions.width < 576 && (
                        <h5 className="progressBarText"> TRANSFER DETAILS </h5>
                      )}
                    <h5 className="progressBarText d-none d-sm-block">
                      {' '}
                      TRANSFER DETAILS{' '}
                    </h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    {props.currentStep === 5 &&
                      windowDimensions.width < 576 && (
                        <h5 className="progressBarText"> CONFIRMATION </h5>
                      )}
                    <h5 className="progressBarText d-none d-sm-block">
                      {' '}
                      CONFIRMATION{' '}
                    </h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    {props.currentStep === 6 &&
                      windowDimensions.width < 576 && (
                        <h5 className="progressBarText"> COMPLETE </h5>
                      )}
                    <h5 className="progressBarText d-none d-sm-block">
                      {' '}
                      COMPLETE{' '}
                    </h5>
                  </div>
                )}
              </Step>
            </ProgressBar>
          ) : props.orderStatus ? (
            <ProgressBar percent={stepPercentage}>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    {(props.orderStatus == 'pending' ||
                      props.orderStatus == 'processing') && (
                      <h5 className="progressBarText"> ORDER PLACED </h5>
                    )}
                    <h5 className="progressBarText d-none d-sm-block">
                      {' '}
                      ORDER PLACED{' '}
                    </h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    {props.orderStatus == 'dispatched' && (
                      <h5 className="progressBarText"> PICKED FOR DELIVERY </h5>
                    )}
                    <h5 className="progressBarText d-none d-sm-block">
                      PICKED FOR DELIVERY
                    </h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    {props.orderStatus == 'delivered' && (
                      <h5 className="progressBarText"> DELIVERED </h5>
                    )}
                    <h5 className="progressBarText d-none d-sm-block">
                      DELIVERED
                    </h5>
                  </div>
                )}
              </Step>
            </ProgressBar>
          ) : (
            <ProgressBar percent={stepPercentage}>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    <h5 className="progressBarText"> ELIGIBILITY </h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    <h5 className="progressBarText"> ACCOUNT INFO </h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    <h5 className="progressBarText"> OTP </h5>
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    <h5 className="progressBarText"> COMPLETE </h5>
                  </div>
                )}
              </Step>
            </ProgressBar>
          )}
        </div>
      </div>
    </>
  );
};

export default MultiProgressBar;
