import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import isIBan from 'validator/lib/isIBAN';
import isBIC from 'validator/lib/isBIC';
import RemoveIco from '.././../../Assets/Images/remove.png';
import axios from 'axios';
import UniversityIco from '../../../Assets/Images/school.svg';
import RentIco from '../../../Assets/Images/house.svg';
import own from '../../../Assets/Images/bank-ico.svg';
import { useDispatch, useSelector } from 'react-redux';
import './add-receipients-form.scss';
import { useFormik } from 'formik';

const receipientsFormValidate = (values) => {
  const errors = {};

  if (values.name?.trim().length < 1) {
    errors.name = 'Please enter name';
  }
  // Refactor
  if (values.beneficiary?.trim().length < 3) {
    errors.beneficiary = 'Please enter address';
  }
  if (values.bankName?.trim().length < 2) {
    errors.bankName = 'Please enter bank name';
  }
  // if (values.accountNumber?.trim().length < 3) {
  //   errors.accountNumber = 'Please enter account number';
  // }

  if (!isIBan(values.iban)) {
    errors.iban = 'Please enter valid IBAN';
  }

  if (!isBIC(values?.swift_code)) {
    errors.swift_code = 'Please enter a valid swift code';
  }

  // if (
  //   !/^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/.test(values.sortCode)
  // ) {
  //   errors.sortCode = 'Please enter valid sort code';
  // }

  return errors;
};

function addReceipientsForm(props) {
  const [selectedVal, setSelected] = useState('');
  const [receipentTypeError, setReceipentTypeError] = useState(false);
  const user = useSelector((state) => state.user);
  const token = user.user?.tokens?.access;
  const handleClose = () => props.setShow(false);
  const fetchRecipients = useSelector(
    (state) => state.data.recipients.data.count,
  );
  const form = useFormik({
    initialValues: {
      name: '',
      beneficiary: '',
      bankName: '',
      accountNumber: '',
      sortCode: '',
      swift_code: '',
      iban: '',
    },
    validate: receipientsFormValidate,
    onSubmit: async (values, { setErrors }) => {
      if (props.editData?._id) {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/recipient/${props.receipientId}`,
            {
              type: selectedVal,
              account_holder_name: values.name,
              bank_name: values.bankName,
              account_number: values.accountNumber,
              iban: values.iban,
              sort_code: values.sortCode,
              bank_address: values.beneficiary,
              swift_code: values.swift_code,
            },
            axiosToken,
          )
          .then((res) => {
            // console.log('res-put form: ', res);
            props.receipiendtUpdate(res.data.recipient);
            props.setShow(false);
          })
          .catch((err) => {
            // console.log('err: ', err);
            if (err.response.data.error.type === 'RECIPIENT_EXISTS') {
              props.setShow(true);
              setReceipentTypeError(true);
            }
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/recipient`,
            {
              type: selectedVal,
              account_holder_name: values.name,
              bank_name: values.bankName,
              account_number: values.accountNumber,
              iban: values.iban,
              sort_code: values.sortCode,
              swift_code: values.swift_code,
              bank_address: values.beneficiary,
            },
            axiosToken,
          )
          .then((res) => {
            // console.log('res-receipients: ', res);
            props.receipiendtAdd(res.data.recipient);
            props.setShow(false);
          })
          .catch((err) => {
            // console.log('err receipients: ', err);
            if (err.response.data.error.type === 'RECIPIENT_EXISTS') {
              props.setShow(true);
              setReceipentTypeError(true);
            }
          });
      }
    },
    enableReinitialze: true,
  });
  let axiosToken = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token,
    },
  };
  useEffect(() => {
    if (user.whoWantPay) {
      setSelected(user.whoWantPay);
    } else {
      setSelected('university');
      form.resetForm();
    }
  }, []);
  useEffect(() => {
    if (props.editData?._id) {
      let receipientValues = props.editData;
      setSelected(receipientValues.type);
      form.setValues({
        name: receipientValues.account_holder_name,
        beneficiary: receipientValues.bank_address,
        bankName: receipientValues.bank_name,
        accountNumber: receipientValues.account_number,
        sortCode: receipientValues.sort_code,
        iban: receipientValues.iban,
        swift_code: receipientValues.swift_code,
      });
    }
  }, [props.editData]);
  useEffect(() => {
    setReceipentTypeError(false);
    if (props.editData === 'receipient') {
      if (selectedVal === 'own') {
        form.setValues({
          name: `${user.user.attributes['custom:firstName']} ${user.user.attributes['custom:lastName']}`,
          beneficiary: '',
          bankName: '',
          accountNumber: '',
          sortCode: '',
          swift_code: '',
          iban: '',
        });
      } else {
        let senderValues = props.editData;
        form.setValues({
          name: '',
          beneficiary: '',
          bankName: '',
          accountNumber: '',
          sortCode: '',
          swift_code: '',
          iban: '',
        });
      }
    }
  }, [selectedVal]);
  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        className="receipient-modal-wrapper pt-0">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.editData?._id ? 'Edit Receipient' : 'Add Receipient'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <h1 className="mt-3"> Select Receipients </h1>
            {/* {form.errors.selectReceipient && form.touched.selectReceipient ? (
              <p className="error-message">{form.errors.selectReceipient}</p>
            ) : null} */}
            <ul className="ps-3 mt-3">
              <li className="text-center">
                <span
                  className={
                    selectedVal === 'university'
                      ? 'active d-flex align-items-center justify-content-center'
                      : 'd-flex align-items-center justify-content-center'
                  }
                  onClick={() => {
                    setSelected('university');
                  }}>
                  <img src={UniversityIco} />
                </span>
                <p className="mt-2"> University </p>
              </li>
              <li
                onClick={() => {
                  setSelected('rent');
                }}
                className="text-center mx-sm-4">
                <span
                  className={
                    selectedVal === 'rent'
                      ? 'active d-flex align-items-center justify-content-center'
                      : 'd-flex align-items-center justify-content-center'
                  }>
                  <img src={RentIco} />
                </span>
                <p className="mt-2"> Rent to Landlord </p>
              </li>
              <li className="text-center">
                <span
                  className={
                    selectedVal === 'own'
                      ? 'active d-flex align-items-center justify-content-center'
                      : 'd-flex align-items-center justify-content-center'
                  }
                  onClick={() => {
                    setSelected('own');
                  }}>
                  <img src={own} />
                </span>
                <p className="mt-2"> Your UK Account </p>
              </li>
            </ul>
            <div className="col-12 mt-4">
              <div className="form-floating mb-4">
                <input
                  name="name"
                  className={
                    form.errors.name && form.touched.name
                      ? 'error-field form-control'
                      : 'form-control'
                  }
                  id="floatingInput"
                  placeholder="First Name"
                  value={form.values.name}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                <label htmlFor="floatingInput">
                  {' '}
                  {selectedVal === 'rent'
                    ? 'Name of landlord'
                    : selectedVal === 'own'
                    ? 'Your Name (as per Bank)'
                    : `Name of ${selectedVal}`}
                  *{' '}
                </label>
                {form.errors.name && form.touched.name ? (
                  <p className="error-message">{form.errors.name}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating mb-4">
                <input
                  name="beneficiary"
                  className={
                    form.errors.beneficiary && form.touched.beneficiary
                      ? 'error-field form-control'
                      : 'form-control'
                  }
                  id="floatingInput"
                  placeholder="First Name"
                  value={form.values.beneficiary}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                <label htmlFor="floatingInput">
                  {selectedVal === 'rent'
                    ? 'landlord Address*'
                    : selectedVal === 'own'
                    ? 'your UK Address*'
                    : `${selectedVal} Address* `}
                  {/* {selectedVal === 'rent' ? 'landlord' : selectedVal} Address
                  Line* address */}
                </label>
                {form.errors.beneficiary && form.touched.beneficiary ? (
                  <p className="error-message">{form.errors.beneficiary}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating mb-4">
                <input
                  name="bankName"
                  className={
                    form.errors.bankName && form.touched.bankName
                      ? 'error-field form-control'
                      : 'form-control'
                  }
                  id="floatingInput"
                  placeholder="First Name"
                  value={form.values.bankName}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                <label htmlFor="floatingInput">Bank Name*</label>
                {form.errors.bankName && form.touched.bankName ? (
                  <p className="error-message">{form.errors.bankName}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating mb-4">
                <input
                  name="accountNumber"
                  className={
                    form.errors.accountNumber && form.touched.accountNumber
                      ? 'error-field form-control'
                      : 'form-control'
                  }
                  id="floatingInput"
                  placeholder="First Name"
                  value={form.values.accountNumber}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                <label htmlFor="floatingInput">
                  {selectedVal === 'own'
                    ? 'Your Account Number'
                    : 'Account Number of Receipient'}
                </label>
                {/* {form.errors.accountNumber && form.touched.accountNumber ? (
                  <p className="error-message">{form.errors.accountNumber}</p>
                ) : null} */}
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating mb-4">
                <input
                  type="email"
                  className={
                    form.errors.swift_code && form.touched.swift_code
                      ? 'error-field form-control'
                      : 'form-control'
                  }
                  id="floatingInput"
                  name="swift_code"
                  placeholder="Swift/BIC"
                  value={form.values.swift_code}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                <label htmlFor="floatingInput">Swift/BIC*</label>
                {form.errors.swift_code && form.touched.swift_code ? (
                  <p className="error-message">{form.errors.swift_code}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating mb-4">
                <input
                  name="iban"
                  className={
                    form.errors.iban && form.touched.iban
                      ? 'error-field form-control'
                      : 'form-control'
                  }
                  id="floatingInput"
                  placeholder="First Name"
                  // value={receipientsIbian}
                  // onChange={(e) => {
                  //   setReceipientsIbian(e.target.value);
                  // }}
                  value={form.values.iban}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                <label htmlFor="floatingInput">IBAN*</label>
                {form.errors.iban && form.touched.iban ? (
                  <p className="error-message">{form.errors.iban}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating mb-4">
                <input
                  name="sortCode"
                  className={
                    form.errors.sortCode && form.touched.sortCode
                      ? 'error-field form-control'
                      : 'form-control'
                  }
                  id="floatingInput"
                  placeholder="First Name"
                  value={form.values.sortCode}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  // value={receipientsSORTCode}
                  // onChange={(e) => {
                  //   setReceipientsSORTCode(e.target.value);
                  // }}
                />
                <label htmlFor="floatingInput">SORT Code</label>
                {/* {form.errors.sortCode && form.touched.sortCode ? (
                  <p className="error-message">{form.errors.sortCode}</p>
                ) : null} */}
              </div>
            </div>
          </div>
        </Modal.Body>
        {receipentTypeError && selectedVal ? (
          <h6
            style={{
              fontSize: '16px',
              color: 'red',
              textAlign: 'center',
              paddingBottom: '20px',
            }}>
            {' '}
            You can add only one bank of{' '}
            {selectedVal == 'rent' ? 'landlord' : selectedVal}{' '}
          </h6>
        ) : (
          ''
        )}
        <Modal.Footer className="justify-content-center btn-wrapper">
          <Button
            variant="primary"
            onClick={() => form.submitForm()}
            className="login-btn">
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default addReceipientsForm;
