import React, { useEffect, useState } from 'react';
import Header from '../../Common/Pages/header/header';
import NavBar from '../../Common/Pages/NavBar/nav-bar';
import BankFirstStepComponent from './bank-first-step/bank-account';
import { Modal } from 'react-bootstrap';
import SorryIco from '../../Assets/Images/sorry-img.svg';
import { useNavigate } from 'react-router-dom';

const BankAccountComponent = () => {
  const [nextStep, setNextStep] = useState(false);
  const [notEligibleBankAccount, setNotEligibleBankAccount] = useState(false);
  const [workingSim, setWorkingSim] = useState();
  const navigate = useNavigate();

  const handleNextStep = () => {
    if (nextStep) {
      navigate('/open-bank-account');
    }
    setNextStep(true);
  };
  const handleNoUKAddress = () => {
    setNotEligibleBankAccount(true);
  };
  const handleClose = () => {
    setNotEligibleBankAccount(false);
  };
  return (
    <div>
      <Header />
      <div className="row">
        <div className="col-3 sidebar-container">
          <NavBar />
        </div>
        <div className="col-12 col-sm-9 right-wrapper px-4 px-sm-0 pb-5">
          <BankFirstStepComponent
            setNextStep={setNextStep}
            nextStep={nextStep}
            workingSim={workingSim}
            setWorkingSim={setWorkingSim}
          />
          <div className="text-center mt-4">
            {nextStep && (
              <button
                className="me-4"
                onClick={handleNoUKAddress}
                style={{
                  backgroundColor: 'transparent',
                  border: '0px',
                  color: 'rgba(51, 176, 136, 1)',
                  fontWeight: 600,
                }}>
                No, UK address yet
              </button>
            )}
            <button
              className={workingSim != 'Yes' ? 'btn-disable' : 'next-btn'}
              disabled={workingSim != 'Yes'}
              onClick={handleNextStep}>
              Next
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={notEligibleBankAccount}
        onHide={handleClose}
        className="sm-receipient-modal">
        <Modal.Header closeButton style={{ border: '0px' }}>
          {/* <h5
            className="modal-title"
            id="staticBackdropLabel"
            style={{ textTransform: 'capitalize' }}>
            Add Bank Details
          </h5> */}
        </Modal.Header>
        <Modal.Body className="text-center">
          <img src={SorryIco} alt="sorry-ico" />
          <h2 style={{ color: '#ECA026', fontSize: '50px' }}> Sorry </h2>
          <p> You are not eligible to open Bank account </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default BankAccountComponent;
