import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import userReducer from './features/user';
import calculatorReducer from './features/calculator';
import dataReducer from './features/data';
import simReducer from './features/sim';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['user', 'data'],
};
const combinedReducer = combineReducers({
  user: userReducer,
  calculator: calculatorReducer,
  data: dataReducer,
  sim: simReducer,
});
export const rootReducer = (state, action) => {
  if (action.type === 'user/setLogout') {
    state = undefined;
  } else {
    return combinedReducer(state, action);
  }
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
