import React, { useLocation, useHistory, useEffect } from 'react';
import logo from '../../../Assets/Images/logo.svg';
import './header.scss';
import CloseIco from '../../../Assets/Images/close-ico.svg';
import { Link, useNavigate } from 'react-router-dom';
import ProfileIco from '../../../Assets/Images/user.png';
import { useDispatch, useSelector } from 'react-redux';
import Flag1 from '../../../Assets/Images/united-kingdom.svg';
import Flag2 from '../../../Assets/Images/india.svg';
import Logout from '../../../Assets/Images/logout.svg';
import UserIco from '../../../Assets/Images/user-ico.svg';
import Help from '../../../Assets/Images/help.svg';
import {
  setUser,
  setLogout,
  setWhoWantPay,
} from '../../../global/features/user';
import {
  setClientSecretId,
  setSimPlanId,
  setHandlePlan,
  setDeliveryType,
} from '../../../global/features/sim';
import { fetchRates } from '../../../global/features/calculator';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import {
  clearSendMoneyVal,
  setSideBarVal,
  clearPayNowId,
  setMenuToggleBtn,
} from '../../../global/features/data';
import MenuIco from '../../../Assets/Images/menu-ico.png';

function Header(props) {
  const urlCheck = window.location.pathname;
  const user = useSelector((state) => state.user);
  const rates = useSelector((state) => state.calculator.rates);
  const userName = user.user.attributes['custom:firstName'];
  const dispatch = useDispatch();
  let profileImage = user.user.attributes['custom:image'];

  useEffect(() => {
    dispatch(fetchRates());
  }, []);

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      dispatch(setUser(null));
      dispatch(setLogout());
    } catch (err) {
      toast.error('Error signing out');
    }
  };
  const resetRedux = () => {
    dispatch(clearPayNowId());
    dispatch(clearSendMoneyVal());
    dispatch(setSideBarVal('home'));
    dispatch(setClientSecretId([]));
    dispatch(setSimPlanId());
    dispatch(setClientSecretId([]));
    dispatch(setHandlePlan());
    dispatch(setDeliveryType());
    dispatch(setWhoWantPay(''));
  };
  const handleTogglbtn = () => {
    dispatch(setMenuToggleBtn());
  };
  return (
    <>
      <div>
        {!user.islogged ? (
          <div className="container h-100">
            <div className="row h-100 d-flex align-items-center">
              <div className="col-6">
                <Link to="/">
                  <img src={logo} alt="logo" className="logo-img" />
                </Link>
              </div>
              <div className="header-close-ico col-6 d-flex justify-content-end">
                <Link to="/">
                  <img src={CloseIco} alt="close-ico" className="close-ico" />
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              props.headerSecond
                ? 'row header-row align-items-center mx-0 px-3'
                : 'row header-row align-items-center w-100 mx-0'
            }>
            {props.headerSecond ? (
              ''
            ) : (
              <div className="col-6 col-sm-3 ps-sm-5">
                <button
                  onClick={resetRedux}
                  style={{ backgroundColor: 'transparent', border: '0px' }}>
                  <Link to="/">
                    <img src={logo} alt="logo" className="logo-img" />
                  </Link>
                </button>
              </div>
            )}
            <div
              className={
                props.headerSecond ? 'col-12' : 'col-6 col-sm-9 ps-sm-5'
              }>
              <div className="row mx-0">
                {props.headerSecond ? (
                  <>
                    <div className="col-2 d-flex align-items-center d-sm-none ps-0">
                      <button
                        className="toggleMenu"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasExample"
                        aria-controls="offcanvasExample">
                        <img
                          src={MenuIco}
                          alt="MenuIco"
                          style={{ width: '40px', height: '40px' }}
                        />
                      </button>
                    </div>
                    <div className="col-7 col-sm-6 text-center text-sm-left col-sm-8 pt-1 pt-sm-0">
                      <div className="d-block d-sm-none">
                        <button
                          onClick={resetRedux}
                          style={{
                            backgroundColor: 'transparent',
                            border: '0px',
                          }}>
                          <Link to="/">
                            <img src={logo} alt="logo" className="logo-img" />
                          </Link>
                        </button>
                      </div>
                      <div className="d-sm-flex d-none align-items-center currency-status ps-0">
                        <Link
                          to="/send-money"
                          style={{ color: '#2a2a2a', textDecoration: 'none' }}>
                          <div className="d-flex align-items-center inner-data">
                            <img src={Flag1} alt="flag" className="me-2" />
                            <h6 className="mb-0">
                              {' '}
                              <strong>£</strong>1{' '}
                            </h6>
                          </div>
                        </Link>
                        <h6 className="mb-0 mx-3">=</h6>
                        <Link
                          to="/send-money"
                          style={{ color: '#2a2a2a', textDecoration: 'none' }}>
                          <div className="d-flex align-items-center inner-data">
                            <img src={Flag2} alt="flag" className="me-2" />
                            <h6 className="mb-0">
                              {' '}
                              <strong>₹</strong>
                              {(1 / rates.GBP).toFixed(2) || 'loading...'}{' '}
                            </h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <div
                  className={
                    props.headerSecond
                      ? 'col-3 col-sm-4 text-end header-profile pe-3 pe-sm-0'
                      : 'col-12 d-flex justify-content-end header-profile'
                  }>
                  <div className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <span className="pe-2 d-none d-sm-inline">
                        {userName}
                      </span>
                      <img
                        src={
                          profileImage
                            ? `${process.env.REACT_APP_API_URL}/user/profile-image/${profileImage}`
                            : ProfileIco
                        }
                        alt="profile-ico"
                        className="profile-img"
                        style={{
                          width: '55px',
                          height: '55px',
                          borderRadius: '50%',
                        }}
                      />
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink">
                      <li className="name-dropdown d-block d-sm-none">
                        <span className="pe-2 d-block d-sm-none">
                          {userName}
                        </span>
                      </li>
                      <li>
                        <Link
                          to="/profile-detail-form"
                          className="dropdown-item">
                          <img
                            src={UserIco}
                            alt="userIco"
                            style={{
                              width: '18px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          />
                          Your Account
                        </Link>
                        {/* <a className="dropdown-item"></a> */}
                      </li>
                      <li>
                        <a
                          href="https://wa.me/message/KVW64HQKH7SID1"
                          target="_blank"
                          className="dropdown-item"
                          rel="noreferrer">
                          <img
                            src={Help}
                            alt="Logout"
                            style={{
                              width: '18px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          />
                          Help
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" onClick={handleLogout}>
                          <img
                            src={Logout}
                            alt="Logout"
                            style={{
                              width: '15px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          />
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Header;
