import React, { useEffect, useState } from 'react';
import SimTravellingComponent from './sim-travelling/sim-travelling';
import SimDeliveryLocation from './sim-delivery-location/sim-delivery-location';
import SimPlans from './sim-plans/sim-plans';
import Header from '../../Common/Pages/header/header';
import NavBar from '../../Common/Pages/NavBar/nav-bar';
import { Link, Navigate } from 'react-router-dom';
import CheckoutPage from './checkout-page/checkout-page';
import OrderStatusComponent from './order-status/order-status';
import AllPlansComponent from './all-plans/all-plans';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { setTravelDate, setDeliveryCountry } from '../../global/features/sim';

const SimCardComponent = () => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [date, setDate] = useState(null);
  const [simDelivery, setSimDelivery] = useState();
  const [simTravelDetails, setSimTravelDetails] = useState();
  const [headerSecond, setHeaderSecond] = useState(true);
  const [simProviders, setSimProviders] = useState([]);
  const user = useSelector((state) => state.user);
  const token = user.user?.tokens?.access;
  const deliveryCountry = useSelector((state) => state.sim.deliveryCountry);
  const sim = useSelector((state) => state.sim);
  let axiosToken = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token,
    },
  };
  const handleSimFlow = async () => {
    setCurrentStep(currentStep + 1);

    try {
      const profileDetail = await axios.put(
        `${process.env.REACT_APP_API_URL}/user/profile?cognito=false`,
        {
          currentCountry: sim.deliveryCountry,
          movingDate: sim.travelDate ?? null,
        },
        axiosToken,
      );
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    if (sim.deliveryCountry) {
      setCurrentStep(currentStep + 2);
    }
    (async () => {
      try {
        const profileDetail = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/profile?cognito=false`,
          axiosToken,
        );
        // dispatch(setTravelDate(profileDetail?.data?.user.movingDate));
        // dispatch(setDeliveryCountry(profileDetail?.data?.user.currentCountry));
        setSimTravelDetails(profileDetail?.data?.user);
      } catch (error) {
        // console.log(error);
      }
    })();
  }, []);

  return (
    <>
      <div className="row" style={{ overflow: 'hidden' }}>
        <div className="col-3 sidebar-container">
          <NavBar />
        </div>
        <div className="col-12 col-sm-9 mx-auto right-wrapper px-4 pb-5 pb-sm-0">
          <Header headerSecond={headerSecond} />
          {currentStep === 1 ? (
            <SimTravellingComponent
              date={date}
              setDate={setDate}
              setSimDelivery={setSimDelivery}
              simDelivery={simDelivery}
            />
          ) : currentStep === 2 ? (
            <SimPlans
              setSimProviders={setSimProviders}
              simProviders={simProviders}
            />
          ) : currentStep === 3 ? (
            <SimPlans />
          ) : (
            'No Page Found'
          )}

          {/* <SimDeliveryLocation /> */}
          {/* <SimPlans /> */}
          {/* <CheckoutPage /> */}
          {/* <OrderStatusComponent /> */}
          <div className="btn-wrapper col-12 text-center mt-5 d-sm-flex align-items-center justify-content-center mb-5">
            {currentStep == 1 ? (
              <button
                disabled={
                  (date && simDelivery === 'India') || simDelivery === 'UK'
                    ? false
                    : true
                }
                className={
                  (date && simDelivery === 'India') || simDelivery === 'UK'
                    ? 'ms-0 me-4 mb-4 mb-sm-0 login-btn d-inline-block'
                    : 'ms-0 me-4 mb-sm-0 btn-disable d-inline-block'
                }
                onClick={handleSimFlow}>
                {' '}
                Next{' '}
              </button>
            ) : (
              ''
            )}
            {/* {currentStep == 2 ? (
              <button
                disabled={!deliveryCountry}
                className={
                  !deliveryCountry
                    ? 'ms-0 me-4 btn-disable d-block'
                    : 'ms-0 me-4 login-btn d-block'
                }
                onClick={handleSimFlow}>
                {' '}
                Next{' '}
              </button>
            ) : (
              ''
            )} */}
            {/* {currentStep === 1 && <Link to="#"> I am already in UK </Link>} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SimCardComponent;
