import React from 'react';
import { Navigate } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';

const ProtectedRoute = ({ auth, children, loading }) => {
  if (loading) {
    return (
      <div className="loader-wrapper d-flex align-items-center justify-content-center">
        <BallTriangle
          height="100"
          width="100"
          color="#45C29A"
          ariaLabel="loading"
        />
      </div>
    );
  }

  if (!auth) {
    return <Navigate to={'/login'} replace />;
  }
  return children;
};

export default ProtectedRoute;
