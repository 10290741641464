import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FrameIco from '../../../Assets/Images/Frame-6.svg';
import FrameIcoSecond from '../../../Assets/Images/Frame-7.svg';

const BankAccountForm = () => {
  const [noUKAddress, setNoUKAddress] = useState(false);
  const handleClose = () => {
    setNoUKAddress(false);
  };
  //   const form = useFormik({
  //     initialValues: {
  //       email: '',
  //       name: '',
  //       phone: '',
  //       bankName: '',
  //       accountNumber: '',
  //       //   selectReceipient: '',
  //     },
  //     validate: receipientsFormValidate,
  //     onSubmit: async (values, { setErrors }) => {

  //     },
  //   });
  const handleNoUKAddress = () => {
    setNoUKAddress(true);
  };
  return (
    <>
      <div className="col-sm-8 col-12">
        <div className="row">
          <div className="col-sm-6 col-12">
            <h2 className="ps-sm-4"> Enter your UK Address </h2>
          </div>
          <div className="col-sm-6 col-12">
            <h2 className="ps-sm-4" style={{ color: 'rgba(69, 194, 154, 1)' }}>
              <button
                className="ps-0"
                onClick={handleNoUKAddress}
                style={{
                  color: 'rgba(69, 194, 154, 1)',
                  background: 'transparent',
                  border: '0px',
                  fontWeight: 600,
                }}>
                I do not have any UK address
              </button>
            </h2>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-12 px-sm-4">
        <div className="form-floating mb-4">
          <input
            type="text"
            className="form-control"
            id="firstName"
            name="firstName"
            // value={values.firstName}
            // onChange={handleChange}
            // onBlur={handleBlur}
            placeholder="First Name"
          />
          <label htmlFor="firstName">Address</label>
          {/* {touched.firstName && errors.firstName ? (
            <p className="error-message">{errors.firstName}</p>
          ) : null} */}
        </div>
      </div>
      <div className="col-sm-8 col-12">
        <div className="row mx-sm-0">
          <div className="col-6 px-sm-4">
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                id="City"
                name="City"
                // value={values.firstName}
                // onChange={handleChange}
                // onBlur={handleBlur}
                placeholder="City"
              />
              <label htmlFor="firstName">City</label>
              {/* {touched.firstName && errors.firstName ? (
                <p className="error-message">{errors.firstName}</p>
            ) : null} */}
            </div>
          </div>
          <div className="col-6 px-sm-4">
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                // value={values.firstName}
                // onChange={handleChange}
                // onBlur={handleBlur}
                placeholder="First Name"
              />
              <label htmlFor="firstName">State</label>
              {/* {touched.firstName && errors.firstName ? (
                <p className="error-message">{errors.firstName}</p>
            ) : null} */}
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-12 px-sm-4">
        <div className="form-floating mb-4">
          <input
            type="text"
            className="form-control"
            id="address"
            name="address"
            // value={values.firstName}
            // onChange={handleChange}
            // onBlur={handleBlur}
            placeholder="First Name"
          />
          <label htmlFor="firstName">Country</label>
          {/* {touched.firstName && errors.firstName ? (
          <p className="error-message">{errors.firstName}</p>
        ) : null} */}
        </div>
      </div>
      <Modal
        show={noUKAddress}
        onHide={handleClose}
        className="sm-receipient-modal">
        <Modal.Header closeButton>
          <h5
            className="modal-title"
            id="staticBackdropLabel"
            style={{ textTransform: 'capitalize' }}>
            No Permanent UK address yet?
          </h5>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="row">
            <div className="col-6">
              <img src={FrameIco} alt="SorryIco" />
            </div>
            <div className="col-6 d-flex align-items-center">
              <p className="text-start">
                You can use your friends/family address or temporary address for
                now.
              </p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-6 d-flex align-items-center">
              <p className="text-start">
                Nothing is delivered on this address unless to order a physical
                debit card
              </p>
            </div>
            <div className="col-6">
              <img src={FrameIcoSecond} alt="SorryIco" />
            </div>
          </div>
          <button className="next-btn mt-5 mb-4" onClick={handleClose}>
            OK
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default BankAccountForm;
