/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import isEmail from 'validator/es/lib/isEmail';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import ResetPasswordIco from '../../Assets/Images/forgot-password-ico.svg';
import './reset-password.scss';

import logo from '../../Assets/Images/logo.svg';
import CloseIco from '../../Assets/Images/close-ico.svg';
import { Link } from 'react-router-dom';
import SetNewPassword from '../set-new-password/set-new-password';

const passwordValidate = (values) => {
  const errors = {};

  if (values.newPassword.trim().length < 6) {
    errors.newPassword = 'Password must be at least 6 characters';
  }

  if (
    values.newPassword !== values.confirmNewPassword ||
    !values.confirmNewPassword
  ) {
    errors.confirmNewPassword = 'Passwords must match';
  }

  return errors;
};

const emailValidate = (values) => {
  const errors = {};

  if (!isEmail(values.email)) {
    errors.email = 'Email is invalid';
  }

  return errors;
};

function ResetPassword() {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const isLoggedin = useSelector((state) => state?.user?.islogged);

  const emailForm = useFormik({
    initialValues: {
      email: '',
    },
    validate: emailValidate,
    onSubmit: async (values, { setErrors }) => {
      if (step === 1) {
        try {
          await Auth.forgotPassword(values.email);
          setStep(2);
        } catch (error) {
          if (error.code === 'UserNotFoundException') {
            setErrors({ email: 'No such Email found' });
            return;
          }

          if (error.code === 'CodeDeliveryFailureException') {
            setErrors({ email: 'Unable to send OTP! Please try again later.' });
            return;
          }

          if (error.code === 'LimitExceededException') {
            setErrors({ email: 'Too many requests. Please try again later.' });
            return;
          }

          setErrors({ email: 'Something went wrong! Please try again later.' });
        }
      }
    },
  });

  const form = useFormik({
    initialValues: {
      otp: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validate: passwordValidate,
    onSubmit: async (values, { setErrors }) => {
      if (step === 2) {
        try {
          await Auth.forgotPasswordSubmit(
            emailForm.values.email,
            values.otp,
            values.newPassword,
          );

          toast.success('Password changed successfully');
          navigate('/login');
        } catch (error) {
          // console.log(error);
          if (error.code === 'CodeMismatchException') {
            setErrors({ otp: 'OTP does not match' });
            return;
          }

          if (error.code === 'ExpiredCodeException') {
            setErrors({ otp: 'OTP has expired! Please try again' });
            return;
          }

          if (error.code === 'LimitExceededException') {
            setErrors({ otp: 'Too many requests. Please try again later.' });
            return;
          }

          setErrors({
            otp: 'Unable to reset password! Please try again later.',
          });
        }
      }
    },
  });

  const resendCode = async () => {
    try {
      await Auth.forgotPassword(emailForm.values.email);

      toast.info('code sent successfully');
    } catch (e) {
      // console.log(e);
      form.setErrors({ otp: 'Unable to resend otp, please try again' });
    }
  };

  const renderContent = () => {
    if (step === 1) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-11 col-sm-6 col-md-4 d-flex align-items-center justify-content-center mx-auto text-center reset-password-wrapper">
              <div className="mt-3 pt-4">
                <img
                  src={ResetPasswordIco}
                  alt="password-ico"
                  className="password-ico"
                />
                <h1 className="pt-5 pb-2"> Forgot Password? </h1>
                <p>
                  No worries! Just enter your registered email address and we'll
                  send you an email to reset your password.
                </p>
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={emailForm.values.email}
                    onChange={emailForm.handleChange}
                    autoComplete="off"
                    autoCorrect="off"
                    onBlur={emailForm.handleBlur}
                    placeholder="name@example.com"
                  />
                  <label htmlFor="email">Email address</label>
                  {emailForm.errors.email && emailForm.touched.email ? (
                    <p className="error-message">{emailForm.errors.email}</p>
                  ) : null}
                </div>
                <div className="btn-wrapper">
                  <button
                    className="mt-4 d-flex align-items-center justify-content-center login-btn"
                    onClick={() => emailForm.submitForm()}>
                    {' '}
                    SEND OTP{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // if (step === 2) {
    //   return (
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-6 col-sm-4 d-flex align-items-center justify-content-center mx-auto text-center reset-password-wrapper">
    //           <div className="mt-2 pt-3">
    //             <img
    //               src={ResetPasswordIco}
    //               alt="password-ico"
    //               className="password-ico"
    //             />
    //             <h1 className="pt-2 pb-2"> Check your email </h1>
    //             <p>
    //               We sent an email to{' '}
    //               <strong>xxxxxx@{email.split('@')[1]}</strong>
    //               <br />
    //               Didn't get the email? Look into your spam folder..
    //             </p>
    //             <div className="form-floating">
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 id="floatingInput"
    //                 value={otp}
    //                 onChange={(e) => setOTP(e.target.value)}
    //                 placeholder="Enter OTP"
    //               />
    //               <label htmlFor="floatingInput">Enter OTP</label>
    //               {errors.otp && <p className="error-message">{errors.otp}</p>}
    //             </div>
    //             <button
    //               className="mt-4 w-100 py-3 login-btn"
    //               onClick={handleSubmit}>
    //               {' '}
    //               Check OTP{' '}
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }

    if (step === 2) {
      return (
        <SetNewPassword
          opt={form.values.otp}
          newPassword={form.values.newPassword}
          confirmNewPassword={form.values.confirmNewPassword}
          handleChange={form.handleChange}
          errors={form.errors}
          touched={form.touched}
          handleBlur={form.handleBlur}
          onSubmit={form.submitForm}
          onResendClick={resendCode}
        />
      );
    }
  };

  if (isLoggedin) {
    navigate('/dashboard', { replace: true });
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row h-100 d-flex align-items-center py-3 header-wrapper">
          <div className="col-6">
            <Link to="/">
              <img src={logo} alt="logo" className="logo-img" />
            </Link>
          </div>
          <div className="header-close-ico col-6 d-flex justify-content-end">
            <Link to="/">
              <img src={CloseIco} alt="close-ico" className="close-ico" />
            </Link>
          </div>
        </div>
      </div>
      {renderContent()}
    </>
  );
}

export default ResetPassword;
