import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import giffgaffIco from '../../../Assets/Images/giffgaff.svg';
import './all-plans.scss';
import Header from '../../../Common/Pages/header/header';
import NavBar from '../../../Common/Pages/NavBar/nav-bar';
import allAnswers from '../all-answers/all-answers';

const AllPlansComponent = () => {
  return (
    <div className="px-3 px-sm-0">
      <Header />
      <div className="row">
        <div className="col-3 ps-0">
          <NavBar />
        </div>
        <div
          className="col-12 col-sm-8 mx-auto right-wrapper px-sm-5"
          id="order-status-wrapper">
          <div className="row">
            <div className="col-6">
              <h1 className="mb-3"> All SIM Plans </h1>
            </div>
            <div className="col-6 text-end">
              <h1 className="mb-3"> Filter </h1>
            </div>
          </div>
          <div className="col-12 all-plans-sec">
            <ul className="ps-0">
              <li>
                <div className="row plans-details-wrapper w-100">
                  <div className="col-5 plans-details-left d-flex align-items-center">
                    <img src={giffgaffIco} alt="giffgaffIco" />
                    <div className="details-sim row p-1 ms-3 align-items-center">
                      <div className="amount-sec">£10</div>
                      <div className="sim-data d-inline w-50">
                        <h5 className="mb-0">Data</h5>
                        <h6 className="mb-0">15GB</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 sim-details">
                    <div className="row">
                      <div className="col-6 mb-3">
                        <h5 className="mb-0">Calls</h5>
                        <h6 className="mb-0">Unlimited</h6>
                      </div>
                      <div className="col-6 mb-3">
                        <h5 className="mb-0">Texts</h5>
                        <h6 className="mb-0">Unlimited</h6>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">Calls to India</h5>
                        <h6 className="mb-0">2p/min</h6>
                      </div>
                      <div className="col-6">
                        <h5 className="mb-0">EU Roaming</h5>
                        <h6 className="mb-0">Included</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 d-flex align-items-center">
                    <Link className="select-plan-btn" to="/sim-checkout">
                      {' '}
                      SELECT PLAN{' '}
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <allAnswers />
        </div>
      </div>
    </div>
  );
};

export default AllPlansComponent;
