import React, { useEffect, useState } from 'react';
import BankBanner from '../../../Assets/Images/bank-account-banner.svg';
import './bank-account.scss';
import UKIco from '../../../Assets/Images/uk-ico.svg';
import IndiaIco from '../../../Assets/Images/india-gate-ico.svg';
import BankAccountForm from '../bank-account-form/bank-account-form';

const BankFirstStepComponent = (props) => {
  const [simCardFlag, setSimCardFlag] = useState(true);

  const handleChange = (e) => {
    props.setWorkingSim(e.target.value);
    if (e.target.value === 'Yes') {
      setSimCardFlag(true);
    } else {
      setSimCardFlag(false);
      props.setNextStep(false);
    }
  };
  return (
    <div className="col-12 content-dashboard mt-3">
      <h1> Get started with opening a bank account </h1>
      <img src={BankBanner} alt="bank-banner" className="w-100" />
      <div className="col-12 mx-auto px-4 px-sm-0 py-4 mt-3" id="bank-wrapper">
        <h2 className="ps-sm-4 mt-0 mt-sm-3 mb-0">
          Do you have a working UK SIM card?
        </h2>
        <ul className="d-flex align-items-center justify-content-start pt-3 ps-0 ps-sm-2 text-center">
          <li className="travelling-sec me-sm-4 mb-sm-0 d-flex align-items-center justify-content-start">
            <div className="col-sm-3 col-2 form-check ms-sm-3">
              <input
                className="form-check-input"
                type="radio"
                name="currentLocation"
                onChange={handleChange}
                id="yes"
                value="Yes"
                //   checked={form.values.currentLocation === 'Outside India'}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                Yes
              </label>
            </div>
          </li>
          <li className="travelling-sec d-flex align-items-center justify-content-start">
            <div className="col-sm-3 col-2 form-check ms-3">
              <input
                className="form-check-input"
                type="radio"
                name="currentLocation"
                onChange={handleChange}
                id="no"
                value="No"
                //   checked={form.values.currentLocation === 'Outside India'}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                No
              </label>
            </div>
          </li>
        </ul>
        {simCardFlag && (
          <>
            <h2 className="ps-sm-4"> Enter your UK number </h2>
            <div className="col-sm-8 col-12 px-sm-4">
              <div className="form-floating mb-4">
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  name="confirmPassword"
                  // value={values.confirmPassword}
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  placeholder="Retype Password"
                />
                <label htmlFor="confirmPassword">UK Number</label>
                {/* {touched.confirmPassword && errors.confirmPassword ? (
                <p className="error-message">{errors.confirmPassword}</p>
              ) : null} */}
              </div>
            </div>
          </>
        )}
        {simCardFlag && props.nextStep && <BankAccountForm />}
        {!simCardFlag && (
          <p
            style={{
              color: 'rgba(190, 30, 45, 1)',
              background: 'rgba(255, 237, 237, 1)',
              padding: '8px 10px',
              margin: '24px',
              fontSize: '16px',
              fontWeight: 600,
            }}>
            We recommend getting/activating a UK number and using that for your
            bank account to avoid loosing access to your bank after you loose
            access to your India number when you land in the UK.
          </p>
        )}
      </div>
    </div>
  );
};
export default BankFirstStepComponent;
