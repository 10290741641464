import React from 'react';
import './refer-friend.scss';
import NavBar from '../../Common/Pages/NavBar/nav-bar';
import Header from '../../Common/Pages/header/header';
import Footer from '../../Common/Pages/footer/footer';

function ReferFriend() {
  return (
    <>
      <Header />
      <div className="container-fluid px-0">
        <div className="row" id="invite-friend-wrapper">
          <div className="col-12 text-center py-5">
            <h1>
              {' '}
              Invite and Earn <strong>75 GBP</strong>{' '}
            </h1>
            <p>
              Earn 75 GBP for every 3 friends who transfer over 200 GBP. They
              will get a fee-free transfer up tp 500 GBP.
            </p>
            <div className="form-floating mt-5 col-3 mb-4 mx-auto">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="First Name"
              />
              <label htmlFor="floatingInput">
                First Name <span>(as per passport)</span>
              </label>
            </div>
            <button className="btn-share"> Share </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ReferFriend;
