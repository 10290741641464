/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import Header from '../../../Common/Pages/header/header';
import NavBar from '../../../Common/Pages/NavBar/nav-bar';
import './receipients.scss';
import AddIco from '../../../Assets/Images/add-ico.svg';
import { Link } from 'react-router-dom';
import FlagIco from '../../../Assets/Images/flag-ico.svg';
import UserIco from '../../../Assets/Images/user.png';
import Footer from '../../../Common/Pages/footer/footer';
import NoReceipients from '../../../Assets/Images/no-recipients.png';
import Flag2 from '../../../Assets/Images/india.svg';
import Arrow from '../../../Assets/Images/vector-2.svg';
import Trend from '../../../Assets/Images/trend.svg';
import ProfileIco from '../../../Assets/Images/profile-img.png';
import BankIco from '../../../Assets/Images/bank-account.svg';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import Accordion from 'react-bootstrap/Accordion';
import DeleteIco from '../../../Assets/Images/delete.png';
import EditIco from '../../../Assets/Images/edit.svg';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import RemoveIco from '../../../Assets/Images/remove.png';
import CheckIco from '../../../Assets/Images/check.png';
import AddReceipientsForm from '../add-receipients-form/add-receipients-form';
import AddSenderForm from '../add-sender-form/add-sender-form';
import { setUser, setWhoWantPay } from '../../../global/features/user';
import {
  payNowSendMoney,
  setSendMoneyStep,
} from '../../../global/features/data';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import AuthFailed from '../../../Assets/Images/failed-1.svg';
import InfoIcon from '../../../Assets/Images/info.svg';
import ExclamationMarkRed from '../../../Assets/Images/exclamation-mark-red.svg';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Receipients() {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const user = useSelector((state) => state.user);
  const token = user.user?.tokens?.access;
  const [receipientsList, setReceipientsList] = useState([]);
  const [senderList, setSenderList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [receipientId, setReceipientId] = useState();
  const [show, setShow] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [editData, setEditData] = useState({});
  const dispatch = useDispatch();
  const [headerSecond, setHeaderSecond] = useState(true);
  const navigate = useNavigate();
  const [filesList, setFilesList] = useState();
  const [docNotVerify, setDocNotVerify] = useState(false);
  const sendMoneyStepVal = useSelector((state) => state.data.sendMoneyStep);
  const recipientFlag = useSelector((state) => state.data);
  const [checkDocStatus, setCheckDocStatus] = useState();
  const [recipientCount, setRecipientCount] = useState();
  const [key, setKey] = useState('home');

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      dispatch(setUser(null));
    } catch (err) {
      toast.error('Error signing out');
    }
  };
  const handleClose = () => {
    setShow(false);
    setDeleteConfirm(false);
    setDocNotVerify(false);
  };
  const handleShow = (data) => {
    setEditData(data);
    setReceipientId(data._id);
    setShow(true);
    dispatch(setWhoWantPay(''));
  };
  const openDeleteModal = (data) => {
    if (data.relation) {
      setEditData(data);
      setReceipientId(data._id);
    } else {
      setEditData(data);
      setReceipientId(data);
    }
    setDeleteConfirm(true);
  };
  const receipiendtAdd = (data) => {
    if (data.relation) {
      setSenderList([...senderList, data]);
    } else {
      setReceipientsList([...receipientsList, data]);
    }
  };
  const receipiendtUpdate = (data) => {
    console.log('receipiendtUpdate', data);
    setReceipientsList((prevState) => {
      return prevState.map((res, i) => {
        if (res._id === data._id) {
          res = {
            ...res,
            account_holder_name: data.account_holder_name,
            type: data.type,
            account_number: data.account_number,
            bank_address: data.bank_address,
            bank_name: data.bank_name,
            iban: data.iban,
            sort_code: data.sort_code,
          };
        }
        return res;
      });
    });
    setSenderList((prevState) => {
      return prevState.map((res, i) => {
        if (res._id === data._id) {
          res = {
            ...res,
            name: data.name,
            relation: data.relation,
            account_number: data.account_number,
            phone: data.phone,
            email: data.email,
            bank_name: data.bank_name,
          };
        }
        return res;
      });
    });
  };
  const handleSuccessClose = () => setSuccessShow(false);
  let axiosToken = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token,
    },
  };
  const filesApi = () => {
    axios
      .get('https://remittance-staging.remigos.com/files', axiosToken)
      .then((res) => {
        // console.log('res files: ', res);
        setFilesList(res.data.status);
        setCheckDocStatus(res.data.incomplete);
        setLoader(false);
      })
      .catch((err) => {
        // console.log('err: ', err);
      });
  };
  useEffect(() => {
    if (recipientFlag.noRecipientFlag) {
      setShow(true);
      setEditData('receipient');
    }
    if (recipientFlag.noSenderAdded) {
      setShow(true);
      setEditData('sender');
    }
    setLoader(true);
    filesApi();
    // Sender List Api
    axios
      .get(`${process.env.REACT_APP_API_URL}/sender`, axiosToken)
      .then((res) => {
        // console.log('res-get-sender: ', res);
        setSenderList(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        // console.log('err-get-sender ', err);
        setLoader(false);
      });
    // Recepients List Api
    axios
      .get(`${process.env.REACT_APP_API_URL}/recipient`, axiosToken)
      .then((res) => {
        // console.log('res-get: ', res);
        let receipientData = res.data.recipients;
        setRecipientCount(res.data.count);
        setReceipientsList(receipientData);
        setLoader(false);
      })
      .catch((err) => {
        // console.log('err.response.data.error: ', err);
        setLoader(false);
      });
  }, []);
  const deleteSender = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/sender/${receipientId}`,
        axiosToken,
      )
      .then((res) => {
        // console.log('res-files: ', res);
        setShow(false);
        const deleteData = senderList.filter((data) => {
          return receipientId != data._id;
        });
        setSenderList([...deleteData]);
        setDeleteConfirm(false);
        setSuccessShow(true);
        // setTimeout(() => {
        //   setSuccessShow(false);
        // }, 1000);
      })
      .catch((err) => {
        // console.log('err: ', err);
      });
  };
  const deleteReceipients = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/recipient/${receipientId}`,
        axiosToken,
      )
      .then((res) => {
        // console.log('res-files: ', res);
        setShow(false);
        setDeleteConfirm(false);
        const deleteData = receipientsList.filter((data) => {
          return receipientId != data._id;
        });
        setReceipientsList([...deleteData]);
        setSuccessShow(true);
        setTimeout(() => {
          setSuccessShow(false);
        }, 2000);
      })
      .catch((err) => {
        // console.log('err: ', err);
      });
  };
  const handlePayNow = (data) => {
    dispatch(setWhoWantPay(data.type));
    if (
      filesList?.father === 'approved' ||
      filesList?.mother === 'approved' ||
      filesList?.spouse === 'approved' ||
      filesList?.own === 'approved'
    ) {
      dispatch(payNowSendMoney([data]));
      navigate('/send-money');
    } else {
      setDocNotVerify(true);
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-3 px-0 sidebar-container">
            <NavBar />
          </div>
          <div className="col-sm-9 col-12 right-wrapper px-0">
            <Header headerSecond={headerSecond} />
            {/* Refactor */}
            <div className="inner-receipient">
              {/* Sender List */}
              <div className="row d-none d-sm-flex align-items-center mt-5 receipient-modal-wrapper mb-2 mx-4">
                <div className="col-6">
                  <h6>
                    {' '}
                    Your Sender
                    {senderList.length != 0 ? (
                      <div className="tooltip-wrap">
                        <img src={InfoIcon} alt="InfoIcon" />
                        <span className="tooltiptext">
                          As per RBI guidelines, to transact you need to share
                          details of account you will send money from in India.
                          We will verify that the payment is received from the
                          account you mentioned and then send it to the receiver
                          account you asked for. If you plan to use multiple
                          accounts, add each account individually.
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                  </h6>
                </div>
                <div className="col-6 d-flex justify-content-end add-receipent-sec mb-2">
                  <button
                    className="d-flex align-items-center"
                    onClick={() => handleShow('sender')}>
                    <img src={AddIco} alt="add-ico" className="me-2" />
                    Add a Sender
                  </button>
                </div>
                {(show && editData === 'sender') || editData.relation ? (
                  <AddSenderForm
                    setShow={setShow}
                    show={show}
                    setEditData={setEditData}
                    editData={editData}
                    receipientId={receipientId}
                    setReceipientId={setReceipientId}
                    receipiendtAdd={receipiendtAdd}
                    receipiendtUpdate={receipiendtUpdate}
                  />
                ) : null}
              </div>
              {/* Accordion Code */}
              <div className="row d-none d-sm-flex mx-4">
                {senderList.length === 0 ? (
                  <div className="d-flex align-items-center justify-content-center new-receipient-sec py-5">
                    <div className="col-3">
                      <img
                        src={NoReceipients}
                        className="mb-4"
                        alt="NoReceipients"
                        style={{ width: '200px' }}
                      />
                    </div>
                    <div className="col-9 d-flex align-items-center">
                      <div>
                        <p>
                          Please add the details of account you will send money
                          from in India
                          <div className="tooltip-wrap">
                            <img src={InfoIcon} alt="InfoIcon" />
                            <span className="tooltiptext">
                              As per RBI guidelines, to transact you need to
                              share details of account you will send money from
                              in India. We will verify that the payment is
                              received from the account you mentioned and then
                              send it to the receiver account you asked for. If
                              you plan to use multiple accounts, add each
                              account individually.
                            </span>
                          </div>
                        </p>
                        <button
                          onClick={() => handleShow('sender')}
                          className="d-block">
                          {' '}
                          ADD SENDER{' '}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-12 mt-2">
                    {senderList?.map((data) => {
                      let str = data.account_number;
                      let accountLastNumber = str.slice(str.length - 4);
                      let accountNum = data.account_number;
                      let account_number = accountNum.replace(
                        /.(?=.{4,}$)/g,
                        'X',
                      );
                      // console.log('data: ', data);
                      return (
                        <>
                          <Accordion
                            defaultActiveKey={data._id}
                            className="mb-sm-4">
                            <Accordion.Item eventKey="1">
                              <div className="d-flex align-items-center">
                                <div className="row w-100 p-3">
                                  <div className="col-sm-6 col-12 mb-4 mb-sm-0 accordion-left-sec d-flex align-items-center">
                                    <img
                                      src={UserIco}
                                      alt="user-ico"
                                      className="userIco me-3"
                                    />
                                    <div>
                                      <p className="mb-0">{data.relation} </p>
                                      <h6 className="mt-2 mt-sm-0">
                                        {data.name}{' '}
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-12 accordion-right-sec d-flex align-items-start align-items-sm-center justify-content-end pe-sm-4 pe-0">
                                    <img
                                      src={Flag2}
                                      alt="flag-ico"
                                      className="me-2"
                                    />
                                    <div>
                                      <p className="mb-0">
                                        INR account ending with{' '}
                                        {accountLastNumber}{' '}
                                      </p>
                                      <button
                                        onClick={() => handleShow(data)}
                                        className="editAccount-btn ms-0">
                                        Edit Account
                                        {/* <img
                                          src={EditIco}
                                          alt="edit-ico"
                                          style={{ width: '25px' }}
                                        />{' '} */}
                                      </button>
                                      <img
                                        src={DeleteIco}
                                        alt="delete-ico"
                                        className="delete-ico ms-2"
                                        onClick={() => openDeleteModal(data)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <Accordion.Header></Accordion.Header>
                              </div>
                              <Accordion.Body>
                                <div
                                  className="accordion"
                                  id="accordionExample">
                                  <div className="accordion-item">
                                    <div className="accordion-body row">
                                      <div className="col-sm-4 col-12 mb-sm-4 mb-2">
                                        <h4 className="mb-sm-3 mb-0">
                                          Full Name
                                        </h4>
                                        <h5>{data.name}</h5>
                                      </div>
                                      <div className="col-sm-4 col-12 mb-sm-4 mb-2">
                                        <h4 className="mb-sm-3 mb-0">
                                          Bank Name
                                        </h4>
                                        <h5>{data.bank_name}</h5>
                                      </div>
                                      <div className="col-sm-4 col-12 mb-sm-4 mb-2">
                                        <h4 className="mb-sm-3 mb-0">
                                          Account Number
                                        </h4>
                                        <h5>{account_number}</h5>
                                      </div>
                                      <div className="col-sm-4 col-12 mb-sm-0 mb-2">
                                        <h4 className="mb-sm-3 mb-0">Email</h4>
                                        <h5 className="email-sec">
                                          {data.email}
                                        </h5>
                                      </div>
                                      <div className="col-sm-4 col-12">
                                        <h4 className="mb-sm-3 mb-0">
                                          Phone Number
                                        </h4>
                                        <h5>{data.phone}</h5>
                                      </div>
                                      <div className="col-sm-4 col-12">
                                        <h4 className="mb-sm-3 mb-0">
                                          Relation
                                        </h4>
                                        <h5>{data.relation}</h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
              {/* Receipients */}
              <div className="row d-none d-sm-flex align-items-center mt-5 receipient-modal-wrapper mb-2 pt-0 mx-4">
                <div className="col-6">
                  <h6>
                    {' '}
                    Your Receipients
                    {receipientsList.length != 0 ? (
                      <div className="tooltip-wrap">
                        <img src={InfoIcon} alt="InfoIcon" />
                        <span className="tooltiptext">
                          If your university hasn't provided these details, you
                          can move money to your UK account and then pay on
                          their portal to save huge FX fees.
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                  </h6>
                </div>
                <div className="col-6 d-flex justify-content-end add-receipent-sec mb-2">
                  <button
                    className="d-flex align-items-center"
                    onClick={() => handleShow('receipient')}>
                    <img src={AddIco} alt="add-ico" className="me-2" />
                    Add a Receipient
                  </button>
                </div>
                {(show && editData === 'receipient') || editData.type ? (
                  <AddReceipientsForm
                    setShow={setShow}
                    show={show}
                    setEditData={setEditData}
                    editData={editData}
                    receipientId={receipientId}
                    setReceipientId={setReceipientId}
                    receipiendtAdd={receipiendtAdd}
                    receipiendtUpdate={receipiendtUpdate}
                    setReceipientsList={setReceipientsList}
                    receipientsList={receipientsList}
                    setRecipientCount={setRecipientCount}
                    recipientCount={recipientCount}
                  />
                ) : null}
              </div>
              {/* Accordion Code */}
              <div className="row d-none d-sm-flex mx-4">
                {receipientsList.length === 0 ? (
                  <div className="row new-receipient-sec py-5 mx-0">
                    <div className="col-sm-3">
                      <img
                        src={NoReceipients}
                        className="mb-4"
                        alt="NoReceipients"
                        style={{ width: '200px' }}
                      />
                    </div>
                    <div className="col-sm-9 d-flex align-items-center">
                      <div>
                        <p className="mb-0">
                          Please add the details of account you want to pay.
                        </p>
                        <p>
                          Please ensure the details are complete and correct.
                          <div className="tooltip-wrap">
                            <img src={InfoIcon} alt="InfoIcon" />
                            <span className="tooltiptext">
                              If your university hasn't provided these details,
                              you can move money to your UK account and then pay
                              on their portal to save huge FX fees.
                            </span>
                          </div>
                        </p>
                        <button
                          onClick={() => handleShow('receipient')}
                          className="d-block">
                          {' '}
                          ADD RECIPIENT{' '}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-12 mt-2">
                    {receipientsList.map((data) => {
                      let str = data.account_number;
                      let accountLastNumber = str.slice(str.length - 4);
                      let accountNum = data.account_number;
                      let account_number = accountNum.replace(
                        /.(?=.{4,}$)/g,
                        'X',
                      );
                      return (
                        <>
                          <Accordion
                            defaultActiveKey={data._id}
                            className="mb-sm-4">
                            <Accordion.Item eventKey="1">
                              <div className="d-flex align-items-center">
                                <div className="row w-100 p-3">
                                  <div className="col-sm-5 col-12 accordion-left-sec d-flex align-items-center">
                                    <img
                                      src={UserIco}
                                      alt="user-ico"
                                      className="userIco me-3"
                                    />
                                    <div>
                                      <p className="mb-0">{data.type}</p>
                                      <h6>{data.account_holder_name}</h6>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-5 accordion-right-sec d-flex align-items-start align-items-sm-center justify-content-end pe-sm-4 pe-0 mt-4 mt-sm-0">
                                    <img
                                      src={FlagIco}
                                      alt="flag-ico"
                                      className="me-2"
                                    />
                                    <div>
                                      <p className="mb-0">
                                        GBP account ending with{' '}
                                        {accountLastNumber}{' '}
                                      </p>
                                      <button
                                        onClick={() => handleShow(data)}
                                        style={{ fontWeight: '500' }}
                                        className="editAccount-btn">
                                        {' '}
                                        Edit Account
                                        {/* <img
                                          src={EditIco}
                                          alt="edit-ico"
                                          style={{ width: '25px' }}
                                        />{' '} */}
                                      </button>
                                      <img
                                        src={DeleteIco}
                                        alt="delete-ico"
                                        className="delete-ico ms-2"
                                        onClick={() =>
                                          openDeleteModal(data._id)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-2 d-flex align-items-start justify-content-center">
                                    {' '}
                                    <button
                                      onClick={() => handlePayNow(data)}
                                      style={{
                                        border: '0px',
                                        borderRadius: '50px',
                                        backgroundColor: '#81E4C4',
                                        padding: '5px 15px',
                                        fontWeight: '600',
                                      }}
                                      className="payNow-btn ms-2">
                                      {' '}
                                      PAY NOW{' '}
                                    </button>{' '}
                                  </div>
                                </div>
                                <Accordion.Header></Accordion.Header>
                              </div>
                              <Accordion.Body>
                                <div
                                  className="accordion"
                                  id="accordionExample">
                                  <div className="accordion-item">
                                    <div className="accordion-body row">
                                      <div className="col-12 col-sm-4 mb-2">
                                        <h4 className="mb-0">Full Name</h4>
                                        <h5>{data.account_holder_name}</h5>
                                      </div>
                                      <div className="col-12 col-sm-4 mb-sm-4 mb-2">
                                        <h4 className="mb-0">Bank Name</h4>
                                        <h5>{data.bank_name}</h5>
                                      </div>
                                      <div className="col-12 col-sm-4 mb-sm-4 mb-2">
                                        <h4 className="mb-0">Account Number</h4>
                                        <h5>{account_number}</h5>
                                      </div>
                                      <div className="col-12 col-sm-4 mb-sm-0 mb-2">
                                        <h4
                                          className="mb-0"
                                          style={{
                                            textTransform: 'capitalize',
                                          }}>
                                          {data.type} Address
                                        </h4>
                                        <h5>{data.bank_address}</h5>
                                      </div>
                                      <div className="col-12 col-sm-4 mb-sm-0 mb-2">
                                        <h4 className="mb-0">SWIFT CODE</h4>
                                        <h5>{data.swift_code}</h5>
                                      </div>
                                      <div className="col-12 col-sm-4 mb-sm-0 mb-2">
                                        <h4 className="mb-0">Relation</h4>
                                        <h5>{data.type}</h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
              <div
                style={{ paddingTop: '100px' }}
                className="mobile-tabs-ui d-sm-none">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3 ps-3">
                  <Tab eventKey="home" title="Your Senders">
                    <div className="inner-receipient pt-3">
                      {/* Sender List */}
                      <div className="col-12 d-flex justify-content-end justify-content-end add-receipent-sec mb-2">
                        <button
                          className="d-flex align-items-center"
                          onClick={() => handleShow('sender')}>
                          <img src={AddIco} alt="add-ico" className="me-2" />
                          Add a Sender
                        </button>
                      </div>
                      {/* Accordion Code */}
                      <div className="row d-sm-none d-flex mx-1">
                        {senderList.length === 0 ? (
                          <div className="d-block text-center new-receipient-sec py-5">
                            <div className="col-12">
                              <img
                                src={NoReceipients}
                                className="mb-4"
                                alt="NoReceipients"
                                style={{ width: '200px' }}
                              />
                            </div>
                            <div className="col-12 d-flex align-items-center">
                              <div>
                                <p>
                                  Please add the details of account you will
                                  send money from in India
                                  <div className="tooltip-wrap">
                                    <img src={InfoIcon} alt="InfoIcon" />
                                    <span className="tooltiptext">
                                      As per RBI guidelines, to transact you
                                      need to share details of account you will
                                      send money from in India. We will verify
                                      that the payment is received from the
                                      account you mentioned and then send it to
                                      the receiver account you asked for. If you
                                      plan to use multiple accounts, add each
                                      account individually.
                                    </span>
                                  </div>
                                </p>
                                <button
                                  onClick={() => handleShow('sender')}
                                  className="d-block mx-auto">
                                  {' '}
                                  ADD SENDER{' '}
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-12 mt-2 px-0">
                            {senderList?.map((data) => {
                              let str = data.account_number;
                              let accountLastNumber = str.slice(str.length - 4);
                              let accountNum = data.account_number;
                              let account_number = accountNum.replace(
                                /.(?=.{4,}$)/g,
                                'X',
                              );
                              return (
                                <>
                                  <Accordion
                                    defaultActiveKey={data._id}
                                    className="mb-4 sender-accordion">
                                    <Accordion.Item eventKey="1">
                                      <div className="d-flex align-items-center">
                                        <div className="row w-100 p-3">
                                          <div className="col-sm-6 col-12 mb-2 accordion-left-sec d-flex align-items-center">
                                            <img
                                              src={UserIco}
                                              alt="user-ico"
                                              className="userIco me-3"
                                            />
                                            <Accordion.Header></Accordion.Header>
                                            <div>
                                              <p className="mb-0">
                                                {data.relation}{' '}
                                              </p>
                                              <h6>{data.name} </h6>
                                            </div>
                                          </div>
                                          <div className="col-sm-6 col-12 accordion-right-sec d-flex align-items-start align-items-sm-center justify-content-start justify-content-sm-end pe-sm-4 pe-0">
                                            <img
                                              src={Flag2}
                                              alt="flag-ico"
                                              className="me-2 flag-ico"
                                            />
                                            <div>
                                              <p className="mb-0">
                                                INR account ending with{' '}
                                                {accountLastNumber}{' '}
                                              </p>
                                              <button
                                                onClick={() => handleShow(data)}
                                                className="editAccount-btn ms-0">
                                                {' '}
                                                Edit Account
                                              </button>
                                              <img
                                                src={DeleteIco}
                                                alt="delete-ico"
                                                className="delete-ico ms-2"
                                                onClick={() =>
                                                  openDeleteModal(data)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <Accordion.Body>
                                        <div
                                          className="accordion"
                                          id="accordionExample">
                                          <div className="accordion-item">
                                            <div className="accordion-body row">
                                              <div className="col-sm-4 col-12 mb-sm-4 mb-2">
                                                <h4 className="mb-sm-3 mb-0">
                                                  Full Name
                                                </h4>
                                                <h5>{data.name}</h5>
                                              </div>
                                              <div className="col-sm-4 col-12 mb-sm-4 mb-2">
                                                <h4 className="mb-sm-3 mb-0">
                                                  Bank Name
                                                </h4>
                                                <h5>{data.bank_name}</h5>
                                              </div>
                                              <div className="col-sm-4 col-12 mb-sm-4 mb-2">
                                                <h4 className="mb-sm-3 mb-0">
                                                  Account Number
                                                </h4>
                                                <h5>{account_number}</h5>
                                              </div>
                                              <div className="col-sm-4 col-12 mb-sm-0 mb-2">
                                                <h4 className="mb-sm-3 mb-0">
                                                  Email
                                                </h4>
                                                <h5 className="email-sec">
                                                  {data.email}
                                                </h5>
                                              </div>
                                              <div className="col-sm-4 col-12">
                                                <h4 className="mb-sm-3 mb-0">
                                                  Phone Number
                                                </h4>
                                                <h5>{data.phone}</h5>
                                              </div>
                                              <div className="col-sm-4 col-12">
                                                <h4 className="mb-sm-3 mb-0">
                                                  Relation
                                                </h4>
                                                <h5>{data.relation}</h5>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="profile" title="Your Recipients">
                    <div className="inner-receipient pt-3">
                      <div className="col-12 d-flex justify-content-end add-receipent-sec mb-2">
                        <button
                          className="d-flex align-items-center"
                          onClick={() => handleShow('receipient')}>
                          <img src={AddIco} alt="add-ico" className="me-2" />
                          Add a Receipient
                        </button>
                      </div>
                      <div className="row d-sm-none d-flex mx-1">
                        {receipientsList.length === 0 ? (
                          <div className="row new-receipient-sec py-5 mx-0 text-center text-sm-start">
                            <div className="col-sm-3 col-12">
                              <img
                                src={NoReceipients}
                                className="mb-4"
                                alt="NoReceipients"
                                style={{ width: '200px' }}
                              />
                            </div>
                            <div className="col-sm-9 col-12 d-flex align-items-center">
                              <div>
                                <p className="mb-0">
                                  Please add the details of account you want to
                                  pay.
                                </p>
                                <p>
                                  Please ensure the details are complete and
                                  correct.
                                  <div className="tooltip-wrap">
                                    <img src={InfoIcon} alt="InfoIcon" />
                                    <span className="tooltiptext">
                                      If your university hasn't provided these
                                      details, you can move money to your UK
                                      account and then pay on their portal to
                                      save huge FX fees.
                                    </span>
                                  </div>
                                </p>
                                <button
                                  onClick={() => handleShow('receipient')}
                                  className="d-block mx-auto">
                                  {' '}
                                  ADD RECIPIENT{' '}
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-12 mt-2 px-0">
                            {receipientsList.map((data) => {
                              let str = data.account_number;
                              let accountLastNumber = str.slice(str.length - 4);
                              let accountNum = data.account_number;
                              let account_number = accountNum.replace(
                                /.(?=.{4,}$)/g,
                                'X',
                              );
                              return (
                                <>
                                  <Accordion
                                    defaultActiveKey={data._id}
                                    className="mb-4">
                                    <Accordion.Item eventKey="1">
                                      <div className="d-flex align-items-center">
                                        <div className="row w-100 p-3">
                                          <div className="col-sm-6 col-12 accordion-left-sec d-flex align-items-center">
                                            <img
                                              src={UserIco}
                                              alt="user-ico"
                                              className="userIco me-3"
                                            />
                                            <div>
                                              <p className="mb-0">
                                                {data.type}
                                              </p>
                                              <h6>
                                                {data.account_holder_name}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="col-12 col-sm-6 accordion-right-sec d-flex align-items-start align-items-sm-center justify-content-start  justify-content-sm-end pe-sm-4 pe-0 mt-2 mt-sm-0">
                                            <img
                                              src={FlagIco}
                                              alt="flag-ico"
                                              className="me-2 flag-ico"
                                            />
                                            <div>
                                              <p className="mb-0">
                                                GBP account ending with{' '}
                                                {accountLastNumber}{' '}
                                              </p>
                                              <button
                                                onClick={() => handleShow(data)}
                                                style={{ fontWeight: '500' }}
                                                className="editAccount-btn">
                                                {' '}
                                                Edit Account
                                                {/* <img
                                                src={EditIco}
                                                alt="edit-ico"
                                                style={{ width: '25px' }}
                                              />{' '} */}
                                              </button>
                                              <img
                                                src={DeleteIco}
                                                alt="delete-ico"
                                                className="delete-ico ms-2"
                                                onClick={() =>
                                                  openDeleteModal(data._id)
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-12 d-flex align-items-start justify-content-center mt-3">
                                            {' '}
                                            <button
                                              onClick={() => handlePayNow(data)}
                                              style={{
                                                border: '0px',
                                                borderRadius: '50px',
                                                backgroundColor: '#81E4C4',
                                                padding: '5px 15px',
                                                fontWeight: '600',
                                              }}
                                              className="payNow-btn ms-2">
                                              {' '}
                                              PAY NOW{' '}
                                            </button>{' '}
                                          </div>
                                        </div>
                                        <Accordion.Header></Accordion.Header>
                                      </div>
                                      <Accordion.Body>
                                        <div
                                          className="accordion"
                                          id="accordionExample">
                                          <div className="accordion-item">
                                            <div className="accordion-body row">
                                              <div className="col-12 col-sm-4 mb-2">
                                                <h4 className="mb-0 mb-sm-3">
                                                  Full Name
                                                </h4>
                                                <h5>
                                                  {data.account_holder_name}
                                                </h5>
                                              </div>
                                              <div className="col-12 col-sm-4 mb-sm-4 mb-2">
                                                <h4 className="mb-0 mb-sm-3">
                                                  Bank Name
                                                </h4>
                                                <h5>{data.bank_name}</h5>
                                              </div>
                                              <div className="col-12 col-sm-4 mb-sm-4 mb-2">
                                                <h4 className="mb-0 mb-sm-3">
                                                  Account Number
                                                </h4>
                                                <h5>{account_number}</h5>
                                              </div>
                                              <div className="col-12 col-sm-4 mb-sm-0 mb-2">
                                                <h4
                                                  className="mb-0 mb-sm-3"
                                                  style={{
                                                    textTransform: 'capitalize',
                                                  }}>
                                                  {data.type} Address
                                                </h4>
                                                <h5>{data.bank_address}</h5>
                                              </div>
                                              <div className="col-12 col-sm-4 mb-sm-0 mb-2">
                                                <h4 className="mb-0 mb-sm-3">
                                                  SWIFT CODE
                                                </h4>
                                                <h5>{data.swift_code}</h5>
                                              </div>
                                              <div className="col-12 col-sm-4 mb-sm-0 mb-2">
                                                <h4 className="mb-0 mb-sm-3">
                                                  Relation
                                                </h4>
                                                <h5>{data.type}</h5>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
              {/* Delete Confirmation Modal */}
              <Modal
                show={deleteConfirm}
                onHide={handleClose}
                className="delete-modal-wrappr">
                <Modal.Body className="text-center">
                  <h2> Confirm Delete </h2>
                  <img src={RemoveIco} alt="RemoveIco" className="w-25 my-3" />
                  <p>
                    {' '}
                    Are you sure you want to delete this{' '}
                    {editData._id ? 'sender' : 'recipient'} details?{' '}
                  </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    className="close-btn">
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    className="confirm-btn"
                    onClick={
                      editData?.relation ? deleteSender : deleteReceipients
                    }>
                    Confirm
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* Not verfied doc modal */}
              <Modal
                show={docNotVerify}
                onHide={handleClose}
                className="notVerify-modal-wrappr">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="text-center">
                  <img src={AuthFailed} alt="RemoveIco" className="w-25 my-3" />
                  <h2> No Authentication Done.... </h2>
                  <p> Sorry! Please finish Authentication. </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                  <Link
                    to="/account-verification"
                    className="link-btn px-3 py-2">
                    {' '}
                    COMPLETE ACCOUNT VERIFICATION{' '}
                  </Link>
                </Modal.Footer>
              </Modal>

              {/* delete success modal */}
              <Modal
                show={successShow}
                onHide={handleSuccessClose}
                className="delete-modal-wrappr">
                <Modal.Body className="text-center">
                  <img src={CheckIco} alt="RemoveIco" className="w-25 my-3" />
                  <h2> Successfully Deleted!</h2>
                  {/* <p> Are you sure you want to delete this document? </p> */}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                  <Button
                    variant="secondary"
                    onClick={handleSuccessClose}
                    className="success-btn">
                    OK
                  </Button>
                </Modal.Footer>
              </Modal>
              <div className="row mt-5">
                <div className="col-12">
                  <Footer />
                </div>
              </div>
            </div>
          </div>
          {loader && (
            <div className="loader-wrapper d-flex align-items-center justify-content-center">
              <BallTriangle
                height="100"
                width="100"
                color="#45C29A"
                ariaLabel="loading"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Receipients;
