/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useRef } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';

import './money-modal.scss';
// @ts-ignore
import PaidTo from '../../../Assets/Images/paid-to.svg';
// @ts-ignore
import PendingIco from '../../../Assets/Images/pending.svg';
// @ts-ignore
import AdditionalIco from '../../../Assets/Images/additional-docs.svg';
// @ts-ignore
import RejectedIco from '../../../Assets/Images/rejected.svg';
// @ts-ignore
import PlusIco from '.././../../Assets/Images/plus-ico.svg';
// @ts-ignore
import JPGIco from '.././../../Assets/Images/jpg-ico.png';
// @ts-ignore
import DocxIco from '.././../../Assets/Images/docx-ico.png';
// @ts-ignore
import PNGIco from '.././../../Assets/Images/png-ico.png';
// @ts-ignore
import PDFIco from '.././../../Assets/Images/pdf-ico.png';

import { transactionDate } from '../../../Util/functions';
import { RootState } from '../../../global';

interface IMoneyModalProps {
  show: boolean;
  handleClose: () => void;
  onDoneClick: () => void;
  title: string;
  transactionData: {
    createdAt: Date;
    fromAmt: number;
    toAmt: number;
    recipientName: string;
    className: string;
    remittance_id: string;
    action: 'UPLOAD_PROOF' | 'UPLOAD_ADDITIONAL_DOCS' | 'UPLOAD_REJECTED_PROOF';
  };
  docsData: {
    field: string;
    label: string;
    location?: string | null;
    originalname?: string | null;
    status?: 'pending' | 'rejected' | 'approved';
    message?: string;
    _id: string;
  }[];
}

const MoneyModal = ({
  show,
  handleClose,
  title,
  transactionData,
  docsData,
  onDoneClick,
}: IMoneyModalProps) => {
  const [docs, setDocs] = useState<
    {
      field: string;
      label: string;
      message?: string;
      status?: 'pending' | 'rejected' | 'approved';
      _id: string;
      file: File | null | { location: string; name: string };
      uploadProgress: number;
    }[]
  >([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const token = useSelector(
    (state: RootState) => state.user.user!.tokens.access,
  );

  useEffect(() => {
    setDocs(
      docsData.map((d) => {
        let uploadProgress = 0;
        let file: { location: string; name: string } | null = null;

        if (d.status === 'rejected') {
          uploadProgress = 0;
          file = null;
        } else if (d.location) {
          uploadProgress = 100;
          file = { location: d.location!, name: d.originalname! };
        }

        return {
          ...d,
          file,
          uploadProgress,
        };
      }),
    );
  }, [docsData]);

  const fileRef = useRef<HTMLInputElement>(null);

  const renderStatusImage = () => {
    switch (transactionData.action) {
      case 'UPLOAD_REJECTED_PROOF':
        return <img src={RejectedIco} height="45" width="45" alt="rejected" />;
      case 'UPLOAD_PROOF':
        return <img src={PendingIco} alt="pending" height="45" width="45" />;
      case 'UPLOAD_ADDITIONAL_DOCS':
        return (
          <img
            src={AdditionalIco}
            alt="additional-docs"
            height="45"
            width="45"
          />
        );
      default:
        return null;
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    const tempDocs = [...docs];

    tempDocs[selectedIndex].file = file;

    try {
      const formData = new FormData();

      formData.append(tempDocs[selectedIndex].field, file);

      if (tempDocs[selectedIndex].field !== 'proof') {
        await axios.patch(
          `${process.env.REACT_APP_API_URL}/money/${transactionData.remittance_id}/docs`,
          formData,
          {
            headers: {
              authorization: token,
            },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percent = Math.floor((loaded * 100) / total);
              tempDocs[selectedIndex].uploadProgress = percent;
            },
          },
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/money/${transactionData.remittance_id}/proof`,
          formData,
          {
            headers: {
              authorization: token,
            },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percent = Math.floor((loaded * 100) / total);
              tempDocs[selectedIndex].uploadProgress = percent;
            },
          },
        );
      }
    } catch (e) {
      // console.log(e);
    }

    setDocs(tempDocs);
  };

  return (
    <Modal show={show} onHide={handleClose} className="modal-dashboard">
      <Modal.Header closeButton>
        <h5 className="modal-title" id="staticBackdropLabel">
          {title}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <h6 className="text-left">{title}</h6>
        <div className="upload-modal row">
          <div className="col-12 text-center">
            <div
              className={`d-flex justify-content-between align-items-center p-3 ${transactionData.className}`}>
              <div>{transactionDate(transactionData.createdAt)}</div>

              <div className="d-flex">
                <img src={PaidTo} alt="paid-to" className="mr-2" />
                <div>
                  <span>Paid to</span>
                  <br />
                  {transactionData.recipientName}
                </div>
              </div>

              <div>
                <span>₹{transactionData.fromAmt}</span>
                <br />£{transactionData.toAmt.toFixed(2)}
              </div>

              <div>{renderStatusImage()}</div>
            </div>
          </div>
          <div className="col-12 mt-3">
            <h3>Please Upload</h3>
            <input
              id="input-file"
              type="file"
              ref={fileRef}
              onChange={onFileChange}
              style={{ display: 'none' }}
              accept=".jpg, .jpeg, .png, .doc, .docx,.pdf"
            />

            {docs.map((doc, index) => {
              const imgClass = doc.file === null ? 'defaultIco' : 'uploadIco';
              let img = PlusIco;

              if (!doc.file) {
                img = PlusIco;
              } else if (doc.file.name.match(/\.(pdf)$/i)) {
                img = PDFIco;
              } else if (doc.file.name.match(/\.(doc|docx)$/i)) {
                img = DocxIco;
              } else if (doc.file.name.match(/\.(png)$/i)) {
                img = PNGIco;
              } else if (doc.file.name.match(/\.(jpg|jpeg)$/i)) {
                img = JPGIco;
              }

              return (
                <div
                  className="row w-100 justify-content-center mb-4 mt-2"
                  key={index}>
                  <div className="col-2 d-flex justify-content-center">
                    <span className="icoContainer">
                      <button
                        type="button"
                        className="h-100"
                        onClick={() => {
                          setSelectedIndex(index);
                          fileRef.current?.click();
                        }}>
                        <img src={img} alt="plus" className={imgClass} />
                      </button>
                    </span>
                  </div>

                  <div className="col-8">
                    <h5 className="docTitle">{doc.label}</h5>
                    <p>{doc.file === null ? 'No Image' : doc.file.name}</p>
                    <ProgressBar now={doc.uploadProgress} />
                  </div>
                  <div className="col-2 d-flex align-items-end">
                    <p className="mb-0">{doc.uploadProgress}%</p>
                  </div>

                  {doc.message && (
                    <div className="mx-3" style={{ width: '70%' }}>
                      <strong>Reason: </strong>
                      {doc.message}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-12 btn-wrapper d-flex align-items-center justify-content-center">
          <button
            className="d-flex align-items-center p-4 justify-content-center next-btn login-btn"
            type="button"
            onClick={onDoneClick}>
            Done
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MoneyModal;
