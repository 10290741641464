import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UserType = {
  id: string;
  attributes: { [key: string]: string };
  tokens: {
    access: string;
    id: string;
    refresh: string;
  };
};

const initialState: {
  user: UserType | null;
  islogged: boolean;
  temp: { [id: string]: string };
  reqDocuments: [];
  documentList: [];
  whoSelRejectedDoc: string;
  whoWantPay: string;
} = {
  user: null,
  temp: {
    transaction_reference_number: '',
  },
  islogged: false,
  reqDocuments: [],
  documentList: [],
  whoSelRejectedDoc: '',
  whoWantPay: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserType | null>) => {
      state.user = action.payload;
      state.islogged = !!action.payload;
    },
    setLogout: (state, action: PayloadAction<UserType | null>) => {
      // console.log(action);
      state;
    },
    setTemp: (state, action: PayloadAction<{ key: string; value: string }>) => {
      // console.log(action);
      state.temp[action.payload.key] = action.payload.value;
    },
    setIslogged: (state, action) => {
      state.islogged = action.payload;
    },
    setReqDoc: (state, action) => {
      state.reqDocuments = action.payload;
    },
    setListDoc: (state, action) => {
      state.documentList = action.payload;
    },
    selectedDocUsr: (state, action) => {
      state.whoSelRejectedDoc = action.payload;
    },
    setIsUserLogin: (state, action) => {
      state.islogged = action.payload;
    },
    setWhoWantPay: (state, action) => {
      state.whoWantPay = action.payload;
    },
  },
});

const {
  setUser,
  setTemp,
  setIslogged,
  setReqDoc,
  setListDoc,
  setIsUserLogin,
  setLogout,
  selectedDocUsr,
  setWhoWantPay,
} = userSlice.actions;
export {
  setUser,
  setTemp,
  setIslogged,
  setReqDoc,
  setListDoc,
  setIsUserLogin,
  selectedDocUsr,
  setLogout,
  setWhoWantPay,
};

export default userSlice.reducer;
