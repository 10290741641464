/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSenderSums } from './calculator';
import { RootState } from './../index';

type DocAPI = {
  _id: string;
  user_id: string;
  docs: {
    location: string;
    field: string;
    originalname: string;
    status: 'pending' | 'approved' | 'rejected';
    message: string | null;
    _id: string;
  }[];
  createdAt: string;
  updatedAt: string;
  status: {
    father: boolean;
    mother: boolean;
    spouse: boolean;
    own: boolean;
  };
};

type RecipientAPI = {
  _id: string;
  user_id: string;
  type: 'own' | 'university' | 'rent';
  account_holder_name: string;
  bank_name: string;
  account_number: string;
  iban: string;
  swift_code?: string;
  sort_code: string;
};

type TransactionAPI = {
  _id: string;
  user_id: string;
  remittance_id: string;
  status:
    | 'PROOF_UPLOAD_PENDING'
    | 'COMPLETED_BY_USER'
    | 'AMOUNT_RECEIVED'
    | 'AMOUNT_TRANSFERRED'
    | 'AMOUNT_CREDITED';
  currency: {
    from: {
      amount: number;
      symbol: string;
    };
    to: {
      amount: number;
      symbol: string;
    };
  };
  recipient: {
    _id: string;
    type: 'own' | 'university' | 'rent';
    account_holder_name: string;
    bank_name: string;
  };
  proof: {
    _id: string;
    field: string;
    location: string;
    originalname: string;
    status: 'pending' | 'approved' | 'rejected';
    message: string | null;
  } | null;
  additional_docs: {
    _id: string;
    field: string;
    label: string;
    location: string;
    originalname: string;
  }[];
  createdAt: string;
  updatedAt: string;
};

interface DataTypes {
  docs: {
    data: DocAPI | null;
    loading: boolean;
    error: string | null;
  };
  recipients: {
    data: {
      recipients: RecipientAPI[];
      count: { university: number; rent: number; own: number };
    } | null;
    loading: boolean;
    error: string | null;
  };
  transactions: {
    data: {
      remittances: TransactionAPI[];
      total: {
        _id: string;
        sum: number;
        count: number;
      };
    } | null;
    loading: boolean;
    error: string | null;
  };
  sendMoneyStep: number;
  payNowSendMoney: any;
  noRecipientFlag: boolean;
  noSenderAdded: boolean;
  sidebarVal: string;
  toggleBtn: boolean;
  bankDetails: any;
}

const initialState: DataTypes = {
  docs: {
    loading: true,
    error: null,
    data: null,
  },
  recipients: {
    loading: true,
    error: null,
    data: null,
  },
  transactions: {
    loading: true,
    error: null,
    data: null,
  },
  sendMoneyStep: 1,
  payNowSendMoney: [],
  noRecipientFlag: false,
  noSenderAdded: false,
  sidebarVal: 'home',
  toggleBtn: false,
  bankDetails: [],
};
const dataReducer = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setDocs: (state, action: PayloadAction<DocAPI>) => {
      state.docs.data = action.payload;
      state.docs.loading = false;
    },
    setDocsLoading: (state, action: PayloadAction<boolean>) => {
      state.docs.loading = action.payload;
    },
    setDocsError: (state, action: PayloadAction<string | null>) => {
      state.docs.error = action.payload;
    },
    setSideBarVal: (state, action) => {
      state.sidebarVal = action.payload;
    },
    setSendMoneyStep: (state, action) => {
      state.sendMoneyStep += 1;
    },
    setBackStep: (state, action) => {
      state.sendMoneyStep -= 1;
    },
    setRecipients: (
      state,
      action: PayloadAction<{
        recipients: RecipientAPI[];
        count: { university: number; rent: number; own: number };
      }>,
    ) => {
      state.recipients.data = action.payload;
      state.recipients.loading = false;
    },
    setRecipientsLoading: (state, action: PayloadAction<boolean>) => {
      state.recipients.loading = action.payload;
    },
    setReceipientCheck: (state) => {
      state.noRecipientFlag = true;
    },
    setSenderCheck: (state) => {
      state.noSenderAdded = true;
    },
    setSenderReset: (state) => {
      state.noSenderAdded = false;
    },
    setReceipientReset: (state) => {
      state.noRecipientFlag = false;
    },
    setMenuToggleBtn: (state) => {
      state.toggleBtn = !state.toggleBtn;
    },
    setRecipientsError: (state, action: PayloadAction<string | null>) => {
      state.recipients.error = action.payload;
    },
    clearSendMoneyVal: (state, action: PayloadAction<string | null>) => {
      state.sendMoneyStep = 1;
    },
    payNowSendMoney: (state, action: PayloadAction<string | null>) => {
      state.payNowSendMoney = action.payload;
      state.sendMoneyStep = 2;
    },
    clearPayNowId: (state, action: PayloadAction<string | null>) => {
      state.payNowSendMoney = '';
    },
    setTransactions: (
      state,
      action: PayloadAction<{
        remittances: TransactionAPI[];
        total: { _id: string; sum: number; count: number };
      }>,
    ) => {
      state.transactions.data = action.payload;
      state.transactions.loading = false;
    },
    setTransactionsLoading: (state, action: PayloadAction<boolean>) => {
      state.transactions.loading = action.payload;
    },
    setTransactionsError: (state, action: PayloadAction<string | null>) => {
      state.transactions.error = action.payload;
    },
    setBankDetails: (state, action: PayloadAction<string | null>) => {
      state.bankDetails = action.payload;
    },
  },
});

const {
  setDocs,
  setDocsError,
  setRecipients,
  setRecipientsError,
  setTransactions,
  setTransactionsError,
  setSendMoneyStep,
  clearSendMoneyVal,
  payNowSendMoney,
  setReceipientCheck,
  clearPayNowId,
  setBackStep,
  setReceipientReset,
  setSideBarVal,
  setMenuToggleBtn,
  setSenderCheck,
  setSenderReset,
  setBankDetails,
} = dataReducer.actions;

const fetchDocs = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { user } = getState().user;

    try {
      const { data } = await axios.get<DocAPI>(
        `${process.env.REACT_APP_API_URL}/files?type=own`,
        {
          headers: {
            authorization: user!.tokens.access,
          },
        },
      );

      dispatch(setDocs(data));
    } catch (e) {
      setDocsError('Error fetching documents');
      // console.log(e);
    }
  };
};

const fetchRecipients = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { user } = getState().user;

    try {
      const { data } = await axios.get<{
        recipients: RecipientAPI[];
        count: { university: number; rent: number; own: number };
      }>(`${process.env.REACT_APP_API_URL}/recipient`, {
        headers: {
          authorization: user!.tokens.access,
        },
      });

      dispatch(setRecipients(data));
    } catch (e) {
      setRecipientsError('Error fetching recipients');
      // console.log(e);
    }
  };
};

const fetchTransactions = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { user } = getState().user;

    try {
      const { data } = await axios.get<{
        remittances: TransactionAPI[];
        total: { _id: string; sum: number; count: number; inrSum: number };
        senderSums: { _id: string; inrSum: number }[];
      }>(`${process.env.REACT_APP_API_URL}/money`, {
        headers: {
          authorization: user!.tokens.access,
        },
      });

      dispatch(setSenderSums(data.senderSums));
      dispatch(setTransactions(data));
    } catch (e) {
      setTransactionsError('Error fetching transactions');
      // console.log(e);
    }
  };
};

export {
  fetchDocs,
  fetchRecipients,
  fetchTransactions,
  setSendMoneyStep,
  clearSendMoneyVal,
  payNowSendMoney,
  clearPayNowId,
  setReceipientCheck,
  setBackStep,
  setReceipientReset,
  setSideBarVal,
  setMenuToggleBtn,
  setSenderCheck,
  setSenderReset,
  setBankDetails,
};

export default dataReducer.reducer;
