import React, { useEffect, useState } from 'react';
import CheckoutStripe from '../checkout-stripe/checkout-stripe';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
const StripePaymentElement = (props) => {
  const clientSecretId = useSelector(
    (state) => state.sim.clientSecretId.client_secret,
  );
  const [clientSecret, setClientSecret] = useState(clientSecretId);

  useEffect(() => {
    setClientSecret(clientSecretId);
  }, []);
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <Elements options={options} stripe={stripePromise}>
        <CheckoutStripe />
      </Elements>
    </>
  );
};

export default StripePaymentElement;
