import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  calculateGST,
  calculateTCS,
  BANK_CHARGE,
  remigosCharge,
  REMIGOS_TRANSACTION_CHARGE,
} from '../../Util/charges';

const INITIAL_AMT = 500_000;
const INITIAL_TCS = undefined;

type ToSymbols = 'AUD' | 'USD' | 'GBP' | 'CAD';

interface CalculatorState {
  from: {
    amount: number;
    symbol: 'INR';
  };
  tcsAmount: number | undefined;
  to: {
    amount: number;
    symbol: ToSymbols;
  };
  rates: { [id: string]: number };
  taxes: {
    remigosCharge: number;
    bankCharge: number;
    gst: number;
    tcs: number | false;
    ourCharge: number;
  };
  finalAmount: number;
  temp: {
    sender_id: string;
    senderSums: {
      _id: string;
      inrSum: number;
    }[];
  };
}

const initialState: CalculatorState = {
  from: {
    amount: INITIAL_AMT,
    symbol: 'INR',
  },
  tcsAmount: INITIAL_TCS,
  rates: {},
  to: {
    amount: 0,
    symbol: 'GBP',
  },
  taxes: {
    remigosCharge: INITIAL_AMT * 0.005,
    bankCharge: BANK_CHARGE,
    gst: calculateGST(INITIAL_AMT),
    tcs: calculateTCS(INITIAL_AMT, INITIAL_TCS || 0),
    ourCharge: BANK_CHARGE + REMIGOS_TRANSACTION_CHARGE,
  },
  finalAmount:
    INITIAL_AMT - INITIAL_AMT * 0.005 - BANK_CHARGE - calculateGST(INITIAL_AMT),
  temp: {
    sender_id: '',
    senderSums: [],
  },
};

const calculatorSlice = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    setAmount: (state, action: PayloadAction<number>) => {
      const amount = action.payload;
      state.from.amount = amount;

      if (amount >= 10000) {
        const { rates, to, tcsAmount } = state;
        const tcs = calculateTCS(amount, tcsAmount || 0);
        let finalCharge = amount * remigosCharge(rates[to.symbol]);

        if (tcs) {
          const temp = amount - tcs;
          finalCharge = temp * remigosCharge(rates[to.symbol]);
        }

        const ourCharge =
          BANK_CHARGE + REMIGOS_TRANSACTION_CHARGE + finalCharge;

        const gst = calculateGST(amount - BANK_CHARGE - (tcs || 0));

        const finalAmount = amount - gst - (tcs || 0) - ourCharge;

        state.taxes.gst = gst;
        state.from.amount = amount;
        state.taxes.tcs = tcs;
        state.finalAmount = finalAmount;
        state.to.amount = finalAmount * rates[to.symbol];
        state.taxes.remigosCharge = finalCharge;
        state.taxes.ourCharge = ourCharge;
      }
    },
    setRates: (state, action: PayloadAction<{ [id: string]: number }>) => {
      const rates = action.payload;
      const { GBP, USD, AUD, CAD } = rates;
      const { to, tcsAmount } = state;
      const { amount } = state.from;
      const tcs = calculateTCS(amount, tcsAmount || 0);
      let finalCharge = amount * remigosCharge(rates[to.symbol]);

      if (tcs) {
        const temp = amount - tcs;
        finalCharge = temp * remigosCharge(rates[to.symbol]);
      }

      const ourCharge = BANK_CHARGE + REMIGOS_TRANSACTION_CHARGE + finalCharge;

      const gst = calculateGST(amount - BANK_CHARGE - (tcs || 0));

      const finalAmount = amount - gst - (tcs || 0) - ourCharge;

      state.rates = { GBP, USD, AUD, CAD };
      state.taxes.gst = gst;
      state.from.amount = amount;
      state.taxes.tcs = tcs;
      state.finalAmount = finalAmount;
      state.to.amount = finalAmount * rates[to.symbol];
      state.taxes.remigosCharge = finalCharge;
      state.taxes.ourCharge = ourCharge;
    },
    setToSymbol: (state, action: PayloadAction<ToSymbols>) => {
      const symbol = action.payload;
      const { from } = state;
      const { amount } = from;

      const { rates, tcsAmount } = state;
      const tcs = calculateTCS(amount, tcsAmount || 0);
      let finalCharge = amount * remigosCharge(rates[symbol]);

      if (tcs) {
        const temp = amount - tcs;
        finalCharge = temp * remigosCharge(rates[symbol]);
      }

      const ourCharge = BANK_CHARGE + REMIGOS_TRANSACTION_CHARGE + finalCharge;

      const gst = calculateGST(amount - BANK_CHARGE - (tcs || 0));

      const finalAmount = amount - gst - (tcs || 0) - ourCharge;

      state.taxes.gst = gst;
      state.from.amount = amount;
      state.taxes.tcs = tcs;
      state.finalAmount = finalAmount;
      state.to.amount = finalAmount * rates[symbol];
      state.to.symbol = symbol;
      state.taxes.remigosCharge = finalCharge;
      state.taxes.ourCharge = ourCharge;
    },
    setTcsAmount: (state, action: PayloadAction<number>) => {
      state.tcsAmount = action.payload;
    },
    setSenderId: (state, action: PayloadAction<string>) => {
      state.temp.sender_id = action.payload;

      if (action.payload) {
        const sum = state.temp.senderSums.find((v) => v._id === action.payload);

        if (sum) {
          state.tcsAmount = sum.inrSum;
        } else {
          state.tcsAmount = undefined;
        }
      }
    },
    setSenderSums: (
      state,
      action: PayloadAction<{ _id: string; inrSum: number }[]>,
    ) => {
      state.temp.senderSums = action.payload;
    },
    setToAmount: (state, action: PayloadAction<number>) => {
      const amount = action.payload;
      const { rates, to } = state;
      state.to.amount = amount;

      const finalAmount = amount / rates[to.symbol];
      state.finalAmount = finalAmount;
    },
  },
});

const {
  setAmount,
  setRates,
  setToSymbol,
  setToAmount,
  setTcsAmount,
  setSenderSums,
  setSenderId,
} = calculatorSlice.actions;

const fetchRates = () => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await axios.get<{
        rates: { [id: string]: number };
      }>(`${process.env.REACT_APP_API_URL}/money/rates`);

      dispatch(
        setRates({
          GBP: res.data.rates.GBP,
          USD: res.data.rates.USD,
          CAD: res.data.rates.CAD,
          AUD: res.data.rates.AUD,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };
};

export {
  setAmount,
  setToSymbol,
  setToAmount,
  fetchRates,
  setSenderId,
  setTcsAmount,
  setSenderSums,
};

export default calculatorSlice.reducer;
