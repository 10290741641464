import React, { useEffect, useState } from 'react';
import TravelIco from '../../../Assets/Images/travel-and-tourism.svg';
import './sim-plans.scss';
import { Link, Navigate } from 'react-router-dom';
import RecommendIco from '../../../Assets/Images/quality.svg';
import PlanHeadIco from '../../../Assets/Images/NicePng_vodafone-logo-png_8364212.svg';
import CheckMark from '../../../Assets/Images/check-mark.svg';
import Giffgaff from '../../../Assets/Images/giffgaff-sim.svg';
import LebaraSim from '../../../Assets/Images/lebara-sim.svg';
import VoxiSim from '../../../Assets/Images/voxi-sim.svg';
import AllAnswers from '../all-answers/all-answers';
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import {
  sim,
  setSimPlanId,
  setTravelDate,
  setDeliveryCountry,
  setHandlePlan,
  setDateOfTravelDays,
  setExpressDeliveryStartDate,
  setExpressDeliveryEndDate,
  setDeliveryType,
  setFreeDeliveryStartDate,
  setFreeDeliveryEndDate,
} from '../../../global/features/sim';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import RecommendedIco from '../../../Assets/Images/ico-recommended.svg';
import DeliveryIco from '../../../Assets/Images/delivery-ico.svg';
import IcoSim from '../../../Assets/Images/ico-sim.svg';
import BagIco from '../../../Assets/Images/bag-ico.svg';
import moment from 'moment';
import Header from '../../../Common/Pages/header/header';

const SimPlans = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sim = useSelector((state) => state.sim);
  const user = useSelector((state) => state.user);
  const token = user.user?.tokens?.access;
  const [planList, setPlanList] = useState([]);
  const [simProviders, setSimProviders] = useState([]);
  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleDateString('es-CL'),
  );
  let axiosToken = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token,
    },
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/sim/plans`, axiosToken)
      .then((res) => {
        setPlanList(res.data.data);
      })
      .catch((err) => {
        // console.log('err-get-sim ', err);
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}/sim/providers`, axiosToken)
      .then((res) => {
        setSimProviders(res.data.data);
      })
      .catch((err) => {
        console.log('err-get-sim ', err);
      });
    (async () => {
      try {
        const profileDetail = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/profile?cognito=false`,
          axiosToken,
        );
      } catch (error) {
        console.log(error);
      }
    })();
    // et formatedDate = sim.travelDate.split('-');
    // let newAge = datediff(formateDate);
    //console.log('newAge: ', newAge);
  }, [sim]);
  useEffect(() => {
    const today = moment(new Date());
    let new_date = moment(today, 'DD.MM.YYYY');
    // Refactor
    const deliveryDate = sim.deliveryCountry === 'UK' ? 3 : 13;
    const freeDeliveryStart = new_date
      .add(deliveryDate, 'days')
      .format('DD-MMM-YYYY');
    const freeDeliveryEnd = new_date.add(1, 'days').format('DD-MMM-YYYY');
    dispatch(setFreeDeliveryStartDate(freeDeliveryStart));
    dispatch(setFreeDeliveryEndDate(freeDeliveryEnd));
  }, []);
  const handleChange = (e) => {
    const departure = moment(e.target.value);
    const today = moment(new Date());
    const diff = Math.ceil(departure.diff(today) / (60 * 60 * 24 * 1000));
    dispatch(setTravelDate(e.target.value));
    dispatch(setDateOfTravelDays(diff));
    var new_date = moment(today, 'DD.MM.YYYY');
    const expressDeliveryStart = new_date.add(6, 'days').format('DD-MMM-YYYY');
    const expressDeliveryEnd = new_date.add(1, 'days').format('DD-MMM-YYYY');
    dispatch(setExpressDeliveryStartDate(expressDeliveryStart));
    dispatch(setExpressDeliveryEndDate(expressDeliveryEnd));
  };
  const datediff = (first, second) => {
    let timeStamp = new Date(second);
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  };
  const formateDate = (dateStr) => {
    let dateBreakDown = dateStr.split('-');
    return `${dateBreakDown[1]}-${dateBreakDown[2]}-${dateBreakDown[0]}`;
  };
  const parseDate = (str) => {
    var mdy = str.split('-');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  };
  const viewDetails = (val) => {
    dispatch(setSimPlanId(val._id));
    if (
      val.standard_delivery_price != 0 &&
      val.express_delivery_price == null &&
      val.country == 'UK'
    ) {
      dispatch(setDeliveryType('standard_delivery'));
    } else if (
      val.standard_delivery_price == 0 &&
      val.express_delivery_price != 0
    ) {
      dispatch(setDeliveryType('express_delivery'));
    } else if (
      val.standard_delivery_price == 0 &&
      val.express_delivery_price == 0
    ) {
      dispatch(setDeliveryType('standard_delivery'));
    }
    navigate('/sim-details');
  };
  const handleChangeRadio = async (e) => {
    dispatch(setDeliveryCountry(e.target.name));
    const today = moment(new Date());
    let new_date = moment(today, 'DD.MM.YYYY');
    const deliveryDate = sim.deliveryCountry === 'India' ? 3 : 13;
    const freeDeliveryStart = new_date
      .add(deliveryDate, 'days')
      .format('DD-MMM-YYYY');
    const freeDeliveryEnd = new_date.add(1, 'days').format('DD-MMM-YYYY');
    dispatch(setFreeDeliveryStartDate(freeDeliveryStart));
    dispatch(setFreeDeliveryEndDate(freeDeliveryEnd));
    try {
      const profileDetail = await axios.put(
        `${process.env.REACT_APP_API_URL}/user/profile?cognito=false`,
        {
          currentCountry: e.target.name,
          movingDate: sim.travelDate ?? null,
        },
        axiosToken,
      );
      dispatch(setDeliveryCountry(e.target.name));
    } catch (error) {
      console.log(error);
    }
  };
  const handlePlan = (val) => {
    dispatch(setHandlePlan(val));
  };
  return (
    <>
      <div className="px-sm-4">
        <div className="row mx-0" style={{ marginTop: '100px' }}>
          <div className="col-5 my-orders-btn d-sm-none d-flex align-items-center">
            <h1 className="mb-0 text-center ms-sm-auto">SIM CARD</h1>
          </div>
          <div className="col-7 my-orders-btn d-sm-none">
            <Link to="/my-orders">
              <h5 className="mb-0 text-center ms-auto">
                My Orders <img src={BagIco} alt="bag-ico" />
              </h5>
            </Link>
          </div>
          <div className="col-sm-8 col-12 pt-3 mb-3">
            <h2 className="top-para">
              As per your selection, we have the following SIM plans for you.
            </h2>
          </div>
          <div className="col-4 my-orders-btn d-none d-sm-block">
            <Link to="/my-orders">
              <h5 className="mb-0 text-center ms-auto">
                My Orders <img src={BagIco} alt="bag-ico" />
              </h5>
            </Link>
          </div>
          <div
            className={
              sim.deliveryCountry != 'UK'
                ? 'col-sm-5 col-12 mt-4 mt-sm-0 d-flex align-items-center top-right-sec mb-4 mb-sm-0'
                : 'col-sm-5 col-12 mt-4 mt-sm-0 d-flex align-items-center top-right-sec mb-4 mb-sm-0'
            }>
            <span className="me-4">
              <img src={IcoSim} alt="bankIco" />
            </span>
            <div>
              <h4> SIM Delivery </h4>
              <div className="col-12 d-flex align-items-center">
                <input
                  className="form-check-input mt-0"
                  type="radio"
                  name="UK"
                  onChange={(e) => handleChangeRadio(e)}
                  id="UK"
                  checked={sim.deliveryCountry === 'UK'}
                  value={sim.deliveryCountry}
                />
                <label
                  className="form-check-label ps-2"
                  htmlFor="flexRadioDefault2">
                  UK
                </label>
                <input
                  className="form-check-input mt-0 ms-5"
                  type="radio"
                  name="India"
                  onChange={(e) => handleChangeRadio(e)}
                  id="India"
                  checked={sim.deliveryCountry === 'India'}
                  value={sim.deliveryCountry}
                />
                <label
                  className="form-check-label ps-2"
                  htmlFor="flexRadioDefault2">
                  India
                </label>
              </div>
            </div>
          </div>
          {sim.deliveryCountry != 'UK' && (
            <div className="col-sm-6 col-12 d-flex align-items-center top-date-selection ps-sm-5">
              <span>
                <img src={TravelIco} alt="bankIco" />
              </span>
              <div className="form-floating ms-4 ms-sm-5 w-50">
                <input
                  className="form-control"
                  id="dateOfTravel"
                  name="dateOfTravel"
                  type="date"
                  value={sim.travelDate}
                  placeholder="Date"
                  onChange={handleChange}
                  // onBlur={handleBlur}
                />
                <label htmlFor="birthDate">
                  Date of travel<strong style={{ color: 'red' }}>* </strong>
                </label>
                {/* {touched.birthDate && errors.birthDate ? (
                    <p className="error-message">{errors.birthDate}</p>
                ) : null} */}
              </div>
            </div>
          )}
        </div>
        <div className="col-10 mx-auto pt-3 mb-5 text-center mt-4">
          <h2 className="plans-text-para">
            You have <strong>20 days</strong> before you travel, you have the
            following choices.
          </h2>
        </div>
        <div className="row mt-2 mx-2 mx-sm-0">
          <h2 className="mb-3" style={{ fontSize: '22px', fontWeight: '700' }}>
            Most Recommended SIM Card for Students
          </h2>
          <div
            className="col-12 px-0 px-sm-3"
            style={{ display: 'flex', flexDirection: 'column' }}>
            {simProviders.map((data) => {
              return (
                <>
                  <div
                    className={
                      sim.deliveryCountry === 'UK' &&
                      data.provider_name === 'Voxi'
                        ? 'inner-content row mb-5 voxi-sim mx-auto'
                        : sim.deliveryCountry === 'India' &&
                          data.provider_name === 'Giffgaff'
                        ? 'inner-content row mb-5 giffgaff-sim mx-auto'
                        : 'inner-content row mb-5 mb-sm-5 mx-auto'
                    }>
                    {(sim.deliveryCountry === 'UK' &&
                      data.provider_name === 'Voxi') ||
                    (sim.deliveryCountry === 'India' &&
                      data.provider_name === 'Giffgaff') ? (
                      <img
                        src={RecommendedIco}
                        alt="recommended-ico"
                        className="recommended-sec"
                      />
                    ) : (
                      ''
                    )}
                    <div className="row h-100 pb-0 pb-sm-5 mx-auto sim-providers-sec">
                      <div className="col-3 col-sm-2">
                        <span className="text-center d-block py-5 py-sm-4 mx-sm-4">
                          <img
                            src={
                              data.provider_name === 'Giffgaff'
                                ? Giffgaff
                                : data.provider_name === 'Lebara'
                                ? LebaraSim
                                : VoxiSim
                            }
                            alt="planHeadIco"
                            className="w-100"
                          />
                        </span>
                      </div>
                      <div className="mt-4 amount-sec pe-sm-4 col-9 col-sm-3">
                        <h5> {data.provider_name} SIM card</h5>
                        <div>
                          <div className="d-flex align-items-start">
                            <span>
                              <p className="mb-0">
                                Delivery
                                {sim.deliveryCountry === 'UK'
                                  ? ' Free'
                                  : ' Charges'}
                              </p>
                              <h6 className="mb-1">
                                ₹{data.standard_delivery_price}
                              </h6>
                            </span>
                            {data.standard_delivery_price == 0 && (
                              <span>
                                <img
                                  src={DeliveryIco}
                                  alt="delivery-ico"
                                  className="ms-2 pt-2"
                                />
                              </span>
                            )}
                          </div>
                          <p
                            style={{
                              fontWeight: '600',
                              fontSize: '12px',
                            }}>
                            Expected Delivery
                            <p
                              style={{
                                fontStyle: 'italic',
                                fontSize: '12px',
                                fontWeight: '600',
                              }}>
                              {sim.freeDeliveryStartDate} -{' '}
                              {sim.freeDeliveryEndDate}
                            </p>
                          </p>
                        </div>
                        {data.express_delivery_price != null && (
                          <span>
                            <p className="mb-0"> Express Delivery </p>
                            <h6 className="mb-1">
                              ₹{data.express_delivery_price}
                            </h6>
                            <p
                              style={{
                                fontWeight: '600',
                                fontSize: '12px',
                              }}>
                              Expected Delivery
                              <p
                                style={{
                                  fontStyle: 'italic',
                                  fontSize: '12px',
                                }}>
                                {sim.expressDeliveryDate} -{' '}
                                {sim.expressDeliveryEndDate}
                              </p>
                            </p>
                          </span>
                        )}
                      </div>
                      <div className="mt-2 mb-4 signal-checker-text d-block d-sm-none">
                        <h6>
                          CheckSignal quality here <br />
                          <strong>
                            <a
                              href="https://www.signalchecker.co.uk/ "
                              target="_blank"
                              style={{
                                color: '#45C29A',
                                textDecoration: 'none',
                              }}
                              rel="noreferrer">
                              www.signalchecker.co.uk
                            </a>
                          </strong>
                        </h6>
                      </div>
                      <div className="col-sm-7 col-12 ps-3 sim-features-sec">
                        <h5> SIM Features </h5>
                        <div className="btm-plans pb-3">
                          <ul className="row pt-3">
                            {data.features.map((val) => {
                              return (
                                <>
                                  <div className="col-sm-6 col-12">
                                    <li>
                                      <img src={CheckMark} alt="CheckMark" />
                                      {val}
                                    </li>
                                  </div>
                                </>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="mt-5 signal-checker-text d-none d-sm-block">
                          <h6>
                            CheckSignal quality here <br />
                            <strong>
                              <a
                                href="https://www.signalchecker.co.uk/ "
                                target="_blank"
                                style={{
                                  color: '#45C29A',
                                  textDecoration: 'none',
                                }}
                                rel="noreferrer">
                                www.signalchecker.com
                              </a>
                            </strong>
                          </h6>
                        </div>
                      </div>
                      <div
                        className="mb-4 text-end row mx-auto"
                        style={{ position: 'relative' }}>
                        <div
                          className="col-12 d-flex align-items-center justify-content-start ps-0 ps-sm-3 pt-4"
                          style={{
                            borderTop: '1px solid #ccc',
                          }}>
                          {data.provider_name == 'Lebara' && (
                            <Accordion className="w-100 sim-plans-accordion mb-2 mb-sm-0">
                              <Accordion.Item
                                eventKey="1"
                                style={{ border: '0px' }}>
                                <div className="d-flex align-items-center">
                                  <div className="row">
                                    <div className="col-12 mb-4 mb-sm-0 accordion-left-sec d-flex align-items-center">
                                      <div>
                                        <h6
                                          className="mt-2 mt-sm-0 d-none d-sm-block"
                                          style={{
                                            fontSize: '18px',
                                            fontWeight: '700',
                                          }}>
                                          See all {data.provider_name} Plans
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                  {data.provider_name == 'Lebara' && (
                                    <Accordion.Header>
                                      <p className="mb-0 d-sm-none pe-2">
                                        <span className="d-none d-sm-inline-block">
                                          See
                                        </span>
                                        All {data.provider_name}
                                        Plans
                                      </p>
                                    </Accordion.Header>
                                  )}
                                </div>
                                <Accordion.Body className="row pb-0 pb-sm-2 px-0 px-sm-3 pt-5 pt-sm-0">
                                  {planList
                                    .filter(
                                      (filterVal) =>
                                        data.provider_name === 'Lebara',
                                    )
                                    .map((val) => {
                                      return (
                                        <>
                                          <div
                                            className="accordion text-start col-12 col-sm-4"
                                            id="accordionExample"
                                            key={val._id}>
                                            <ul className="ps-0">
                                              <div
                                                className="d-flex align-items-center p-1 mb-4"
                                                style={{
                                                  background: '#D5F6EB',
                                                  borderRadius: '50px',
                                                  position: 'relative',
                                                }}>
                                                <span
                                                  className="d-flex align-items-center justify-content-center text-white"
                                                  style={{
                                                    width: '60px',
                                                    height: '60px',
                                                    backgroundColor: '#2a2a2a',
                                                    borderRadius: '50px',
                                                  }}>
                                                  <h5>
                                                    {' '}
                                                    £{val.price_per_month}{' '}
                                                  </h5>
                                                </span>
                                                <div className="ms-3 mt-1">
                                                  <h4 className="mb-0">
                                                    {' '}
                                                    Data{' '}
                                                  </h4>
                                                  <h5> {val.data} </h5>
                                                </div>
                                                <input
                                                  className="form-check-input"
                                                  type="radio"
                                                  name="currentLocation"
                                                  id="UK"
                                                  value="UK"
                                                  onChange={() =>
                                                    handlePlan(val)
                                                  }
                                                  style={{
                                                    position: 'absolute',
                                                    right: '20px',
                                                  }}
                                                />
                                              </div>
                                              <li className="mb-3">
                                                <h4 className="mb-0">
                                                  {' '}
                                                  Calls{' '}
                                                </h4>
                                                <h5> {val.call} </h5>
                                              </li>
                                              <li className="mb-3">
                                                <h4 className="mb-0">
                                                  {' '}
                                                  Calls to India
                                                </h4>
                                                <h5>
                                                  {' '}
                                                  {val.calling_to_india}{' '}
                                                </h5>
                                              </li>
                                              <li className="mb-3">
                                                <h4 className="mb-0">
                                                  {' '}
                                                  Texts{' '}
                                                </h4>
                                                <h5> {val.texts} </h5>
                                              </li>
                                              <li className="mb-3">
                                                <h4 className="mb-0">
                                                  {' '}
                                                  EU Roaming{' '}
                                                </h4>
                                                <h5> {val.eu_roaming} </h5>
                                              </li>
                                            </ul>
                                          </div>
                                        </>
                                      );
                                    })}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )}
                        </div>

                        <div className="view-details-btn">
                          <button
                            className={
                              !sim.lebaraPlan && data.provider_name === 'Lebara'
                                ? 'btn-disable px-3 py-2'
                                : 'plan-btn px-3 py-2'
                            }
                            disabled={
                              !sim.lebaraPlan && data.provider_name === 'Lebara'
                            }
                            onClick={() => viewDetails(data)}>
                            <span className="d-sm-block d-none">
                              VIEW DETAILS
                            </span>
                            <span className="d-block d-sm-none">VIEW</span>
                          </button>
                          {!sim.lebaraPlan &&
                            data.provider_name === 'Lebara' && (
                              <p
                                style={{
                                  textAlign: 'center',
                                  fontSize: '13px',
                                  fontStyle: 'italic',
                                  fontWeight: '500',
                                }}>
                                Please select a plan first.
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="px-4">
          <AllAnswers />
        </div>
      </div>
    </>
  );
};

export default SimPlans;
