import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CheckMark from '../../../Assets/Images/check-mark.svg';
import SimIco from '../../../Assets/Images/sim-img.svg';
import Header from '../../../Common/Pages/header/header';
import NavBar from '../../../Common/Pages/NavBar/nav-bar';
import './sim-details.scss';
import AllAnswers from '../all-answers/all-answers';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Giffgaff from '../../../Assets/Images/giffgaff-sim.svg';
import LebaraSim from '../../../Assets/Images/lebara-sim.svg';
import VoxiSim from '../../../Assets/Images/voxi-sim.svg';
import { useDispatch } from 'react-redux';
import sim, { setDeliveryType } from '../../../global/features/sim';
import BagIco from '../../../Assets/Images/bag-ico.svg';

const SimDetails = () => {
  const user = useSelector((state) => state.user);
  const token = user.user?.tokens?.access;
  const sim = useSelector((state) => state.sim);
  const planId = useSelector((state) => state.sim.simPlanId);
  const simDeliveryType = useSelector((state) => state.sim.deliveryType);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    let axiosToken = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/sim/providers/${planId}`,
        axiosToken,
      )
      .then((res) => {
        setSelectedPlan(res.data.data);
      })
      .catch((err) => {
        // console.log('err-get-sim ', err);
      });
  }, [planId]);

  const handleDeliveryType = (e) => {
    dispatch(setDeliveryType(e.target.name));
  };
  return (
    <div className="container-fluid">
      <Header />
      <div className="row">
        <div className="col-3 ps-0 sidebar-container">
          <NavBar />
        </div>
        <div
          className="col-12 col-sm-8 mx-auto right-wrapper px-sm-5"
          style={{ paddingTop: '100px' }}>
          <div className="row d-flex d-sm-none mb-4">
            <div className="col-6 sim-heading d-flex align-items-center">
              <h5 className="mb-0 text-center">
                {selectedPlan.provider_name} SIM Details
              </h5>
            </div>
            <div className="col-6 my-orders-btn">
              <h5 className="mb-0 text-center ms-auto">
                My Orders <img src={BagIco} alt="bag-ico" />
              </h5>
            </div>
          </div>
          <div className="row mt-sm-5 mt-3 sim-details-wrapper mx-2 mx-sm-0">
            <div className="col-sm-4 col-12">
              <h5 className="mb-0 text-center d-none d-sm-block">
                {selectedPlan.provider_name} SIM Details
              </h5>
              <span className="text-sm-end text-center d-block py-4 mx-4">
                {' '}
                <img
                  src={
                    selectedPlan.provider_name === 'Giffgaff'
                      ? Giffgaff
                      : selectedPlan.provider_name === 'Lebara'
                      ? LebaraSim
                      : VoxiSim
                  }
                  alt="planHeadIco"
                />
              </span>
            </div>
            <div className="mt-4 amount-sec px-4 col-sm-7 col-12">
              <div className="row">
                <div className="col-6">
                  <div className="mt-3 mb-1 delivery-heading">
                    <p className="mb-0"> Delivery Charges </p>
                    <h6 className="mb-0 me-1">
                      ₹
                      {sim.deliveryType == 'standard_delivery'
                        ? selectedPlan.standard_delivery_price
                        : selectedPlan.express_delivery_price}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="btm-plans pt-3 mt-3">
                <h3> SIM Features </h3>
                <ul className="row pt-2">
                  {selectedPlan?.features?.map((val) => {
                    return (
                      <>
                        <div className="col-sm-6 col-12">
                          <li key={val._id} className="pb-2">
                            <img src={CheckMark} alt="CheckMark" />
                            {val}
                          </li>
                        </div>
                      </>
                    );
                  })}
                </ul>
              </div>
              <div className="delivery-options">
                <ul className="d-sm-flex align-items-center justify-content-start pt-4 ps-0 text-center">
                  {(sim.deliveryType == 'express_delivery' ||
                    selectedPlan.provider_name == 'Giffgaff') &&
                    sim.deliveryCountry !== 'UK' && (
                      <li className="travelling-sec me-sm-4 d-flex align-items-center justify-content-start">
                        <div className="col-sm-12 col-12 form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="express_delivery"
                            id="express_delivery"
                            value="express_delivery"
                            onChange={(e) => handleDeliveryType(e)}
                            checked={
                              sim.deliveryType == 'express_delivery'
                                ? true
                                : false
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2">
                            Express Delivery
                          </label>
                          <p
                            style={{
                              fontStyle: 'italic',
                              fontSize: '12px',
                            }}>
                            {sim.expressDeliveryDate} -{' '}
                            {sim.expressDeliveryEndDate}
                          </p>
                        </div>
                      </li>
                    )}
                  {(sim.deliveryType == 'standard_delivery' ||
                    selectedPlan.provider_name === 'Giffgaff') && (
                    <li className="travelling-sec d-flex align-items-center justify-content-start">
                      <div className="col-sm-12 col-12 form-check ms-sm-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="standard_delivery"
                          id="standard_delivery"
                          value="standard_delivery"
                          onChange={(e) => handleDeliveryType(e)}
                          checked={
                            sim.deliveryType == 'standard_delivery'
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2">
                          Normal Delivery
                        </label>
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}>
                          {sim.freeDeliveryStartDate} -{' '}
                          {sim.freeDeliveryEndDate}
                        </p>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
              <div className="btn-wrapper mt-4 py-2 py-sm-0 my-4 text-center text-sm-start">
                <Link
                  className={
                    !simDeliveryType
                      ? 'btn-disable py-2 px-4'
                      : 'login-btn py-2 px-4'
                  }
                  to={simDeliveryType ? '/sim-checkout' : '#'}>
                  {' '}
                  ORDER SIM
                </Link>
              </div>
            </div>
          </div>
          <AllAnswers />
        </div>
      </div>
    </div>
  );
};

export default SimDetails;
