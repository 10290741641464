import React, { useState } from 'react';
import TravelIco from '../../../Assets/Images/travel-and-tourism.svg';
import './sim-travelling.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTravelDate,
  setDateOfTravelDays,
  setExpressDeliveryStartDate,
  setDeliveryCountry,
} from '../../../global/features/sim';
import SimBanner from '../../../Assets/Images/sim-banner.svg';
import axios from 'axios';
import IndiaIco from '../../../Assets/Images/india-gate-ico.svg';
import UKIco from '../../../Assets/Images/uk-ico.svg';
import moment from 'moment';

const SimTravellingComponent = (props) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user);
  const simData = useSelector((state) => state.sim);
  const token = userDetails.user?.tokens?.access;
  const handleDate = (e) => {
    props.setDate(e.target.value);
    dispatch(setTravelDate(e.target.value));
  };
  // Refactor Name
  const handleChange = (e) => {
    dispatch(setDeliveryCountry(e.target.value));
    props.setSimDelivery(e.target.value);
    // Refactor
    const departure = moment(e.target.value);
    const today = moment(new Date());
    const diff = Math.ceil(departure.diff(today) / (60 * 60 * 24 * 1000));
    // dispatch(setTravelDate(e.target.value));
    dispatch(setDateOfTravelDays(diff));
    var new_date = moment(today, 'DD.MM.YYYY');
    const expressDeliveryStart = new_date.add(5, 'days').format('DD-MMM-YYYY');
    dispatch(setExpressDeliveryStartDate(expressDeliveryStart));
  };

  return (
    <div className="row px-4" id="travelling-container">
      <div className="sim-banner">
        <img src={SimBanner} alt="sim-banner" className="w-100 px-0 mb-5" />
        <span>
          <h3> Get your UK SIM from here </h3>
          <p>
            Based on your selection, we will find you the best possible SIM Card{' '}
          </p>
        </span>
      </div>
      <h1 className="main-heading">
        {' '}
        Based on your selection, we will find you the best possible SIM Card{' '}
      </h1>
      <p className="selectOptionPara">
        {' '}
        Please select the options which suits you best.{' '}
      </p>
      <div className="col-12 mx-auto py-4 mt-3" id="travelling-wrapper">
        <h2 className="ps-sm-4 mt-3"> Where do you want the SIM delivered? </h2>
        <ul className="d-sm-flex align-items-center justify-content-start pt-3 ps-0 ps-sm-4 text-center">
          <li className="travelling-sec me-sm-4 mb-5 mb-sm-0 d-flex align-items-center justify-content-start">
            <span>
              <img src={UKIco} alt="bankIco" />
            </span>
            <div className="col-sm-3 col-2 form-check ms-3">
              <input
                className="form-check-input"
                type="radio"
                name="currentLocation"
                onChange={handleChange}
                id="uk"
                value="UK"
                //   checked={form.values.currentLocation === 'Outside India'}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                UK
              </label>
            </div>
          </li>
          <li className="travelling-sec d-flex align-items-center justify-content-start">
            <span>
              <img src={IndiaIco} alt="bankIco" />
            </span>
            <div className="col-sm-3 col-2 form-check ms-3">
              <input
                className="form-check-input"
                type="radio"
                name="currentLocation"
                onChange={handleChange}
                id="india"
                value="India"
                //   checked={form.values.currentLocation === 'Outside India'}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                India
              </label>
            </div>
          </li>
        </ul>
        {props.simDelivery == 'India' && (
          <>
            <h2 className="ps-sm-4 mt-5"> When are you travelling? </h2>
            <ul className="d-flex align-items-center justify-content-start pt-3 ps-0 ps-sm-4 text-center">
              <li className="travelling-sec">
                <span className="d-inline-block d-sm-flex">
                  <img src={TravelIco} alt="bankIco" />
                </span>
              </li>
              <div className="form-floating mb-2 ms-3">
                <input
                  className="form-control"
                  id="birthDate"
                  name="birthDate"
                  type="date"
                  value={props.date}
                  placeholder="Date"
                  onChange={handleDate}
                />
                <label htmlFor="birthDate">Date of Travel</label>
                {/* {touched.birthDate && errors.birthDate ? (
            <p className="error-message">{errors.birthDate}</p>
          ) : null} */}
              </div>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default SimTravellingComponent;
