/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './calculator.scss';
import { countries } from '../../../Util/currency-data';

import { RootState } from '../../../global';
import {
  fetchRates,
  setToSymbol,
  setAmount,
  setToAmount,
} from '../../../global/features/calculator';
import { fetchTransactions } from '../../../global/features/data';
import { weekDays } from '../../../Util/constants';

const isValidNumber = (num: string) => {
  if (num.match(/^-?\d+$/)) {
    return true;
  } else if (num.match(/^\d+\.\d+$/)) {
    return true;
  } else {
    return false;
  }
};

function Calculator() {
  const currencyCountry = Object.values(countries).filter(
    (i) => i.name !== 'India',
  );

  const { fromAmt, toSymbol, rates, finalAmt, toAmt, tcs, gst, ourCharge } =
    useSelector((state: RootState) => ({
      fromAmt: state.calculator.from.amount,
      toSymbol: state.calculator.to.symbol,
      rates: state.calculator.rates,
      finalAmt: state.calculator.finalAmount,
      toAmt: state.calculator.to.amount,
      tcs: state.calculator.taxes.tcs,
      gst: state.calculator.taxes.gst,
      bankCharge: state.calculator.taxes.bankCharge,
      ourCharge: state.calculator.taxes.ourCharge,
      remigosCharge: state.calculator.taxes.remigosCharge,
    }));
  const dispatch = useDispatch();

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchRates());
    // @ts-ignore
    dispatch(fetchTransactions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFromInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidNumber(e.target.value)) {
      dispatch(setAmount(parseFloat(e.target.value)));
    } else if (e.target.value.trim().length < 1) {
      dispatch(setAmount(0));
    }
  };

  const onToInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidNumber(e.target.value)) {
      dispatch(setToAmount(parseFloat(e.target.value)));
    }
  };

  const shouldArriveByText = () => {
    const now = new Date();

    const marketHour = new Date();
    marketHour.setHours(14);
    marketHour.setMinutes(0, 0, 0);

    if ([0, 5, 6].includes(now.getDay())) {
      return weekDays[1];
    }

    if (marketHour.getTime() < now.getTime()) {
      return now.getDay() === 4 ? weekDays[1] : weekDays[now.getDay() + 2];
    }

    return weekDays[now.getDay() + 1];
  };

  return (
    <>
      <div>
        <div className="col-12 wrapper-calculator">
          <div className="row fields-row">
            <div className="form-floating mb-4 col-9">
              <input
                type="text"
                value={fromAmt.toFixed()}
                className="form-control"
                id="floatingInput"
                placeholder="You Send"
                onChange={onFromInputChange}
              />
              <label htmlFor="floatingInput">You Send</label>
              {fromAmt < 10000 && (
                <p className="error-message" style={{ marginBottom: '0' }}>
                  Amount should be Rs. 10000 or more
                </p>
              )}
            </div>
            <div className="col-3">
              <div className="currency-flag currency-flag-usd" />

              <select>
                <option value={countries.INR.currency.code}>
                  {countries.INR.currency.code}
                </option>
              </select>
            </div>
          </div>
          <div className="col-12">
            <ul className="mb-0">
              <li className="our-charges-sec pt-3">
                <h6 className="mb-0 pb-0 pt-1 ps-2">
                  {`₹${ourCharge.toFixed(2)}`}
                  <span> Our Charges</span>
                </h6>
              </li>
              <li className="our-charges-sec">
                <h6 className="mb-0 pb-0 pt-1 ps-2">
                  {`₹${gst.toFixed(2)}`}
                  <span> 18% GST</span>
                </h6>
              </li>
              {tcs && (
                <li className="our-charges-sec">
                  <h6 className="mb-0 pb-0 pt-1 ps-2">
                    {`₹${tcs.toFixed(2)}`}
                    <span> TCS</span>
                  </h6>
                </li>
              )}
              <li className="amount-convert-sec">
                <h6 className="mb-0 pb-0 pt-1 ps-2">
                  {`₹${finalAmt.toFixed(2)}`}
                  <span> Amount we will convert</span>
                </h6>
              </li>
              <li className="exchange-rate-sec pb-4">
                <h6 className="mb-0 pb-0 pt-1 ps-2">
                  {(1 / rates[toSymbol]).toFixed(2)}
                  <span>
                    {' '}
                    Exchange Rate - Guaranteed Rate for <strong>
                      1 Hour
                    </strong>{' '}
                  </span>
                </h6>
              </li>
            </ul>
          </div>
          <div className="row fields-row">
            <div className="form-floating mb-4 col-9">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                value={toAmt.toFixed(2)}
                placeholder="Recipient Receives"
                disabled
                onChange={onToInputChange}
              />
              <label htmlFor="floatingInput">Recipient Receives</label>
            </div>
            <div className="col-3">
              <select
                value={toSymbol}
                // @ts-ignore
                onChange={(e) => dispatch(setToSymbol(e.target.value))}>
                {currencyCountry.map((obj) => {
                  return (
                    <option value={obj.currency.code} key={obj.id}>
                      <img src={obj.flag} />
                      {obj.currency.code}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-12">
            <div className="row mx-0 mt-4">
              <div className="col-6 left-bottom-wrapper pt-2">
                <h6>
                  <strong>
                    You are saving Rs. {(fromAmt * 0.02).toFixed(2)}
                  </strong>
                  <br />
                  as compared to bank
                </h6>
              </div>
              <div className="col-6 right-bottom-wrapper pt-2">
                <h6 className="">
                  <span>Your funds should arrive by</span>
                  <br />
                  <strong>{shouldArriveByText()}</strong>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Calculator;
