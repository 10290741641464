import React from 'react';
import './check-eligibility.scss';
import { universities } from '../../Util/constants';

interface ICheckEligibilityProps {
  passport: string;
  university: string;
  currentLocation: 'India' | 'Outside India';
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
  touched: { [field: string]: boolean };
  errors: { [field: string]: string };
}

function CheckEligibility({
  passport,
  university,
  currentLocation,
  touched,
  errors,
  onBlur,
  onChange,
}: ICheckEligibilityProps) {
  return (
    <>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-11 col-sm-6 d-flex align-items-center justify-content-center mx-auto text-center reset-password-wrapper">
              <div className="pt-4 w-100">
                <h1 className="pt-5 pb-4 page-heading">
                  {' '}
                  Let’s check your eligibility{' '}
                </h1>
                <div className="form-floating">
                  <select
                    className="form-select"
                    id="passport"
                    name="passport"
                    value={passport}
                    onChange={onChange}
                    onBlur={onBlur}
                    aria-label="Floating label select example">
                    <option value="" selected disabled hidden>
                      Select Passport
                    </option>
                    <option value="India">India</option>
                    <option value="Other Passport">Other Passport</option>
                  </select>
                  <label htmlFor="floatingSelect">Select your Passport</label>
                  {touched.passport && errors.passport && (
                    <p className="error-message">{errors.passport}</p>
                  )}
                </div>
                <div className="form-floating mt-4">
                  <select
                    className="form-select"
                    value={university}
                    onChange={onChange}
                    onBlur={onBlur}
                    id="university"
                    name="university"
                    aria-label="Floating label select example">
                    {universities.map((university, i) => (
                      <option value={university} key={i}>
                        {university}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelect">
                    Select University applied to
                  </label>
                  {touched.university && errors.university && (
                    <p className="error-message">{errors.university}</p>
                  )}
                </div>
                <div className="col-12 text-start mb-3 mt-5 ps-3">
                  <h6 className="currentLocationHead"> Current Location </h6>
                  <div className="row mt-4 mx-0">
                    <div className="col-6 form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="currentLocation"
                        value="India"
                        id="currentLocationIndia"
                        onChange={onChange}
                        onBlur={onBlur}
                        checked={currentLocation === 'India'}
                      />
                      <label
                        className="currentLocationIndia"
                        htmlFor="flexRadioDefault1">
                        India
                      </label>
                    </div>
                    <div className="col-6 form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="currentLocation"
                        onBlur={onBlur}
                        value="Outside India"
                        onChange={onChange}
                        id="currentLocation"
                        checked={currentLocation === 'Outside India'}
                      />
                      <label className="currentLocation" htmlFor="flexRadio">
                        Outside India
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckEligibility;
