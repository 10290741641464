import Login from './Component/login/index';
import Signup from './Component/signup/signup';
import Dashboard from './Component/dashboard/dashboard';
import VerifyOTP from './Component/verify-otp/verify-otp';
import Calculator from './Common/Pages/calculator/calculator';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import Recipients from './Component/accounts/all-recepients/receipients';
import SendMoney from './Component/send-money/send-money';
import AccountVerification from './Component/upload-document/complete-account-verification/complete-account-verification';
import BankTransfer from './Component/send-money/bank-transfer/bank-transfer';
import ResetPassword from './Component/reset-password/reset-password';
import ReferFriend from './Component/refer-friend/refer-friend';
import ProfileDetailsForm from './Component/profile-detail-form/profile-detail-form';
import Transactions from './Component/transactions/transactions';
import ReuploadSuccess from './Component/upload-document/reupload-success-doc/reupload-success-doc';
import RejectedDocUpload from './Component/upload-document/rejected-doc-upload/rejected-doc-upload';
import SendMoneyStatus from './Component/send-money-status/send-money-status';
import SimCard from './Component/sim-card/index';
import AllPlansComponent from './Component/sim-card/all-plans/all-plans';
import SimTravellingComponent from './Component/sim-card/sim-travelling/sim-travelling';
import SimDeliveryLocation from './Component/sim-card/sim-delivery-location/sim-delivery-location';
import SimPlans from './Component/sim-card/sim-plans/sim-plans';
import SimCheckoutPage from './Component/sim-card/checkout-page/checkout-page';
import SimDetails from './Component/sim-card/sim-details/sim-details';
import StripePaymentElement from './Component/sim-card/stripe-payment/stripe-payment';
import PaymentCompleted from './Component/sim-card/payment-complete/payment-completed';
import OrderStatusComponent from './Component/sim-card/order-status/order-status';
import MyOrder from './Component/sim-card/my-orders/my-orders';
import BankAccountComponent from './Component/bank-account/index';
import BankAccountOpen from './Component/bank-account/bank-revolut/bank-revolut';

export const urls = [
  {
    path: '/login',
    element: <Login />,
    protected: false,
  },
  {
    path: '/signup',
    element: <Signup />,
    protected: false,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    protected: false,
  },
  {
    path: '/verify-otp',
    element: <VerifyOTP />,
    protected: false,
  },
  {
    path: '/calc',
    element: <Calculator />,
    protected: false,
  },
  {
    path: '/profile-detail-form',
    element: <ProfileDetailsForm />,
    protected: true,
  },
  {
    path: '/transactions',
    element: <Transactions />,
    protected: true,
  },
  {
    path: '/',
    element: <Dashboard />,
    protected: true,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    protected: true,
  },
  {
    path: '/account-verification',
    element: <AccountVerification />,
    protected: true,
  },
  {
    path: '/rejected-doc-list',
    element: <RejectedDocUpload />,
    protected: true,
  },
  {
    path: '/reupload-success',
    element: <ReuploadSuccess />,
    protected: true,
  },
  {
    path: '/receipients',
    element: <Recipients />,
    protected: true,
  },
  {
    path: '/send-money',
    element: <SendMoney />,
    protected: true,
  },
  {
    path: '/bank-account',
    element: <BankAccountComponent />,
    protected: true,
  },
  {
    path: '/open-bank-account',
    element: <BankAccountOpen />,
    protected: true,
  },
  {
    path: '/bankTransfer',
    element: <BankTransfer />,
    protected: true,
  },
  {
    path: '/refer-friend',
    element: <ReferFriend />,
    protected: true,
  },
  // Refactor
  {
    path: '/sim-card',
    element: <SimCard />,
    protected: true,
  },
  {
    path: '/travelling-date',
    element: <SimTravellingComponent />,
    protected: true,
  },
  {
    path: '/delivery-location',
    element: <SimDeliveryLocation />,
    protected: true,
  },
  {
    path: '/sim-plan',
    element: <SimPlans />,
    protected: true,
  },
  {
    path: '/sim-checkout',
    element: <SimCheckoutPage />,
    protected: true,
  },
  {
    path: '/sim-details',
    element: <SimDetails />,
    protected: true,
  },
  {
    path: '/all-plans',
    element: <AllPlansComponent />,
    protected: true,
  },
  {
    path: '/status/:remittance_id',
    element: <SendMoneyStatus />,
    protected: true,
  },
  {
    path: '/payment-page',
    element: <StripePaymentElement />,
    protected: true,
  },
  {
    path: '/payment-completed',
    element: <PaymentCompleted />,
    protected: true,
  },
  {
    path: '/my-orders',
    element: <MyOrder />,
    protected: true,
  },
  {
    path: '/order-status',
    element: <OrderStatusComponent />,
    protected: true,
  },
];
