import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import LogintoBank from '../../Assets/Images/loginto-bank.svg';
import AddBankDet from '../../Assets/Images/add-bank-det.svg';
import BankDetAdded from '../../Assets/Images/bank-det-added.svg';
import CongratsIco from '../../Assets/Images/congrats.svg';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './add-bank-details.scss';

const AddBankDetails = (props) => {
  const handleClose = () => props.setSliderShow(false);

  return (
    <Modal
      show={props.sliderShow}
      onHide={handleClose}
      className="sm-receipient-modal">
      <Modal.Header closeButton>
        <h5
          className="modal-title"
          id="staticBackdropLabel"
          style={{ textTransform: 'capitalize' }}>
          Add Bank Details
        </h5>
      </Modal.Header>
      <Modal.Body className="text-center">
        {/* Slider */}
        <Carousel interval={null}>
          {/* <Carousel.Item>
            <div style={{ height: '400px' }}>
              <img src={LogintoBank} alt="First slide" className="pt-4" />
              <p className="mt-4">Log into your bank account</p>
            </div>
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item> */}
          <Carousel.Item>
            <div style={{ height: '450px' }}>
              {/* <div className="d-flex align-items-center px-sm-5 px-3 pb-4 pt-3">
                // <img
                //   src={AddBankDet}
                //   alt="First slide"
                //   className="add-bank-ico"
                // />
                <p className="text-start ms-3">
                  Add SBM bank details as beneficiary account
                </p>
              </div> */}
              {/* <div className="account-details row px-5">
                <div className="col-12 text-start">
                  <h6> Bank Name </h6>
                  <p> SBM Bank </p>
                </div>
                <div className="col-12 text-start">
                  <h6> Bank Branch </h6>
                  <p> SBM Bank - branch </p>
                </div>
              </div> */}
              <div className="account-details row px-5 h-75 align-items-center">
                <div className="col-12 text-center">
                  <img
                    src={AddBankDet}
                    alt="First slide"
                    className="add-bank-ico pb-3"
                  />
                  <h4 className="mb-4">
                    Please contact customer care for bank details.
                  </h4>
                  <a
                    href="https://wa.me/message/KVW64HQKH7SID1"
                    target="_blank"
                    className="btn-redirect"
                    rel="noreferrer">
                    Click Here
                  </a>
                </div>
                {/* <div className="col-sm-5 col-12 text-start">
                  <h6> IFSC Code </h6>
                  <p> ICIC0006252 </p>
                </div>*/}
              </div>
            </div>
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          {/* <Carousel.Item>
            <div style={{ height: '400px', margin: 'auto' }}>
              <img src={BankDetAdded} alt="First slide" className="pt-3" />
              <p className="mt-4">
                Wait for 24 hours to get larger transaction limit!
              </p>
            </div>
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item> */}
          {/* <Carousel.Item>
            <div style={{ height: '400px', margin: 'auto' }}>
              <img src={CongratsIco} alt="First slide" className="pt-3" />
              <p className="mt-4">Now you can easily transfer money</p>
            </div>
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item> */}
        </Carousel>
        {/* <Button onClick={addReceipient} className="addNowBtn">
        Add Now
      </Button> */}
      </Modal.Body>
    </Modal>
  );
};

export default AddBankDetails;
