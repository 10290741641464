import React, { useEffect, useState, useContext, createContext } from 'react';
import SchoolIco from '../../Assets/Images/school.svg';
import HeartIco from '../../Assets/Images/heart.svg';
import BankIco from '../../Assets/Images/bank-ico.svg';
import HouseIco from '../../Assets/Images/house.svg';
import AccountIco from '../../Assets/Images/bank-account.svg';
import ParentsIco from '../../Assets/Images/parents.svg';
import './sender.scss';
import { useLinkClickHandler } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import AddReceipientsForm from '../accounts/add-receipients-form/add-receipients-form';
import { setListDoc } from '../../global/features/user';
import PendingIco from '../../Assets/Images/pending.svg';
import RejectedIco from '../../Assets/Images/rejected.svg';
import UploadIco from '../../Assets/Images/up-arrow.png';
import CheckIco from '../../Assets/Images/verified.svg';
import RejectedDocUpload from '../upload-document/rejected-doc-upload/rejected-doc-upload';
import BackIco from '../../Assets/Images/back-ico.svg';
import AuthFailed from '../../Assets/Images/failed-1.svg';
import { useNavigate } from 'react-router-dom';
import { selectedDocUsr, setWhoWantPay } from '../../global/features/user';
import { BallTriangle } from 'react-loader-spinner';
import Calculator from '../../Common/Pages/calculator/calculator';
import {
  setReceipientCheck,
  setSenderCheck,
  clearSendMoneyVal,
  setBankDetails,
} from '../../global/features/data';
import UniversityIco from '../../Assets/Images/educational-school.svg';
import RentIco from '../../Assets/Images/for-rent.svg';
import OwnIco from '../../Assets/Images/own-ico.svg';
import { setSenderId } from '../../global/features/calculator';
import marketClosedIco from '../../Assets/Images/market-closed.svg';
import moment from 'moment';
import 'moment-timezone';

const ThemeContext = createContext();

function Sender(props) {
  const [activeClass, setActiveClass] = useState('active');
  const user = useSelector((state) => state.user);
  const userData = useSelector((state) => state.data?.docs?.data?.status);
  const dataList = useSelector((state) => state.documentList);
  const token = user.user?.tokens?.access;
  const [senderListData, setSenderListData] = useState([]);
  const [radioSenderVal, setRadioSenderVal] = useState('');
  const [radioValue, setRadioValue] = useState('1');
  const [show, setShow] = useState(false);
  const [listDocStatus, setListDocStatus] = useState();
  const [uploadRejectedDoc, setUploadRejectedDoc] = useState(false);
  const [checkDocStatus, setCheckDocStatus] = useState();
  const [docUploadData, setDocUploadData] = useState(false);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [receipientCount, setReceipientCount] = useState();
  const [allDocList, setAllDocList] = useState();
  const [senderListModal, setSenderListModal] = useState(false);

  let timeZoneIST = moment().tz('Asia/Calcutta|Asia/Kolkata').format();
  let current_time = moment(timeZoneIST).format('HH:mm');
  let convertIntoInt = parseInt(current_time);
  const startTime = 9;
  const endTime = 14;
  let axiosToken = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token,
    },
  };
  const receipientVal = (data) => {
    dispatch(setWhoWantPay(data));
    props.setRentAgreementDoc(false);
    props.setReceipientPayVal(data);
    if (
      (props.countReceipientList && props.countReceipientList[data] === 0) ||
      props.countReceipientList[data] === undefined
    ) {
      setActiveClass('active');
      setShow(true);
    }
    // else if (data === 'rent') {
    //   setActiveClass('active');
    //   const rentDoc = allDocList.docs.some((val) => {
    //     return val.field == 'rentAgreement';
    //   });
    //   if (rentDoc) {
    //     props.setRentAgreementDoc(false);
    //   } else {
    //     props.setRentAgreementDoc(true);
    //   }
    // }
  };
  const payHandler = (val) => {
    props.setPayVal('');
    setActiveClass('active');
    if (val === 'relative') {
      props.setPayVal(`relative - father`);
    } else {
      props.setPayVal(val);
    }
  };
  const getRelative = (event) => {
    props.setSelected(event.target.value);
    if (props.payVal === 'relative') {
      props.setPayVal(`relative - father`);
    } else {
      props.setPayVal(`relative - ${event.target.value}`);
    }
    props.setSenderAccountVal(event.target.value);
  };
  const handleSenderVal = (val) => {
    if (props.senderList.count.own === 0 && val === 'own') {
      setSenderListModal(true);
    }
    props.setSenderAccountVal(val);
    setRadioSenderVal('');
  };
  const senderRadioVal = (e) => {
    // Refactor
    if (props.senderList.count[e.target.value] === 0) {
      setSenderListModal(true);
      props.setSenderAccountVal(e.target.value);
    } else {
      props.setSenderAccountVal(e.target.value);
      props.setRelativeContent((prevState) => {
        return prevState.map((element) => {
          if (element.label === e.target.value) {
            element.isCheked = true;
          } else {
            element.isCheked = false;
          }
          return element;
        });
      });
    }
  };
  const innerSenderRadioVal = (e) => {
    setRadioSenderVal(e.target.value);
  };
  const navigate = useNavigate();
  useEffect(() => {
    api();
  }, [props.senderAccountVal]);
  useEffect(() => {
    dispatch(setSenderId(radioSenderVal));
  }, [radioSenderVal]);
  const api = (val) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/sender?relation=${props.senderAccountVal}`,
        axiosToken,
      )
      .then((res) => {
        // console.log('res-get: api ', res);
        let receipientData = res.data.data;
        setSenderListData(receipientData);
      })
      .catch((err) => {
        // console.log('err.response.data.error: ', err);
      });
  };
  const getListOfReceipient = () => {
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/recipient`, axiosToken)
      .then((res) => {
        // console.log('res-get: ', res);
        let receipientData = res.data.count;
        setReceipientCount(receipientData);
        props.setCountReceipientList(receipientData);
        setLoader(false);
      })
      .catch((err) => {
        // console.log('err: ', err);
      });
  };
  useEffect(() => {
    getListOfReceipient();
    if (props.senderId) {
      setRadioSenderVal(props.senderId);
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/files`, axiosToken)
      .then((res) => {
        setAllDocList(res.data);
        setListDocStatus(res.data.status);
        setCheckDocStatus(res.data.incomplete);
        props?.setDocStatus(res.data.incomplete);
        setLoader(false);
      })
      .catch((err) => {
        // console.log('err: ', err);
      });
    if (props.setTimeVal) {
      if (convertIntoInt >= startTime && convertIntoInt <= endTime) {
        props.setTimeVal(true);
      } else {
        props.setTimeVal && props.setTimeVal(false);
      }
    }
  }, []);
  const handleSelectedValue = (data) => {
    props.setSenderId(data._id);
  };
  const handleSuccessClose = () => {
    props.setRentAgreementDoc(false);
    props.setReceipientPayVal('');
    setShow(false);
  };
  const uploadClick = (val) => {
    dispatch(selectedDocUsr(val));
    props.setWhoUploadDoc(val);
    setUploadRejectedDoc(true);
    navigate(`/rejected-doc-list`);
  };
  const previousButton = () => {
    setDocUploadData(false);
  };
  const addReceipient = () => {
    dispatch(setReceipientCheck());
    navigate('/receipients');
  };
  const addSender = () => {
    dispatch(setSenderCheck());
    dispatch(clearSendMoneyVal());
    navigate('/receipients');
  };
  // Refactor
  const handleModalClose = () => {
    setSenderListModal(false);
  };
  const handleSchedule = () => {
    props.setTimeVal(true);
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/money/bank-detail?recipient=${user.whoWantPay}&sender=relative`,
        axiosToken,
      )
      .then((res) => {
        dispatch(setBankDetails(res.data.data));
      })
      .catch((err) => {
        // console.log('err: ', err);
      });
  }, [user.whoWantPay]);
  return (
    <>
      <div className="row mt-4" id="sender-wrapper">
        {props.sendMoneyBar ? (
          // Refactor
          <div className="row mx-auto text-sm-center py-sm-5 pt-4 pb-3 send-money justify-content-center">
            <h1> Who is sending the money? </h1>
            <div className="col-sm-4 col-12 text-center py-sm-5">
              <ul
                className={
                  user.whoWantPay != 'own' || !props.senderAccountVal
                    ? 'd-flex align-items-center justify-content-center pt-2 ps-0 ps-sm-4'
                    : 'd-flex align-items-center justify-content-center justify-content-sm-end pt-2 ps-0 ps-sm-4'
                }>
                {user.whoWantPay != 'own' && (
                  <li>
                    <button
                      onClick={() => {
                        handleSenderVal('own');
                      }}>
                      <span
                        className={
                          activeClass === 'active' &&
                          props.senderAccountVal === 'own'
                            ? 'active'
                            : ''
                        }>
                        <img src={AccountIco} alt="bankIco" />
                      </span>
                    </button>
                    <h6> Own Account </h6>
                  </li>
                )}
                <li
                  className={
                    user.whoWantPay != 'own'
                      ? 'ms-sm-5 mt-sm-0'
                      : 'ms-sm-0 mt-sm-0'
                  }>
                  <button
                    onClick={() => {
                      handleSenderVal('relative');
                    }}>
                    <span
                      className={
                        (activeClass === 'active' &&
                          props.senderAccountVal === 'relative') ||
                        props.senderAccountVal === 'father' ||
                        props.senderAccountVal === 'spouse' ||
                        props.senderAccountVal === 'mother'
                          ? 'active'
                          : ''
                      }>
                      <img src={ParentsIco} alt="bankIco" />
                    </span>
                  </button>
                  <h6> Relative </h6>
                </li>
              </ul>
            </div>
            {props.senderAccountVal && (
              <div
                className={
                  props.senderAccountVal === 'father' ||
                  props.senderAccountVal === 'mother' ||
                  props.senderAccountVal === 'spouse'
                    ? 'col-sm-6 col-12 text-center py-sm-5 d-sm-flex align-items-start justify-content-start relative-details mt-2'
                    : 'col-sm-4 col-12 text-center py-sm-5 d-sm-flex align-items-start justify-content-start relative-details mt-2'
                }>
                {props.senderAccountVal === 'own' ? (
                  <>
                    <ul className="text-start">
                      {senderListData.map((data, i) => {
                        return (
                          <li
                            className="text-start mb-sm-3 form-check"
                            key={data._id}
                            onClick={() => handleSelectedValue(data)}>
                            <input
                              id={`flexRadioDefault${i}`}
                              className="form-check-input"
                              type="radio"
                              name={data.bank_name}
                              key={`keyRadio${i}`}
                              value={data._id}
                              checked={radioSenderVal === data._id}
                              onChange={innerSenderRadioVal}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`flexRadioDefault ${i}`}>
                              <h6> {data.bank_name} </h6>
                              <p> A/C number: {data.account_number} </p>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : (
                  <ul
                    className={
                      user.whoWantPay != 'own' || !props.senderAccountVal
                        ? 'd-flex justify-content-center'
                        : 'd-inline-sm-block d-flex justify-content-center'
                    }>
                    {props.relativeContent.map((data, i) => {
                      return (
                        <li
                          className="text-start pb-3 form-check"
                          key={data.label}
                          onClick={() => handleSelectedValue(data)}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name={data.label}
                            id={`flexRadioDefault ${i}`}
                            value={data.label}
                            checked={data.isCheked}
                            onChange={senderRadioVal}
                            disabled={
                              data.label && userData[data.label] != 'approved'
                                ? true
                                : false
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexRadioDefault ${i}`}>
                            {data.label}
                          </label>
                          {userData[data.label] != 'approved' && (
                            <p
                              style={{
                                fontSize: '10px',
                                color: 'red',
                                paddingBottom: '0px',
                                marginBottom: '0px',
                              }}>
                              {' '}
                              KYC Pending.{' '}
                            </p>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                )}
                {props.senderAccountVal === 'father' ||
                props.senderAccountVal === 'spouse' ||
                props.senderAccountVal === 'mother' ? (
                  <ul>
                    {senderListData.map((data, i) => {
                      return (
                        <li
                          className="text-start pb-3 form-check"
                          key={data._id}
                          onClick={() => handleSelectedValue(data)}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name={`relativeReceipient ${i}`}
                            id={`relativeReceipient ${i}`}
                            value={data._id}
                            key={data._id}
                            checked={radioSenderVal === data._id}
                            onChange={innerSenderRadioVal}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`relativeReceipient ${i}`}>
                            <h6> {data.bank_name} </h6>
                            <p> A/C number: {data.account_number} </p>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        ) : props.receipients ? (
          // Refactor
          <div className="col-12 mx-auto text-sm-center py-sm-5 pt-4 pb-5 px-4 px-sm-3">
            {!props.timeVal ? (
              <div className="row">
                <div className="col-sm-7 col-12 text-center text-sm-left">
                  <img
                    src={marketClosedIco}
                    alt="authFailed"
                    style={{ width: '350px' }}
                  />
                  <h1 className="mt-4"> Market Closed... </h1>
                  <p>
                    Market operates Monday-Friday 9am - 2pm IST except public
                    holidays. But you can schedule a transaction for the next
                    working day even now.
                  </p>
                  <p>
                    You can transfer the money now and we will book a rate for
                    you early in the morning next working day.
                  </p>
                  <div className="btn-wrapper mt-4">
                    <button
                      onClick={handleSchedule}
                      className="login-btn px-3 py-2">
                      Schedule a Transaction
                    </button>
                  </div>
                </div>
                <div className="col-sm-5 mt-5 mt-sm-0 col-12">
                  <Calculator />
                </div>
              </div>
            ) : (
              <>
                {listDocStatus?.father === 'approved' ||
                listDocStatus?.mother === 'approved' ||
                listDocStatus?.spouse === 'approved' ||
                listDocStatus?.own === 'approved' ? (
                  <>
                    <h1 className> Whom do you want to pay? </h1>
                    <ul className="d-flex align-items-start justify-content-center pt-4 ps-0 ps-sm-4 text-center">
                      <li
                        className="university-sec"
                        disabled={
                          props.countReceipientList?.university === 0
                            ? true
                            : false
                        }
                        onClick={() => receipientVal('university')}>
                        <span
                          className={
                            props.receipientPayVal === 'university'
                              ? 'active'
                              : ''
                          }>
                          <img src={SchoolIco} alt="bankIco" />
                        </span>
                        <h6 className="mt-3">University</h6>
                      </li>
                      <li
                        className="mx-sm-5 own-sec"
                        disabled={
                          props.countReceipientList?.rent === 0 ? true : false
                        }
                        onClick={() => receipientVal('own')}>
                        <span
                          disabled={
                            props.countReceipientList?.own === 0 ? true : false
                          }
                          className={
                            props.receipientPayVal === 'own' ? 'active' : ''
                          }>
                          <img src={BankIco} alt="bankIco" />
                        </span>
                        <h6 className="mt-3">Own Account</h6>
                      </li>
                      <li
                        className="rent-sec"
                        disabled={
                          props.countReceipientList?.rent === 0 ? true : false
                        }
                        onClick={() => receipientVal('rent')}>
                        <span
                          className={
                            props.receipientPayVal === 'rent' ? 'active' : ''
                          }>
                          <img src={HouseIco} alt="bankIco" />
                        </span>
                        <h6 className="mt-3">Rent</h6>
                      </li>
                    </ul>
                  </>
                ) : (
                  <div className="row">
                    <div className="col-sm-7 col-12">
                      <img
                        src={AuthFailed}
                        alt="authFailed"
                        style={{ width: '200px' }}
                      />
                      <h1 className="mt-4"> No Authentication Done.... </h1>
                      <p> Sorry! Please finish Authentication. </p>
                      <div className="btn-wrapper mt-4">
                        <Link
                          to="/account-verification"
                          className="login-btn px-3 py-2">
                          {' '}
                          COMPLETE ACCOUNT VERIFICATION{' '}
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-5 mt-5 mt-sm-0 col-12">
                      <Calculator />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <>
            {/* {uploadRejectedDoc ? (
              <RejectedDocUpload
                listDocStatus={listDocStatus}
                setListDocStatus={setListDocStatus}
                setWhoUploadDoc={setWhoUploadDoc}
                whoUploadDoc={whoUploadDoc}
                docUploadData={docUploadData}
                setDocUploadData={setDocUploadData}
              />
            ) : ( */}
            <div className="row mx-auto text-center py-5 px-0 px-sm-3">
              <div className="verification-doc-status pt-0">
                <ul className="ps-0 ps-sm-3">
                  {checkDocStatus?.own === false ? (
                    <li
                      className={
                        listDocStatus?.own === 'pending'
                          ? 'pendingDoc p-2 mx-0 mx-sm-3'
                          : listDocStatus?.own === 'rejected'
                          ? 'rejectedDoc p-2 mx-0 mx-sm-3'
                          : listDocStatus?.own === 'approved'
                          ? 'verifiedDoc p-2 mx-0 mx-sm-3'
                          : 'p-2'
                      }>
                      <div className="d-flex align-items-center">
                        {' '}
                        <span className="d-flex align-items-center justify-content-center">
                          <img src={AccountIco} alt="ico-parent" />{' '}
                        </span>
                        <div className="ps-2 col-6 col-sm-10 text-start">
                          <h2> Own Account </h2>
                        </div>
                        <div className="col-4 col-sm-1">
                          {listDocStatus?.own === 'rejected' ? (
                            <img
                              src={
                                listDocStatus?.own === 'rejected'
                                  ? UploadIco
                                  : ''
                              }
                              style={{ width: '25px', cursor: 'pointer' }}
                              alt="uploadIco"
                              onClick={() => uploadClick('own')}
                            />
                          ) : (
                            ''
                          )}
                          <img
                            src={
                              listDocStatus?.own === 'pending'
                                ? PendingIco
                                : listDocStatus?.own === 'rejected'
                                ? RejectedIco
                                : listDocStatus?.own === 'approved'
                                ? CheckIco
                                : ''
                            }
                            alt="pendingIco"
                            className="ms-3 statusIco ms-4"
                          />
                        </div>
                      </div>
                    </li>
                  ) : (
                    ''
                  )}
                  {checkDocStatus?.father === false ? (
                    <li
                      className={
                        listDocStatus?.father === 'pending'
                          ? 'pendingDoc p-2 mx-0 mx-sm-3'
                          : listDocStatus?.father === 'rejected'
                          ? 'rejectedDoc p-2 mx-0 mx-sm-3'
                          : listDocStatus?.father === 'approved'
                          ? 'verifiedDoc p-2 mx-0 mx-sm-3'
                          : 'p-2'
                      }>
                      <div className="d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center">
                          <img src={ParentsIco} alt="ico-parent" />{' '}
                        </span>
                        <div className="ps-2 col-sm-10 col-6 text-start text-start">
                          <h2> Father Account </h2>
                        </div>
                        <div className="col-4 col-sm-1">
                          {listDocStatus?.father === 'rejected' ? (
                            <img
                              src={
                                listDocStatus?.father === 'rejected'
                                  ? UploadIco
                                  : ''
                              }
                              style={{ width: '25px', cursor: 'pointer' }}
                              alt="uploadIco"
                              onClick={() => uploadClick('father')}
                            />
                          ) : (
                            ''
                          )}
                          <img
                            src={
                              listDocStatus?.father === 'pending'
                                ? PendingIco
                                : listDocStatus?.father === 'rejected'
                                ? RejectedIco
                                : listDocStatus?.father === 'approved'
                                ? CheckIco
                                : ''
                            }
                            alt="pendingIco"
                            className="ms-3 statusIco ms-4"
                          />
                        </div>
                      </div>
                    </li>
                  ) : (
                    ''
                  )}
                  {checkDocStatus?.mother === false ? (
                    <li
                      className={
                        listDocStatus?.mother === 'pending'
                          ? 'pendingDoc p-2 mx-0 mx-sm-3'
                          : listDocStatus?.mother === 'rejected'
                          ? 'rejectedDoc p-2 mx-0 mx-sm-3'
                          : listDocStatus?.mother === 'approved'
                          ? 'verifiedDoc p-2 mx-0 mx-sm-3'
                          : 'p-2'
                      }>
                      <div className="d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center">
                          <img src={ParentsIco} alt="ico-parent" />{' '}
                        </span>
                        <div className="ps-2 col-sm-10 col-6 text-start">
                          <h2> Mother Account </h2>
                        </div>
                        <div className="col-sm-1 col-4">
                          {listDocStatus?.mother === 'rejected' ? (
                            <img
                              src={
                                listDocStatus?.mother === 'rejected'
                                  ? UploadIco
                                  : ''
                              }
                              style={{ width: '25px', cursor: 'pointer' }}
                              alt="uploadIco"
                              onClick={() => uploadClick('mother')}
                            />
                          ) : (
                            ''
                          )}
                          <img
                            src={
                              listDocStatus?.mother === 'pending'
                                ? PendingIco
                                : listDocStatus?.mother === 'rejected'
                                ? RejectedIco
                                : listDocStatus?.mother === 'approved'
                                ? CheckIco
                                : ''
                            }
                            alt="pendingIco"
                            className="ms-3 statusIco ms-4"
                          />
                        </div>
                      </div>
                    </li>
                  ) : (
                    ''
                  )}
                  {checkDocStatus?.spouse === false ? (
                    <li
                      className={
                        listDocStatus?.spouse === 'pending'
                          ? 'pendingDoc p-2 mb-5 mx-0 mx-sm-3'
                          : listDocStatus?.spouse === 'rejected'
                          ? 'rejectedDoc p-2 mb-5 mx-0 mx-sm-3'
                          : listDocStatus?.spouse === 'approved'
                          ? 'verifiedDoc p-2 mb-5 mx-0 mx-sm-3'
                          : 'p-2'
                      }>
                      <div className="d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center">
                          <img src={ParentsIco} alt="ico-parent" />{' '}
                        </span>
                        <div className="ps-2 col-6 col-sm-10 text-start">
                          <h2> Spouse Account </h2>
                        </div>
                        <div className="col-sm-1 col-4">
                          {listDocStatus?.spouse === 'rejected' ? (
                            <img
                              src={
                                listDocStatus?.spouse === 'rejected'
                                  ? UploadIco
                                  : ''
                              }
                              style={{ width: '25px', cursor: 'pointer' }}
                              alt="uploadIco"
                              onClick={() => uploadClick('spouse')}
                            />
                          ) : (
                            ''
                          )}
                          <img
                            src={
                              listDocStatus?.spouse === 'pending'
                                ? PendingIco
                                : listDocStatus?.spouse === 'rejected'
                                ? RejectedIco
                                : listDocStatus?.spouse === 'approved'
                                ? CheckIco
                                : ''
                            }
                            alt="pendingIco"
                            className="ms-3 statusIco ms-4"
                          />
                        </div>
                      </div>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
              {/* Refactor */}
              {checkDocStatus?.father === true ||
              checkDocStatus?.mother === true ||
              checkDocStatus?.own === true ||
              checkDocStatus?.spouse === true ? (
                <h1> Who will Pay? </h1>
              ) : (
                ''
              )}
              <div
                className={
                  props.payVal === 'relative' ||
                  props.payVal === 'relative - father' ||
                  props.payVal === 'relative - spouse' ||
                  props.payVal === 'relative - mother'
                    ? 'col-12 col-sm-6 text-center py-sm-5 senderFirst'
                    : 'col-12 mx-auto text-center py-sm-5 senderFirst'
                }>
                <ul
                  className={
                    props.payVal === 'relative' ||
                    props.payVal === 'relative - father' ||
                    props.payVal === 'relative - spouse' ||
                    props.payVal === 'relative - mother'
                      ? 'd-flex align-items-center justify-content-center justify-content-sm-end pt-2 ps-0 ps-sm-3 mt-3 mt-sm-0'
                      : 'ps-0 ps-sm-3  d-flex align-items-center justify-content-center pt-2 mt-3 mt-sm-0'
                  }>
                  {checkDocStatus?.own === true ? (
                    <li className="me-4 me-sm-0">
                      <button
                        className="mb-2"
                        onClick={() => {
                          payHandler('ownAccount');
                        }}>
                        <span
                          className={
                            activeClass === 'active' &&
                            props.payVal === 'ownAccount'
                              ? 'active'
                              : ''
                          }>
                          <img src={AccountIco} alt="bankIco" />
                        </span>
                      </button>
                      <h6> Own Account </h6>
                    </li>
                  ) : (
                    ''
                  )}
                  {checkDocStatus?.father === true ||
                  checkDocStatus?.mother === true ||
                  checkDocStatus?.spouse === true ? (
                    <li
                      className={
                        checkDocStatus?.own != true ? 'ms-0' : 'ms-sm-5'
                      }>
                      <button
                        className="mb-2"
                        onClick={() => {
                          payHandler('relative');
                        }}>
                        <span
                          className={
                            (activeClass === 'active' &&
                              props.payVal === 'relative') ||
                            props.payVal === 'relative - father' ||
                            props.payVal === 'relative - spouse' ||
                            props.payVal === 'relative - mother'
                              ? 'active'
                              : ''
                          }>
                          <img src={ParentsIco} alt="bankIco" />
                        </span>
                      </button>
                      <h6> Relative </h6>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
              <div
                className={
                  props.payVal === 'ownAccount' || props.payVal === ''
                    ? 'd-none'
                    : 'col-12 col-sm-3 text-center py-sm-5 d-flex align-items-start justify-content-sm-start justify-content-center relative-details'
                }>
                <ul>
                  {checkDocStatus?.father === true && (
                    <li className="text-start pb-3 form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        value="father"
                        defaultChecked={props.selected === 'father'}
                        onChange={getRelative}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2">
                        Father
                      </label>
                    </li>
                  )}
                  {checkDocStatus?.mother === true && (
                    <li className="text-start pb-3 form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault3"
                        value="mother"
                        checked={props.selected === 'mother'}
                        onChange={getRelative}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault3">
                        Mother
                      </label>
                    </li>
                  )}
                  {checkDocStatus?.spouse === true && (
                    <li className="text-start pb-3 form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        value="spouse"
                        checked={props.selected === 'spouse'}
                        onChange={getRelative}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1">
                        Spouse
                      </label>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </>
        )}
        {props.rentAgreementDoc ? (
          <Modal
            show={props.rentAgreementDoc}
            onHide={handleSuccessClose}
            className="sm-receipient-modal">
            <Modal.Body className="text-center py-5">
              <span
                style={{
                  border: '4px solid #81E4C4',
                  width: '150px',
                  height: '150px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  margin: 'auto',
                }}>
                <img
                  src={RentIco}
                  style={{ width: '100px' }}
                  alt="university-ico"
                />
              </span>
              <h3> Please Upload Rent Agreement </h3>
              <Button
                onClick={handleSuccessClose}
                className="addNowBtn me-3"
                style={{
                  backgroundColor: '#2a2a2a',
                  color: 'white',
                  padding: '5px 20px',
                  fontSize: '18px',
                }}>
                Cancel
              </Button>
              <Button
                onClick={handleSuccessClose}
                className="addNowBtn"
                style={{
                  padding: '5px 20px',
                  fontSize: '18px',
                }}>
                Upload Now
              </Button>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
        {props.countReceipientList &&
        props.countReceipientList[props.receipientPayVal] === 0 ? (
          <Modal
            show={show}
            onHide={handleSuccessClose}
            className="sm-receipient-modal">
            <Modal.Header closeButton>
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ textTransform: 'capitalize' }}>
                Add{' '}
                {props.receipientPayVal === 'rent'
                  ? 'landlords bank details'
                  : props.receipientPayVal}
              </h5>
            </Modal.Header>
            <Modal.Body className="text-center">
              <span
                style={{
                  border: '4px solid #81E4C4',
                  width: '150px',
                  height: '150px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  margin: 'auto',
                }}>
                <img
                  src={
                    props.receipientPayVal === 'rent'
                      ? RentIco
                      : props.receipientPayVal === 'university'
                      ? UniversityIco
                      : OwnIco
                  }
                  style={{ width: '100px' }}
                  alt="university-ico"
                />
              </span>
              <h3>
                {' '}
                To transact, you need to save{' '}
                {props.receipientPayVal === 'rent'
                  ? 'landlords'
                  : props.receipientPayVal}{' '}
                bank details in accounts{' '}
              </h3>
              <Button onClick={addReceipient} className="addNowBtn">
                Add Now
              </Button>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
        <Modal
          show={senderListModal}
          onHide={handleModalClose}
          className="sm-receipient-modal">
          <Modal.Header closeButton>
            <h5
              className="modal-title"
              id="staticBackdropLabel"
              style={{ textTransform: 'capitalize' }}>
              Add {props.senderAccountVal}
            </h5>
          </Modal.Header>
          <Modal.Body className="text-center">
            <span
              style={{
                border: '4px solid #81E4C4',
                width: '150px',
                height: '150px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                margin: 'auto',
              }}>
              <img
                src={
                  props.receipientPayVal === 'rent'
                    ? RentIco
                    : props.receipientPayVal === 'university'
                    ? UniversityIco
                    : OwnIco
                }
                style={{ width: '100px' }}
                alt="university-ico"
              />
            </span>
            <h3>
              {' '}
              To transact, you need to save {props.senderAccountVal} Sender bank
              details in accounts{' '}
            </h3>
            <Button onClick={addSender} className="addNowBtn">
              Add Now
            </Button>
          </Modal.Body>
        </Modal>
      </div>
      {loader ? (
        <div className="loader-wrapper d-flex align-items-center justify-content-center">
          <BallTriangle
            height="100"
            width="100"
            color="#45C29A"
            ariaLabel="loading"
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default Sender;
export { ThemeContext };
