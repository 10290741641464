/* eslint-disable react/no-unescaped-entities */
import React from 'react';

interface IProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onResendClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  error?: string | null;
}

const OTP = ({ value, onChange, onResendClick, error }: IProps) => {
  return (
    <div className="container">
      <div className="row justify-content-center pt-4">
        <div className="col-11 col-sm-6 text-center">
          <h1 className="pb-4 page-heading mb-2"> Account Verification </h1>
          <p>We've sent you an OTP on your E-mail. Please enter it here</p>
          <div className="col-12">
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                id="otp"
                name="otp"
                placeholder="OTP"
                maxLength={6}
                value={value}
                onChange={onChange}
              />
              <label htmlFor="floatingInput">Enter OTP</label>
              {error && <p className="error-message">{error}</p>}
              <p>
                Didn't receive E-mail?{' '}
                <a href="#" onClick={onResendClick}>
                  Resend OTP
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTP;
