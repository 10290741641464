/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import './checkout-page.scss';
import Giffgaff from '../../../Assets/Images/giffgaff-sim.svg';
import { Link, Navigate } from 'react-router-dom';
import Header from '../../../Common/Pages/header/header';
import NavBar from '../../../Common/Pages/NavBar/nav-bar';
import EditIco from '../../../Assets/Images/edit.svg';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import CheckoutStripe from '../checkout-stripe/checkout-stripe';
import { useNavigate } from 'react-router-dom';
import sim, {
  setUserAddress,
  setClientSecretId,
  setPlanSelected,
} from '../../../global/features/sim';
import { BallTriangle } from 'react-loader-spinner';
import { Modal, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import LebaraSim from '../../../Assets/Images/lebara-sim.svg';
import VoxiSim from '../../../Assets/Images/voxi-sim.svg';

const checkoutValidateForm = (values) => {
  const errors = {};

  if (values.firstName?.trim().length < 1) {
    errors.firstName = 'Please enter name';
  }
  if (values.lastName?.trim().length < 1) {
    errors.lastName = 'Please enter name';
  }
  if (values.mobileNumber?.trim().length < 1) {
    errors.mobileNumber = 'Please enter name';
  }
  if (values.emailAddress?.trim().length < 1) {
    errors.emailAddress = 'Please enter name';
  }
  if (values.address?.trim().length < 1) {
    errors.address = 'Please enter name';
  }
  if (values.city?.trim().length < 1) {
    errors.city = 'Please enter name';
  }
  if (values.state?.trim().length < 1) {
    errors.state = 'Please enter name';
  }
  if (values.country?.trim().length < 1) {
    errors.country = 'Please enter name';
  }
  if (values.pinCode?.trim().length < 1) {
    errors.pinCode = 'Please enter name';
  }

  return errors;
};

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const token = user.user?.tokens?.access;
  const [values, setValues] = useState();
  const simData = useSelector((state) => state.sim);
  const planId = useSelector((state) => state.sim.simPlanId);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [planCharges, setPlanCharges] = useState();
  const [gstVal, setGstVal] = useState();
  const [editAddress, setEditAddress] = useState(false);
  const [shippingCharges, setShippingCharges] = useState();
  const rates = useSelector((state) => state.calculator.rates);
  const [totalAmount, setTotalAmount] = useState();
  const [postCode, setPostCode] = useState();
  const [loader, setLoader] = useState(false);
  const [userIndiaAddress, setUserIndiaAddress] = useState();
  const [userUKAddress, setUserUKAddress] = useState();
  const [userAddressID, setUserAddressID] = useState();
  const [userId, setUserId] = useState();

  let axiosToken = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token,
    },
  };
  let user_Id = '';
  const form = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      mobileNumber: '',
      address: '',
      city: '',
      state: '',
      country: '',
      pinCode: '',
    },
    validate: checkoutValidateForm,
    onSubmit: async (values, { setErrors }) => {
      setLoader(true);
      if (userUKAddress) {
        try {
          let testUpload = await axios.put(
            `${process.env.REACT_APP_API_URL}/user/address/${userId}`,
            {
              first_name: values.firstName,
              last_name: values.lastName,
              phone: values.mobileNumber,
              email: values.emailAddress,
              address: values.address,
              city: values.city,
              state: values.state,
              country: simData.deliveryCountry === 'UK' ? 'UK' : values.country,
              zip: values.pinCode,
            },
            axiosToken,
          );
          user_Id = testUpload.data.data._id;
        } catch (error) {
          // console.log(error);
        }
      } else {
        let testUpload = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/address`,
          {
            first_name: values.firstName,
            last_name: values.lastName,
            phone: values.mobileNumber,
            email: values.emailAddress,
            address: values.address,
            city: values.city,
            state: values.state,
            country: values.country,
            zip: values.pinCode,
          },
          axiosToken,
        );
        user_Id = testUpload.data.data._id;
      }
      try {
        let statusUploadDoc = await axios.post(
          `${process.env.REACT_APP_API_URL}/sim/order`,
          {
            sim_provider_id: simData.simPlanId,
            user_address_id: user_Id,
            sim_plan_id: simData?.lebaraPlan?._id
              ? simData?.lebaraPlan?._id
              : null,
            delivery_type: simData.deliveryType,
          },
          axiosToken,
        );
        dispatch(setClientSecretId(statusUploadDoc.data));
      } catch (error) {
        // console.log(error);
      }
      if (gstVal != 0) {
        navigate('/payment-page');
        setLoader(false);
      } else {
        navigate('/payment-completed');
        setLoader(false);
      }
    },
    enableReinitialze: true,
  });
  useEffect(() => {
    (async () => {
      // try {
      //   const getIndiaUserAddress  = await axios.get(
      //     `${process.env.REACT_APP_API_URL}/user/address?country=India`,
      //     axiosToken,
      //   );
      //   console.log('getUserIndiaAddress: ', getIndiaUserAddress.data?.data);
      //   setUserIndiaAddress(getIndiaUserAddress.data?.data[0]);
      //   setUserAddressID(getIndiaUserAddress.data?.data[0]._id);
      //   setUserId(getIndiaUserAddress.data?.data[0]._id);
      // } catch (err) {
      //   console.log('err: ', err);
      // }
      try {
        const selectedCountry =
          simData.deliveryCountry == 'UK' ? 'UK' : 'India';
        const getUKUserAddress = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/address?country=${selectedCountry}`,
          axiosToken,
        );
        setUserUKAddress(getUKUserAddress.data?.data[0]);
        setUserAddressID(getUKUserAddress.data?.data[0]._id);
        setUserId(getUKUserAddress.data?.data[0]._id);
      } catch (err) {
        // console.log('err: ', err);
      }
    })();
    let userData = user.user.attributes;
    form.setValues({
      firstName: userUKAddress
        ? userUKAddress?.first_name
        : userData['custom:firstName'],
      lastName: userUKAddress
        ? userUKAddress?.last_name
        : userData['custom:lastName'],
      mobileNumber: userUKAddress
        ? userUKAddress?.phone
        : userData['custom:phone'],
      emailAddress: userUKAddress ? userUKAddress?.email : userData.email,
      address:
        simData.deliveryCountry === 'UK'
          ? userUKAddress?.address
          : userData['custom:address'],
      city:
        simData.deliveryCountry === 'UK'
          ? userUKAddress?.city
          : userData['custom:city'],
      state:
        simData.deliveryCountry === 'UK'
          ? userUKAddress?.state
          : userData['custom:state'],
      country:
        simData.deliveryCountry === 'UK'
          ? userUKAddress?.country
          : userData['custom:country'],
      pinCode:
        simData.deliveryCountry === 'UK'
          ? userUKAddress?.zip
          : userData['custom:zip'],
    });
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/sim/providers/${planId}`,
          axiosToken,
        );
        if (response) {
          // Refactor
          dispatch(setPlanSelected(response.data.data));
          setSelectedPlan(response.data.data);
          // const deliveryPrice = response.data.data.standard_delivery_price === 0 ? response.data.data.express_delivery_price : response.data.data.standard_delivery_price;
          const deliveryPrice =
            simData.deliveryType === 'express_delivery'
              ? response.data.data.express_delivery_price
              : response.data.data.standard_delivery_price;
          const planCharges = simData?.lebaraPlan?.price_per_month ?? 0;
          const simPlanCharges = (+planCharges / rates.GBP).toFixed(0);
          const simPlanFinalCharges = +simPlanCharges + 1;
          const simPlanChargesGST = ((+simPlanCharges * 0.18) / 1.18).toFixed(
            0,
          );
          const gstCal = ((+deliveryPrice * 0.18) / 1.18).toFixed(0);
          const finalShippingCharges =
            +deliveryPrice != 0 ? deliveryPrice - +gstCal : 0;
          const finalGstCal = +simPlanChargesGST
            ? +simPlanChargesGST + +gstCal
            : +gstCal;
          const amountTotal =
            +finalShippingCharges + +simPlanCharges + +finalGstCal;
          setTotalAmount(amountTotal);
          setGstVal(finalGstCal);
          setShippingCharges(finalShippingCharges);
          setPlanCharges(simPlanFinalCharges);
        }
      } catch (error) {
        // console.log(error);
      }
    })();
  }, []);
  useEffect(() => {
    let userData = user.user.attributes;
    // userUKAddress?.address
    form.setValues({
      firstName: userUKAddress
        ? userUKAddress?.first_name
        : userData['custom:firstName'],
      lastName: userUKAddress
        ? userUKAddress?.last_name
        : userData['custom:lastName'],
      mobileNumber: userUKAddress
        ? userUKAddress?.phone
        : userData['custom:phone'],
      emailAddress: userUKAddress ? userUKAddress?.email : userData.email,
      address:
        simData.deliveryCountry === 'UK'
          ? userUKAddress?.address
          : userData['custom:address'],
      city:
        simData.deliveryCountry === 'UK'
          ? userUKAddress?.city
          : userData['custom:city'],
      state:
        simData.deliveryCountry === 'UK'
          ? userUKAddress?.state
          : userData['custom:state'],
      country:
        simData.deliveryCountry === 'UK'
          ? userUKAddress?.country
          : userData['custom:country'],
      pinCode:
        simData.deliveryCountry === 'UK'
          ? userUKAddress?.zip
          : userData['custom:zip'],
    });
  }, [userId]);
  const fetchZip = async (e) => {
    const { value } = e.target;
    if (form.values.country === 'India') {
      axios
        .get(`https://api.postalpincode.in/pincode/${value}`)
        .then((res) => {
          const data = res.data[0].PostOffice.map((el) => {
            return (
              form.setFieldValue('city', el.Block),
              form.setFieldValue('state', el.State),
              form.setFieldValue('country', el.Country),
              form.setFieldValue('address', '')
            );
          });
        })
        .catch((err) => {
          // console.log('err.response.data.error: ', err);
        });
    } else {
      try {
        const ukPostCode = await axios.get(
          `https://api.postcodes.io/postcodes/${value}`,
          axiosToken,
        );
        setPostCode(ukPostCode);
        form.setFieldValue('city', ukPostCode.data.result.region),
          form.setFieldValue('state', ukPostCode.data.result.admin_county),
          // form.setFieldValue('country', ukPostCode.data.result.country),
          form.setFieldValue('address', '');
      } catch (error) {
        // console.log('error: ', error);
      }
    }
  };
  const handleClick = () => {
    form.setFieldValue('city', '');
    form.setFieldValue('pinCode', '');
    form.setFieldValue('state', '');
  };
  const handleEditAddress = () => {
    setEditAddress(true);
  };
  const handleClose = () => {
    setEditAddress(false);
  };
  const handleSaveDetails = async () => {
    setEditAddress(false);
  };
  return (
    // Refactor #1
    <div className="container-fluid">
      <Header />
      <div className="row" id="checkout-page">
        <div className="col-3 ps-0 sidebar-container">
          <NavBar />
        </div>
        <div
          className="col-12 col-sm-8 mx-auto right-wrapper px-sm-5"
          style={{ paddingTop: '100px' }}
        >
          <h1 className="mb-4 d-sm-none d-block"> Checkout Detail </h1>
          <div className="row inner-wrapper">
            <div className="col-sm-8 col-12 details-form-wrapper pe-sm-5">
              <h1 className="mb-4 d-none d-sm-block"> Checkout Detail </h1>
              <div className="row d-none d-sm-flex">
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="firstName"
                      value={form.values.firstName}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">First Name</label>
                    {form.touched.firstName && form.errors.firstName ? (
                      <p className="error-message">{form.errors.firstName}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="lastName"
                      value={form.values.lastName}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">Last Name</label>
                    {form.touched.lastName && form.errors.lastName ? (
                      <p className="error-message">{form.errors.lastName}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="mobileNumber"
                      name="mobileNumber"
                      placeholder="mobileNumber"
                      value={form.values.mobileNumber}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">Mobile Number</label>
                    {form.touched.mobileNumber && form.errors.mobileNumber ? (
                      <p className="error-message">
                        {form.errors.mobileNumber}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="emailAddress"
                      name="emailAddress"
                      placeholder="emailAddress"
                      value={form.values.emailAddress}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">Enter Email Address</label>
                    {form.touched.emailAddress && form.errors.emailAddress ? (
                      <p className="error-message">
                        {form.errors.emailAddress}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row align-items-center mx-0 px-0">
                  <div className="col-sm-9 col-12">
                    <h2> Shipping Details </h2>
                    <p>
                      {' '}
                      As per out records your shipping details is as below{' '}
                    </p>
                  </div>
                  <div className="text-end col-sm-3 col-12 h-sm-100 d-flex align-items-center justify-content-end ps-sm-0">
                    <Link to="/sim-card" style={{ textDecoration: 'none' }}>
                      <h6 style={{ color: '#45C29A', fontWeight: '700' }}>
                        {' '}
                        Address Change?{' '}
                      </h6>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <select
                      className="form-select"
                      id="country"
                      disabled
                      name="country"
                      value={
                        simData.deliveryCountry != 'UK'
                          ? 'United Kingdom'
                          : form.values.country
                      }
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      onClick={handleClick}
                      aria-label="Floating label select example"
                    >
                      {/* <option value={simData.deliveryCountry == 'UK' ? 'UK' : 'India'} disabled={simData.deliveryCountry == 'UK' ? false : true} hidden>
                        Select Country
                      </option> */}
                      <option value="India">India</option>
                      <option selected value="UK">
                        United Kingdom
                      </option>
                    </select>
                    <label htmlFor="country">Country</label>
                    {form.touched.country && form.errors.country ? (
                      <p className="error-message">{form.errors.country}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="pinCode"
                      disabled={simData.deliveryCountry == 'UK' ? false : true}
                      name="pinCode"
                      placeholder="pinCode"
                      value={form.values.pinCode}
                      onBlur={fetchZip}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">PIN Code</label>
                    {form.touched.pinCode && form.errors.pinCode ? (
                      <p className="error-message">{form.errors.pinCode}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="state"
                      name="state"
                      placeholder="state"
                      // disabled={simData.deliveryCountry == 'UK' ? false : true}
                      value={form.values.state}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">State</label>
                    {form.touched.state && form.errors.state ? (
                      <p className="error-message">{form.errors.state}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="city"
                      name="city"
                      placeholder="City"
                      disabled={simData.deliveryCountry == 'UK' ? false : true}
                      value={form.values.city}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">City</label>
                    {form.touched.city && form.errors.city ? (
                      <p className="error-message">{form.errors.city}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="address"
                      name="address"
                      placeholder="address"
                      value={form.values.address}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">Address</label>
                    {form.touched.address && form.errors.address ? (
                      <p className="error-message">{form.errors.address}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-12 selected-plan-wrapper ps-sm-5 mt-4 mt-sm-0">
              <div className="selected-plan" key={selectedPlan._id}>
                <h4> Order Summary </h4>
                <div
                  className="row mx-0 pb-4"
                  style={{ borderBottom: '1px solid #A6A6A6' }}
                >
                  <div className="col-10 ps-0 d-flex align-items-center justify-content-start pt-3">
                    <img
                      src={
                        selectedPlan.provider_name === 'Giffgaff'
                          ? Giffgaff
                          : selectedPlan.provider_name === 'Lebara'
                          ? LebaraSim
                          : VoxiSim
                      }
                      alt="planHeadIco"
                      style={{ width: '70px' }}
                    />
                    <ul className="ps-3">
                      <li> {selectedPlan.provider_name} SIM </li>
                      <li>
                        {' '}
                        {simData.deliveryType === 'express_delivery'
                          ? 'Express'
                          : 'Normal'}{' '}
                        Delivery{' '}
                      </li>
                      <li>
                        {simData.deliveryType == 'express_delivery' ? (
                          <p
                            style={{
                              fontStyle: 'italic',
                              fontSize: '12px',
                              fontWeight: '500',
                            }}
                          >
                            {simData.expressDeliveryDate} -{' '}
                            {simData.expressDeliveryEndDate}
                          </p>
                        ) : (
                          <p
                            style={{
                              fontStyle: 'italic',
                              fontSize: '12px',
                              fontWeight: '500',
                            }}
                          >
                            {simData.freeDeliveryStartDate} -{' '}
                            {simData.freeDeliveryEndDate}
                          </p>
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="col-2 d-flex align-items-center justify-content-end pe-0">
                    <h5 className="mb-0 d-flex align-items-center justify-content-center">
                      {/* ₹{selectedPlan.express_delivery_price == null ? selectedPlan.standard_delivery_price :  selectedPlan.express_delivery_price} */}
                      ₹
                      {simData.deliveryType === 'express_delivery'
                        ? selectedPlan.express_delivery_price
                        : selectedPlan.standard_delivery_price}
                    </h5>
                  </div>
                </div>
                <div className="row mt-3">
                  {simData.lebaraPlan ? (
                    <>
                      <div className="col-8 mb-3">
                        <h6 className="mb-0"> Sim Plan Charges </h6>
                      </div>
                      <div className="col-4 mb-3 text-end">
                        <h5 className="mb-0">₹{planCharges}</h5>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  <div className="col-8 mb-3">
                    <h6 className="mb-0"> Shipping Charges </h6>
                  </div>
                  <div className="col-4 mb-3 text-end">
                    <h5 className="mb-0">₹{shippingCharges}</h5>
                  </div>
                  <div className="col-6 mb-3">
                    <h6 className="mb-0"> Tax </h6>
                  </div>
                  <div className="col-6 mb-3 text-end">
                    <h5 className="mb-0"> ₹{gstVal} </h5>
                  </div>
                  <div className="col-8 mb-3">
                    <h6 className="mb-0"> Subtotal </h6>
                  </div>
                  <div className="col-4 mb-3 text-end">
                    <h5 className="mb-0">₹{totalAmount}</h5>
                  </div>
                  <div
                    className="col-6 mb-3 py-2"
                    style={{
                      borderTop: '1px solid #A6A6A6',
                      borderBottom: '1px solid #A6A6A6',
                    }}
                  >
                    <h6 className="mb-0"> Total </h6>
                  </div>
                  <div
                    className="col-6 mb-3 text-end py-2"
                    style={{
                      borderTop: '1px solid #A6A6A6',
                      borderBottom: '1px solid #A6A6A6',
                    }}
                  >
                    <h5 className="mb-0"> ₹{totalAmount} </h5>
                  </div>
                </div>
              </div>
              {/* Refactor order */}
              <div className="col-12 text-center d-none d-sm-block">
                <button
                  className="mt-4 py-2 px-4 select-plan-btn"
                  onClick={() => form.submitForm()}
                >
                  {gstVal == 0 ? 'PLACE ORDER' : 'PROCEED TO PAYMENT'}
                </button>
              </div>
            </div>
            <div className="shipping-details-outer d-sm-none d-block">
              <h2> Shipping Details </h2>
              <p> As per out records your shipping details is as below </p>
              <button
                className="edit-address-btn mb-3"
                onClick={handleEditAddress}
              >
                <img src={EditIco} alt="edit-ico" />
                Edit
              </button>
              <div
                className="col-12 p-4 shipping-details-wrapper"
                style={{
                  backgroundColor: 'rgba(213, 246, 235, 0.52)',
                  borderRadius: '10px',
                }}
              >
                <div className="col-12 pb-1">
                  <h3 className="mb-0"> Full Name </h3>
                  <h4> {form.values.firstName} </h4>
                </div>
                <div className="col-12 pb-1">
                  <h3 className="mb-0"> Phone Number </h3>
                  <h4> {form.values.mobileNumber} </h4>
                </div>
                <div className="col-12 pb-1">
                  <h3 className="mb-0"> Email Address </h3>
                  <h4> {form.values.emailAddress} </h4>
                </div>
                <div className="col-12 pb-1">
                  <h3 className="mb-0"> Full Address </h3>
                  <h4>{form.values.address}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 text-center mb-5 d-sm-none d-block">
            <button
              className="mt-4 py-2 px-4 select-plan-btn"
              onClick={() => form.submitForm()}
            >
              {gstVal == 0 ? 'PLACE ORDER' : 'PROCEED TO PAYMENT'}
            </button>
          </div>
        </div>
        {/* Mobile Checkout Form  */}
        <Modal
          show={editAddress}
          onHide={handleClose}
          className="checkout-modal"
        >
          <Modal.Header closeButton>
            <h5
              className="modal-title"
              id="staticBackdropLabel"
              style={{ textTransform: 'capitalize' }}
            >
              Add Bank Details
            </h5>
          </Modal.Header>
          <Modal.Body>
            <div className="col-sm-8 col-12 details-form-wrapper pe-sm-5">
              <h1
                className="mb-2 d-sm-none d-block"
                style={{ fontSize: '20px', fontWeight: 700 }}
              >
                {' '}
                EDIT DETAILS{' '}
              </h1>
              <div className="row d-sm-none d-flex">
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="firstName"
                      value={form.values.firstName}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">First Name</label>
                    {form.touched.firstName && form.errors.firstName ? (
                      <p className="error-message">{form.errors.firstName}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="lastName"
                      value={form.values.lastName}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">Last Name</label>
                    {form.touched.lastName && form.errors.lastName ? (
                      <p className="error-message">{form.errors.lastName}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="mobileNumber"
                      name="mobileNumber"
                      placeholder="mobileNumber"
                      value={form.values.mobileNumber}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">Mobile Number</label>
                    {form.touched.mobileNumber && form.errors.mobileNumber ? (
                      <p className="error-message">
                        {form.errors.mobileNumber}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="emailAddress"
                      name="emailAddress"
                      placeholder="emailAddress"
                      value={form.values.emailAddress}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">Enter Email Address</label>
                    {form.touched.emailAddress && form.errors.emailAddress ? (
                      <p className="error-message">
                        {form.errors.emailAddress}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row align-items-center mx-0 px-0">
                  <div className="col-sm-9 col-12">
                    <h2 style={{ fontSize: '20px', fontWeight: 700 }}>
                      {' '}
                      EDIT SHIPPING ADDRESS{' '}
                    </h2>
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <select
                      className="form-select"
                      id="country"
                      disabled={
                        simData.deliveryCountry === 'India' ? false : true
                      }
                      name="country"
                      value={form.values.country}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      onClick={handleClick}
                      aria-label="Floating label select example"
                    >
                      <option value="" selected disabled hidden>
                        Select Country
                      </option>
                      <option value="India">India</option>
                      <option value="UK">United Kingdom</option>
                    </select>
                    <label htmlFor="country">Country</label>
                    {form.touched.country && form.errors.country ? (
                      <p className="error-message">{form.errors.country}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="pinCode"
                      disabled
                      name="pinCode"
                      placeholder="pinCode"
                      value={form.values.pinCode}
                      onBlur={fetchZip}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">PIN Code</label>
                    {form.touched.pinCode && form.errors.pinCode ? (
                      <p className="error-message">{form.errors.pinCode}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="state"
                      name="state"
                      placeholder="state"
                      disabled
                      value={form.values.state}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">State</label>
                    {form.touched.state && form.errors.state ? (
                      <p className="error-message">{form.errors.state}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6 col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="city"
                      name="city"
                      placeholder="City"
                      disabled
                      value={form.values.city}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">City </label>
                    {form.touched.city && form.errors.city ? (
                      <p className="error-message">{form.errors.city}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      id="address"
                      name="address"
                      placeholder="address"
                      value={form.values.address}
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                    />
                    <label htmlFor="city">Address</label>
                    {form.touched.address && form.errors.address ? (
                      <p className="error-message">{form.errors.address}</p>
                    ) : null}
                  </div>
                </div>
                <button
                  className="save-response-btn"
                  onClick={handleSaveDetails}
                >
                  {' '}
                  Save Details{' '}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {loader ? (
        <div className="loader-wrapper d-flex align-items-center justify-content-center">
          <BallTriangle
            height="100"
            width="100"
            color="#45C29A"
            ariaLabel="loading"
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default CheckoutPage;
