/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import logo from '../../Assets/Images/logo.svg';
// @ts-ignore
import CloseIco from '../../Assets/Images/close-ico.svg';

import './verify-otp.scss';
import { RootState } from '../../global';

const validate = (vals: { otp: string }) => {
  const errors: { [id: string]: string } = {};

  if (vals.otp.length < 2) {
    errors.otp = 'Please enter a valid otp';
  }

  return errors;
};

const VerifyOTP = () => {
  const navigate = useNavigate();
  const { email, password } = useSelector(
    (state: RootState) => state.user.temp,
  );

  useEffect(() => {
    if (email || password) {
      return;
    }

    navigate('/login', { replace: true });
  }, []);

  const form = useFormik({
    initialValues: {
      otp: '',
    },
    validate,
    onSubmit: async (values, { setErrors }) => {
      try {
        // console.log(values, email, password);
        await Auth.confirmSignUp(email, values.otp);

        await Auth.signIn(email, password);
        navigate('/', { replace: true });
      } catch (e) {
        if (e.code === 'CodeMismatchException') {
          setErrors({ otp: 'Invalid Otp' });
          return;
        }

        if (e.code === 'ExpiredCodeException') {
          setErrors({ otp: 'Otp is expired' });
        }

        if (e.code === 'TooManyRequestsException') {
          setErrors({ otp: 'You have exceeded your otp limit' });
          return;
        }

        if (e.code === 'UserNotFoundException') {
          setErrors({ otp: 'No such user found' });
          return;
        }

        setErrors({
          otp: "Sorry! We're unable to log you in at the moment. Please check your email and password.",
        });
      }
    },
  });

  return (
    <>
      <div className="container-fluid h-100">
        <div className="row h-100 d-flex align-items-center py-3 header-wrapper">
          <div className="col-6">
            <img src={logo} alt="logo" className="logo-img" />
          </div>
          <div className="header-close-ico col-6 d-flex justify-content-end">
            <Link to="/">
              <img src={CloseIco} alt="close-ico" className="close-ico" />
            </Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center pt-4">
          <div className="col-6 text-center">
            <h1 className="pb-4 page-heading mb-2"> Account Verification </h1>
            <p>We've sent you an OTP on your E-mail. Please enter it here</p>
            <form method="POST" onSubmit={form.handleSubmit}>
              <div className="col-12">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="otp"
                    name="otp"
                    value={form.values.otp}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    autoComplete="off"
                    autoCorrect="off"
                    maxLength={6}
                    placeholder="OTP"
                  />
                  <label htmlFor="otp">Enter OTP</label>
                  {form.touched.otp && form.errors.otp && (
                    <p className="error-message">{form.errors.otp}</p>
                  )}
                </div>
                <div className="col-12 btn-wrapper d-flex align-items-center justify-content-center">
                  <button
                    type="submit"
                    className="mt-4 p-4 d-flex align-items-center justify-content-center next-btn login-btn">
                    {' '}
                    Verify{' '}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
