import React, { useEffect, useState } from 'react';
import Header from '../../Common/Pages/header/header';
import Footer from '../../Common/Pages/footer/footer';
import MultiProgressBar from '../../Common/Pages/multiProgressBar/multi-progress-bar';
import Sender from '../../Component/sender/sender';
import UploadDoc from '../upload-document/upload-doc-/upload-doc';
import ThankYou from '../../Common/Pages/thank-you/thank-you';
import './send-money.scss';
import BankTransfer from '../../Component/send-money/bank-transfer/bank-transfer';
import BackIco from '../../Assets/Images/back-ico.svg';
import bookRate from '../../Assets/Images/book-rate.svg';
import { Link } from 'react-router-dom';
import TermsConditions from '../send-money/terms-conditions/terms-conditions';
import ConfirmationBooked from '../send-money/confirmation-booked/confirmation-booked';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {
  setSendMoneyStep,
  clearSendMoneyVal,
  setBackStep,
} from '../../global/features/data';
import { setTemp } from '../../global/features/user';
import moment from 'moment';

function SendMoney() {
  const [sendMoneyBar, setSendMoneyBar] = useState('sendMoney');
  const [currentStep, setCurrentStep] = useState(1);
  const [transferDetails, setTransferDetails] = useState('trasnferDetails');
  const [confirmation, setConfirmation] = useState('confirmation');
  const [receipients, setReceipients] = useState('receipients');
  const [termsConditions, setTermsConditions] = useState('termsConditions');
  const [confirmationFlag, setConfirmationFlag] = useState(false);
  const [receipientPayVal, setReceipientPayVal] = useState('');
  const [activeClass, setActiveClass] = useState('active');
  const user = useSelector((state) => state.user);
  const data = useSelector((state) => state.data);
  const sendMoneyStepVal = useSelector((state) => state.data.sendMoneyStep);
  const payNowSelected = useSelector((state) => state.data.payNowSendMoney);
  const dispatch = useDispatch();
  const token = user.user?.tokens?.access;
  const [listofReceipient, setListofReceipient] = useState([]);
  const [selectedReceipient, setSelectedReceipient] = useState();
  const [payVal, setPayVal] = useState('ownAccount');
  const [show, setShow] = useState(false);
  const [senderId, setSenderId] = useState();
  const [receipientId, setReceipientId] = useState();
  const [uploadProof, setUploadProof] = useState('');
  const [remittanceId, setRemittanceId] = useState('');
  const dataCal = useSelector((state) => state.calculator);
  const [countReceipientList, setCountReceipientList] = useState('');
  const [proofUploadedStatus, setProofUploadedStatus] = useState(false);
  const [sendMoneyReceipient, setSendMoneyReceipient] = useState(false);
  const [selectedOwn, setSelectedOwn] = useState('');
  const [listDocStatus, setListDocStatus] = useState();
  const [confirmBooking, setConfirmBooking] = useState(false);
  const [documentStatus, setDocumentStatus] = useState();
  const [rentAgreementDoc, setRentAgreementDoc] = useState(false);
  const [senderAccountVal, setSenderAccountVal] = useState('');
  const [senderList, setSenderList] = useState();
  const [marketTime, setMarketTime] = useState();
  const [timeVal, setTimeVal] = useState();
  const [relativeContent, setRelativeContent] = useState([
    { label: 'father', isCheked: false },
    { label: 'mother', isCheked: false },
    { label: 'spouse', isCheked: false },
  ]);
  const transaction_reference_number = useSelector(
    (state) => state.user.temp.transaction_reference_number,
  );
  let axiosToken = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token,
    },
  };

  const nextButton = () => {
    dispatch(setSendMoneyStep());
    if (sendMoneyStepVal == 4) {
      setConfirmationFlag(true);
    }
    setCurrentStep(setSendMoneyStep());
    if (listofReceipient === undefined || listofReceipient.length === 0) {
      setListofReceipient(payNowSelected);
    }
  };
  const handleProofTransfer = (val) => {
    setUploadProof(val);
    setShow(true);
  };
  const benificiaryTransfer = () => {
    dispatch(setSendMoneyStep(sendMoneyStepVal));
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/recipient?type=${receipientPayVal}`,
        axiosToken,
      )
      .then((res) => {
        // console.log('res-get: api ', res);
        let receipientData = res.data.recipients;
        setListofReceipient(receipientData);
        receipientData.map((data) => {
          return setReceipientId(data._id);
        });
      })
      .catch((err) => {
        // console.log('err.response.data.error: ', err);
      });
  };
  const sendMoneyRecord = () => {
    dispatch(setTemp({ key: 'transaction_reference_number', value: '' }));
    setConfirmBooking(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/money`,
        {
          from: { amount: dataCal.from.amount, symbol: dataCal.from.symbol },
          rates: {
            GBP: dataCal.rates.GBP,
            USD: dataCal.rates.USD,
            AUD: dataCal.rates.AUD,
            CAD: dataCal.rates.CAD,
          },
          to: { amount: dataCal.to.amount, symbol: dataCal.to.symbol },
          taxes: {
            remigosCharge: dataCal.taxes.remigosCharge,
            bankCharge: dataCal.taxes.bankCharge,
            gst: dataCal.taxes.gst,
          },
          recipient_id: receipientId,
          sender_id: senderId,
          finalAmount: dataCal.finalAmount,
          transaction_reference_number,
        },
        axiosToken,
      )
      .then((res) => {
        // console.log('res-post: money ', res);
        setRemittanceId(res.data.remittance_id);
      })
      .catch((err) => {
        // console.log('err.response.data.error: ', err);
      });
  };
  const bookingConfirmModal = () => {
    dispatch(setSendMoneyStep());
    setConfirmBooking(false);
    if (sendMoneyStepVal == 4 && !confirmationFlag) {
      setConfirmationFlag(true);
    }
    // setCurrentStep((prevState) => {
    //   if (prevState == 4 && confirmationFlag) {
    //     return sendMoneyStepVal;
    //   } else if (prevState !== 4) {
    //     return sendMoneyStepVal;
    //   } else if (prevState == 4 && !confirmationFlag) {
    //     setConfirmationFlag(true);
    //   }
    //   return prevState;
    // });
  };
  const handleClose = () => {
    setConfirmBooking(false);
  };
  useEffect(() => {
    docStatus();
    if (payNowSelected) {
      // setCurrentStep(sendMoneyStepVal);
      payNowSelected?.map((data) => {
        setSelectedOwn(data._id);
        setReceipientPayVal(data.type);
      });
    } else {
      if (receipientPayVal != 'own') {
        listofReceipient?.map((data) => {
          setSelectedOwn(data._id);
        });
      }
    }
  }, [currentStep]);
  useEffect(() => {
    setCurrentStep(sendMoneyStepVal);
  }, [sendMoneyStepVal]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/sender`, axiosToken)
      .then((res) => {
        // console.log('res sender: ', res);
        setSenderList(res.data);
        if (res.data.data.length === 0) {
          setSenderAccountVal('');
        }
      })
      .catch((err) => {
        // console.log('err: ', err);
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}/time`, axiosToken)
      .then((res) => {
        // Refactor
        let time = res.data.time;
        const hours = time.substring(time.indexOf('T') + 1, time.indexOf(':'));
        const newTime = new Date(time);
        let current_time = moment().format('HH:mm');
        const currentTime = +hours * 60;
        setMarketTime(currentTime);
      })
      .catch((err) => {
        // console.log('err: ', err);
      });
    if (user.whoWantPay === 'own') {
      console.log('Rishabh');
      setSenderAccountVal('');
    }
  }, []);

  const docStatus = async () => {
    let axiosToken = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
      },
    };
    let statusUploadDoc = {};
    try {
      statusUploadDoc = await axios.get(
        `${process.env.REACT_APP_API_URL}/files`,
        axiosToken,
      );
      setListDocStatus(statusUploadDoc);
    } catch (error) {
      // console.log(error);
    }
  };
  const startTime = +'09:00';
  const endTime = +'02:00';
  return (
    <>
      <Header />
      <div className="container mb-sm-5">
        <div className="row" id="send-money-wrapper">
          {currentStep > 1 && currentStep <= 5 && (
            <div className="text-start">
              <button
                onClick={() => dispatch(setBackStep())}
                className="back-btn pb-1 d-block d-sm-none">
                <img src={BackIco} alt="back-ico" className="me-2" />
                Back{' '}
              </button>
            </div>
          )}
          <div className="col-12 pt-4 px-0 px-sm-3">
            <h1 className="ps-4"> Send Money </h1>
            {/* {marketTime >= startTime && marketTime <= endTime && (
              <MultiProgressBar
                currentStep={currentStep}
                sendMoneyBar={sendMoneyBar}
              />
            )} */}
            <MultiProgressBar
              currentStep={currentStep}
              sendMoneyBar={sendMoneyBar}
            />
            {!proofUploadedStatus ? (
              <>
                {payNowSelected._id && currentStep === 2 ? (
                  ''
                ) : (
                  <>
                    {currentStep > 1 && currentStep <= 5 ? (
                      <button
                        onClick={() => dispatch(setBackStep())}
                        className="back-btn pb-1 d-none d-sm-block">
                        <img src={BackIco} alt="back-ico" className="me-2" />
                        Back{' '}
                      </button>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
            ) : (
              ''
            )}
            {(() => {
              switch (currentStep) {
                case 1:
                  return (
                    <Sender
                      setActiveClass={setActiveClass}
                      activeClass={activeClass}
                      setReceipientPayVal={setReceipientPayVal}
                      receipientPayVal={receipientPayVal}
                      receipients={receipients}
                      countReceipientList={countReceipientList}
                      setCountReceipientList={setCountReceipientList}
                      setSendMoneyReceipient={setSendMoneyReceipient}
                      sendMoneyReceipient={sendMoneyReceipient}
                      listDocStatus={listDocStatus}
                      setListDocStatus={setListDocStatus}
                      setRentAgreementDoc={setRentAgreementDoc}
                      rentAgreementDoc={rentAgreementDoc}
                      senderAccountVal={senderAccountVal}
                      setSenderAccountVal={setSenderAccountVal}
                      relativeContent={relativeContent}
                      setRelativeContent={setRelativeContent}
                      marketTime={marketTime}
                      setMarketTime={setMarketTime}
                      setTimeVal={setTimeVal}
                      timeVal={timeVal}
                    />
                  );
                case 2:
                  return (
                    <Sender
                      sendMoneyBar={sendMoneyBar}
                      payVal={payVal}
                      setPayVal={setPayVal}
                      senderId={senderId}
                      setSenderId={setSenderId}
                      listofReceipient={listofReceipient}
                      setDocStatus={setDocumentStatus}
                      docStatus={documentStatus}
                      senderAccountVal={senderAccountVal}
                      setSenderAccountVal={setSenderAccountVal}
                      relativeContent={relativeContent}
                      setRelativeContent={setRelativeContent}
                      setListDocStatus={setListDocStatus}
                      senderList={senderList}
                      setSenderList={setSenderList}
                    />
                  );
                case 3:
                  return (
                    <BankTransfer
                      receipientPayVal={receipientPayVal}
                      setReceipientPayVal={setReceipientPayVal}
                      setTimeVal={setTimeVal}
                      timeVal={timeVal}
                    />
                  );
                case 4:
                  return (
                    <>
                      <BankTransfer
                        transferDetails="transferDetails"
                        receipientPayVal={receipientPayVal}
                        listofReceipient={listofReceipient}
                        selectedOwn={selectedOwn}
                        setSelectedOwn={setSelectedOwn}
                        setTimeVal={setTimeVal}
                        timeVal={timeVal}
                      />
                      {confirmationFlag && <TermsConditions />}
                    </>
                  );
                case 5:
                  return (
                    <BankTransfer
                      receipientPayVal={receipientPayVal}
                      listofReceipient={listofReceipient}
                      confirmation="confirmation"
                      selectedOwn={selectedOwn}
                      setSelectedOwn={setSelectedOwn}
                    />
                  );
                case 6:
                  return (
                    <>
                      {!proofUploadedStatus ? (
                        <ConfirmationBooked
                          receipientPayVal={receipientPayVal}
                          listofReceipient={listofReceipient}
                          confirmation="confirmation"
                          setTimeVal={setTimeVal}
                          timeVal={timeVal}
                        />
                      ) : (
                        <ThankYou
                          confirmation="confirmation"
                          sendMoneyBar={sendMoneyBar}
                        />
                      )}
                    </>
                  );
                default:
                  return 'No Page Found';
              }
            })()}
            <div className="col-12 btn-wrapper d-sm-flex align-items-center justify-content-center py-4 py-sm-0">
              {(() => {
                switch (currentStep) {
                  case 1:
                    return (
                      <>
                        {!timeVal ? (
                          ''
                        ) : (
                          <>
                            {listDocStatus?.data.status.father === 'approved' ||
                            listDocStatus?.data.status.mother === 'approved' ||
                            listDocStatus?.data.status.spouse === 'approved' ||
                            listDocStatus?.data.status.own === 'approved' ? (
                              <button
                                disabled={
                                  receipientPayVal === '' ? true : false
                                }
                                className={
                                  receipientPayVal === '' ||
                                  countReceipientList[receipientPayVal] === 0
                                    ? 'btn-disable mt-4 p-4 d-flex align-items-center justify-content-center'
                                    : 'mt-4 p-4 d-flex align-items-center justify-content-center next-btn login-btn'
                                }
                                onClick={benificiaryTransfer}>
                                Next
                              </button>
                            ) : (
                              ''
                            )}
                          </>
                        )}
                      </>
                    );
                  case 2:
                    return (
                      <button
                        disabled={senderId ? false : true}
                        className={
                          senderId
                            ? 'mt-4 p-4 d-flex align-items-center justify-content-center next-btn login-btn'
                            : 'btn-disable mt-sm-4 p-4 d-flex align-items-center justify-content-center '
                        }
                        onClick={nextButton}>
                        Next
                      </button>
                    );
                  case 3:
                    return (
                      <button
                        className="mt-4 p-4 d-flex align-items-center justify-content-center next-btn login-btn"
                        onClick={nextButton}>
                        {' '}
                        Confirm{' '}
                      </button>
                    );
                  case 4:
                    return (
                      <>
                        {!confirmationFlag ? (
                          <button
                            disabled={selectedOwn === '' ? true : false}
                            className={
                              selectedOwn === ''
                                ? 'btn-disable mt-4 p-4 d-flex align-items-center justify-content-center'
                                : 'mt-4 p-4 d-flex align-items-center justify-content-center next-btn login-btn'
                            }
                            onClick={() => setConfirmationFlag(true)}>
                            Show Terms And Condition
                          </button>
                        ) : (
                          <button
                            // disabled={selectedOwn === '' ? true : false}
                            className="mt-4 p-4 d-flex align-items-center justify-content-center next-btn login-btn"
                            onClick={nextButton}>
                            Next
                          </button>
                        )}
                      </>
                    );
                  case 5:
                    return (
                      <>
                        <button
                          className="mt-4 p-4 d-flex align-items-center justify-content-center next-btn login-btn"
                          onClick={sendMoneyRecord}>
                          {timeVal ? 'Book Now' : 'Schedule Now'}
                        </button>
                      </>
                    );
                  case 6:
                    return (
                      <>
                        {!proofUploadedStatus ? (
                          <div className="row">
                            <div className="col-12 d-sm-flex text-center text-start align-items-center justify-content-center">
                              <button
                                className="mt-5 p-4 d-flex align-items-center justify-content-center next-btn login-btn"
                                onClick={() =>
                                  handleProofTransfer('uploadProof')
                                }>
                                UPLOAD PROOF OF TRANSFER
                              </button>
                              <Link
                                className="upload-later-btn mt-sm-5 ms-sm-4 d-flex align-items-center justify-content-center"
                                to="/dashboard"
                                onClick={() => dispatch(clearSendMoneyVal())}>
                                {' '}
                                UPLOAD LATER{' '}
                              </Link>
                            </div>
                            <div className="text-center pt-3 bottom-text">
                              <p className="text-center ">
                                Please upload a screenshot of the transfer with
                                the UTR details or your account statement
                                showing the amount of debit
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="btn-wrapper">
                            <Link
                              to="/dashboard"
                              onClick={() => dispatch(clearSendMoneyVal())}
                              className="mt-4 p-4 d-flex align-items-center justify-content-center login-btn link-btn"
                              style={{ display: 'inline-block' }}>
                              Go to Dashboard
                            </Link>
                          </div>
                        )}
                      </>
                    );
                }
              })()}
            </div>
            {/* <div className="btn-wrapper d-flex justify-content-center mt-3 mb-5"></div> */}
            {uploadProof ? (
              <UploadDoc
                setShow={setShow}
                uploadProof={uploadProof}
                setUploadProof={setUploadProof}
                show={show}
                remittanceId={remittanceId}
                setRemittanceId={setRemittanceId}
                setProofUploadedStatus={setProofUploadedStatus}
                proofUploadedStatus={proofUploadedStatus}
              />
            ) : (
              ''
            )}
          </div>
          <Modal
            show={confirmBooking}
            onHide={handleClose}
            className="book-modal-wrappr">
            <Modal.Body className="text-center p-0">
              <Modal.Header closeButton>
                <h5 className="modal-title" id="staticBackdropLabel">
                  {timeVal ? 'Book Rate' : 'Transfer money'}
                </h5>
              </Modal.Header>
              <h2 style={{ fontSize: '24px' }} className="pt-4 pb-3">
                {timeVal
                  ? 'Are you sure you want to book rate?'
                  : 'You can transfer money now'}
              </h2>
              <img src={bookRate} alt="RemoveIco" className="w-50 my-3" />
              <h5 className="pt-2 modal-para">
                {timeVal
                  ? 'The price will be locked only for 1 hour. Will you be able to transfer money before that?'
                  : 'Transfer the money to the bank account and we will be in touch with you after completing the transaction.'}
              </h5>
              <div className="pt-3 pb-4">
                <Button
                  className="confirm-booking-cancel me-3"
                  onClick={bookingConfirmModal}>
                  Yes
                </Button>
                <Button className="confirm-booking-btn" onClick={handleClose}>
                  No
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SendMoney;
