import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './transactions.scss';
import Footer from '../../Common/Pages/footer/footer';
import Header from '../../Common/Pages/header/header';
import NavBar from '../../Common/Pages/NavBar/nav-bar';
import MoneyModal from '../../Common/Pages/money-modal/money-modal';
import StatusModal from '../../Common/Pages/status/status';

import { transactionDate } from '../../Util/functions';
import { fetchTransactions } from '../../global/features/data';

import InfoIco from '../../Assets/Images/info.png';
import PaidTo from '../../Assets/Images/paid-to.svg';
import PendingIco from '../../Assets/Images/pending.svg';
import AdditionalIco from '../../Assets/Images/additional-docs.svg';
import RejectedIco from '../../Assets/Images/rejected.svg';
import ProcessingIco from '../../Assets/Images/processing.png';

const Transactions = () => {
  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const transactions = useSelector((state) => state.data.transactions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTransactions());
  }, []);

  if (transactions.loading) {
    return <p>loading...</p>;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-3 px-0 sidebar-container">
          <NavBar />
        </div>
        <div className="col-sm-9 col-12 px-0 right-wrapper">
          <Header headerSecond={true} />

          <div className="d-flex justify-content-center mt-100">
            <div className="col-11 px-0 table-wrapper">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">
                      <span>Last</span>
                      <br /> 30 Days
                    </th>
                    <th scope="col">
                      <span>TRANSACTIONS</span>
                      <br />
                      {transactions.data.total.count}
                    </th>
                    <th scope="col">
                      <span>TOTAL TRANSFER</span>
                      <br />£{transactions.data.total.sum.toFixed(2)}
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {transactions.data.remittances.map((transaction) => {
                    let className = 'done';
                    let imgElm = null;
                    const additionalDocsSize = transaction.additional_docs
                      ? transaction.additional_docs.filter((d) => !d?.location)
                          .length
                      : 0;

                    const data = { ...transaction };

                    if (!transaction.proof) {
                      className = 'warning';
                      imgElm = (
                        <img
                          src={PendingIco}
                          alt="pending"
                          height="45"
                          width="45"
                        />
                      );
                      data.title = 'Upload Proof of Transfer';
                      data.action = 'UPLOAD_PROOF';
                    } else if (additionalDocsSize > 0) {
                      className = 'warning';
                      imgElm = (
                        <img
                          src={AdditionalIco}
                          alt="additional-docs"
                          height="45"
                          width="45"
                        />
                      );
                      data.title = 'Additional Documents Required';
                      data.action = 'UPLOAD_ADDITIONAL_DOCS';
                    } else if (
                      transaction?.proof &&
                      transaction.proof.status === 'rejected'
                    ) {
                      className = 'error';
                      imgElm = (
                        <img
                          src={RejectedIco}
                          alt="rejected"
                          height="45"
                          width="45"
                        />
                      );
                      data.title = 'Transfer Failed';
                      data.action = 'UPLOAD_REJECTED_PROOF';
                    } else if (transaction.status !== 'AMOUNT_CREDITED') {
                      className = 'pending';
                      imgElm = (
                        <img
                          src={ProcessingIco}
                          alt="pending"
                          height="40"
                          width="40"
                        />
                      );
                    }

                    data.className = className;

                    return (
                      <tr
                        key={transaction.remittance_id}
                        onClick={() => {
                          if (
                            className === 'error' ||
                            className === 'warning'
                          ) {
                            setShowModal(true);
                            setSelectedTransaction(data);
                          }
                        }}
                        className={className}>
                        <th scope="row" className="col-first">
                          {transactionDate(new Date(transaction.createdAt))}
                        </th>
                        <td className="d-flex">
                          <img src={PaidTo} alt="paid-to" className="mr-2" />
                          <div>
                            <span>Paid to</span>
                            <br />
                            {transaction.recipient
                              ? transaction?.recipient?.account_holder_name
                              : 'Deleted Recipient'}
                          </div>
                        </td>
                        <td>
                          <span>₹{transaction.currency.from.amount}</span>
                          <br />£{transaction.currency.to.amount.toFixed(2)}
                        </td>
                        <td>
                          {imgElm}{' '}
                          <img
                            src={InfoIco}
                            height={35}
                            width={35}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedTransaction(data);
                              setShowStatusModal(true);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {selectedTransaction && (
            <MoneyModal
              show={showModal}
              title={selectedTransaction?.title}
              handleClose={() => setShowModal(false)}
              onDoneClick={() => {
                dispatch(fetchTransactions());
                setShowModal(false);
              }}
              transactionData={{
                fromAmt: selectedTransaction?.currency.from.amount,
                toAmt: selectedTransaction?.currency.to.amount,
                createdAt: new Date(selectedTransaction?.createdAt),
                recipientName:
                  selectedTransaction?.recipient.account_holder_name,
                className: selectedTransaction?.className,
                remittance_id: selectedTransaction?.remittance_id,
                action: selectedTransaction?.action,
              }}
              docsData={
                selectedTransaction?.action === 'UPLOAD_PROOF' ||
                selectedTransaction?.action === 'UPLOAD_REJECTED_PROOF'
                  ? [
                      {
                        field: selectedTransaction.proof?.field || 'proof',
                        label: 'Proof Of Transfer',
                        location: selectedTransaction.proof?.location,
                        originalname: selectedTransaction.proof?.originalname,
                        _id: selectedTransaction.proof?._id,
                        status: selectedTransaction.proof?.status,
                        message: selectedTransaction.proof?.message,
                      },
                    ]
                  : selectedTransaction?.additional_docs.map((doc) => ({
                      field: doc.field,
                      label: doc.label,
                      _id: doc._id,
                      location: doc.location,
                      originalname: doc.originalname,
                    }))
              }
            />
          )}

          {selectedTransaction && (
            <StatusModal
              show={showStatusModal}
              title="Transaction Details"
              transactionData={selectedTransaction}
              handleClose={() => setShowStatusModal(false)}
            />
          )}

          <div className="mt-5">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
