import React, { useState } from 'react';
import RateBookedIco from '../../../Assets/Images/rate-booked.svg';
import './confirmation-booked.scss';
import { useDispatch, useSelector } from 'react-redux';
import UploadDoc from '../../upload-document/upload-doc-/upload-doc';

function ConfirmationBooked() {
  const dataCal = useSelector((state) => state.calculator);
  const [show, setShow] = useState(false);
  const bankDetails = useSelector((state) => state.data.bankDetails);
  return (
    <>
      <div id="bank-transfer-wrapper" className="row mt-4">
        <div className="col-12 pe-4">
          <div className="left-sec h-100 px-5 py-sm-5">
            <div className="row mt-5 mx-0 confirm-booked-wrapper">
              <div className="col-sm-7 col-12 text-center text-start">
                <img
                  src={RateBookedIco}
                  alt="money-transfer-ico"
                  className="booked-img"
                />
              </div>
              <div
                className={
                  bankDetails === null
                    ? 'col-sm-5 col-12 ps-0 pb-5 d-flex align-items-center'
                    : 'col-sm-5 col-12 ps-0 pb-5'
                }>
                {bankDetails === null ? (
                  <div className="text-center">
                    <h2 className="mb-3">
                      Please contact customer care for bank detail.
                    </h2>
                    <a
                      href="https://wa.me/message/KVW64HQKH7SID1"
                      target="_blank"
                      className="btn-redirect"
                      rel="noreferrer">
                      Click Here
                    </a>
                  </div>
                ) : (
                  <>
                    <div className="col-12 ps-0 mb-4">
                      <h1> Your Rate is booked </h1>
                    </div>
                    <div className="col-12 ps-0 mb-4">
                      <h4>
                        {' '}
                        Please transfer{' '}
                        <strong> ₹{dataCal.from.amount} </strong> <br />
                        to the following account
                      </h4>
                    </div>
                    <div className="col-12 ps-0 mb-4">
                      <h6> Bank Name </h6>
                      <h5> {bankDetails.bank_name} </h5>
                    </div>
                    <div className="col-12 ps-0 mb-4">
                      <h6> Bank Branch </h6>
                      <h5> {bankDetails.branch} </h5>
                    </div>
                    <div className="col-12 ps-0 mb-4">
                      <h6> Account Number </h6>
                      <h5> {bankDetails.account_number} </h5>
                    </div>
                    <div className="col-12 ps-0">
                      <h6> IFSC Code </h6>
                      <h5> {bankDetails.ifsc_code} </h5>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmationBooked;
