/* eslint-disable prettier/prettier */
/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import PlusIco from '.././../../Assets/Images/plus-ico.svg';
import JPGIco from '.././../../Assets/Images/jpg-ico.png';
import DocxIco from '.././../../Assets/Images/docx-ico.png';
import PNGIco from '.././../../Assets/Images/png-ico.png';
import PDFIco from '.././../../Assets/Images/pdf-ico.png';
import CheckIco from '.././../../Assets/Images/check.png';
import DeleteIco from '.././../../Assets/Images/delete.png';
import CameraIco from '.././../../Assets/Images/camera.svg';
import UploadIco from '.././../../Assets/Images/upload.svg';
import RemoveIco from '.././../../Assets/Images/remove.png';
import './upload-doc.scss';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { getImageSize } from '../../../Util/image';
import { toBase64, getByteBase64 } from '../../../Util/base64';
import axios from 'axios';
import { ProgressBar } from 'react-bootstrap';
import { setUser, setReqDoc } from '../../../global/features/user';
import { useDispatch, useSelector } from 'react-redux';
import { ListofDoc } from '../../../Util/docDataList';
import { BallTriangle } from 'react-loader-spinner';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

function UploadDoc(props) {
  const [photo, setPhoto] = useState(null);
  const [imageSize, setImageSize] = useState({ width: 300, height: 100 });
  const [isUseCamera, setUseCamera] = useState(false);
  const [result, setResult] = useState(null);
  const [canvasSize, setCanvasSize] = useState({ width: 300, height: 100 });
  const refPhoto = useRef(null);
  const refCanvas = useRef(null);
  const [documents, setDocuments] = useState([]);
  const [uploadPercantage, setUploadPercantage] = useState(0);
  const user = useSelector((state) => state.user);
  const token = user.user.tokens.access;
  const [keysonDelete, setKeysonDelete] = useState(ListofDoc);
  const [progressBarId, setProgressBarId] = useState();
  const [docTypeImage, setDocTypeImage] = useState(PlusIco);
  const [loader, setLoader] = useState(false);
  const [showErroText, setShowErroText] = useState(false);
  const dataList = useSelector((state) => state.user.documentList);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    props.setShow(false);
  };
  const handleShow = () => setShow(true);
  let axiosToken = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: token,
    },
    onUploadProgress: (e) => {
      const { loaded, total } = e;
      let percentage = Math.floor((loaded * 100) / total);
      setUploadPercantage((prevState) => {
        return props.listofDoc?.map((data, i) => {
          if (data.key === progressBarId) {
            data.uploadStatus = percentage;
          }
        });
      });
    },
  };
  // useEffect(() => {
  //   if (refPhoto?.current) {
  //     const { offsetWidth, offsetHeight } = refPhoto.current;
  //     setCanvasSize({ width: offsetWidth, height: offsetHeight });
  //   }
  // }, [photo]);
  const handleTakePhoto = async (dataUri) => {
    if (dataUri) {
      setPhoto(dataUri);
      const imageSize = await getImageSize(dataUri);
      setImageSize(imageSize);
      setUseCamera(false);
    }
    var image = new Image();
    image.onload = function () {
      // console.log(image.src, 'src'); // image is loaded and we have image width
    };
    image.src = dataUri;
    setDocuments('');
  };
  const handleUploadPhoto = (e) => {
    setShowErroText('');
    const input = e.target.files[0];
    setDocuments(input);
  };
  const submitUploadDoc = () => {
    const size = (documents.size / 1024 / 1024).toFixed(2);
    let data = new FormData();
    data.append(`${progressBarId}`, documents);
    let fileExtension = documents.name.split('.').at(-1);
    if (documents.length === 0) {
      setShowErroText(true);
    } else if (
      fileExtension != 'png' &&
      fileExtension != 'jpg' &&
      fileExtension != 'pdf' &&
      fileExtension != 'doc' &&
      fileExtension != 'docx' &&
      fileExtension != 'jpeg'
    ) {
      setShowErroText('Please upload below mentioned files only.');
    } else if (size > 10) {
      setShowErroText('The file size is larger than 10MB.');
    } else {
      props.setListofDoc((prevState) => {
        return props.listofDoc.map((data, i) => {
          if (data.key === progressBarId) {
            data.docName = documents.name;
          }
        });
      });
      props.setListofDoc(props.listofDoc);
      props.setListofDoc((prevState) => {
        return props.listofDoc.map((data) => {
          let fileExtension = documents.name.split('.').at(-1);
          if (data.key === progressBarId) {
            if (documents.type === 'application/pdf') {
              data.image = PDFIco;
            }
          }
          if (data.key === progressBarId) {
            if (documents.type === 'application/msword') {
              data.image = DocxIco;
            }
          }
          if (data.key === progressBarId) {
            if (documents.type === 'image/jpeg') {
              data.image = JPGIco;
            }
          }
          if (data.key === progressBarId) {
            if (documents.type === 'image/png') {
              data.image = PNGIco;
            }
          }
        });
      });
      props.setListofDoc(props.listofDoc);
      axios
        .post(`${process.env.REACT_APP_API_URL}/files`, data, axiosToken)
        .then((res) => {
          // console.log('res upload:', res);
          setShow(false);
        })
        .catch((err) => {
          // console.log('err: ', err);
        });
      const enableButton = props.listofDoc.some(
        (el) => el.required && el.docName == 'No Image',
      );
      props.setDocButton(enableButton);
      setShowErroText(false);
      // Refactor
      if (showErroText) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  };
  const handleUploadProof = (e) => {
    const input = e.target.files[0];
    setDocuments(input);
  };
  const uploadProofDoc = () => {
    props.remittanceId;
    let data = new FormData();
    data.append('proof', documents);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/money/${props.remittanceId}/proof`,
        data,
        axiosToken,
      )
      .then((res) => {
        // console.log('res upload:', res);
        // navigate('/')
        props.setProofUploadedStatus(true);
        props.setShow(false);
      })
      .catch((err) => {
        // console.log('err', err);
      });
  };
  const handleResetPhoto = () => {
    setPhoto(false);
    setResult(null);
    const canvas = refCanvas.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, 300, 100);
  };

  // useEffect(() => {
  //   if (result) {
  //     const canvas = refCanvas.current;
  //     const ctx = canvas.getContext('2d');
  //     ctx.clearRect(0, 0, canvasSize.width, canvasSize.height);
  //     const { detected_objects } = result;
  //     const resolutionMultiply = canvasSize.width / imageSize.width;
  //     detected_objects.forEach((object, i) => {
  //       const { bottom, left, right, top } = object.bounding_box;
  //       const x = left * resolutionMultiply;
  //       const y = top * resolutionMultiply;
  //       const width = (right - left) * resolutionMultiply;
  //       const height = (bottom - top) * resolutionMultiply;
  //       drawBox({ ctx, x, y, width, height, color: colorScheme[i] });
  //     });
  //   }
  // }, [result, canvasSize, imageSize]);

  const openDocModal = (data) => {
    if (data.image === null) {
      setShow(true);
      setProgressBarId(data.key);
      setDocuments([]);
      setPhoto(false);
      setShowErroText(false);
    }
  };
  const handleDeleteDoc = (data) => {
    let axiosToken = {
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        Authorization: token,
      },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/files/${keysonDelete}`,
        axiosToken,
      )
      .then((res) => {
        // console.log('res: ', res);
        const enableButton = props.listofDoc.some(
          (el) => el.required && el.image != null,
        );
        props.setDocButton(enableButton);
        setUploadPercantage((prevState) => {
          // console.log('keysonDelete: ', keysonDelete);
          return props.listofDoc.map((data, i) => {
            if (data.key === keysonDelete) {
              data.uploadStatus = 0;
            }
          });
        });
        props.setListofDoc((prevState) => {
          return props.listofDoc.map((data) => {
            if (data.key === keysonDelete) {
              data.docName = 'No Image';
              data.image = null;
            }
          });
        });
        props.setListofDoc(props.listofDoc);
      })
      .catch((err) => {
        // console.log('err: ', err);
      });
  };
  return (
    <>
      <div className="container px-0 px-sm-3">
        {props.uploadProof ? (
          <Modal show={props.show} onHide={handleClose} className="modal-outer">
            <Modal.Header closeButton>
              <h5 className="modal-title" id="staticBackdropLabel">
                Upload
              </h5>
            </Modal.Header>
            <Modal.Body className="text-center">
              <div className="upload-modal row">
                <p className="mt-3 mb-4">
                  Documents uploaded must be within 10MB file size
                </p>
                <div className="col-10 mx-auto">
                  <div id="file-upload" className="ms-1">
                    <img
                      src={UploadIco}
                      alt="upload-ico"
                      className="upload-ico pt-3"
                    />
                    {/* <h6> Drag and Drop </h6>
                    <p> or hit the “Browse” button </p> */}
                    <div className="btn-wrapper">
                      <div className="file file--upload pb-2 mt-3">
                        <label htmlFor="input-file">BROWSE</label>
                        <input
                          id="input-file"
                          type="file"
                          accept=".jpg, .jpeg, .png, .doc, .docx,.pdf"
                          onChange={handleUploadProof}
                        />
                      </div>
                      <span className="text-success upload-file-text">
                        {' '}
                        {documents ? documents.name : ''}
                      </span>
                      <span
                        className={
                          showErroText ? 'd-block text-danger' : 'd-none'
                        }
                      >
                        {' '}
                        Please select document{' '}
                      </span>
                      <p className="mt-3">
                        {' '}
                        .pdf, .jpg, .png, .docx files can be uploaded{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="btn-wrapper mt-5 mb-4">
                  <button
                    className="login-btn"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="hide"
                    onClick={uploadProofDoc}
                  >
                    {' '}
                    SUBMIT{' '}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div className="row mt-3 mt-sm-0" id="upload-doc-wrapper">
            <div className="col-12 mx-auto text-center py-5">
              <h1> Upload Documents </h1>
              <p> Documents uploaded must be within 10MB file size </p>
              <ul className="pt-4 ps-0 ps-sm-3">
                {props.listofDoc.map((data, i) => {
                  let requiredDocCheck = data.required;
                  let mandatoryDocText;
                  if (requiredDocCheck) {
                    mandatoryDocText = '*';
                  } else {
                    mandatoryDocText = '(optional)';
                  }
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <li
                      className="d-flex align-items-center justify-content-center col-12 col-sm-10 mx-auto"
                      key={data.key}
                    >
                      <div className="row w-100 justify-content-start justify-content-sm-center mb-sm-5 mb-4">
                        <div className="col-3 col-sm-2 d-flex justify-content-center ps-0 ps-sm-3">
                          <span
                            className={data.image === null ? 'upload-ico' : ''}
                          >
                            <Button
                              onClick={() => openDocModal(data)}
                              className="h-100"
                            >
                              <img
                                src={data.image === null ? PlusIco : data.image}
                                alt="bankIco"
                                className={
                                  data.image === null
                                    ? 'defaultIco'
                                    : 'uploadIco'
                                }
                              />
                            </Button>
                          </span>
                        </div>
                        <div className="col-8 col-sm-7 ps-0 ps-sm-3">
                          <div className="text-start">
                            <h6 className="mb-sm-0">
                              {' '}
                              {data.label} {mandatoryDocText}{' '}
                            </h6>
                            {/passport/i.test(data.label) && (
                              <p className="mb-1" style={{ fontSize: '12px' }}>
                                {' '}
                                Front and Back page.{' '}
                              </p>
                            )}
                            <p className="d-none d-sm-block">
                              {' '}
                              {data.docName}{' '}
                            </p>
                            <div className="progressBar">
                              <ProgressBar
                                now={data.uploadStatus}
                                data-before={data.id}
                              />
                              <p
                                className="d-block d-sm-none pt-1"
                                style={{
                                  fontWeight: '600',
                                  color: '#45C29A',
                                  fontSize: '14px',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {' '}
                                {data.docName}{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-1 col-sm-2 d-flex align-items-center justify-content-center justify-content-sm-start">
                          <p className="percantageStatus d-none d-sm-block">
                            {data.uploadStatus}%
                          </p>
                          {data.uploadStatus > 0 ? (
                            <img
                              src={CheckIco}
                              alt="complete-ico"
                              className="ico-check mx-2 d-none d-sm-block"
                            />
                          ) : (
                            ''
                          )}
                          {/* Delete Confirmation modal */}
                          {data.uploadStatus > 0 ? (
                            <button
                              className={
                                data.uploadStatus > 0
                                  ? 'ico-delete mx-2'
                                  : 'disable-ico mx-2'
                              }
                              onClick={() => setKeysonDelete(data.key)}
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <img src={DeleteIco} alt="complete-ico" />
                            </button>
                          ) : (
                            ''
                          )}
                          <div
                            className="modal fade"
                            id="exampleModal"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog w-sm-25 w-75 mx-auto h-100 d-flex align-items-center">
                              <div className="modal-content">
                                <div className="modal-body">
                                  <h2> Confirm Delete </h2>
                                  <img
                                    src={RemoveIco}
                                    alt="RemoveIco"
                                    className="w-25 my-3"
                                  />
                                  <p>
                                    {' '}
                                    Are you sure you want to delete this
                                    document?{' '}
                                  </p>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-secondary me-3"
                                      data-bs-dismiss="modal"
                                      onClick={() => handleDeleteDoc()}
                                    >
                                      Confirm
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-bs-dismiss="modal"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <Modal show={show} onHide={handleClose} className="modal-outer">
                <Modal.Header closeButton>
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Upload
                  </h5>
                </Modal.Header>
                <Modal.Body className="text-center">
                  <div className="upload-modal row">
                    <p className="mb-4 mt-3">
                      Documents uploaded must be within 10MB file size
                    </p>
                    <div className="col-10 mx-auto">
                      <div id="file-upload" className="ms-1">
                        <img
                          src={UploadIco}
                          alt="upload-ico"
                          className="upload-ico pt-3"
                        />
                        {/* <h6 className="mt-3"> Drag and Drop </h6>
                          <p> or hit the “Browse” button </p> */}
                        <div className="btn-wrapper mt-3">
                          <div className="file file--upload pb-2">
                            <label htmlFor="input-file">BROWSE</label>
                            <input
                              id="input-file"
                              type="file"
                              accept=".jpg, .jpeg, .png, .doc, .docx,.pdf"
                              onChange={handleUploadPhoto}
                            />
                          </div>
                          {showErroText !=
                            'The file size is larger than 10MB.' &&
                          showErroText !=
                            'Please upload below mentioned files only.' ? (
                            <span className="text-success">
                              {' '}
                              {documents ? documents.name : ''}
                            </span>
                          ) : (
                            ''
                          )}
                          {!documents.name && showErroText ? (
                            <span className="text-danger">
                              {' '}
                              Please select document{' '}
                            </span>
                          ) : (
                            ''
                          )}
                          {showErroText ===
                            'The file size is larger than 10MB.' ||
                          showErroText ===
                            'Please upload below mentioned files only.' ? (
                            <span className="text-danger">
                              {' '}
                              {showErroText}{' '}
                            </span>
                          ) : (
                            ''
                          )}
                          <p className="mt-3">
                            {' '}
                            .pdf, .jpg, .png, .docx files can be uploaded{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className='col-1 d-flex align-items-center justify-content-center'>  
                        <span>or</span>
                      </div>   */}
                    {/* <div className='col-5'>
                        <div
                          id="take-picture"
                          className="h-100 d-flex align-items-center justify-content-center">
                          <div>
                            {photo && (
                              <div className={`relative ${!photo && 'hidden'}`}>
                                <img ref={refPhoto} src={photo} width="400" />
                                <canvas
                                  ref={refCanvas}
                                  width="400"
                                  className="absolute z-10 top-0 left-0"
                                />
                              </div>
                            )}
                            <div>
                              {!photo &&
                                (isUseCamera ? (
                                  <Camera onTakePhoto={handleTakePhoto} />
                                ) : (
                                  <div className="flex flex-col">
                                    <button
                                      className="bg-gray-200 p-2"
                                      onClick={() => setUseCamera(true)}>
                                      <img
                                        src={CameraIco}
                                        alt="img"
                                        className="camera-ico"
                                      />
                                    </button>
                                    <h6 className="mt-3">Take Picture</h6>
                                  </div>
                                ))}
                              {photo && (
                                <button
                                  className="bg-gray-200 p-2"
                                  onClick={handleResetPhoto}>
                                  Change Photo
                                </button>
                              )}
                              {isUseCamera && !photo && (
                                <button
                                  className="bg-gray-200 p-2"
                                  onClick={() => setUseCamera(false)}>
                                  Cancel camera
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>   */}
                    <div className="btn-wrapper mt-5 mb-4">
                      <button
                        disabled={!documents.name}
                        className={
                          !documents.name ? 'btn-disable' : 'login-btn'
                        }
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="hide"
                        onClick={submitUploadDoc}
                      >
                        {' '}
                        SUBMIT{' '}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        )}
      </div>
      {loader && (
        <div className="loader-wrapper d-flex align-items-center justify-content-center">
          <BallTriangle
            height="100"
            width="100"
            color="#45C29A"
            ariaLabel="loading"
          />
        </div>
      )}
    </>
  );
}

export default UploadDoc;
