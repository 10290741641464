import React from 'react';
import SimIco from '../../../Assets/Images/sim-img.svg';
import { Link } from 'react-router-dom';

const AllAnswers = () => {
  return (
    <div className="row mt-5">
      <h3 className="answers-heading mb-3">
        {' '}
        In doubt? Maybe we have some answers{' '}
      </h3>
      <div className="col-sm-3 col-12 text-left">
        <div className="answers-content text-center p-4 mb-4">
          <img src={SimIco} alt="sim-img" />
        </div>
        <h6 style={{ fontSize: '16px', fontWeight: '600' }}>
          Why do I need a UK sim?
        </h6>
        <p style={{ fontSize: '14px', fontWeight: '400' }}>
          If you are moving to the UK, you need to have a local number to
          receive calls and access basic services like Uber. It is also cheaper
          to use a UK number as compared to buying an international SIM or using
          international roaming on your Indian number.
        </p>
        {/* <Link
          to="#"
          style={{
            color: 'rgba(69, 194, 154, 1)',
            fontSize: '14px',
            fontWeight: '600',
            textDecoration: 'none',
          }}>
          {' '}
          Read More{' '}
        </Link> */}
      </div>
      <div className="col-sm-3 col-12 text-left mt-3 mt-sm-0">
        <div className="answers-content text-center p-4 mb-4">
          <img src={SimIco} alt="sim-img" />
        </div>
        <h6 style={{ fontSize: '16px', fontWeight: '600' }}>
          Are the monthly plans costly because I am buying the SIM in India?
        </h6>
        <p style={{ fontSize: '14px', fontWeight: '400' }}>
          No matter where you buy the SIM and when you activate it, you always
          get the same plan as any person buying the SIM in the UK. If anything
          you actually save money on the temporary numbers or roaming charges if
          you buy it in India. Win-Win!
        </p>
        {/* <Link
          to="#"
          style={{
            color: 'rgba(69, 194, 154, 1)',
            fontSize: '14px',
            fontWeight: '600',
            textDecoration: 'none',
          }}>
          {' '}
          Read More{' '}
        </Link> */}
      </div>
      <div className="col-sm-3 col-12 text-left mt-3 mt-sm-0">
        <div className="answers-content text-center p-4 mb-4">
          <img src={SimIco} alt="sim-img" />
        </div>
        <h6 style={{ fontSize: '16px', fontWeight: '600' }}>
          Can I just buy a SIM at the airport?
        </h6>
        <p style={{ fontSize: '14px', fontWeight: '400' }}>
          Yes you can. But those numbers are generally disposable and you cant
          use them for more than a month. They are also 3X more expensive than a
          regular SIM. You should only buy them if you dont have enough time or
          you like seeing the world burn. &#128521;
        </p>
        {/* <Link
          to="#"
          style={{
            color: 'rgba(69, 194, 154, 1)',
            fontSize: '14px',
            fontWeight: '600',
            textDecoration: 'none',
          }}>
          {' '}
          Read More{' '}
        </Link> */}
      </div>
      <div className="col-sm-3 col-12 text-left mt-3 mt-sm-0">
        <div className="answers-content text-center p-4 mb-4">
          <img src={SimIco} alt="sim-img" />
        </div>
        <h6 style={{ fontSize: '16px', fontWeight: '600' }}>
          Why should I buy a Giffgaff SIM?
        </h6>
        <p style={{ fontSize: '14px', fontWeight: '400' }}>
          The biggest benefit of Giffgaff SIM apart from its cheap plans is that
          you can activate it here in India and start receiving OTPs for free.
          This lets you access all the basic necessities like Bank, Cab
          services, Internships way earlier than the people who have no SIM.
        </p>
        {/* <Link
          to="#"
          style={{
            color: 'rgba(69, 194, 154, 1)',
            fontSize: '14px',
            fontWeight: '600',
            textDecoration: 'none',
          }}>
          {' '}
          Read More{' '}
        </Link> */}
      </div>
      <div className="col-12 btn-wrapper text-center mt-5">
        <button className="login-btn"> See All Answers </button>
      </div>
    </div>
  );
};
export default AllAnswers;
