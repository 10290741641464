/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './../index';
interface DataTypes {
  travelDate: number;
  deliveryCountry: string;
  simPlanId: string;
  userAaddressId: string;
  clientSecretId: [];
  lebaraPlan: string;
  deliveryType: string;
  travelDays: number;
  expressDeliveryDate: string;
  expressDeliveryEndDate: string;
  freeDeliveryStartDate: string;
  freeDeliveryEndDate: string;
  selectedPlan: [];
}

const initialState: DataTypes = {
  travelDate: null,
  deliveryCountry: '',
  simPlanId: '',
  userAaddressId: '',
  clientSecretId: [],
  lebaraPlan: '',
  deliveryType: '',
  travelDays: 0,
  expressDeliveryDate: '',
  expressDeliveryEndDate: '',
  freeDeliveryStartDate: '',
  freeDeliveryEndDate: '',
  selectedPlan: [],
};
const simReducer = createSlice({
  name: 'sim',
  initialState,
  reducers: {
    setTravelDate: (state, action) => {
      state.travelDate = action.payload;
    },
    setDeliveryCountry: (state, action) => {
      state.deliveryCountry = action.payload;
    },
    setDateOfTravelDays: (state, action) => {
      state.travelDays = action.payload;
    },
    setSimPlanId: (state, action) => {
      state.simPlanId = action.payload;
    },
    setUserAddress: (state, action) => {
      state.userAaddressId = action.payload;
    },
    setClientSecretId: (state, action) => {
      state.clientSecretId = action.payload;
    },
    setHandlePlan: (state, action) => {
      state.lebaraPlan = action.payload;
    },
    setDeliveryType: (state, action) => {
      state.deliveryType = action.payload;
    },
    setExpressDeliveryStartDate: (state, action) => {
      state.expressDeliveryDate = action.payload;
    },
    setExpressDeliveryEndDate: (state, action) => {
      state.expressDeliveryEndDate = action.payload;
    },
    setFreeDeliveryStartDate: (state, action) => {
      state.freeDeliveryStartDate = action.payload;
    },
    setFreeDeliveryEndDate: (state, action) => {
      state.freeDeliveryEndDate = action.payload;
    },
    setPlanSelected: (state, action) => {
      state.selectedPlan = action.payload;
    },
  },
});
const {
  setTravelDate,
  setDeliveryCountry,
  setSimPlanId,
  setUserAddress,
  setClientSecretId,
  setHandlePlan,
  setDeliveryType,
  setDateOfTravelDays,
  setExpressDeliveryStartDate,
  setExpressDeliveryEndDate,
  setFreeDeliveryStartDate,
  setFreeDeliveryEndDate,
  setPlanSelected,
} = simReducer.actions;

export {
  setTravelDate,
  setDeliveryCountry,
  setSimPlanId,
  setUserAddress,
  setClientSecretId,
  setHandlePlan,
  setDeliveryType,
  setDateOfTravelDays,
  setExpressDeliveryStartDate,
  setExpressDeliveryEndDate,
  setFreeDeliveryStartDate,
  setFreeDeliveryEndDate,
  setPlanSelected,
};

export default simReducer.reducer;
