import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import RegisterCompleteIco from '../../Assets/Images/thumbs-up.svg';
import Footer from '../../Common/Pages/footer/footer';
import Header from '../../Common/Pages/header/header';
import './send-money-status.scss';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

const SendMoneyStatus = () => {
  const params = useParams();
  const [progress, setProgress] = useState(0);
  const token = useSelector((state) => state.user.user.tokens.access);

  const fetchRemittance = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/money/${params.remittance_id}`,
        {
          headers: {
            authorization: token,
          },
        },
      );

      if (res.data.status === 'COMPLETED_BY_USER') {
        setProgress(0);
      }

      if (res.data.status === 'AMOUNT_RECEIVED') {
        setProgress(34);
      }

      if (res.data.status === 'AMOUNT_TRANSFERRED') {
        setProgress(67);
      }

      if (res.data.status === 'AMOUNT_CREDITED') {
        setProgress(100);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    fetchRemittance();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 px-0">
          <Header />
        </div>
      </div>
      <div className="container px-0 content-wrapper">
        <div className="row pb-5" id="verification-completed">
          <h1 className="text-center pt-5"> Complete </h1>
          <div className="col-sm-5 col-11 d-flex align-items-center justify-content-center mx-auto text-center reset-password-wrapper">
            <div className="mt-1 pt-5 w-100">
              <span>
                <img
                  src={RegisterCompleteIco}
                  alt="password-ico"
                  className="password-ico"
                />
              </span>
              <h1 className="pt-5 pb-2 page-heading"> Thank You </h1>
              <p>
                We are looking into the documents you have uploaded. Once we
                verify we will get back to you.
              </p>
            </div>
          </div>
          <div className="col-8 mx-auto mt-4">
            <ProgressBar percent={progress}>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    ✓
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    ✓
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    ✓
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? 'accomplished' : null
                    }`}>
                    ✓
                  </div>
                )}
              </Step>
            </ProgressBar>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SendMoneyStatus;
