import React from 'react';
import RegisterCompleteIco from '../../../Assets/Images/thumbs-up.svg';
import './payment-completed.scss';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import WhatsAppIco from '../../../Assets/Images/whatsapp-ico.svg';
import { Link } from 'react-router-dom';
import Header from '../../../Common/Pages/header/header';

const PaymentCompleted = (props) => {
  return (
    <>
      <Header />
      <div className="container px-0">
        <div className="row pt-sm-5">
          <div className="col-12 col-sm-8 d-flex align-items-center justify-content-center mx-auto text-center reset-password-wrapper">
            <div className="mt-5 pt-5 pt-sm-1 pb-5 w-sm-100 col-11 col-sm-12">
              <span>
                <img
                  src={RegisterCompleteIco}
                  alt="password-ico"
                  className="password-ico"
                />
              </span>
              <h1 className="pt-5 pb-2 page-heading"> Congratulations! </h1>
              <p>
                We have received your SIM card application We will start our
                delivery process.
              </p>
              <div className="row text-wrapper align-items-center py-3 mx-0">
                <div className="col-sm-6 col-12">
                  <p className="mb-0 text-sm-start text-center text-para pb-3 mb-3 pb-sm-0 mb-sm-0">
                    You will get email notification as well as Whatsapp
                    notification once the delivery process begins
                  </p>
                </div>
                <div className="col-sm-6 col-12 d-flex align-items-center">
                  <img src={WhatsAppIco} alt="whatapp-ico" className="pe-3" />
                  <p className="mb-0 text-start">
                    In case you have not signed up for Whatsapp Notification
                  </p>
                </div>
              </div>
              <p className="py-4">
                {' '}
                To see order status click here -
                <Link
                  to="/order-status"
                  style={{
                    color: '#45C29A',
                    textDecoration: 'none',
                    fontWeight: '700',
                  }}>
                  See Order Status
                </Link>
              </p>
              <div className="btn-wrapper">
                <Link
                  to="/"
                  style={{
                    background: 'rgba(129, 228, 196, 1)',
                    color: '#000',
                  }}
                  className="login-btn py-2 px-4">
                  GO TO DASHBOARD
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentCompleted;
