import React, { useEffect, useState } from 'react';
import Header from '../../../Common/Pages/header/header';
import NavBar from '../../../Common/Pages/NavBar/nav-bar';
import RevolutBG from '../../../Assets/Images/revolut-bg.svg';
import CreditCardIco from '../../../Assets/Images/card-ico-2.svg';
import NoFeesIco from '../../../Assets/Images/no-fee-ico.svg';
import OpenAccountIco from '../../../Assets/Images/fast-ico.svg';
import IndiaFlag from '../../../Assets/Images/india-flag.svg';
import DepositIco from '../../../Assets/Images/deposit-ico.svg';
import Accordion from 'react-bootstrap/Accordion';
import './bank-revolut.scss';
import Ico1 from '../../../Assets/Images/ico-1.svg';
import BankIco1 from '../../../Assets/Images/bank-ico1.svg';
import BankIco2 from '../../../Assets/Images/bank-ico-2.svg';
import CreditCard1 from '../../../Assets/Images/credit-card-ico-1.svg';
import CreditCard2 from '../../../Assets/Images/credit-card-ico-2.svg';
import InvoiceIco from '../../../Assets/Images/invoice-ico-1.svg';
import MoneyIco from '../../../Assets/Images/money-ico-2.svg';
import ShippingIco from '../../../Assets/Images/shipping-ico.svg';
import TransactionIco from '../../../Assets/Images/transaction-ico.svg';
import TransferMoney from '../../../Assets/Images/transfer-money-ico.svg';
import { Link } from 'react-router-dom';
import carBanner from '../../../Assets/Images/card-revolut.svg';

const BankRevolutComponent = () => {
  return (
    <>
      <div>
        <Header />
        <div className="row">
          <div className="col-3">
            <NavBar />
          </div>
          <div className="col-12 col-sm-8 mx-auto right-content-wrapper px-4 px-sm-0 pb-5">
            <h1 className="pb-2"> Get started with opening a bank account </h1>
            <div style={{ position: 'relative' }}>
              <div className="revolutBanner">
                <h1> You are eligible for a UK bank account with</h1>
                <h2> Revolut </h2>
                <h3>
                  Open Revolut bank account and get <br /> £5.00 credit for
                  free.
                </h3>
                <a
                  href="https://revolut.ngih.net/c/3585464/583783/9626"
                  target="_blank"
                  className="btnOpenAccount"
                  rel="noreferrer">
                  OPEN NEW ACCOUNT
                </a>
              </div>
              <img src={RevolutBG} alt="bank-banner" className="w-100" />
              <img src={carBanner} alt="banner" className="revolutImg" />
            </div>
            <div className="row mx-0 mt-4 mb-5">
              <div className="col-2 revolut-tile-sec d-flex align-items-center text-center">
                <div className="inner-revolut-tile-sec">
                  <img src={IndiaFlag} alt="deposit-ico" />
                  <p> Receive money from India </p>
                </div>
              </div>
              <div className="col-2 revolut-tile-sec d-flex align-items-center text-center">
                <div className="inner-revolut-tile-sec">
                  <img src={OpenAccountIco} alt="deposit-ico" />
                  <p> Open account in 2 mins </p>
                </div>
              </div>
              <div className="col-2 revolut-tile-sec d-flex align-items-center text-center">
                <div className="inner-revolut-tile-sec">
                  <img src={NoFeesIco} alt="deposit-ico" />
                  <p> No monthly fees, no minimum balance </p>
                </div>
              </div>
              <div className="col-2 revolut-tile-sec d-flex align-items-center text-center">
                <div className="inner-revolut-tile-sec">
                  <img src={CreditCardIco} alt="deposit-ico" />
                  <p> Get a free virtual debit card </p>
                </div>
              </div>
              <div className="col-2 revolut-tile-sec d-flex align-items-center text-center">
                <div className="inner-revolut-tile-sec">
                  <img src={DepositIco} alt="deposit-ico" />
                  <p> Deposit insured upto £85,000.00 </p>
                </div>
              </div>
            </div>
            <div>
              <Accordion
                className="w-100 sim-plans-accordion pb-4"
                style={{ borderBottom: '1px solid #DEDEDE' }}>
                <Accordion.Item eventKey="1" style={{ border: '0px' }}>
                  <div className="d-flex align-items-center mt-4">
                    <span>
                      <img src={Ico1} alt="deposit-ico" />
                    </span>
                    <h3
                      className="w-100 ps-4"
                      style={{ fontSize: '20px', fontWeight: '700' }}>
                      Is my money safe?
                    </h3>
                    <Accordion.Header>
                      <p className="mb-0 d-sm-none pe-2">
                        {/* See all {data.provider_name} Plans */}
                      </p>
                    </Accordion.Header>
                  </div>
                  <Accordion.Body className="row pb-0 pb-sm-2 px-0 px-sm-3">
                    Yes, We at Remigos keep your money safe. With the help of
                    our disposable virtual cards, you can safely make one-time
                    purchases. Additionally, as an electronic money institution
                    (EMI), your funds with Revolut are safeguarded rather than
                    covered by the financial services compensation scheme.
                    (FSCS). Your money is protected by regulated banks by
                    legislation and under FCA regulation. In order to protect
                    you, not only do we keep an eye on your account, and flag
                    suspicious activities, but we also safeguard your card
                    information and request additional verification. Not only
                    this, but your credit card information is also always secure
                    with us, whether we are processing it or keeping it. We
                    stand guard for your money at all times.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div>
              <Accordion
                className="w-100 sim-plans-accordion pb-4"
                style={{ borderBottom: '1px solid #DEDEDE' }}>
                <Accordion.Item eventKey="1" style={{ border: '0px' }}>
                  <div className="d-flex align-items-center mt-4">
                    <span>
                      <img src={BankIco1} alt="deposit-ico" />
                    </span>
                    <h3
                      className="w-100 ps-4"
                      style={{ fontSize: '20px', fontWeight: '700' }}>
                      Where is the bank located?
                    </h3>
                    <Accordion.Header>
                      <p className="mb-0 d-sm-none pe-2">
                        {/* See all {data.provider_name} Plans */}
                      </p>
                    </Accordion.Header>
                  </div>
                  <Accordion.Body className="row pb-0 pb-sm-2 px-0 px-sm-3">
                    Revolut, a British-Lithuanian neobank and financial
                    technology company offers banking services. It is a virtual
                    bank that specializes in mobile banking, card payments,
                    money remittance, and foreign exchange. It is a full-fledged
                    online bank and thus there are no branches. Despite not
                    having a physical location, banking had never been so easy.
                    You can use all of the banking services offered by simply
                    downloading the app. Revolut is one stop for all things
                    money. The digital banking allows you to withdraw your money
                    from any ATM with the help of an easy-to-use ATM card. In
                    India, Revolut’s banking services are somewhat similar to
                    those offered by Paytm bank or PhonePe bank services.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div>
              <Accordion
                className="w-100 sim-plans-accordion pb-4"
                style={{ borderBottom: '1px solid #DEDEDE' }}>
                <Accordion.Item eventKey="1" style={{ border: '0px' }}>
                  <div className="d-flex align-items-center mt-4">
                    <span>
                      <img src={TransactionIco} alt="deposit-ico" />
                    </span>
                    <h3
                      className="w-100 ps-4"
                      style={{ fontSize: '20px', fontWeight: '700' }}>
                      What if I want to make a deposit / withdraw cash?
                    </h3>
                    <Accordion.Header>
                      <p className="mb-0 d-sm-none pe-2">
                        {/* See all {data.provider_name} Plans */}
                      </p>
                    </Accordion.Header>
                  </div>
                  <Accordion.Body className="row pb-0 pb-sm-2 px-0 px-sm-3">
                    As of yet, there is no provision to accept cash deposits or
                    cheques to your Revolut account with Remigos. However, there
                    are several other methods by which you can add money to your
                    Remigos’ bank account. We give you the opportunity to
                    receive money in multiple currencies via a bank transfer.
                    Additionally, you can also use your own card to add money.
                    If someone else would like to add money to your account,
                    they can send money to your account instantly via their
                    Revolut app or bank transfer. Some other methods to add
                    money to your account include Apple Pay or Google Pay, with
                    money from other Revolut users, with linked accounts & with
                    direct deposit, and Early Salary. Similar to adding money,
                    withdrawing any sum from your Remigos’ bank account with
                    Revolut is also very convenient. Customers who have a
                    Standard plan account are entitled to five fee-free
                    withdrawals of up to 200 GBP per month from any ATM. After
                    that, a 2% commission is charged with a minimum payment of 1
                    GBP. With the Standard account, you can spend, transfer, or
                    exchange up to 1,000 GBP every month. After that, there is a
                    0.5% charge.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div>
              <Accordion
                className="w-100 sim-plans-accordion pb-4"
                style={{ borderBottom: '1px solid #DEDEDE' }}>
                <Accordion.Item eventKey="1" style={{ border: '0px' }}>
                  <div className="d-flex align-items-center mt-4">
                    <span>
                      <img src={MoneyIco} alt="deposit-ico" />
                    </span>
                    <h3
                      className="w-100 ps-4"
                      style={{ fontSize: '20px', fontWeight: '700' }}>
                      What is the minimum balance requirement?
                    </h3>
                    <Accordion.Header>
                      <p className="mb-0 d-sm-none pe-2">
                        {/* See all {data.provider_name} Plans */}
                      </p>
                    </Accordion.Header>
                  </div>
                  <Accordion.Body className="row pb-0 pb-sm-2 px-0 px-sm-3">
                    While the company offers other types of cards and accounts,
                    Remigos provides you with a free bank account which is the
                    Revolut Standard Card. There is no minimum balance
                    requirement restriction with this plan. You can have zero
                    balance in your account without incurring any charges. There
                    are also not any operational charges with the Revolut
                    standard plan account that Remigos opens for you. It can be
                    noted that Revolut offers some premium plans which may come
                    with some minimum balance requisite. However, as long as you
                    continue to utilize the services of the standard plan, you
                    shall not be required to have any minimum balance.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div>
              <Accordion
                className="w-100 sim-plans-accordion pb-4"
                style={{ borderBottom: '1px solid #DEDEDE' }}>
                <Accordion.Item eventKey="1" style={{ border: '0px' }}>
                  <div className="d-flex align-items-center mt-4">
                    <span>
                      <img src={InvoiceIco} alt="deposit-ico" />
                    </span>
                    <h3
                      className="w-100 ps-4"
                      style={{ fontSize: '20px', fontWeight: '700' }}>
                      Is there a monthly fees that I will have to pay?
                    </h3>
                    <Accordion.Header>
                      <p className="mb-0 d-sm-none pe-2">
                        {/* See all {data.provider_name} Plans */}
                      </p>
                    </Accordion.Header>
                  </div>
                  <Accordion.Body className="row pb-0 pb-sm-2 px-0 px-sm-3">
                    At Remigos’ we know the value of your money and that is why
                    we offer you the Revolut Standard card which has no cost
                    associated with it. This plan enables you to manage your
                    expenditures and international transfers without any //
                    eslint-disable-next-line react/no-unescaped-entities
                    additional fees. The Standard plan&apos;s primary
                    characteristics are listed below:
                    <ul className="ps-5 pt-3">
                      <li>Free currency conversion up to £1,000 per month.</li>
                      <li>
                        ATM withdrawals up to £1,200 per month (or an equal
                        amount in a foreign currency) are free of charge.
                      </li>
                      <li>1 free overseas transfer each month.</li>
                    </ul>
                    Revolut additionally offers upgraded plans such as Revolut
                    Premium at additional charges and you can always upgrade.
                    However, with your Remigos’ Standard plan, you won&apos;t be
                    levied any additional costs as long as you stay within the
                    monthly restrictions.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div>
              <Accordion
                className="w-100 sim-plans-accordion pb-4"
                style={{ borderBottom: '1px solid #DEDEDE' }}>
                <Accordion.Item eventKey="1" style={{ border: '0px' }}>
                  <div className="d-flex align-items-center mt-4">
                    <span>
                      <img src={BankIco2} alt="deposit-ico" />
                    </span>
                    <h3
                      className="w-100 ps-4"
                      style={{ fontSize: '20px', fontWeight: '700' }}>
                      What if I dont like the bank account?
                    </h3>
                    <Accordion.Header>
                      <p className="mb-0 d-sm-none pe-2">
                        {/* See all {data.provider_name} Plans */}
                      </p>
                    </Accordion.Header>
                  </div>
                  <Accordion.Body className="row pb-0 pb-sm-2 px-0 px-sm-3">
                    If you don&apos;t like your bank account, you can simply
                    stop using it. You don&apos;t have to delete your account
                    just because you no longer use it. If you decide to change
                    your mind, it might be difficult to create a new account
                    with the same phone number in the future. Since Remigos
                    Standard plan is free and Revolut doesn&apos;t charge for
                    inactive accounts, you could just keep your account open.
                    However, if you still wish to close your account, can follow
                    some simple steps to close your account on the latest
                    version of the app or website. Before you can close your
                    account, you might need to speak with a Revolut live agent
                    or customer support representative. This is because you must
                    complete certain tasks, like withdrawing all currency
                    amounts from your account. No money will be allowed to
                    remain in the account after all transactions have been made.
                    All debit cards connected to the account will be canceled
                    once it is closed, making it impossible for you to access
                    any remaining money.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div>
              <Accordion
                className="w-100 sim-plans-accordion pb-4"
                style={{ borderBottom: '1px solid #DEDEDE' }}>
                <Accordion.Item eventKey="1" style={{ border: '0px' }}>
                  <div className="d-flex align-items-center mt-4">
                    <span>
                      <img src={CreditCard1} alt="deposit-ico" />
                    </span>
                    <h3
                      className="w-100 ps-4"
                      style={{ fontSize: '20px', fontWeight: '700' }}>
                      How will I get my debit card?
                    </h3>
                    <Accordion.Header>
                      <p className="mb-0 d-sm-none pe-2">
                        {/* See all {data.provider_name} Plans */}
                      </p>
                    </Accordion.Header>
                  </div>
                  <Accordion.Body className="row pb-0 pb-sm-2 px-0 px-sm-3">
                    With Remigos student bank account you obtain a virtual card
                    that functions much like a normal bank card. You won&apos;t
                    get a physical copy, and the app is the only secure way to
                    view the card information. The virtual card is instantly
                    usable after it has been generated. The card information can
                    be used to make purchases over the phone or online. For
                    contactless phone payments, you can even connect the virtual
                    card to Apple Pay or Google Pay. However, you can also order
                    a physical debit card by following some simple steps on the
                    Revolut app’s ‘Cards’ tab. A Standard plan card will be free
                    of charge.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div>
              <Accordion
                className="w-100 sim-plans-accordion pb-4"
                style={{ borderBottom: '1px solid #DEDEDE' }}>
                <Accordion.Item eventKey="1" style={{ border: '0px' }}>
                  <div className="d-flex align-items-center mt-4">
                    <span>
                      <img src={ShippingIco} alt="deposit-ico" />
                    </span>
                    <h3
                      className="w-100 ps-4"
                      style={{ fontSize: '20px', fontWeight: '700' }}>
                      Will it be delivered to my house?
                    </h3>
                    <Accordion.Header>
                      <p className="mb-0 d-sm-none pe-2">
                        {/* See all {data.provider_name} Plans */}
                      </p>
                    </Accordion.Header>
                  </div>
                  <Accordion.Body className="row pb-0 pb-sm-2 px-0 px-sm-3">
                    If you wish to receive a physical debit card with your
                    Remigos’ Revolut bank account, you will have to order the
                    same by using the latest version of the app. Select your
                    card design and, if available, customize the card. After
                    providing your address, place your order. Before you
                    complete your order and checkout, if there is the cost of
                    the card and/or transportation will also be shown. Make sure
                    your delivery location is accurate and that you have given
                    all the necessary information, including your apartment and
                    building numbers. Your card may arrive with standard
                    shipping in up to nine working days. Select express shipping
                    if you require it immediately to receive the card in three
                    business days.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div>
              <Accordion
                className="w-100 sim-plans-accordion pb-4"
                style={{ borderBottom: '1px solid #DEDEDE' }}>
                <Accordion.Item eventKey="1" style={{ border: '0px' }}>
                  <div className="d-flex align-items-center mt-4">
                    <span>
                      <img src={TransferMoney} alt="deposit-ico" />
                    </span>
                    <h3
                      className="w-100 ps-4"
                      style={{ fontSize: '20px', fontWeight: '700' }}>
                      How will I transfer money to that account from my Indian
                      account?
                    </h3>
                    <Accordion.Header>
                      <p className="mb-0 d-sm-none pe-2">
                        {/* See all {data.provider_name} Plans */}
                      </p>
                    </Accordion.Header>
                  </div>
                  <Accordion.Body className="row pb-0 pb-sm-2 px-0 px-sm-3">
                    With Remigos you can expect ease of use every step of the
                    way when you want to transfer money from India to your UK
                    bank account. The whole process is so convenient and simple
                    that it can be done in just a few clicks. All you need to do
                    is to Sign up online, or on our app with an email address.
                    Once you&apos;ve done that, you need to complete a KYC
                    through your PAN card, Passport, Visa, and College offer
                    letter to verify your identity. Then just fill in the
                    details of your recipient’s bank account and pay using a
                    bank transfer and you are sorted. With Remigos money
                    transfer, you can not only easily track your transfer on the
                    platform, but you also receive real-time updates on your
                    Whatsapp & email. Alternatively, you also have the option to
                    add money via the Revolut app. You will need to use the app
                    or website to get the information & make the transfer. You
                    will need to initiate a ‘Regular Bank Transfer’, and choose
                    International (cross-border) detail for transferring funds
                    from India. With these details, you can now start a transfer
                    on the website or mobile app of your bank. However, there
                    may be some additional charges which you can find out on the
                    Revolut website.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div>
              <Accordion
                className="w-100 sim-plans-accordion pb-4"
                style={{ borderBottom: '1px solid #DEDEDE' }}>
                <Accordion.Item eventKey="1" style={{ border: '0px' }}>
                  <div className="d-flex align-items-center mt-4">
                    <span>
                      <img src={CreditCard1} alt="deposit-ico" />
                    </span>
                    <h3
                      className="w-100 ps-4"
                      style={{ fontSize: '20px', fontWeight: '700' }}>
                      How is it better than getting a forex card or Indian debit
                      card?
                    </h3>
                    <Accordion.Header>
                      <p className="mb-0 d-sm-none pe-2">
                        {/* See all {data.provider_name} Plans */}
                      </p>
                    </Accordion.Header>
                  </div>
                  <Accordion.Body className="row pb-0 pb-sm-2 px-0 px-sm-3">
                    Typically, on an Indian debit card, there will be visa
                    charges and forex cards incur the forex markup charges. With
                    an Indian debit card or a forex card, you end up paying
                    transaction charges that you would not have to incur when
                    using Remigos. Depending on the type of your forex card, you
                    might be charged extra fees when using an ATM overseas,
                    activating the card, keeping the card inactive, retrieving
                    money fees, etc. However, Remigos’ is a better and more
                    economical option to choose as you save money by avoiding
                    all these additional transaction charges. The additional
                    benefit of using Remigos over forex cards and Indian debit
                    cards is that with Remigos’ you can easily send money to
                    your friends and family or withdraw for your own use. On the
                    other hand, not only does reloaded money take a long time to
                    be credited to the forex cards, each transaction done on
                    these cards incurs additional fees. Indian debit cards may
                    be good for use for a short period of time, however, these
                    also do not fair well when compared to the advantages of
                    having a Remigos’ UK bank account.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankRevolutComponent;
