import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser, setTemp, setIsUserLogin } from '../../global/features/user';
import './index.scss';
import Image from '../../Assets/Images/girl-1.svg';
import CloseIco from '../../Assets/Images/close-ico.svg';
import logo from '../../Assets/Images/logo.svg';
import { BallTriangle } from 'react-loader-spinner';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedin = useSelector((state) => state?.user?.islogged);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isEmail(email) || password.length < 6) {
      setErrors({ password: "Sorry! That email or password didn't work" });
      return;
    }
    try {
      setLoader(true);
      const user = await Auth.signIn(email, password);
      // console.log(user);

      dispatch(
        setUser({
          id: user.attributes.sub,
          attributes: user.attributes,
          tokens: {
            access: user.signInUserSession.accessToken.jwtToken,
            refresh: user.signInUserSession.refreshToken.token,
            id: user.signInUserSession.idToken.jwtToken,
          },
        }),
      );
      navigate('/dashboard');
    } catch (err) {
      // console.log(err);
      setLoader(false);
      if (err.code === 'UserNotConfirmedException') {
        dispatch(setTemp({ key: 'email', value: email }));
        dispatch(setTemp({ key: 'password', value: password }));
        await Auth.resendSignUp(email);
        navigate('/verify-otp');
        return;
      }
      if (err.code === 'UserNotFoundException') {
        setErrors({ email: 'No such user found' });
        return;
      }
      setErrors({ password: "Sorry! That email or password didn't work" });
    }
  };
  if (isLoggedin) {
    navigate('/dashboard', { replace: true });
  }
  return (
    <>
      <div className="container-fluid h-100">
        <div className="row h-100 text-center">
          <div className="col-4 d-none d-sm-block login-left-wrapper pt-5">
            <div className="h-25 pt-5">
              <img src={logo} alt="logo" className="logo-img" />
            </div>
            <div className="h-75 d-flex align-items-end">
              <img src={Image} alt="girl-img" className="w-100" />
            </div>
          </div>
          <div className="col-11 col-sm-4 mx-auto d-flex align-items-start align-items-sm-center justify-content-center login-right-wrapper">
            <div className="w-sm-50">
              <Link to="/" className="d-block d-sm-none text-center">
                <img src={logo} alt="close-ico" className="logo-img" />
              </Link>
              <h2 className="text-center text-sm-start pb-3">
                {' '}
                Welcome Amigo!{' '}
              </h2>
              <form method="POST" onSubmit={handleSubmit}>
                <div className="form-floating mb-4">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="name@example.com"
                  />
                  <label htmlFor="floatingInput">Email address</label>
                  {errors.email && (
                    <p className="text-danger">{errors.email}</p>
                  )}
                </div>
                <div className="form-floating">
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="floatingPassword"
                    placeholder="Password"
                  />
                  <label htmlFor="floatingPassword">Password</label>
                  {errors.password && (
                    <p className="text-danger">{errors.password}</p>
                  )}
                </div>
                <div className="btn-wrapper text-center">
                  <button
                    onClick={handleSubmit}
                    className="mt-4 p-4 d-flex align-items-center justify-content-center login-btn link-btn">
                    LOGIN
                  </button>
                </div>
              </form>
              <div className="row mt-4">
                <div className="col-6 text-start remember-me-text">
                  <label className="label">
                    Remember Me
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="col-6 text-end forgot-pass-link">
                  <Link to="/reset-password">Forgot Password?</Link>
                </div>
                <div className="col-12 mt-5 new-member-signup-text">
                  <h4>
                    {' '}
                    New to Remigos?
                    <Link to="/signup"> Sign Up</Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-wrapper d-flex align-items-center justify-content-center">
            <BallTriangle
              height="100"
              width="100"
              color="#45C29A"
              ariaLabel="loading"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Login;
