import React, { useEffect, useState } from 'react';
import NavBar from '../../Common/Pages/NavBar/nav-bar';
import Header from '../../Common/Pages/header/header';
import ProfileIco from '../../Assets/Images/user.png';
import isEmail from 'validator/lib/isEmail';
import { useFormik } from 'formik';
import './profile-detail-form.scss';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import UploadIco from '.././../Assets/Images/upload.svg';
import EditIco from '.././../Assets/Images/edit.svg';
import { Auth } from 'aws-amplify';
import { BallTriangle } from 'react-loader-spinner';
import { setUser } from '../../global/features/user';
import CheckIco from '.././../Assets/Images/check.png';
import RemoveIco from '.././../Assets/Images/remove.png';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
const validate = (values) => {
  const errors = {};

  if (values.firstName.trim().length < 2) {
    errors.firstName = 'Name is required';
  }

  if (values.lastName.trim().length < 2) {
    errors.lastName = 'Name is required';
  }

  if (!isEmail(values.email)) {
    errors.email = 'Please enter a valid email';
  }

  if (values.phone.trim().length < 10) {
    errors.phone = 'Please enter a valid phone number';
  }

  if (values.address.trim().length < 4) {
    errors.address = 'Please enter a valid address';
  }

  if (values.zip.trim().length < 5) {
    errors.zip = 'Please enter a valid zip code';
  }

  if (values.country === '') {
    errors.country = 'Please select a country';
  }

  if (values.state === '') {
    errors.state = 'Please select a state';
  }

  if (values.city === '') {
    errors.city = 'Please select a city';
  }

  return errors;
};
const changePasswordVal = (values) => {
  const errors = {};

  if (values.oldPassword === '') {
    errors.oldPassword = 'Please enter old password';
  }
  if (values.newPassword.length < 6) {
    errors.newPassword = 'Password must be at least 6 characters';
  }
  if (
    values.newPassword !== values.confirmPassword ||
    !values.confirmPassword
  ) {
    errors.confirmPassword = "Passwords don't match";
  }

  return errors;
};

function ProfileDetailForm() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const userName = user.user.attributes;
  const [headerSecond, setHeaderSecond] = useState(true);
  const [loader, setLoader] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [oldPassword, setOldPassword] = useState();
  const token = user.user?.tokens?.access;
  const [show, setShow] = useState(false);
  let profileImage = user.user.attributes['custom:image'];
  const [documents, setDocuments] = useState([]);
  const [changePassShow, setChangePassShow] = useState(false);
  const [oldPassIncorrect, setOldPassIncorrect] = useState(false);
  const [limitExceededException, setlimitExceededException] = useState(false);

  const updateRedux = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

      await axios.put(
        `${process.env.REACT_APP_API_URL}/user/profile`,
        {
          email: user.attributes.email,
          firstName: user.attributes['custom:firstName'],
          lastName: user.attributes['custom:lastName'],
          phone: user.attributes['custom:phone'],
          countryCode: user.attributes['custom:countryCode'],
          dob: user.attributes['custom:dob'],
          country: user.attributes['custom:country'],
          state: user.attributes['custom:state'],
          address: user.attributes['custom:address'],
          city: user.attributes['custom:city'],
          zip: user.attributes['custom:zip'],
          passport: user.attributes['custom:passport'],
          university: user.attributes['custom:university'],
          currentLocation: user.attributes['custom:currentLocation'],
        },
        {
          headers: {
            authorization: user.signInUserSession.accessToken.jwtToken,
          },
        },
      );

      dispatch(
        setUser({
          id: user.attributes.sub,
          attributes: user.attributes,
          tokens: {
            access: user.signInUserSession.accessToken.jwtToken,
            refresh: user.signInUserSession.refreshToken.token,
            id: user.signInUserSession.idToken.jwtToken,
          },
        }),
      );
    } catch (e) {
      // console.log(e);
    }
  };

  const handleSuccessClose = () => {
    setSuccessShow(!successShow);
    setChangePassShow(false);
  };
  const handleCloseChangePass = () => {
    setChangePassShow(false);
  };

  const formPassword = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validate: changePasswordVal,
    onSubmit: (values, { setErrors }) => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          // console.log('user: ', user);
          return Auth.changePassword(
            user,
            values.oldPassword,
            values.newPassword,
          );
        })
        .then((data) => {
          // console.log('data', data);
          setChangePassShow(false);
          setSuccessShow(!successShow);
        })
        .catch((err) => {
          // console.log('error', err);
          if (err.code === 'NotAuthorizedException') {
            setlimitExceededException(false);
            setOldPassIncorrect(true);
          } else if (err.code == 'LimitExceededException') {
            setOldPassword(false);
            setlimitExceededException(true);
          }
        });
    },
  });

  const form = useFormik({
    initialValues: {
      firstName: userName['custom:firstName'],
      lastName: userName['custom:lastName'],
      email: userName['email'],
      phone: `${userName['custom:countryCode']}${userName['custom:phone']}`,
      address: userName['custom:address'],
      city: userName['custom:city'],
      state: userName['custom:state'],
      zip: userName['custom:zip'],
      country: userName['custom:country'],
      university: userName['custom:university'],
      currentLocation: userName['custom:currentLocation'],
      passport: userName['custom:passport'],
    },
    validate,
    onSubmit: async (values) => {
      try {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        const userAttr = user.attributes;
        const countryValues = parsePhoneNumber(form?.values.phone);
        const countryCode = countryValues?.countryCallingCode;
        const phone = countryValues.nationalNumber;
        // console.log(countryValues);
        const userAttrUpdate = {
          ...userAttr,
          'custom:firstName': values.firstName,
          'custom:lastName': values.lastName,
          'custom:phone': phone,
          'custom:address': values.address,
          'custom:city': values.city,
          'custom:state': values.state,
          'custom:countryCode': `+${countryCode}`,
          'custom:zip': values.zip,
          'custom:country': values.country,
          'custom:passport': values.passport,
          'custom:university': values.university,
          'custom:currentLocation': values.currentLocation,
        };
        await Auth.updateUserAttributes(user, userAttrUpdate);
        setSuccessShow(true);
        await updateRedux();
      } catch (e) {
        // console.log(e);
      }
    },
  });
  const fetchZip = (e) => {
    const { value } = e.target;

    axios
      .get(`https://api.postalpincode.in/pincode/${value}`)
      .then((res) => {
        if (res.data.Status === 'Error') {
          setLoader(false);
          return;
        }
        form.setFieldValue('city', res.data[0].PostOffice[0].Block);
        form.setFieldValue('state', res.data[0].PostOffice[0].State);
        form.setFieldValue('country', res.data[0].PostOffice[0].Country);
      })
      .catch((err) => {
        // console.log('err.response.data.error: ', err);
      });
  };

  useEffect(() => {
    setLoader(true);
    axios
      .get(`https://api.postalpincode.in/pincode/${form.values.zip}`)
      .then((res) => {
        if (res.data.Status === 'Error') {
          return;
        }
        form.setFieldValue('city', res.data[0].PostOffice[0].Block);
        form.setFieldValue('state', res.data[0].PostOffice[0].State);
        form.setFieldValue('country', res.data[0].PostOffice[0].Country);
        setLoader(false);
      })
      .catch((err) => {
        // console.log('err.response.data.error: ', err);
      });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/user/profile-image/${profileImage}`,
      )
      .then((res) => {
        // console.log('res-get w456: ', res);
      })
      .catch((err) => {
        // console.log('err.response.data.error: ', err);
      });
  }, []);
  // useEffect(() => {
  //   const countryValues = parsePhoneNumber(form?.values.phone);
  //   const countryCode = countryValues?.countryCallingCode;
  //   console.log('countryCode: ', countryCode);
  //   form.setFieldValue('countryCode', `+${countryCode}`);
  // }, [form.submitForm]);
  const handleUploadProof = (e) => {
    const input = e.target.files[0];
    setDocuments(input);
  };
  const editProfileImage = () => {
    setShow(true);
  };
  const submitBtn = () => {
    let data = new FormData();
    data.append('image', documents);
    let axiosToken = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/profile-image`,
        data,
        axiosToken,
      )
      .then((res) => {
        // console.log('res post profile: ', res);
        updateRedux();
        setShow(false);
      })
      .catch((err) => {
        // console.log('err.response.data.error: ', err);
      });
  };
  const changePasswordOpen = () => {
    // formPassword.setFieldValue('oldPassword', '');
    formPassword.values.oldPassword;
    setChangePassShow(true);
  };
  const handlePhoneNumber = (e) => {
    form.setFieldValue('phone', e || '');
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-3 px-0 sidebar-container">
            <NavBar />
          </div>
          <div className="col-12 col-sm-9 right-wrapper px-0">
            <div className="col-12">
              <Header headerSecond={headerSecond} />
            </div>
            <div className="col-11 p-sm-5 p-3 mx-auto wrapper-profile-form">
              <div className="row form-wrapper p-sm-5 p-3">
                <div className="col-12 col-sm-2">
                  <img
                    src={
                      profileImage
                        ? `${process.env.REACT_APP_API_URL}/user/profile-image/${profileImage}`
                        : ProfileIco
                    }
                    alt="profile-ico"
                    style={{
                      width: '100px',
                      height: '100px',
                      borderRadius: '50%',
                    }}
                  />
                  <button className="editBtn" onClick={editProfileImage}>
                    <img
                      src={EditIco}
                      alt="editIco"
                      style={{ width: '30px' }}
                    />
                  </button>
                  <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    className="profile-upload-modal">
                    <Modal.Header closeButton>
                      <h5 className="modal-title" id="staticBackdropLabel">
                        Upload
                      </h5>
                    </Modal.Header>
                    <Modal.Body className="text-center py-4">
                      <div className="col-12">
                        <div id="file-upload" className="ms-1">
                          <img
                            src={UploadIco}
                            alt="upload-ico"
                            className="upload-ico pt-3"
                          />
                          {/* <h6> Drag and Drop </h6>
                          <p> or hit the “Browse” button </p> */}
                          <div className="btn-wrapper">
                            <div className="file file--upload pb-2">
                              <label htmlFor="input-file">BROWSE</label>
                              <input
                                id="input-file"
                                type="file"
                                accept=".jpg, .jpeg, .png, .doc, .docx,.pdf"
                                onChange={handleUploadProof}
                              />
                            </div>
                            <span className="text-success">
                              {' '}
                              {documents ? documents.name : ''}
                            </span>
                            {/* <span
                              className={
                                showErroText ? 'd-block text-danger' : 'd-none'
                              }>
                              {' '}
                              Please select document{' '}
                            </span> */}
                            <p className="mt-3">
                              {' '}
                              .pdf, .jpg, .png, .docx files can be uploaded{' '}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <span
                        className={
                          showErroText ? 'd-block text-danger' : 'd-none'
                        }>
                        {' '}
                        Please select document{' '}
                      </span> */}
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center btn-wrapper">
                      <Button onClick={submitBtn} className="login-btn">
                        SUBMIT
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className="col-12 col-sm-10 pt-4">
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <div className="form-floating mb-4">
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          name="firstName"
                          placeholder="First Name"
                          value={form.values.firstName}
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                        />
                        <label htmlFor="firstName">
                          First Name <span>(as per passport)</span>
                        </label>
                        {form.errors.firstName && form.touched.firstName && (
                          <p className="error-message">
                            {form.errors.firstName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-floating mb-4">
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          name="lastName"
                          placeholder="Last Name"
                          value={form.values.lastName}
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                        />
                        <label htmlFor="firstName">
                          Last Name <span>(as per passport)</span>
                        </label>
                        {form.errors.lastName && form.touched.lastName && (
                          <p className="error-message">
                            {form.errors.lastName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-floating mb-4 disabled-field">
                        <input
                          type="password"
                          disabled={true}
                          className="form-control"
                          id="password"
                          name="password"
                          placeholder="Password"
                          value="passwordtest"
                        />
                        <label htmlFor="password">Password</label>
                        {form.errors.lastName && form.touched.lastName && (
                          <p className="error-message">
                            {form.errors.lastName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 d-flex pt-sm-3 pb-3 pb-sm-0 change-password-sec">
                      <button onClick={changePasswordOpen}>
                        {' '}
                        Change Password{' '}
                      </button>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-floating mb-4 disabled-field">
                        <input
                          className="form-control"
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Email Address"
                          disabled
                          value={form.values.email}
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                        />
                        <label htmlFor="email">Enter Email Address</label>
                        {form.errors.email && form.touched.email && (
                          <p className="error-message">{form.errors.email}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-floating mb-4">
                        <PhoneInput
                          international
                          id="phone"
                          name="phone"
                          defaultCountry="IN"
                          value={form.values.phone}
                          countryCallingCodeEditable={false}
                          onChange={handlePhoneNumber}
                          onBlur={form.handleBlur}
                        />
                        {form.errors.phone && form.touched.phone && (
                          <p className="error-message">{form.errors.phone}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          id="address"
                          name="address"
                          type="text"
                          placeholder="Enter Address"
                          value={form.values.address}
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                        />
                        <label htmlFor="email">Enter Address</label>
                        {form.errors.address && form.touched.address && (
                          <p className="error-message">{form.errors.address}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 mt-4">
                      <div className="form-floating mb-sm-4">
                        <input
                          type="text"
                          className="form-control"
                          id="zip"
                          name="zip"
                          placeholder="Zip Code"
                          value={form.values.zip}
                          onChange={form.handleChange}
                          onBlur={fetchZip}
                        />
                        <label htmlFor="zip">Pin Code</label>
                        {form.errors.zip && form.touched.zip && (
                          <p className="error-message">{form.errors.zip}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 mt-4">
                      <div className="form-floating mb-4">
                        <input
                          className="form-control"
                          id="country"
                          name="country"
                          type="text"
                          disabled
                          placeholder="Phone Number"
                          value={form.values.country}
                        />
                        <label htmlFor="phone">Country</label>
                        {form.errors.country && form.touched.country && (
                          <p className="error-message">{form.errors.country}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-floating mb-4 mb-sm-0">
                        <input
                          className="form-control"
                          id="state"
                          disabled
                          name="state"
                          type="text"
                          placeholder="Phone Number"
                          value={form.values.state}
                        />
                        <label htmlFor="phone">State</label>
                        {form.errors.state && form.touched.state && (
                          <p className="error-message">{form.errors.state}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-floating mb-4 mb-sm-0">
                        <input
                          className="form-control"
                          id="city"
                          name="city"
                          disabled
                          type="text"
                          placeholder="City"
                          value={form.values.city}
                        />
                        <label htmlFor="phone">City</label>
                        {form.errors.city && form.touched.city && (
                          <p className="error-message">{form.errors.city}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <div className="form-floating mb-4 disabled-field">
                        <input
                          type="text"
                          className="form-control"
                          id="passport"
                          name="passport"
                          disabled
                          placeholder="PassPort"
                          value={userName['custom:passport']}
                        />
                        <label htmlFor="zip">Passport</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating mb-4 disabled-field">
                        <input
                          type="text"
                          className="form-control"
                          id="passport"
                          disabled
                          name="passport"
                          placeholder="PassPort"
                          value={userName['custom:university']}
                        />
                        <label htmlFor="zip">University</label>
                      </div>
                    </div>
                    <div className="col-12 text-start mb-3 ps-3">
                      <h6 className="currentLocationHead">
                        {' '}
                        Current Location{' '}
                      </h6>
                      <div className="row mt-4 mx-0">
                        <div className="col-12 col-sm-3 mb-3 mb-sm-0 form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="currentLocation"
                            id="India"
                            value="India"
                            onChange={form.handleChange}
                            checked={form.values.currentLocation === 'India'}
                          />
                          <label className="form-check-label" htmlFor="India">
                            India
                          </label>
                        </div>
                        <div className="col-12 col-sm-3 form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="currentLocation"
                            onChange={form.handleChange}
                            id="outsideIndia"
                            value="Outside India"
                            checked={
                              form.values.currentLocation === 'Outside India'
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2">
                            Outside India
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 text-center btn-wrapper mt-sm-5 mt-2">
                <button className="login-btn" onClick={() => form.submitForm()}>
                  {' '}
                  Save{' '}
                </button>
              </div>
            </div>
          </div>
          <Modal
            show={successShow}
            onHide={handleSuccessClose}
            className="delete-modal-wrappr">
            <Modal.Body className="text-center">
              <img src={CheckIco} alt="RemoveIco" className="w-25 my-3" />
              <h3>Account Updated Successfully</h3>
              {/* <p> Are you sure you want to delete this document? </p> */}
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button
                variant="secondary"
                onClick={handleSuccessClose}
                className="success-btn">
                OK
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Change Password */}
          <Modal
            show={changePassShow}
            onHide={handleCloseChangePass}
            className="delete-modal-wrappr">
            <Modal.Header closeButton>
              <h5 className="modal-title" id="staticBackdropLabel">
                Change Password
              </h5>
            </Modal.Header>
            <Modal.Body className="text-center mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="form-floating mb-4">
                    <input
                      type="password"
                      className={
                        oldPassIncorrect
                          ? 'form-control border-danger'
                          : 'form-control'
                      }
                      id="enter-otp"
                      name="oldPassword"
                      placeholder="Old Password"
                      value={formPassword.values.oldPassword}
                      onChange={formPassword.handleChange}
                      onBlur={formPassword.handleBlur}
                    />
                    <label htmlFor="firstName">Old Password</label>
                    {oldPassIncorrect ? (
                      <p className="text-start text-danger">
                        {' '}
                        Old password is wrong{' '}
                      </p>
                    ) : (
                      ''
                    )}
                    {formPassword.errors.oldPassword &&
                    formPassword.touched.oldPassword ? (
                      <p className="error-message">
                        {formPassword.errors.oldPassword}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating mb-4">
                    <input
                      type="password"
                      className="form-control"
                      id="new-password"
                      name="newPassword"
                      placeholder="New Password"
                      value={formPassword.values.newPassword}
                      onBlur={formPassword.handleBlur}
                      onChange={formPassword.handleChange}
                    />
                    <label htmlFor="firstName">New Password</label>
                    {formPassword.errors.newPassword &&
                    formPassword.touched.newPassword ? (
                      <p className="error-message">
                        {formPassword.errors.newPassword}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating mb-4">
                    <input
                      type="password"
                      className="form-control"
                      id="confirm-password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={formPassword.values.confirmPassword}
                      onBlur={formPassword.handleBlur}
                      onChange={formPassword.handleChange}
                    />
                    <label htmlFor="firstName">Confirm Password</label>
                    {formPassword.errors.confirmPassword &&
                    formPassword.touched.confirmPassword ? (
                      <p className="error-message">
                        {formPassword.errors.confirmPassword}
                      </p>
                    ) : null}
                  </div>
                </div>
                {limitExceededException ? (
                  <p className="text-danger">
                    {' '}
                    Attempt to many times. <br /> Please try after some time.{' '}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <div className="btn-wrapper">
                <Button
                  variant="secondary"
                  onClick={formPassword.submitForm}
                  className="login-btn">
                  Submit
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
        {loader && (
          <div className="loader-wrapper d-flex align-items-center justify-content-center">
            <BallTriangle
              height="100"
              width="100"
              color="#45C29A"
              ariaLabel="loading"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ProfileDetailForm;
