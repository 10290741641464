import React from 'react';
import RegisterCompleteIco from '../../../Assets/Images/thumbs-up.svg';
import './thank-you.scss';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

function ThankYou(props) {
  var stepPercentage = 0;
  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 34;
  } else if (props.currentStep === 3) {
    stepPercentage = 67;
  } else if (props.currentStep === 4) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }

  return (
    <>
      <div>
        {props.accountVerification ? (
          <div className="container px-0">
            <div className="row mt-sm-5" id="verification-completed">
              <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center mx-auto text-center reset-password-wrapper">
                <div className="mt-5 pt-1 pb-5 w-100">
                  <span>
                    <img
                      src={RegisterCompleteIco}
                      alt="password-ico"
                      className="password-ico"
                    />
                  </span>
                  <h1 className="pt-5 pb-2 page-heading"> Thank You </h1>
                  <p>
                    We are looking into the documents you have uploaded. Once we
                    verify we will get back to you.
                  </p>
                  <p>
                    It take max 2 hours during working day to get it checked and
                    we revert back with any queries
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : props.confirmation ? (
          <div className="container px-0 mt-4">
            <div className="row pb-5" id="verification-completed">
              <h1 className="text-center pt-5"> Complete </h1>
              <div className="col-sm-5 col-10 mx-auto d-flex align-items-center justify-content-center mx-auto text-center reset-password-wrapper">
                <div className="mt-1 pt-5 w-100">
                  <span>
                    <img
                      src={RegisterCompleteIco}
                      alt="password-ico"
                      className="password-ico mt-0"
                    />
                  </span>
                  <h1 className="pt-5 pb-2 page-heading"> Thank You </h1>
                  <p>
                    We are looking into the documents you have uploaded. Once we
                    verify we will get back to you.
                  </p>
                </div>
              </div>
              <div className="col-8 mx-auto mt-4 pb-5">
                <ProgressBar percent={stepPercentage}>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${
                          accomplished ? 'accomplished' : null
                        }`}>
                        <h5 className="progressBarText">COMPLETED BY USER</h5>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${
                          accomplished ? 'accomplished' : null
                        }`}>
                        <h5 className="progressBarText">
                          AMOUNT RECEIVED BY US
                        </h5>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${
                          accomplished ? 'accomplished' : null
                        }`}>
                        <h5 className="progressBarText">
                          AMOUNT TRANSFERED BY US
                        </h5>{' '}
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished, index }) => (
                      <div
                        className={`indexedStep ${
                          accomplished ? 'accomplished' : null
                        }`}>
                        <h5 className="progressBarText">AMOUNT CREDITED</h5>
                      </div>
                    )}
                  </Step>
                </ProgressBar>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-5 d-flex align-items-center justify-content-center mx-auto text-center reset-password-wrapper">
                <div className="mt-5 pt-5 w-100">
                  <img
                    src={RegisterCompleteIco}
                    alt="password-ico"
                    className="password-ico"
                  />
                  <h1 className="pt-5 pb-2 page-heading">
                    {' '}
                    Thank You for Registering.{' '}
                  </h1>
                  {/* <p> 
                                        New Password has been set.
                                        Please login with your new Password.
                                    </p> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ThankYou;
