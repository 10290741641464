import React, { useEffect, useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from './global/features/user';
import './_style.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import awsExports from './aws-exports';
import ProtectedRoute from './protectedRoute';
import { urls } from './urls';
import {
  fetchDocs,
  fetchRecipients,
  fetchTransactions,
} from './global/features/data';
Amplify.configure(awsExports);

function App() {
  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState(0);
  const { user, docs, recipients, transactions } = useSelector((state) => ({
    user: state?.user?.user,
    docs: state?.data?.docs,
    recipients: state?.data?.recipients,
    transactions: state?.data?.transactions,
  }));
  // console.log(
  //   process.env.REACT_APP_USER_POOLS_ID,
  //   process.env.REACT_APP_API_URL,
  // );

  const isLoggedin = useSelector((state) => state?.user?.islogged);
  const dispatch = useDispatch();

  const checkLoggedIn = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

      dispatch(
        setUser({
          id: user.attributes.sub,
          attributes: user.attributes,
          tokens: {
            access: user.signInUserSession.accessToken.jwtToken,
            refresh: user.signInUserSession.refreshToken.token,
            id: user.signInUserSession.idToken.jwtToken,
          },
        }),
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(setUser(null));
    }
  };

  useEffect(() => {
    // console.log(process.env.REACT_APP_USER_POOLS_ID);
    checkLoggedIn();
  }, []);
  useEffect(() => {
    if (user) {
      dispatch(fetchDocs());
      dispatch(fetchRecipients());
      dispatch(fetchTransactions());
    }
  }, [user?.tokens?.access]);

  const renderRoutes = () => {
    return urls.map((url) => {
      if (url.protected) {
        return (
          <Route
            path={url.path}
            key={url.path}
            element={
              <ProtectedRoute loading={loading} auth={isLoggedin}>
                {url.element}
              </ProtectedRoute>
            }
          />
        );
      }

      return <Route path={url.path} key={url.path} element={url.element} />;
    });
  };

  return (
    <div className="App">
      <Router>
        <Routes>{renderRoutes()}</Routes>
      </Router>
    </div>
  );
}

export default App;
