/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import SetNewPasswordIco from '../../Assets/Images/new-password-ico.svg';

function SetNewPassword({
  otp,
  newPassword,
  confirmNewPassword,
  handleChange,
  handleBlur,
  onSubmit,
  errors,
  touched,
  onResendClick,
}) {
  return (
    <>
      <div>
        {/* <Header /> */}
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-11 col-md-4 d-flex align-items-center justify-content-center mx-auto text-center reset-password-wrapper">
              <div className="mt-2 pt-2 w-100">
                <img
                  src={SetNewPasswordIco}
                  alt="password-ico"
                  className="password-ico"
                />
                <h1 className="pt-3 pb-2"> Set New Password </h1>
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="otp"
                    name="otp"
                    autoComplete="off"
                    autoCorrect="off"
                    maxLength={6}
                    value={otp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter OTP"
                  />
                  <label htmlFor="otp">Enter OTP</label>
                  {errors.otp && touched.otp ? (
                    <p className="error-message">{errors.otp}</p>
                  ) : null}
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    value={newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="newPassword"
                    autoComplete="off"
                    autoCorrect="off"
                    name="newPassword"
                    placeholder="New Password"
                  />
                  <label htmlFor="newPassword">New Password</label>
                  {errors.newPassword && touched.newPassword ? (
                    <p className="error-message">{errors.newPassword}</p>
                  ) : null}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    value={confirmNewPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    autoCorrect="off"
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    placeholder="Confirm New Password"
                  />
                  <label htmlFor="confirmNewPassword">
                    Confirm New Password
                  </label>
                  {errors.confirmNewPassword && touched.confirmNewPassword ? (
                    <p className="error-message">{errors.confirmNewPassword}</p>
                  ) : null}
                </div>
                <div className="btn-wrapper text-center">
                  <button
                    className="mt-4 py-3 login-btn d-flex align-items-center justify-content-center login-btn"
                    onClick={onSubmit}>
                    {' '}
                    CONFIRM{' '}
                  </button>
                  <p>
                    Didn't receive E-mail?{' '}
                    <a href="#" onClick={onResendClick}>
                      Resend OTP
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SetNewPassword;
