import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import StripeCard from '../stripe-card/stripe-card';
import './checkout-stripe.scss';
import Header from '../../../Common/Pages/header/header';
import { useDispatch, useSelector } from 'react-redux';
import LebaraSim from '../../../Assets/Images/lebara-sim.svg';
import VoxiSim from '../../../Assets/Images/voxi-sim.svg';
import Giffgaff from '../../../Assets/Images/giffgaff-sim.svg';

const CheckoutStripe = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const simData = useSelector((state) => state.sim);
  const selectedPlan = useSelector((state) => state.sim.selectedPlan);
  const rates = useSelector((state) => state.calculator.rates);
  const [totalAmount, setTotalAmount] = useState();
  const [gstVal, setGstVal] = useState();
  const [shippingCharges, setShippingCharges] = useState();
  const [planCharges, setPlanCharges] = useState();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  useEffect(() => {
    const deliveryPrice =
      simData.deliveryType === 'express_delivery'
        ? selectedPlan.express_delivery_price
        : selectedPlan.standard_delivery_price;
    const planCharges = simData?.lebaraPlan?.price_per_month ?? 0;
    const simPlanCharges = (+planCharges / rates.GBP).toFixed(0);
    const simPlanFinalCharges = +simPlanCharges + 1;
    const simPlanChargesGST = ((+simPlanCharges * 0.18) / 1.18).toFixed(0);
    const gstCal = ((+deliveryPrice * 0.18) / 1.18).toFixed(0);
    const finalShippingCharges =
      +deliveryPrice != 0 ? deliveryPrice - +gstCal : 0;
    const finalGstCal = +simPlanChargesGST
      ? +simPlanChargesGST + +gstCal
      : +gstCal;
    const amountTotal = +finalShippingCharges + +simPlanCharges + +finalGstCal;
    setTotalAmount(amountTotal);
    setGstVal(finalGstCal);
    setShippingCharges(finalShippingCharges);
    setPlanCharges(simPlanFinalCharges);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_STRIPE_RETURN_URL}/payment-completed`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }
  };
  const paymentElementOptions = {
    layout: 'tabs',
  };
  return (
    <>
      <Header />
      <div className="row" id="payment-form">
        <div className="col-12 col-sm-8">
          <form onSubmit={handleSubmit}>
            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />
            <div className="btn-wrapper text-center mt-4">
              <button
                disabled={isLoading || !stripe || !elements}
                id="submit"
                className="login-btn">
                <span id="button-text">
                  {isLoading ? (
                    <div className="spinner" id="spinner"></div>
                  ) : (
                    'Pay now'
                  )}
                </span>
              </button>
            </div>
            {/* Show any error or success messages */}
            {message && (
              <div id="payment-message" className="text-center text-danger">
                {message}
              </div>
            )}
          </form>
        </div>
        <div className="col-sm-4 col-12 d-none d-sm-block selected-plan-wrapper ps-sm-5 mt-4 mt-sm-0">
          <div className="selected-plan" key={selectedPlan._id}>
            <h4> Order Summary </h4>
            <div
              className="row mx-0 pb-4"
              style={{ borderBottom: '1px solid #A6A6A6' }}>
              <div className="col-10 ps-0 d-flex align-items-center justify-content-start pt-3">
                <img
                  src={
                    selectedPlan.provider_name === 'Giffgaff'
                      ? Giffgaff
                      : selectedPlan.provider_name === 'Lebara'
                      ? LebaraSim
                      : VoxiSim
                  }
                  alt="planHeadIco"
                  style={{ width: '70px' }}
                />
                <ul className="ps-3">
                  <li> {selectedPlan.provider_name} SIM </li>
                  <li>
                    {' '}
                    {simData.deliveryType === 'express_delivery'
                      ? 'Express'
                      : 'Normal'}{' '}
                    Delivery{' '}
                  </li>
                  <li>
                    {simData.deliveryType == 'express_delivery' ? (
                      <p
                        style={{
                          fontStyle: 'italic',
                          fontSize: '12px',
                          fontWeight: '500',
                        }}>
                        {simData.expressDeliveryDate} -{' '}
                        {simData.expressDeliveryEndDate}
                      </p>
                    ) : (
                      <p
                        style={{
                          fontStyle: 'italic',
                          fontSize: '12px',
                          fontWeight: '500',
                        }}>
                        {simData.freeDeliveryStartDate} -{' '}
                        {simData.freeDeliveryEndDate}
                      </p>
                    )}
                  </li>
                </ul>
              </div>
              <div className="col-2 d-flex align-items-center justify-content-end pe-0">
                <h5 className="mb-0 d-flex align-items-center justify-content-center">
                  ₹
                  {simData.deliveryType === 'express_delivery'
                    ? selectedPlan.express_delivery_price
                    : selectedPlan.standard_delivery_price}
                </h5>
              </div>
            </div>
            <div className="row mt-3">
              {simData.lebaraPlan ? (
                <>
                  <div className="col-8 mb-3">
                    <h6 className="mb-0"> Sim Plan Charges </h6>
                  </div>
                  <div className="col-4 mb-3 text-end">
                    <h5 className="mb-0">₹{planCharges}</h5>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className="col-8 mb-3">
                <h6 className="mb-0"> Shipping Charges </h6>
              </div>
              <div className="col-4 mb-3 text-end">
                <h5 className="mb-0">₹{shippingCharges}</h5>
              </div>
              <div className="col-6 mb-3">
                <h6 className="mb-0"> Tax </h6>
              </div>
              <div className="col-6 mb-3 text-end">
                <h5 className="mb-0"> ₹{gstVal} </h5>
              </div>
              <div className="col-8 mb-3">
                <h6 className="mb-0"> Subtotal </h6>
              </div>
              <div className="col-4 mb-3 text-end">
                <h5 className="mb-0">₹{totalAmount}</h5>
              </div>
              <div
                className="col-6 mb-3 py-2"
                style={{
                  borderTop: '1px solid #A6A6A6',
                  borderBottom: '1px solid #A6A6A6',
                }}>
                <h6 className="mb-0"> Total </h6>
              </div>
              <div
                className="col-6 mb-3 text-end py-2"
                style={{
                  borderTop: '1px solid #A6A6A6',
                  borderBottom: '1px solid #A6A6A6',
                }}>
                <h5 className="mb-0"> ₹{totalAmount} </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CheckoutStripe;
