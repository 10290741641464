const BANK_CHARGE = 500;
const REMIGOS_TRANSACTION_CHARGE = 700;
const GST_RATE = 0.18;
const TCS_LIMIT = 700_000;

const MAX_TAXABLE_VALUE_L1 = 1000;
const MIN_L1 = 250;

const MAX_TAXABLE_VALUE_L2 = 4500;

const remigosCharge = (rate: number) => {
  return 0.6 / (1 / rate);
};

const calculateGST = (amount: number) => {
  let taxableValue = 0;

  const temp_l1 =
    amount * 0.01 < MAX_TAXABLE_VALUE_L1 ? amount * 0.01 : MAX_TAXABLE_VALUE_L1;
  taxableValue += temp_l1 < MIN_L1 ? MIN_L1 : temp_l1;

  if (amount >= 100_000) {
    const temp_l2 = (amount - 100_000) * 0.005;
    taxableValue +=
      temp_l2 < MAX_TAXABLE_VALUE_L2 ? temp_l2 : MAX_TAXABLE_VALUE_L2;
  }

  if (amount >= 1000_000) {
    taxableValue += (amount - 1000_000) * 0.001;
  }

  return taxableValue * 0.18 + BANK_CHARGE * 0.18;
};

const calculateTCS = (fromAmt: number, tcsAmt: number): false | number => {
  if (tcsAmt > TCS_LIMIT) {
    return (fromAmt / 1.05) * 0.05;
  }

  // tcsAmt > 7lacs
  // tcs = (fromAmt / 1.05) * 0.05

  // 700_000 - tcsAmt = khali_tanki
  // fromAmt - khali_tanki = taxableValue
  // tcs = (taxableValue * 1.05) * 0.05

  const totalAmt = fromAmt + tcsAmt;
  const tcsTaxableAmt = totalAmt - TCS_LIMIT;

  if (tcsTaxableAmt <= 0) {
    return false;
  }

  return (tcsTaxableAmt / 1.05) * 0.05;
};

export {
  remigosCharge,
  BANK_CHARGE,
  GST_RATE,
  TCS_LIMIT,
  calculateGST,
  calculateTCS,
  REMIGOS_TRANSACTION_CHARGE,
};
