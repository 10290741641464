import React, { useEffect, useState } from 'react';
import AllAnswers from '../all-answers/all-answers';
import Header from '../../../Common/Pages/header/header';
import NavBar from '../../../Common/Pages/NavBar/nav-bar';
import { useSelector } from 'react-redux';
import EmptyBag from '../../../Assets/Images/empty-cart.svg';
import './my-orders.scss';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const MyOrders = () => {
  const user = useSelector((state) => state.user);
  const sim = useSelector((state) => state.sim);
  const token = user.user?.tokens?.access;
  const [allOrderList, setAllOrderList] = useState();
  const [loader, setLoader] = useState(false);
  const [receiptUrl, setReceiptUrl] = useState();

  const navigate = useNavigate();

  let axiosToken = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token,
    },
  };
  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        const getAllOrder = await axios.get(
          `${process.env.REACT_APP_API_URL}/sim/order`,
          axiosToken,
        );
        setAllOrderList(getAllOrder.data.data);
      } catch (err) {
        console.log('err: ', err);
      }
    })();
    setLoader(false);
  }, []);
  const handleInvoice = async (id) => {
    try {
      const getAllOrder = await axios.get(
        `${process.env.REACT_APP_API_URL}/sim/order/${id}/receipt`,
        axiosToken,
      );
      setReceiptUrl(getAllOrder.data.receipt_url);
      window.open(getAllOrder.data.receipt_url, '_blank');
    } catch (err) {
      console.log('err: ', err);
    }
  };
  return (
    <>
      <div className="container-fluid">
        <Header />
        <div className="row">
          <div className="col-3 ps-0 sidebar-container">
            <NavBar />
          </div>
          <div
            className="col-12 col-sm-9 mx-auto"
            id="orders-wrapper"
            style={{ paddingTop: '100px' }}>
            <div className="row mb-4 mx-0">
              <h1 className="mb-4">{allOrderList ? 'All' : 'My'} Orders</h1>
              {allOrderList?.length != 0 ? (
                <>
                  {allOrderList?.map((el) => {
                    const formatChange = moment(el.createdAt).format(
                      'DD-MMM-YYYY',
                    );

                    return (
                      <div
                        className={
                          el.payment?.amount_charged
                            ? 'col-12 pb-3 order-status-content mb-3'
                            : 'col-12 pb-3 order-status-content mb-3 no-payment'
                        }
                        key={el._id}>
                        <div className="row inner-order-details pb-2 pt-4 mx-0 px-2">
                          <div className="col-sm-12 col-12 ps-0 order-number-sec">
                            <h2>
                              Order no. <strong>:4565756</strong>
                            </h2>
                          </div>
                        </div>
                        <div className="row inner-order-details pb-2 pt-4 mx-0">
                          <div className="col-sm-4 col-12 ps-0 order-number-sec d-flex aling-items-center px-3 px-sm-0">
                            <div className="pe-4">
                              {/* <img
                                src={
                                  providerName == 'Voxi'
                                    ? VoxiSim
                                    : providerName == 'Lebara'
                                    ? LebaraSim
                                    : Giffgaff
                                }
                                style={{ width: '60px' }}
                                alt="sim-img"
                              /> */}
                            </div>
                            <div>
                              <h2>{el.sim_provider.provider_name} SIM</h2>
                              <h3>
                                <strong>
                                  {el.delivery_type == 'standard_delivery'
                                    ? 'Standard Delivery'
                                    : 'Express Delivery'}
                                </strong>
                                <br />
                                (3-5 Working Days)
                              </h3>
                            </div>
                          </div>
                          <div className="col-sm-8 col-12 ps-sm-0 pt-4 pt-sm-0 ps-sm-3 order-details d-flex align-items-center justify-content-sm-end justify-content-start">
                            <ul className="ps-0 row w-100">
                              <li className="col-sm-4 col-6">
                                <h5> Payment Made </h5>
                                <h6>₹{el.amount_chargeable / 100 ?? '0.00'}</h6>
                              </li>
                              <li className="col-sm-4 col-6">
                                <h5> Order Date </h5>
                                <h6> {formatChange} </h6>
                              </li>
                              <li className="col-sm-4 col-6 pt-sm-0 pt-4">
                                <h5> Shipping to </h5>
                                <h6>
                                  {`${el.user_address.city},
                                  ${el.user_address.country}`}
                                </h6>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {el?.payment?.amount_charged && (
                          <div className="btn-wrapper text-sm-end text-center mt-3">
                            <button
                              className="invoice-btn"
                              onClick={() => handleInvoice(el._id)}>
                              VIEW INVOICE
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="col-12 empty-orders-wrapper text-center">
                  <img src={EmptyBag} alt="empty-bag" />
                  <h2 className="py-3"> Place your first order today! </h2>
                  <div className="btn-wrapper">
                    <Link
                      to="/sim-card"
                      className="login-btn"
                      style={{ padding: '8px 12px' }}>
                      ORDER YOUR SIM
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <AllAnswers />
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader-wrapper d-flex align-items-center justify-content-center">
          <BallTriangle
            height="100"
            width="100"
            color="#45C29A"
            ariaLabel="loading"
          />
        </div>
      )}
    </>
  );
};

export default MyOrders;
