const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/**
 *
 * @param {Date} date
 */
const transactionDate = (date) => {
  const day = date.getDate();
  const year = date.getFullYear();

  return `${day} ${months[date.getMonth()]}, ${year}`;
};

export { transactionDate };
