import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import RemoveIco from '.././../../Assets/Images/remove.png';
import axios from 'axios';
import UniversityIco from '../../../Assets/Images/school.svg';
import RentIco from '../../../Assets/Images/house.svg';
import own from '../../../Assets/Images/bank-ico.svg';
import { useDispatch, useSelector } from 'react-redux';
import './add-sender-form.scss';
import { useFormik } from 'formik';
import ParentsIco from '../../../Assets/Images/parents.svg';
import AccountIco from '../../../Assets/Images/bank-account.svg';
import isEmail from 'validator/es/lib/isEmail';
import CheckIco from '../../../Assets/Images/check.png';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';

const receipientsFormValidate = (values) => {
  const errors = {};

  if (!isEmail(values.email)) {
    errors.email = 'Please enter valid email';
  }
  if (values.name?.trim().length < 2) {
    errors.name = 'Please enter name';
  }
  if (values.bankName?.trim().length < 3) {
    errors.bankName = 'Please enter bank name';
  }
  if (values.accountNumber?.trim().length < 3) {
    errors.accountNumber = 'Please enter account number';
  }
  if (values.phone?.trim().length < 10) {
    errors.phone = 'Please enter valid phone number';
  }
  let mystring = values.phone.substring(0, 3);
  if (mystring != '+91') {
    errors.phone = 'Please enter Indian number';
  }

  return errors;
};

function addSenderForm(props) {
  const [senderSelVal, setSenderSelVal] = useState('own');
  const [selectedRadio, setSelectedRadio] = useState('father');
  const [successShow, setSuccessShow] = useState(false);
  const user = useSelector((state) => state.user.user);
  // console.log(user);
  const token = user?.tokens?.access;
  const [activeClass, setActiveClass] = useState('');
  const handleClose = () => props.setShow(false);
  const handleSuccessClose = () => setSuccessShow(false);
  const [countryCode, setCountryCode] = useState();
  const form = useFormik({
    initialValues: {
      email: '',
      name: '',
      phone: '',
      bankName: '',
      accountNumber: '',
      //   selectReceipient: '',
    },
    validate: receipientsFormValidate,
    onSubmit: async (values, { setErrors }) => {
      if (!props.editData?._id) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/sender`,
            {
              relation: senderSelVal,
              name: values.name,
              bank_name: values.bankName,
              account_number: values.accountNumber,
              email: values.email,
              phone: values.phone,
            },
            axiosToken,
          )
          .then((res) => {
            // console.log('res-sender: ', res);
            props.receipiendtAdd(res.data.sender);
            props.setShow(false);
            setSuccessShow(true);
            setTimeout(() => {
              setSuccessShow(false);
            }, 2000);
          })
          .catch((err) => {
            // console.log('err: ', err);
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/sender/${props.receipientId}`,
            {
              relation: senderSelVal,
              name: values.name,
              bank_name: values.bankName,
              account_number: values.accountNumber,
              email: values.email,
              phone: values.phone,
            },
            axiosToken,
          )
          .then((res) => {
            // console.log('res-put-sender: ', res);
            props.receipiendtUpdate(res.data.sender);
            props.setShow(false);
            setSuccessShow(true);
            setTimeout(() => {
              setSuccessShow(false);
            }, 2000);
          })
          .catch((err) => {
            // console.log('err: ', err);
          });
      }
    },
  });
  let axiosToken = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token,
    },
  };

  useEffect(() => {
    if (props.editData?._id) {
      let senderValues = props.editData;
      setSenderSelVal(senderValues.relation);
      form.setValues({
        email: senderValues.email,
        name: senderValues.name,
        phone: senderValues.phone,
        bankName: senderValues.bank_name,
        accountNumber: senderValues.account_number,
      });
    } else {
      setSenderSelVal('own');
      form.resetForm();
    }
  }, [props.editData]);

  const payHandler = (val) => {
    if (val === 'relative') {
      setSenderSelVal('father');
      setActiveClass('active');
    } else {
      setSenderSelVal(val);
      setActiveClass('active');
    }
  };
  const getRelative = (event) => {
    setSelectedRadio(event.target.value);
    setSenderSelVal(event.target.value);
  };
  useEffect(() => {
    let phone = `${user.attributes['custom:countryCode']}${user.attributes['custom:phone']}`;
    if (user.attributes['custom:countryCode'] !== '+91') {
      phone = '+91';
    }
    if (props.editData === 'sender') {
      if (senderSelVal === 'own') {
        form.setValues({
          email: user.attributes.email,
          name: `${user.attributes['custom:firstName']} ${user.attributes['custom:lastName']}`,
          phone,
          bankName: '',
          accountNumber: '',
        });
      } else {
        form.setValues({
          email: '',
          name: '',
          phone: '',
          bankName: '',
          accountNumber: '',
        });
      }
    }
  }, [senderSelVal]);
  const handlePhoneNumber = (e) => {
    form.setFieldValue('phone', e || '');
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        className="receipient-modal-wrapper pt-0">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.editData?._id ? 'Edit Sender' : 'Add Sender'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <h1 className="my-3"> Select Sender </h1>
            {form.errors.selectReceipient ? (
              <p className="error-message">{form.errors.selectReceipient}</p>
            ) : null}
            <>
              <div
                className={
                  senderSelVal === 'father' ||
                  senderSelVal === 'spouse' ||
                  senderSelVal === 'mother'
                    ? 'col-sm-7 col-12'
                    : 'col-12'
                }>
                <ul
                  className={
                    senderSelVal === 'father' ||
                    senderSelVal === 'spouse' ||
                    senderSelVal === 'mother'
                      ? 'active d-block align-items-center justify-content-end pt-2'
                      : 'd-block align-items-center justify-content-center pt-2'
                  }>
                  <li>
                    <button
                      onClick={() => {
                        payHandler('own');
                      }}>
                      <span
                        className={
                          senderSelVal === 'own'
                            ? 'active d-flex justify-content-center'
                            : 'd-flex justify-content-center'
                        }>
                        <img src={AccountIco} alt="bankIco" />
                      </span>
                    </button>
                    <h6> Own Account </h6>
                  </li>
                  <li className="ms-1">
                    <button
                      onClick={() => {
                        payHandler('relative');
                      }}>
                      <span
                        className={
                          (activeClass === 'active' &&
                            senderSelVal === 'relative') ||
                          senderSelVal === 'father' ||
                          senderSelVal === 'spouse' ||
                          senderSelVal === 'mother'
                            ? 'active d-flex justify-content-center'
                            : 'd-flex justify-content-center'
                        }>
                        <img src={ParentsIco} alt="bankIco" />
                      </span>
                    </button>
                    <h6> Relative </h6>
                  </li>
                </ul>
              </div>
              <div
                className={
                  senderSelVal === 'own' || senderSelVal === ''
                    ? 'd-none'
                    : 'col-sm-3 col-12 text-center pt-2 d-flex align-items-start justify-content-start relative-details'
                }>
                <ul>
                  <li className="text-start pb-3 form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value="father"
                      checked={selectedRadio === 'father'}
                      onChange={getRelative}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2">
                      Father
                    </label>
                  </li>
                  <li className="text-start form-check pb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                      value="mother"
                      checked={selectedRadio === 'mother'}
                      onChange={getRelative}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault3">
                      Mother
                    </label>
                  </li>
                  <li className="text-start form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value="spouse"
                      checked={selectedRadio === 'spouse'}
                      onChange={getRelative}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1">
                      Spouse
                    </label>
                  </li>
                </ul>
              </div>
            </>
            <div className="col-12 mt-4">
              <div className="form-floating mb-4">
                <input
                  name="email"
                  className={
                    form.errors.email && form.touched.email
                      ? 'error-field form-control'
                      : 'form-control'
                  }
                  id="floatingInput"
                  placeholder="Email Address"
                  //   value={senderEmail}
                  //   onChange={(e) => setSenderEmail(e.target.value)}
                  value={form.values.email}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                <label htmlFor="floatingInput">Email Address* </label>
                {form.errors.email && form.touched.email ? (
                  <p className="error-message">{form.errors.email}</p>
                ) : null}
              </div>
            </div>

            <div className="col-12">
              <div className="form-floating mb-4">
                <input
                  name="name"
                  className={
                    form.errors.name && form.touched.name
                      ? 'error-field form-control'
                      : 'form-control'
                  }
                  id="floatingInput"
                  placeholder="First Name"
                  //   value={senderName}
                  //   onChange={(e) => setSenderName(e.target.value)}
                  value={form.values.name}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                <label htmlFor="floatingInput">Name of Sender* </label>
                {form.errors.name && form.touched.name ? (
                  <p className="error-message">{form.errors.name}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating mb-4">
                <input
                  name="bankName"
                  className={
                    form.errors.bankName && form.touched.bankName
                      ? 'error-field form-control'
                      : 'form-control'
                  }
                  id="floatingInput"
                  placeholder="First Name"
                  //   value={senderBankName}
                  //   onChange={(e) => setSenderBankName(e.target.value)}
                  value={form.values.bankName}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                <label htmlFor="floatingInput">Bank Name* </label>
                {form.errors.bankName && form.touched.bankName ? (
                  <p className="error-message">{form.errors.bankName}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating mb-4">
                <input
                  name="accountNumber"
                  className={
                    form.errors.accountNumber && form.touched.accountNumber
                      ? 'error-field form-control'
                      : 'form-control'
                  }
                  id="floatingInput"
                  placeholder="First Name"
                  //   value={senderAccount}
                  //   onChange={(e) => setSenderAccount(e.target.value)}
                  value={form.values.accountNumber}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                <label htmlFor="floatingInput">Account Number*</label>
                {form.errors.accountNumber && form.touched.accountNumber ? (
                  <p className="error-message">{form.errors.accountNumber}</p>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating mb-4 input-phone">
                {/* <input
                  name="phone"
                  className={
                    form.errors.phone && form.touched.phone
                      ? 'error-field form-control'
                      : 'form-control'
                  }
                  id="floatingInput"
                  placeholder="Phone"
                  //   value={senderPhone}
                  //   onChange={(e) => setPhone(e.target.value)}
                  value={form.values.phone}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                /> */}
                {/* <PhoneInput
                  international
                  id="phone"
                  name="phone"
                  country="US"
                  value={form.values.phone}
                  countryCallingCodeEditable={false}
                  onChange={handlePhoneNumber}
                  onBlur={form.handleBlur}
                /> */}
                <Input
                  country="IN"
                  international
                  withCountryCallingCode
                  id="phone"
                  name="phone"
                  value={form.values.phone}
                  onChange={handlePhoneNumber}
                  onBlur={form.handleBlur}
                />
                {/* {countryCode != 91 ? (
                  <p style={{ fontSize: '12px', color: 'red' }}>
                    Please Enter Indian Number{' '}
                  </p>
                ) : (
                  ''
                )} */}
                {/* <p style={{ fontSize: '12px' }}>
                    {' '}
                    Please enter only Indian number{' '}
                  </p> */}
                {form.errors.phone && form.touched.phone ? (
                  <p className="error-message">{form.errors.phone}</p>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center btn-wrapper">
          <Button
            variant="primary"
            onClick={form.submitForm}
            className="login-btn">
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={successShow}
        onHide={handleSuccessClose}
        className="delete-modal-wrappr">
        <Modal.Body className="text-center">
          <img src={CheckIco} alt="RemoveIco" className="w-25 my-3" />
          <h2>
            {' '}
            {props.editData?._id
              ? 'Successfully Edited!'
              : 'Successfully Created!'}
          </h2>
          {/* <p> Are you sure you want to delete this document? </p> */}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={handleSuccessClose}
            className="success-btn">
            ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default addSenderForm;
