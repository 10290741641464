import React from 'react';
import TravelIco from '../../../Assets/Images/travel-and-tourism.svg';
import './sim-delivery-location.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setDeliveryCountry } from '../../../global/features/sim';

const SimDeliveryLocation = () => {
  // const maxDate = new Date();
  const user = useSelector((state) => state.sim);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(setDeliveryCountry(e.target.value));
  };

  return (
    <div className="col-10 mx-auto text-center p-5" id="travelling-wrapper">
      <h1 className> Where do you want the SIM delivered? </h1>
      <ul className="d-sm-flex align-items-center justify-content-center pt-4 ps-0 ps-sm-4 text-center">
        <li className="travelling-sec me-sm-4 mb-5 mb-sm-0 d-flex align-items-center justify-content-start">
          <span>
            <img src={TravelIco} alt="bankIco" />
          </span>
          <div className="col-sm-3 col-2 form-check ms-3">
            <input
              className="form-check-input"
              type="radio"
              name="currentLocation"
              onChange={handleChange}
              id="uk"
              value="UK"
              //   checked={form.values.currentLocation === 'Outside India'}
            />
            <label className="form-check-label" htmlFor="flexRadioDefault2">
              UK
            </label>
          </div>
        </li>
        <li className="travelling-sec d-flex align-items-center justify-content-start">
          <span>
            <img src={TravelIco} alt="bankIco" />
          </span>
          <div className="col-sm-3 col-2 form-check ms-3">
            <input
              className="form-check-input"
              type="radio"
              name="currentLocation"
              onChange={handleChange}
              id="india"
              value="India"
              //   checked={form.values.currentLocation === 'Outside India'}
            />
            <label className="form-check-label" htmlFor="flexRadioDefault2">
              India
            </label>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default SimDeliveryLocation;
