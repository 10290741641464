import React, { useEffect, useState, useContext, createContext } from 'react';
import PendingIco from '../../../Assets/Images/pending.svg';
import RejectedIco from '../../../Assets/Images/rejected.svg';
import FailedImage from '../../../Assets/Images/failed-1.svg';
import ParentsIco from '../../../Assets/Images/parents.svg';
import { Modal, Button } from 'react-bootstrap';
import { ProgressBar } from 'react-bootstrap';
import CheckIco from '../../../Assets/Images/check.png';
import DeleteIco from '../../../Assets/Images/delete.png';
import RemoveIco from '../../../Assets/Images/remove.png';
import PlusIco from '../../../Assets/Images/plus-ico.svg';
import './rejected-doc-upload.scss';
import UploadIco from '../../../Assets/Images/upload.svg';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import JPGIco from '../../../Assets/Images/jpg-ico.png';
import DocxIco from '../../../Assets/Images/docx-ico.png';
import PNGIco from '../../../Assets/Images/png-ico.png';
import PDFIco from '../../../Assets/Images/pdf-ico.png';
import ReuploadSuccess from '../reupload-success-doc/reupload-success-doc';
import Header from '../../../Common/Pages/header/header';
import Footer from '../../../Common/Pages/footer/footer';
import BackIco from '../../../Assets/Images/back-ico.svg';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../sender/sender';
const uploadDocID = createContext();

function RejectedDocUpload(props) {
  const [show, setShow] = useState(false);
  const user = useSelector((state) => state.user);
  const selecteDoc = user.whoSelRejectedDoc;
  const token = user.user.tokens.access;
  const [docStatusList, setDocStatusList] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [progressBarId, setProgressBarId] = useState();
  const [uploadPercantage, setUploadPercantage] = useState(0);
  const [rejectedUploaded, setRejectedUploaded] = useState('');
  const [docUploadData, setDocUploadData] = useState(false);
  const [listDocStatus, setListDocStatus] = useState();
  const [showErroText, setShowErroText] = useState(false);

  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
    setShow(false);
    setDocuments('');
  };
  let axiosToken = {
    headers: {
      'content-type': 'application/json;charset=UTF-8',
      Authorization: token,
    },
  };
  const handleDeleteDoc = (data) => {
    // let axiosToken = {
    //   headers: {
    //     'content-type': 'application/json;charset=UTF-8',
    //     Authorization: token,
    //   },
    // };
    // axios
    //   .delete(
    //     `${process.env.REACT_APP_API_URL}/files/${keysonDelete}`,
    //     axiosToken,
    //   )
    //   .then((res) => {
    //     console.log('res: ', res);
    //     const enableButton = props.listofDoc.some(
    //       (el) => el.required && el.docName === 'No Image',
    //     );
    //     props.setDocButton(enableButton);
    //     setUploadPercantage((prevState) => {
    //       return props.listofDoc.map((data, i) => {
    //         if (data.key === keysonDelete) {
    //           data.uploadStatus = 0;
    //         }
    //       });
    //     });
    //     props.setListofDoc((prevState) => {
    //       return props.listofDoc.map((data) => {
    //         if (data.key === keysonDelete) {
    //           data.docName = 'No Image';
    //           data.image = null;
    //         }
    //       });
    //     });
    //     props.setListofDoc(props.listofDoc);
    //   })
    //   .catch((err) => {
    //     console.log('err: ', err);
    //   });
  };
  const handleUploadPhoto = (e) => {
    setShowErroText('');
    const input = e.target.files[0];
    setDocuments(input);
  };
  const openDocModal = (val) => {
    setProgressBarId(val.field);
    uploadDocID.value = val._id;
    setRejectedUploaded(val._id);
    setShow(true);
  };
  const submitUploadDoc = () => {
    setShow(false);
    let axiosToken = {
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        Authorization: token,
      },
      onUploadProgress: (e) => {
        const { loaded, total } = e;
        var percentage = Math.floor((loaded * 100) / total);
        setUploadPercantage((prevState) => {
          return docStatusList.map((data, i) => {
            if (data.field === progressBarId) {
              data.uploadStatus = percentage;
            }
          });
        });
      },
    };
    const size = (documents.size / 1024 / 1024).toFixed(2);
    let data = new FormData();
    data.append(`${progressBarId}`, documents);
    let fileExtension = documents.name.split('.').at(-1);
    if (
      fileExtension != 'png' &&
      fileExtension != 'jpg' &&
      fileExtension != 'pdf' &&
      fileExtension != 'doc' &&
      fileExtension != 'docx' &&
      fileExtension != 'jpeg'
    ) {
      setShowErroText('Please upload below mentioned files only.');
    } else if (size > 10) {
      setShowErroText('The file size is larger than 10MB.');
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/files`, data, axiosToken)
        .then((res) => {
          // console.log('res upload:', res);
        })
        .catch((err) => {
          // console.log('err: ', err);
        });
    }
  };
  const getFilesData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/files/?type=${selecteDoc}`,
        axiosToken,
      )
      .then((res) => {
        // console.log('res: ', res);
        setListDocStatus(res.data.status);
        setDocStatusList(res.data.docs);
      })
      .catch((err) => {
        // console.log('err: ', err);
      });
  };
  useEffect(() => {
    getFilesData();
  }, []);

  const filterRejectedDoc = docStatusList.filter((data) => {
    return data.status == 'rejected';
  });
  const previousButton = () => {
    navigate('/account-verification');
  };
  const handleUploadRejected = () => {
    navigate('/reupload-success');
  };
  return (
    <>
      <Header />
      <div className="container wrapper-rejected-doc px-0 px-sm-3 pb-sm-0 pb-5">
        <button
          onClick={previousButton}
          className="back-btn pb-3 text-start d-block d-sm-none ps-4 ps-sm-0">
          {' '}
          <img src={BackIco} alt="back-ico" className="me-2" />
          Back{' '}
        </button>
        <div
          className="col-12 pt-3 pb-4 text-start text-sm-center px-4 px-sm-0"
          id="account-verification-wrapper">
          <h1> One-time Complete Account Verification </h1>
          <p>
            {' '}
            You can transfer any number of times once your documents are
            complete. You might have to update the details as and when they
            change.{' '}
          </p>
        </div>
        <button
          onClick={previousButton}
          className="back-btn pb-3 text-start d-none d-sm-block">
          {' '}
          <img src={BackIco} alt="back-ico" className="me-2" />
          Back{' '}
        </button>
        <div id="rejected-doc">
          <div className="verification-doc-status">
            <ul className="ps-0">
              <li
                className={
                  listDocStatus?.own === 'rejected' ||
                  listDocStatus?.father === 'rejected' ||
                  listDocStatus?.mother === 'rejected' ||
                  listDocStatus?.spouse === 'rejected'
                    ? 'rejectedDoc p-2'
                    : 'p-2'
                }>
                <div className="d-flex align-items-center">
                  {' '}
                  <span className="d-flex align-items-center justify-content-center">
                    <img src={ParentsIco} alt="ico-parent" />{' '}
                  </span>
                  <div className="ps-2 col-6 col-sm-10 text-start">
                    {/* <h6 className="text-start mb-0"> {selecteDoc} Account </h6> */}
                    <h2 style={{ textTransform: 'capitalize' }}>
                      {' '}
                      {selecteDoc} Account{' '}
                    </h2>
                  </div>
                  <img
                    src={
                      listDocStatus?.own === 'rejected' ||
                      listDocStatus?.father === 'rejected' ||
                      listDocStatus?.mother === 'rejected' ||
                      listDocStatus?.spouse === 'rejected'
                        ? RejectedIco
                        : 'p-2'
                    }
                    alt="pendingIco"
                    className="ms-3 statusIco ms-4"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div className="col-sm-4 col-12 mx-auto text-center">
            <img
              src={FailedImage}
              alt="failed-img"
              style={{ width: '100px' }}
            />
            <h1> Failed Verification.... </h1>
            <p className="text-center">
              {' '}
              Sorry! Verification has failed. Please re-upload the following
              documents{' '}
            </p>
          </div>
          <div className="upload-rejected-sec">
            <ul className="ps-0 ps-sm-3">
              {filterRejectedDoc.map((data, i) => {
                let ext = data.originalname.split('.').pop();
                return (
                  <li
                    key={data._id}
                    className="d-flex align-items-center justify-content-center col-sm-10 col-12 mx-auto mt-sm-5 mt-3">
                    <div className="row mx-auto justify-content-center mb-4 upload-bar-sec pt-4">
                      <div className="col-sm-2 col-4 d-flex justify-content-center">
                        <span>
                          <img
                            src={
                              ext === 'pdf'
                                ? PDFIco
                                : ext === 'doc'
                                ? DocxIco
                                : ext === 'png'
                                ? PNGIco
                                : JPGIco
                            }
                            alt="icon"
                            style={{ width: '50px' }}
                          />
                        </span>
                      </div>
                      <div className="col-sm-7 col-8 ps-0 ps-sm-3">
                        <div className="text-start">
                          <h6>{data.field}</h6>
                          <p className="d-none d-sm-block">
                            {data.originalname}
                          </p>
                        </div>
                        <div className="progressBar">
                          <ProgressBar
                            now={data.uploadStatus}
                            data-before={data.id}
                          />
                          <p
                            className="d-block d-sm-none mt-2"
                            style={{
                              fontWeight: '600',
                              color: '#45C29A',
                              fontSize: '14px',
                            }}>
                            {data.originalname}
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-sm-3 col-12 d-sm-flex align-items-sm-end"
                        style={{ position: 'relative' }}>
                        <Button
                          onClick={() => openDocModal(data)}
                          className="btn-upload d-sm-block d-none"
                          style={{
                            position: 'absolute',
                            top: '0px',
                            marginBottom: '0px !important',
                          }}>
                          Upload
                        </Button>
                        <div className="col-12 d-sm-none d-flex align-items-center justify-content-center my-2 my-sm-0">
                          <Button
                            onClick={() => openDocModal(data)}
                            className="btn-upload">
                            Upload
                          </Button>
                        </div>
                        {/* <img
                          src={CheckIco}
                          alt="complete-ico"
                          className="ico-check mx-2"
                        /> */}
                        {/* Delete Confirmation modal */}
                        <p className="mb-0" style={{ lineHeight: '18px' }}>
                          {data.uploadStatus ?? 0}%
                        </p>
                        {data.uploadStatus && (
                          <button
                            className="ico-check mx-2 mb-0 d-none d-sm-block pt-sm-4"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal">
                            <img src={DeleteIco} alt="complete-ico" />
                          </button>
                        )}
                        <div
                          className="modal fade"
                          id="exampleModal"
                          tabIndex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div className="modal-dialog w-25 h-100 d-flex align-items-center text-center">
                            <div className="modal-content">
                              <div className="modal-body">
                                <h2> Confirm Delete </h2>
                                <img
                                  src={RemoveIco}
                                  alt="RemoveIco"
                                  className="w-25 my-3"
                                />
                                <p>
                                  {' '}
                                  Are you sure you want to delete this document?{' '}
                                </p>
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-secondary me-3"
                                    data-bs-dismiss="modal"
                                    onClick={() => handleDeleteDoc()}>
                                    Confirm
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal">
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8 col-12 mx-auto mt-2 pb-3">
                        <p>
                          <strong className="text-danger">
                            Reason of Failure :
                          </strong>{' '}
                          {data.message != null
                            ? data.message
                            : 'No Reason for Failure'}
                        </p>
                      </div>
                    </div>
                  </li>
                );
              })}
              <div className="col-12 text-center">
                {' '}
                <button
                  onClick={handleUploadRejected}
                  className="submit-upload">
                  SUBMIT
                </button>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="modal-rejected">
        <Modal.Header closeButton>
          <h5 className="modal-title" id="staticBackdropLabel">
            Upload
          </h5>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="upload-modal row">
            <p className="mt-3 mb-4">
              Documents uploaded must be within 10MB file size
            </p>
            <div className="col-10 mx-auto">
              <div id="file-upload" className="ms-1">
                <img
                  src={UploadIco}
                  alt="upload-ico"
                  className="upload-ico pt-3"
                />
                {/* <h6> Drag and Drop </h6>
                <p> or hit the “Browse” button </p> */}
                <div className="btn-wrapper">
                  <div className="file file--upload pb-2 mt-3">
                    <label htmlFor="input-file">BROWSE</label>
                    <input
                      id="input-file"
                      type="file"
                      accept=".jpg, .jpeg, .png, .doc, .docx,.pdf"
                      onChange={handleUploadPhoto}
                    />
                  </div>
                  {showErroText != 'The file size is larger than 10MB.' &&
                  showErroText !=
                    'Please upload below mentioned files only.' ? (
                    <span className="text-success upload-file-text">
                      {' '}
                      {documents ? documents.name : ''}
                    </span>
                  ) : (
                    ''
                  )}
                  {showErroText === 'The file size is larger than 10MB.' ||
                  showErroText ===
                    'Please upload below mentioned files only.' ? (
                    <span className="text-danger"> {showErroText} </span>
                  ) : (
                    ''
                  )}
                  <p className="mt-3">
                    {' '}
                    .pdf, .jpg, .png, .docx files can be uploaded{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className="btn-wrapper mt-5 mb-4">
              <button
                disabled={!documents.name}
                className={!documents.name ? 'btn-disable' : 'login-btn'}
                type="button"
                data-bs-dismiss="modal"
                onClick={submitUploadDoc}
                aria-label="hide">
                {' '}
                Submit{' '}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}

export default RejectedDocUpload;
export { uploadDocID };
