import React, { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import Logo from '../../../Assets/Images/logo.svg';
import './nav-bar.scss';
import closeIco from '../../../Assets/Images/close-ico.svg';
import {
  setReceipientReset,
  setSideBarVal,
  setSenderReset,
} from '../../../global/features/data';
import BankIco from '../../../Assets/Images/bank-ico.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuToggleBtn } from '../../../global/features/data';
import AddBankDetails from '../../../Common/add-bank-details/add-bank-details';
import {
  setSimPlanId,
  setClientSecretId,
  setHandlePlan,
  setDeliveryType,
} from '../../../global/features/sim';
import { setWhoWantPay } from '../../../global/features/user';

function NavBar() {
  const location = window.location.pathname;
  const dispatch = useDispatch();
  const sideBarSelected = useSelector((state) => state.data.sidebarVal);
  const toggleMenu = useSelector((state) => state.data.toggleBtn);
  const [sliderShow, setSliderShow] = useState(false);

  // Refactor
  useEffect(() => {
    if (location == '/receipients') {
      dispatch(setSideBarVal('accounts'));
    }
  }, []);

  const sidebarVal = (val) => {
    dispatch(setSideBarVal(val));
    dispatch(setReceipientReset());
    dispatch(setSenderReset());
    dispatch(setSimPlanId());
    dispatch(setClientSecretId([]));
    dispatch(setHandlePlan());
    dispatch(setDeliveryType());
    dispatch(setWhoWantPay(''));
  };
  const openBankDetails = () => {
    setSliderShow(true);
  };
  const handleTogglbtn = () => {
    dispatch(setMenuToggleBtn());
  };
  return (
    <>
      {!toggleMenu ? (
        <>
          <ProSidebar className="d-none d-sm-block">
            <div className="d-flex justify-content-end m-4 d-flex d-sm-none">
              <img
                onClick={handleTogglbtn}
                src={closeIco}
                alt="closeIco"
                style={{ width: '25px', height: '25px' }}
              />
            </div>
            <Link
              to="/"
              className="text-center"
              onClick={() => dispatch(setSideBarVal('home'))}>
              <img
                src={Logo}
                alt="logo"
                className="logo-dashboard mt-sm-5 mb-4 mx-auto"
              />
            </Link>
            <Menu iconShape="square">
              <MenuItem
                className={
                  sideBarSelected === 'home' ? 'homeMenu active' : 'homeMenu'
                }>
                <Link
                  to="/dashboard"
                  onClick={() => sidebarVal('home')}
                  className="d-flex align-items-center justify-content-center login-btn link-btn">
                  Home
                </Link>
              </MenuItem>
              {/* <MenuItem className="d-flex align-items-center cardMenu">
              Apply for Debit Card
              <span className="coming-soon-tag ms-1"> COMING SOON </span>
            </MenuItem> */}
              <MenuItem
                className={
                  sideBarSelected === 'account-verification'
                    ? 'accountVerification active'
                    : 'accountVerification'
                }>
                <Link
                  to="/account-verification"
                  onClick={() => sidebarVal('account-verification')}
                  className="d-flex align-items-center justify-content-center login-btn link-btn">
                  Upload Documents
                </Link>
              </MenuItem>
              <MenuItem
                className={
                  sideBarSelected === 'accounts'
                    ? 'receipentsMenu active'
                    : 'receipentsMenu'
                }>
                <Link
                  to="/receipients"
                  onClick={() => sidebarVal('accounts')}
                  className="d-flex align-items-center justify-content-center login-btn link-btn">
                  Accounts
                </Link>
                <Link
                  to="#"
                  onClick={openBankDetails}
                  style={{
                    position: 'absolute',
                    right: ' 12px',
                    textAlign: 'center',
                    paddingTop: '15px',
                    color: '#2a2a2a !important',
                  }}>
                  <img
                    src={BankIco}
                    alt="bankIco"
                    title="Add Bank Details"
                    style={{ width: '20px' }}
                  />
                  <p style={{ fontSize: '8px', color: '#2a2a2a' }}>
                    Add our bank details
                  </p>
                </Link>
              </MenuItem>
              <MenuItem className="sendMoneyMenu">
                <Link
                  to="/send-money"
                  onClick={() => sidebarVal('send-money')}
                  className="d-flex align-items-center justify-content-center login-btn link-btn">
                  Send Money
                </Link>
              </MenuItem>
              {/* <MenuItem className="welcomePackMenu">Get your Welcome Pack</MenuItem> */}
              <MenuItem
                className={
                  sideBarSelected === 'sim-card'
                    ? 'simCardMenu active'
                    : 'simCardMenu'
                }>
                <Link
                  to="/sim-card"
                  onClick={() => sidebarVal('sim-card')}
                  className="d-flex align-items-center justify-content-center login-btn link-btn">
                  SIM Card
                </Link>
                {/* <span className="coming-soon-tag ms-1"> COMING SOON </span> */}
              </MenuItem>
              <MenuItem
                className={
                  sideBarSelected === 'bank-account'
                    ? 'bankAccount active'
                    : 'bankAccount'
                }>
                <Link
                  to="/bank-account"
                  onClick={() => sidebarVal('bank-account')}
                  className="d-flex align-items-center justify-content-center login-btn link-btn">
                  Bank Account
                </Link>
              </MenuItem>
              <MenuItem className="referFriendMenu">
                {/* <Link
                to="/refer-friend"
                onClick={() => sidebarVal('refer-friend')}
                className="d-flex align-items-center justify-content-center login-btn link-btn">
                Refer a Friend
              </Link> */}
                Refer a Friend
                <span className="coming-soon-tag ms-1"> COMING SOON </span>
              </MenuItem>
            </Menu>
            <div className="d-flex justify-content-start expert-sec flex-column align-items-start pl-1 ms-4 mb-4">
              <p className="w-75">
                Want to know if this is a good time to transfer?
              </p>
              <div>
                <a
                  className="h-button"
                  target="_blank"
                  href="https://wa.me/message/KVW64HQKH7SID1"
                  rel="noreferrer">
                  Ask an expert
                </a>
              </div>
            </div>
          </ProSidebar>
          {/* <a
            className="btn btn-primary"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample">
            Link with href
          </a> */}
          <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
              <Link
                to="/"
                className="text-center"
                onClick={() => dispatch(setSideBarVal('home'))}>
                <img
                  src={Logo}
                  alt="logo"
                  className="logo-dashboard mt-sm-5 mx-auto"
                />
              </Link>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
            </div>
            <div className="offcanvas-body px-0">
              <ProSidebar className="d-inline-block d-sm-none">
                {/* <div className="d-flex justify-content-end m-4 d-flex d-sm-none">
                  <img
                    onClick={handleTogglbtn}
                    src={closeIco}
                    alt="closeIco"
                    style={{ width: '25px', height: '25px' }}
                  />
                </div> */}
                <Menu iconShape="square">
                  <MenuItem
                    className={
                      sideBarSelected === 'home'
                        ? 'homeMenu active'
                        : 'homeMenu'
                    }>
                    <Link
                      to="/dashboard"
                      onClick={() => sidebarVal('home')}
                      className="d-flex align-items-center justify-content-center login-btn link-btn">
                      Home
                    </Link>
                  </MenuItem>
                  {/* <MenuItem className="d-flex align-items-center cardMenu">
                  Apply for Debit Card
                  <span className="coming-soon-tag ms-1"> COMING SOON </span>
                </MenuItem> */}
                  <MenuItem
                    className={
                      sideBarSelected === 'account-verification'
                        ? 'accountVerification active'
                        : 'accountVerification'
                    }>
                    <Link
                      to="/account-verification"
                      onClick={() => sidebarVal('account-verification')}
                      className="d-flex align-items-center justify-content-center login-btn link-btn">
                      Upload Documents
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className={
                      sideBarSelected === 'accounts'
                        ? 'receipentsMenu active'
                        : 'receipentsMenu'
                    }>
                    <Link
                      to="/receipients"
                      onClick={() => sidebarVal('accounts')}
                      className="d-flex align-items-center justify-content-center login-btn link-btn">
                      Accounts
                    </Link>
                    <Link
                      to="#"
                      onClick={openBankDetails}
                      style={{
                        position: 'absolute',
                        right: ' 12px',
                        textAlign: 'center',
                        paddingTop: '15px',
                        color: '#2a2a2a !important',
                      }}>
                      <img
                        src={BankIco}
                        alt="bankIco"
                        title="Add Bank Details"
                        style={{ width: '20px' }}
                      />
                      <p style={{ fontSize: '8px', color: '#2a2a2a' }}>
                        {' '}
                        Add our bank details{' '}
                      </p>
                    </Link>
                  </MenuItem>
                  <MenuItem className="sendMoneyMenu">
                    <Link
                      to="/send-money"
                      onClick={() => sidebarVal('send-money')}
                      className="d-flex align-items-center justify-content-center login-btn link-btn">
                      Send Money
                    </Link>
                  </MenuItem>
                  {/* <MenuItem className="welcomePackMenu">Get your Welcome Pack</MenuItem> */}
                  <MenuItem
                    className={
                      sideBarSelected === 'sim-card'
                        ? 'simCardMenu active'
                        : 'simCardMenu'
                    }>
                    <Link
                      to="/sim-card"
                      onClick={() => sidebarVal('sim-card')}
                      className="d-flex align-items-center justify-content-center login-btn link-btn">
                      SIM Card
                    </Link>
                    {/* <span className="coming-soon-tag ms-1"> COMING SOON </span> */}
                  </MenuItem>
                  <MenuItem className="referFriendMenu">
                    {/* <Link
                    to="/refer-friend"
                    onClick={() => sidebarVal('refer-friend')}
                    className="d-flex align-items-center justify-content-center login-btn link-btn">
                    Refer a Friend
                  </Link> */}
                    Refer a Friend
                    <span className="coming-soon-tag ms-1"> COMING SOON </span>
                  </MenuItem>
                </Menu>
                <div className="d-flex justify-content-start expert-sec flex-column align-items-start pl-1 ms-4 mb-4">
                  <p className="w-75">
                    Want to know if this is a good time to transfer?
                  </p>
                  <div>
                    <a
                      className="h-button"
                      target="_blank"
                      href="https://wa.me/message/KVW64HQKH7SID1"
                      rel="noreferrer">
                      Ask an expert
                    </a>
                  </div>
                </div>
              </ProSidebar>
            </div>
          </div>
          <AddBankDetails
            sliderShow={sliderShow}
            setSliderShow={setSliderShow}
          />
        </>
      ) : (
        ''
      )}
    </>
  );
}

export default NavBar;
