import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDocs,
  fetchRecipients,
  fetchTransactions,
} from '../../global/features/data';
import { transactionDate } from '../../Util/functions';
import Header from '../../Common/Pages/header/header';
import './dashboard.scss';
import HouseIco from '../../Assets/Images/house.svg';
import GraduateIco from '../../Assets/Images/graduate-cap.svg';
import BankIco from '../../Assets/Images/bank.svg';
import TravelingAd from '../../Assets/Images/young-adults-traveling-london .svg';
import CompleteVerificationIco from '../../Assets/Images/complete-verification.svg';
import NoRecipient from '../../Assets/Images/no-recipient.svg';
import SendMoney from '../../Assets/Images/send-money-dashboard.svg';
import PaidTo from '../../Assets/Images/paid-to.svg';
import PendingIco from '../../Assets/Images/pending.svg';
import ProcessingIco from '../../Assets/Images/processing.png';
import AdditionalIco from '../../Assets/Images/additional-docs.svg';
import RejectedIco from '../../Assets/Images/rejected.svg';
import SentIco from '../../Assets/Images/sent.svg';
import ProfileIco from '../../Assets/Images/profile-img.png';
import Flag1 from '../../Assets/Images/united-kingdom.svg';
import Flag2 from '../../Assets/Images/india.svg';
import Arrow from '../../Assets/Images/vector-2.svg';
import Trend from '../../Assets/Images/trend.svg';
import Footer from '../../Common/Pages/footer/footer';
import NavBar from '../../Common/Pages/NavBar/nav-bar';
import RejectedDocUpload from '../upload-document/rejected-doc-upload/rejected-doc-upload';
import { Modal, Button } from 'react-bootstrap';
import { ProgressBar } from 'react-bootstrap';
import UploadIco from '.././../Assets/Images/upload.svg';
import MoneyModal from '../../Common/Pages/money-modal/money-modal';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';
import StatusModal from '../../Common/Pages/status/status';
import InfoIco from '../../Assets/Images/info.png';
import TickIco from '../../Assets/Images/check-ico-2.svg';
import AddBankDetails from '../../Common/add-bank-details/add-bank-details';
import { setSideBarVal } from '../../global/features/data';

function Dashboard() {
  const dispatch = useDispatch();
  const [headerSecond, setHeaderSecond] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const userDetails = useSelector((state) => state.user);
  const rates = useSelector((state) => state.calculator.rates);
  const token = userDetails.user?.tokens?.access;
  const [loader, setLoader] = useState(false);
  const toggleMenu = useSelector((state) => state.data.toggleBtn);
  const [sliderShow, setSliderShow] = useState(false);
  const { user, docs, recipients, transactions } = useSelector((state) => ({
    user: state.user.user,
    docs: state.data.docs,
    recipients: state.data.recipients,
    transactions: state.data.transactions,
  }));
  const [showStatusModal, setShowStatusModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      dispatch(fetchDocs());
      dispatch(fetchRecipients());
      dispatch(fetchTransactions());
    }
  }, [user.tokens.access]);
  useEffect(() => {
    // console.log('SliderShow: ', sliderShow);
  }, []);
  const renderCases = () => {
    if (docs.loading || recipients.loading || transactions.loading) {
      return <p>loading...</p>;
    }

    const docVerified = Object.keys(docs.data.status).find(
      (key) => docs.data.status[key] === 'approved',
    );
    const docsRejected = Object.keys(docs.data.status).find(
      (key) => docs.data.status[key] === 'rejected',
    );
    const docsPending = Object.keys(docs.data.status).find(
      (key) =>
        docs.data.status[key] === 'pending' && !docs.data.incomplete[key],
    );

    let bar;

    if (docVerified) {
      bar = (
        <div>
          <p
            style={{
              background: 'rgba(213, 246, 235, 0.77)',
              padding: '15px',
              paddingTop: '5px',
              paddingBottom: '5px',
              borderRadius: '5px',
              fontSize: '15px',
              fontWeight: 600,
              color: '#3BA885',
            }}>
            {docVerified.charAt(0).toUpperCase() + docVerified.slice(1)} Account
            has been verified
          </p>
        </div>
      );
    }

    if (docsPending) {
      bar = (
        <div>
          <p
            style={{
              background: 'rgba(246, 239, 213, 0.77)',
              padding: '15px',
              paddingTop: '5px',
              paddingBottom: '5px',
              borderRadius: '5px',
              fontSize: '15px',
              fontWeight: 600,
              color: 'rgb(168, 115, 59)',
            }}>
            {docsPending.charAt(0).toUpperCase() + docsPending.slice(1)} Account
            is being verified
          </p>
        </div>
      );
    }

    if (docsRejected) {
      bar = (
        <div onClick={() => navigate('/account-verification')}>
          <p
            style={{
              background: 'rgba(246, 213, 213, 0.77)',
              padding: '15px',
              paddingTop: '5px',
              paddingBottom: '5px',
              borderRadius: '5px',
              fontSize: '15px',
              fontWeight: 600,
              cursor: 'pointer',
              color: 'rgb(168, 59, 59)',
            }}>
            {docsRejected.charAt(0).toUpperCase() + docsRejected.slice(1)}{' '}
            Account has been rejected
          </p>
        </div>
      );
    }

    const openBankDetails = () => {
      setSliderShow(true);
    };

    if (!docVerified) {
      return (
        <div>
          {bar}
          <div className="d-flex justify-content-center">
            <img
              src={CompleteVerificationIco}
              alt="complete-account-verification"
            />
          </div>
          <h5 className="text-center mt-4">
            Complete one time identity verification now
          </h5>
          <p className="py-2" style={{ fontSize: '18px', textAlign: 'center' }}>
            To transact, add our bank details.
            <Link
              to="#"
              onClick={openBankDetails}
              style={{ color: '#3BA885', fontWeight: '600' }}>
              See How
            </Link>
          </p>
          <div className="d-flex justify-content-center mt-3 mb-1 btn-wrapper">
            <Link to="/account-verification" style={{ textDecoration: 'none' }}>
              <button className="p-4 login-btn next-btn d-flex justify-content-center align-items-center">
                COMPLETE VERIFICATION NOW
              </button>
            </Link>
          </div>
          <AddBankDetails
            sliderShow={sliderShow}
            setSliderShow={setSliderShow}
          />
        </div>
      );
    }

    if (docVerified && recipients.data.recipients.length === 0) {
      return (
        <div>
          {/* <div>
            <p
              style={{
                background: 'rgba(213, 246, 235, 0.77)',
                padding: '15px',
                borderRadius: '5px',
                fontSize: '18px',
                fontWeight: 600,
                color: '#3BA885',
              }}>
              <img
                src={TickIco}
                alt="tick-ico"
                className="me-3"
                style={{ width: '32px', height: '32px' }}
              />
              {docVerified.charAt(0).toUpperCase() + docVerified.slice(1)}{' '}
              Account - {user.attributes['custom:firstName']}{' '}
              {user.attributes['custom:lastName']} has been successfully
              verified
            </p>
          </div> */}
          {bar}
          <div className="row">
            <div className="d-flex justify-content-center align-items-start col-3">
              <img
                src={NoRecipient}
                alt="no-recipient"
                className="mt-3 w-100"
              />
            </div>
            <div className="col-6">
              <div className="col-12">
                <h5 className="mt-4">
                  Add recipients to start making payments
                </h5>
              </div>
              <p className="py-2" style={{ fontSize: '18px' }}>
                To transact, add our bank details.
                <Link
                  to=""
                  onClick={openBankDetails}
                  style={{ color: '#3BA885', fontWeight: '600' }}>
                  See How
                </Link>
              </p>
              <div className="d-flex justify-content-start mt-3 mb-1 mt-2 btn-wrapper">
                <Link to="/receipients" style={{ textDecoration: 'none' }}>
                  <button className="p-4 login-btn next-btn d-flex justify-content-center align-items-center">
                    GO TO ACCOUNTS
                  </button>
                </Link>
              </div>
              <AddBankDetails
                sliderShow={sliderShow}
                setSliderShow={setSliderShow}
              />
            </div>
          </div>
        </div>
      );
    }

    if (docVerified && transactions.data.remittances.length === 0) {
      return (
        <div>
          {/* <div>
            <p
              style={{
                background: 'rgba(213, 246, 235, 0.77)',
                padding: '15px',
                borderRadius: '5px',
                fontSize: '18px',
                fontWeight: 600,
                color: '#3BA885',
              }}>
              <img
                src={TickIco}
                alt="tick-ico"
                className="me-3"
                style={{ width: '32px', height: '32px' }}
              />
              {docVerified.charAt(0).toUpperCase() + docVerified.slice(1)}{' '}
              Account - {user.attributes['custom:firstName']}{' '}
              {user.attributes['custom:lastName']} has been successfully
              verified
            </p>
          </div> */}
          {bar}

          <div className="row">
            <div className="d-flex justify-content-center align-items-start col-12 col-sm-3">
              <img src={SendMoney} alt="no-recipient" className="mt-3 w-100" />
            </div>
            <div className="col-12 col-sm-8 text-center text-sm-start">
              <div className="col-12">
                <h5 className="mt-4">
                  To transact, you need to add our bank details to your
                  netbanking.{' '}
                </h5>
              </div>
              <p className="py-2" style={{ fontSize: '18px' }}>
                To transact, add our bank details.
                <Link
                  to=""
                  onClick={openBankDetails}
                  style={{ color: '#3BA885', fontWeight: '600' }}>
                  See How
                </Link>
              </p>
              <div className="d-flex justify-content-sm-start justify-content-center mb-3 mt-2 btn-wrapper">
                <Link to="/send-money" style={{ textDecoration: 'none' }}>
                  <button className="p-4 login-btn next-btn d-flex justify-content-center align-items-center">
                    Send Money
                  </button>
                </Link>
              </div>
              <AddBankDetails
                sliderShow={sliderShow}
                setSliderShow={setSliderShow}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="col-12 table-responsive">
        <table className="table transactions-table">
          <thead>
            <tr>
              <th scope="col">
                <span>Last</span>
                <br /> 30 Days
              </th>
              <th scope="col">
                <span>TRANSACTIONS</span>
                <br />
                {transactions.data.total.count}
              </th>
              <th scope="col">
                <span>TOTAL TRANSFER</span>
                <br />£{transactions.data.total.sum.toFixed(2)}
              </th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {transactions.data.remittances.slice(0, 5).map((transaction) => {
              let className = 'done';
              let imgElm = null;
              const additionalDocsSize = transaction.additional_docs
                ? transaction.additional_docs.filter((d) => !d?.location).length
                : 0;

              const data = { ...transaction };

              if (transaction.status === 'CANCELLED') {
                className = 'cancelled';
              } else if (
                ['AMOUNT_RECEIVED', 'AMOUNT_TRANSFERRED'].includes(
                  transaction.status,
                )
              ) {
                className = 'pending';
                imgElm = (
                  <img
                    src={ProcessingIco}
                    alt="pending"
                    height="40"
                    width="40"
                  />
                );
              } else if (
                !transaction.proof &&
                transaction.status !== 'AMOUNT_CREDITED'
              ) {
                className = 'warning';
                imgElm = (
                  <img src={PendingIco} alt="pending" height="45" width="45" />
                );
                data.title = 'Upload Proof of Transfer';
                data.action = 'UPLOAD_PROOF';
              } else if (
                additionalDocsSize > 0 &&
                transaction.status !== 'AMOUNT_CREDITED'
              ) {
                className = 'warning';
                imgElm = (
                  <img
                    src={AdditionalIco}
                    alt="additional-docs"
                    height="45"
                    width="45"
                  />
                );
                data.title = 'Additional Documents Required';
                data.action = 'UPLOAD_ADDITIONAL_DOCS';
              } else if (
                transaction?.proof &&
                transaction.proof.status === 'rejected' &&
                transaction.status !== 'AMOUNT_CREDITED'
              ) {
                className = 'error';
                imgElm = (
                  <img
                    src={RejectedIco}
                    alt="rejected"
                    height="45"
                    width="45"
                  />
                );
                data.title = 'Transfer Failed';
                data.action = 'UPLOAD_REJECTED_PROOF';
              } else if (transaction.status !== 'AMOUNT_CREDITED') {
                className = 'pending';
                imgElm = (
                  <img
                    src={ProcessingIco}
                    alt="pending"
                    height="40"
                    width="40"
                  />
                );
              }

              data.className = className;

              return (
                <tr
                  key={transaction.remittance_id}
                  onClick={() => {
                    if (className === 'error' || className === 'warning') {
                      setShowModal(true);
                      setSelectedTransaction(data);
                    }
                  }}
                  className={className}>
                  <td scope="row" className="col-first">
                    {transactionDate(new Date(transaction.createdAt))}
                  </td>
                  <td className="d-flex">
                    <img src={PaidTo} alt="paid-to" className="me-2" />
                    <div>
                      <span>Paid to</span>
                      <br />
                      {transaction.recipient
                        ? transaction?.recipient?.account_holder_name
                        : 'Deleted Recipient'}
                    </div>
                  </td>
                  <td>
                    <span>₹{transaction.currency.from.amount}</span>
                    <br />£{transaction.currency.to.amount.toFixed(2)}
                  </td>
                  <td>
                    {imgElm}
                    <img
                      src={InfoIco}
                      height={35}
                      width={35}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedTransaction(data);
                        setShowStatusModal(true);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const payFees = async () => {
    setLoader(true);
    let statusUploadDoc = {};
    let statusReceipientDoc = {};

    let axiosToken = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
      },
    };
    try {
      statusUploadDoc = await axios.get(
        `${process.env.REACT_APP_API_URL}/files`,
        axiosToken,
      );
      statusReceipientDoc = await axios.get(
        `${process.env.REACT_APP_API_URL}/recipient`,
        axiosToken,
      );
    } catch (error) {
      // console.log(error);
    }
    if (
      statusUploadDoc.data?.status?.father === 'approved' ||
      statusUploadDoc.data?.status?.mother === 'approved' ||
      statusUploadDoc.data?.status?.spouse === 'approved' ||
      statusUploadDoc.data?.status?.own === 'approved'
    ) {
      if (statusReceipientDoc.data?.count?.university === 0) {
        dispatch(setSideBarVal('accounts'));
        navigate('/receipients');
        setLoader(false);
      } else {
        navigate('/send-money');
        setLoader(false);
      }
    } else {
      navigate('/account-verification');
      setLoader(false);
    }
  };
  const transferMoney = async () => {
    setLoader(true);
    let statusUploadDoc = {};
    let statusReceipientDoc = {};

    let axiosToken = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
      },
    };
    try {
      statusUploadDoc = await axios.get(
        `${process.env.REACT_APP_API_URL}/files`,
        axiosToken,
      );
      statusReceipientDoc = await axios.get(
        `${process.env.REACT_APP_API_URL}/recipient`,
        axiosToken,
      );
    } catch (error) {
      // console.log(error);
    }
    if (
      statusUploadDoc.data?.status?.father === 'approved' ||
      statusUploadDoc.data?.status?.mother === 'approved' ||
      statusUploadDoc.data?.status?.spouse === 'approved' ||
      statusUploadDoc.data?.status?.own === 'approved'
    ) {
      if (statusReceipientDoc.data?.count?.own === 0) {
        navigate('/receipients');
        setLoader(false);
      } else {
        navigate('/send-money');
        setLoader(false);
      }
    } else {
      navigate('/account-verification');
      setLoader(false);
    }
  };
  const payRent = async () => {
    setLoader(true);
    let statusUploadDoc = {};
    let statusReceipientDoc = {};

    let axiosToken = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
      },
    };
    try {
      statusUploadDoc = await axios.get(
        `${process.env.REACT_APP_API_URL}/files`,
        axiosToken,
      );
      statusReceipientDoc = await axios.get(
        `${process.env.REACT_APP_API_URL}/recipient`,
        axiosToken,
      );
    } catch (error) {
      // console.log(error);
    }
    if (
      statusUploadDoc.data?.status?.father === 'approved' ||
      statusUploadDoc.data?.status?.mother === 'approved' ||
      statusUploadDoc.data?.status?.spouse === 'approved' ||
      statusUploadDoc.data?.status?.own === 'approved'
    ) {
      if (statusReceipientDoc.data?.count?.rent === 0) {
        navigate('/receipients');
        setLoader(false);
      } else {
        navigate('/send-money');
        setLoader(false);
      }
    } else {
      navigate('/account-verification');
      setLoader(false);
    }
  };
  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 px-0 sidebar-container">
              <NavBar />
            </div>
            <div className="col-12 col-sm-9 right-wrapper px-0">
              <Header headerSecond={headerSecond} />
              <div className="content-dashboard">
                <div className="d-sm-none d-flex align-items-center justify-content-end currency-status ps-0">
                  <Link
                    to="/send-money"
                    style={{ color: '#2a2a2a', textDecoration: 'none' }}>
                    <div className="d-flex align-items-center inner-data">
                      <img src={Flag1} alt="flag" className="me-2" />
                      <h6 className="mb-0">
                        {' '}
                        <strong>£</strong>1{' '}
                      </h6>
                    </div>
                  </Link>
                  <h6 className="mb-0 mx-1 mx-sm-3">=</h6>
                  <Link
                    to="/send-money"
                    style={{ color: '#2a2a2a', textDecoration: 'none' }}>
                    <div className="d-flex align-items-center inner-data">
                      <img src={Flag2} alt="flag" className="me-2" />
                      <h6 className="mb-0">
                        {' '}
                        <strong>₹</strong>
                        {(1 / rates.GBP).toFixed(2) || 'loading...'}{' '}
                      </h6>
                    </div>
                  </Link>
                </div>
                <h1 className="pt-2 pt-sm-4 pb-2"> Home </h1>
                <div className="row me-sm-0">
                  <div className="col-12 col-sm-4 text-center">
                    <div className="rent-sec py-2 py-sm-4">
                      <div className="d-flex d-sm-block align-items-center ps-3 ps-sm-3">
                        <img src={GraduateIco} alt="houseIco" />
                        <h2 className="mt-3 ps-3 p-sm-0">Fees</h2>
                        <Button
                          onClick={payFees}
                          className="d-flex d-sm-none align-items-center justify-content-center pay-fees-btn">
                          PAY FEES
                        </Button>
                      </div>
                      <p className="mx-sm-auto text-start text-sm-center px-3 px-sm-0 pt-3 pt-sm-0">
                        Pay your university fees directly from your Indian bank
                        A/C in few hours
                      </p>
                      <div className="btn-wrapper">
                        <Button
                          onClick={payFees}
                          className="mt-4 p-4 d-none d-sm-flex align-items-center justify-content-center login-btn link-btn">
                          PAY FEES
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4 my-4 my-sm-0 text-center">
                    <div className="rent-sec py-2 py-sm-4">
                      <div className="d-flex d-sm-block align-items-center ps-3 ps-sm-3">
                        <img
                          src={BankIco}
                          alt="houseIco"
                          className="ps-1 ps-sm-0"
                        />
                        <h2 className="mt-3 ps-2 ps-sm-0">Your Bank</h2>
                        <Button
                          onClick={transferMoney}
                          className="d-flex d-sm-none align-items-center justify-content-center pay-fees-btn">
                          TRANSFER
                          <span className="d-none d-sm-block">MONEY</span>
                        </Button>
                      </div>
                      <p className="mx-sm-auto text-start text-sm-center px-3 px-sm-0 pt-3 pt-sm-0">
                        Move your money from India to your bank A/C in UK in
                        just few clicks
                      </p>
                      <div className="btn-wrapper">
                        <Button
                          onClick={transferMoney}
                          className="mt-4 p-4 d-none d-sm-flex align-items-center justify-content-center login-btn link-btn">
                          TRANSFER MONEY
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4 text-center">
                    <div className="rent-sec py-2 py-sm-4">
                      <div className="d-flex d-sm-block align-items-center ps-3 ps-sm-3">
                        <img src={HouseIco} alt="houseIco" />
                        <h2 className="mt-3 ps-3 p-sm-0">Rent</h2>
                        <Button
                          onClick={payRent}
                          className="d-flex d-sm-none align-items-center justify-content-center pay-fees-btn">
                          PAY RENT
                        </Button>
                      </div>
                      <p className="mx-sm-auto text-start text-sm-center px-3 px-sm-0 pt-3 pt-sm-0">
                        Now pay rent to you landlord directly from your bank A/c
                        in India.
                      </p>
                      <div className="btn-wrapper">
                        <Button
                          onClick={payRent}
                          className="mt-4 p-4 d-none d-sm-flex align-items-center justify-content-center login-btn link-btn">
                          PAY RENT
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mx-0 mt-4 mt-sm-3">
                  <div className="col-12 mb-4 text-end d-none d-sm-block">
                    <a
                      style={{ color: '#2bb085', fontWeight: '600' }}
                      href={require('../../Assets/Images/Remigos-UK-E-book.pdf')}
                      download="Remigos-UK-E-book">
                      Download UK E-Book
                    </a>
                  </div>
                  <div className="col-12 col-sm-8 table-wrapper mb-4 mb-sm-0">
                    <div className="row pt-4 inner-table-heading">
                      <div
                        className="col-6 mb-4"
                        style={{ borderBottom: '1px solid #EADED6' }}>
                        <h1> Transactions </h1>
                      </div>
                      <div
                        className="col-6 mb-4 text-end"
                        style={{ borderBottom: '1px solid #EADED6' }}>
                        {transactions.data?.remittances.length > 5 && (
                          <div className="pe-0 h-100 text-end sell-all-text d-flex align-items-center justify-content-end">
                            <a href="/transactions"> SEE ALL </a>
                          </div>
                        )}
                      </div>
                      {renderCases()}
                    </div>
                  </div>
                  <div className="col-12 col-sm-4 right-ad-wrapper text-end text-sm-center">
                    <a
                      style={{ color: '#2bb085', fontWeight: '600' }}
                      className="d-block d-sm-none"
                      href={require('../../Assets/Images/Remigos-UK-E-book.pdf')}
                      download="Remigos-UK-E-book">
                      Download UK E-Book
                    </a>
                    <img
                      src={TravelingAd}
                      className="ad-img mt-3 mt-sm-0"
                      alt="ad-post"
                    />
                  </div>
                </div>
              </div>

              {selectedTransaction && (
                <MoneyModal
                  show={showModal}
                  title={selectedTransaction?.title}
                  handleClose={() => setShowModal(false)}
                  onDoneClick={() => {
                    dispatch(fetchTransactions());
                    setShowModal(false);
                  }}
                  transactionData={{
                    fromAmt: selectedTransaction?.currency.from.amount,
                    toAmt: selectedTransaction?.currency.to.amount,
                    createdAt: new Date(selectedTransaction?.createdAt),
                    recipientName:
                      selectedTransaction?.recipient.account_holder_name,
                    className: selectedTransaction?.className,
                    remittance_id: selectedTransaction?.remittance_id,
                    action: selectedTransaction?.action,
                  }}
                  docsData={
                    selectedTransaction?.action === 'UPLOAD_PROOF' ||
                    selectedTransaction?.action === 'UPLOAD_REJECTED_PROOF'
                      ? [
                          {
                            field: selectedTransaction.proof?.field || 'proof',
                            label: 'Proof Of Transfer',
                            location: selectedTransaction.proof?.location,
                            originalname:
                              selectedTransaction.proof?.originalname,
                            _id: selectedTransaction.proof?._id,
                            status: selectedTransaction.proof?.status,
                            message: selectedTransaction.proof?.message,
                          },
                        ]
                      : selectedTransaction?.additional_docs.map((doc) => ({
                          field: doc.field,
                          label: doc.label,
                          _id: doc._id,
                          location: doc.location,
                          originalname: doc.originalname,
                        }))
                  }
                />
              )}

              {selectedTransaction && (
                <StatusModal
                  show={showStatusModal}
                  title="Transaction Details"
                  transactionData={selectedTransaction}
                  handleClose={() => setShowStatusModal(false)}
                />
              )}
              <div className="mt-5">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader-wrapper d-flex align-items-center justify-content-center">
          <BallTriangle
            height="100"
            width="100"
            color="#45C29A"
            ariaLabel="loading"
          />
        </div>
      )}
    </>
  );
}

export default Dashboard;
