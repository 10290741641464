export const universities = [
  'London Business School',
  'The University of Cambridge',
  'The University of Oxford',
  'University College London',
  'The University of Greenwich',
  'University College Birmingham',
  'The University of Aberdeen',
  'University of Abertay Dundee',
  'Aberystwyth University',
  'Anglia Ruskin University',
  'Aston University',
  'Bangor University',
  'Bath Spa University',
  'The University of Bath',
  'University of Bedfordshire',
  "The Queen's University of Belfast",
  'Birkbeck College',
  'Birmingham City University',
  'The University of Birmingham',
  'Bishop Grosseteste University',
  'The University of Bolton',
  'The Arts University Bournemouth',
  'Bournemouth University',
  'The University of Bradford',
  'The University of Brighton',
  'The University of Bristol',
  'Brunel University London',
  'The University of Buckingham',
  'Buckinghamshire New University',
  'The Institute of Cancer Research',
  'Canterbury Christ Church University',
  'Cardiff University',
  'Cardiff Metropolitan University',
  'The University of Central Lancashire',
  'University of Chester ',
  'The University of Chichester',
  'The City University',
  'Conservatoire for Dance and Drama',
  'Courtauld Institute of Art',
  'Coventry University',
  'Cranfield University',
  'University for the Creative Arts ',
  'University of Cumbria',
  'De Montfort University',
  'University of Derby',
  'The University of Dundee',
  'University of Durham',
  'The University of East Anglia',
  'The University of East London',
  'Edge Hill University',
  'The University of Edinburgh',
  'The University of Essex',
  'The University of Exeter',
  'Falmouth University',
  'Glasgow Caledonian University',
  'Glasgow School of Art',
  'The University of Glasgow',
  'University of Gloucestershire',
  'Glynd?r University',
  'Goldsmiths College',
  'Guildhall School of Music and Drama',
  'Harper Adams University',
  'Heriot-Watt University',
  'University of Hertfordshire',
  'Heythrop College',
  'The University of Huddersfield',
  'The University of Hull',
  '"Imperial College of Science',
  'Institute of Education',
  'The University of Keele',
  'The University of Kent',
  "King's College London",
  'Kingston University',
  'Leeds College of Art ',
  'Liverpool School of Tropical Medicine',
  'University of Wales Trinity Saint David',
  'The University of Lancaster',
  'Leeds Beckett University',
  'Leeds Trinity University',
  'The University of Leeds',
  'The University of Leicester',
  'The University of Lincoln',
  'Liverpool Hope University',
  'The Liverpool Institute for Performing Arts ',
  'Liverpool John Moores University',
  'The University of Liverpool',
  'University of London (Institutes and activities)',
  'London Metropolitan University',
  'London School of Economics and Political Science',
  'London School of Hygiene and Tropical Medicine',
  '"University of the Arts',
  'Loughborough University',
  'The Manchester Metropolitan University',
  'The University of Manchester',
  'Middlesex University',
  'Edinburgh Napier University',
  'University of Newcastle-upon-Tyne',
  'Newman University',
  'The University of Northampton',
  'University of Northumbria at Newcastle',
  'Norwich University of the Arts',
  'The Nottingham Trent University',
  'University of Nottingham',
  'Oxford Brookes University',
  'Plymouth College of Art',
  'University of Plymouth',
  'The University of Portsmouth',
  '"Queen Margaret University',
  'Queen Mary University of London',
  'Ravensbourne',
  'The University of Reading',
  'The Robert Gordon University',
  'Roehampton University ',
  'Rose Bruford College',
  'Royal Academy of Music',
  'Royal Agricultural University',
  'The Royal Central School of Speech and Drama',
  'Royal College of Art',
  'Royal College of Music',
  'Royal Conservatoire of Scotland',
  'Royal Holloway and Bedford New College',
  'Royal Northern College of Music',
  'The Royal Veterinary College',
  'SRUC',
  'The University of St Andrews',
  "St George's Hospital Medical School",
  'University of St Mark and St John',
  "St Mary's University College",
  '"St Mary\'s University',
  'The University of Salford',
  'The School of Oriental and African Studies',
  'Sheffield Hallam University',
  'The University of Sheffield',
  'London South Bank University',
  'University of South Wales',
  'Southampton Solent University',
  'The University of Southampton',
  'Staffordshire University',
  'The University of Stirling',
  'Stranmillis University College',
  'The University of Strathclyde',
  'The University of Sunderland',
  'The University of Surrey',
  'The University of Sussex',
  'Swansea University',
  'The National Film and Television School',
  'Teesside University',
  'The University of West London ',
  'The Open University',
  'The University of Wales',
  'Trinity Laban Conservatoire of Music and Dance',
  'University of the Highlands and Islands',
  'University of Ulster',
  'University Campus Suffolk',
  'The University of Warwick',
  'University of the West of England',
  'The University of the West of Scotland',
  'The University of Westminster',
  'The University of Winchester',
  'The University of Wolverhampton',
  'University of Worcester',
  'Writtle College',
  'York St John University',
  'The University of York',
];

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
