import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import { Auth } from 'aws-amplify';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Link, useNavigate } from 'react-router-dom';

// @ts-ignore
import logo from '../../Assets/Images/logo.svg';
// @ts-ignore
import CloseIco from '../../Assets/Images/close-ico.svg';
import ThankYou from '../../Common/Pages/thank-you/thank-you';
import 'react-step-progress-bar/styles.css';
import CheckEligibility from '../check-eligibility/check-eligibility';
import SignupForm from '../signup-form/signup-form';
import OTP from '../../Common/Pages/otp/otp';
import MultiProgressBar from '../../Common/Pages/multiProgressBar/multi-progress-bar';
import { RootState } from '../../global';
import { setUser } from '../../global/features/user';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';

interface FormTypes {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  phone: string;
  birthDate: string;
  country: string;
  city: string;
  state: string;
  zip: string;
  address: string;
  other: string;
  countryCode: string;
}

interface EligibilityForm {
  passport: string;
  university: string;
  currentLocation: 'India' | 'Outside India';
}

const validate = (values: FormTypes) => {
  const errors: { [id: string]: string } = {};

  if (values.firstName.trim().length < 2) {
    errors.firstName = 'Name is required';
  }

  if (values.lastName.trim().length < 2) {
    errors.lastName = 'Name is required';
  }

  if (!isEmail(values.email)) {
    errors.email = 'Please enter a valid email';
  }

  if (values.password.trim().length < 6) {
    errors.password = 'Password must be at least 6 characters';
  }

  if (values.password !== values.confirmPassword || !values.confirmPassword) {
    errors.confirmPassword = "Passwords don't match";
  }

  if (values.phone.trim().length < 10) {
    errors.phone = 'Please enter a valid phone number';
  }

  if (!parsePhoneNumber(values.phone)?.countryCallingCode) {
    errors.phone = 'Please enter a valid country code';
  }

  if (values.address.trim().length < 4) {
    errors.address = 'Please enter a valid address';
  }

  if (isNaN(Date.parse(values.birthDate))) {
    errors.birthDate = 'Please enter a valid date';
  }

  if (values.zip.trim().length < 5) {
    errors.zip = 'Please enter a valid zip code';
  }

  if (values.country === '') {
    errors.country = 'Please select a country';
  }

  if (values.state === '') {
    errors.state = 'Please select a state';
  }

  if (values.city === '') {
    errors.city = 'Please select a city';
  }

  return errors;
};

const checkEligibilityForm = (values: EligibilityForm) => {
  const errors: { [id: string]: string } = {};

  if (values.university === '') {
    errors.university = 'Please select a university';
  }

  if (values.passport === '') {
    errors.passport = 'Please select a passport';
  } else if (values.passport !== 'India') {
    errors.passport = 'We currently support India passport only';
  }

  return errors;
};

const Signup = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [otp, setOTP] = useState('');
  const [otpError, setOtpError] = useState<string | null>(null);
  const isLoggedIn = useSelector((state: RootState) => state?.user?.islogged);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const eligibilityForm = useFormik<EligibilityForm>({
    initialValues: {
      passport: 'India',
      university: 'London Business School',
      currentLocation: 'India',
    },
    validate: checkEligibilityForm,
    onSubmit: async (values) => {
      if (currentStep === 1) {
        if (values.university === '' || values.passport === '') {
          return;
        }

        if (values.passport !== 'India') {
          return;
        }

        setCurrentStep((prevState) => {
          return prevState + 1;
        });
      }
    },
  });

  const userForm = useFormik<FormTypes>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      address: '',
      confirmPassword: '',
      phone: '',
      birthDate: '',
      country: 'India',
      city: '',
      state: '',
      zip: '',
      other: '',
      countryCode: '+91',
    },
    validate,
    onSubmit: async (values, { setErrors }) => {
      try {
        const phoneParts = parsePhoneNumber(values.phone);
        await Auth.signUp({
          username: values.email,
          password: values.password,
          attributes: {
            email: values.email,
            'custom:firstName': values.firstName,
            'custom:lastName': values.lastName,
            'custom:countryCode': `+${phoneParts?.countryCallingCode}`,
            'custom:phone': phoneParts?.nationalNumber,
            'custom:dob': values.birthDate,
            'custom:country': values.country,
            'custom:state': values.state,
            'custom:address': values.address,
            'custom:city': values.city,
            'custom:zip': values.zip,
            'custom:passport': eligibilityForm.values.passport,
            'custom:university': eligibilityForm.values.university,
            'custom:currentLocation': eligibilityForm.values.currentLocation,
          },
        });

        setCurrentStep(3);
      } catch (e) {
        // console.log(e);
        if (e.code === 'UsernameExistsException') {
          setErrors({ email: 'Account with this email already exists' });
          return;
        }
        if (e.code === 'TooManyRequestsException') {
          setErrors({ other: 'Too many requests! Please try again later' });
          return;
        }

        setErrors({ other: 'Something went wrong! Please try again later' });
      }
    },
  });

  const onResendOTPClick = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();

    try {
      await Auth.resendSignUp(userForm.values.email);

      toast.info('code sent successfully');
    } catch (e) {
      setOtpError('Something went wrong! Please try again later');
    }
  };

  const previousButton = () => {
    setCurrentStep((prevState) => {
      return prevState - 1;
    });
  };

  const nextButton = async () => {
    if (currentStep === 1) {
      await eligibilityForm.submitForm();
    }

    if (currentStep === 2) {
      await userForm.submitForm();
    }

    if (currentStep === 3) {
      try {
        await Auth.confirmSignUp(userForm.values.email, otp);
        setCurrentStep((prevState) => {
          return prevState + 1;
        });

        const user = await Auth.signIn(
          userForm.values.email,
          userForm.values.password,
        );

        await axios.put(
          `${process.env.REACT_APP_API_URL}/user/profile`,
          {
            email: user.attributes.email,
            firstName: user.attributes['custom:firstName'],
            lastName: user.attributes['custom:lastName'],
            phone: user.attributes['custom:phone'],
            countryCode: user.attributes['custom:countryCode'],
            dob: user.attributes['custom:dob'],
            country: user.attributes['custom:country'],
            state: user.attributes['custom:state'],
            address: user.attributes['custom:address'],
            city: user.attributes['custom:city'],
            zip: user.attributes['custom:zip'],
            passport: user.attributes['custom:passport'],
            university: user.attributes['custom:university'],
            currentLocation: user.attributes['custom:currentLocation'],
          },
          {
            headers: {
              authorization: user.signInUserSession.accessToken.jwtToken,
            },
          },
        );

        dispatch(
          setUser({
            id: user.attributes.sub,
            attributes: user.attributes,
            tokens: {
              access: user.signInUserSession.accessToken.jwtToken,
              refresh: user.signInUserSession.refreshToken.token,
              id: user.signInUserSession.idToken.jwtToken,
            },
          }),
        );

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/user/send-mail`,
            {},
            {
              headers: {
                authorization: user.signInUserSession.accessToken.jwtToken,
              },
            },
          )
          .then(() => null)
          .catch(() => null);

        navigate('/');
      } catch (e) {
        setOtpError('Invalid OTP');
      }
    }
  };

  const handleZip = async (e: React.FocusEvent<HTMLInputElement>) => {
    try {
      const res = await axios.get(
        `https://api.postalpincode.in/pincode/${e.target.value}`,
      );

      if (res.data.Status === 'Error') {
        return;
      }

      userForm.setValues({
        ...userForm.values,
        city: res.data[0].PostOffice[0].Block,
        state: res.data[0].PostOffice[0].State,
        country: res.data[0].PostOffice[0].Country,
      });
    } catch (e) {
      // console.log(e);
    }
  };

  const handlePhoneNumber = (e: string) => {
    // console.log(e);
    userForm.setFieldValue('phone', e || '');
  };

  if (isLoggedIn) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <>
      <div className="pb-5 mb-3">
        <div className="container-fluid h-100">
          <div className="row h-100 d-flex align-items-center py-3 header-wrapper">
            <div className="col-6">
              <Link to="/">
                <img src={logo} alt="logo" className="logo-img" />
              </Link>
            </div>
            <div className="header-close-ico col-6 d-flex justify-content-end">
              <Link to="/">
                <img src={CloseIco} alt="close-ico" className="close-ico" />
              </Link>
            </div>
          </div>
        </div>
        <MultiProgressBar currentStep={currentStep} />
        {(() => {
          switch (currentStep) {
            case 1:
              return (
                <CheckEligibility
                  passport={eligibilityForm.values.passport}
                  university={eligibilityForm.values.university}
                  currentLocation={eligibilityForm.values.currentLocation}
                  onChange={eligibilityForm.handleChange}
                  onBlur={eligibilityForm.handleBlur}
                  errors={eligibilityForm.errors}
                  touched={eligibilityForm.touched}
                />
              );
            case 2:
              return (
                <SignupForm
                  values={{ ...userForm.values }}
                  handleChange={userForm.handleChange}
                  handlePhoneNumber={handlePhoneNumber}
                  handleBlur={userForm.handleBlur}
                  handleZipBlur={handleZip}
                  touched={userForm.touched}
                  errors={userForm.errors}
                />
              );
            case 3:
              return (
                <OTP
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                  onResendClick={onResendOTPClick}
                  error={otpError}
                />
              );
            case 4:
              return <ThankYou />;
            default:
              return 'No Page Found';
          }
        })()}
        <div className="container">
          <div className="row mx-0 justify-content-center">
            <div className="col-10 mx-auto btn-wrapper d-block d-sm-flex align-items-center justify-content-center">
              {currentStep === 2 ? (
                <button
                  className="mt-4 p-4 d-flex align-items-center justify-content-center prev-btn"
                  onClick={previousButton}>
                  {' '}
                  Previous{' '}
                </button>
              ) : (
                ''
              )}
              {currentStep !== 4 ? (
                <button
                  className="mt-4 p-4 d-flex align-items-center justify-content-center next-btn login-btn"
                  onClick={nextButton}>
                  {' '}
                  Next{' '}
                </button>
              ) : (
                <div className="btn-wrapper">
                  <Link
                    to="/dashboard"
                    className="mt-4 p-4 d-flex align-items-center justify-content-center login-btn link-btn"
                    style={{ display: 'inline-block' }}>
                    Go to Dashboard
                  </Link>
                </div>
              )}
            </div>
            {currentStep === 2 && userForm.errors.other ? (
              <p className="error-message" style={{ textAlign: 'center' }}>
                {userForm.errors.other}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
