'use strict';
import React, { useContext, useEffect, useState } from 'react';
import MultiProgressBar from '../../../Common/Pages/multiProgressBar/multi-progress-bar';
import Header from '../../../Common/Pages/header/header';
import ThankYou from '../../../Common/Pages/thank-you/thank-you';
import './complete-account-verification.scss';
import Sender from '../../sender/sender';
import { Link } from 'react-router-dom';
import BackIco from '../../../Assets/Images/back-ico.svg';
import UploadDoc from '../upload-doc-/upload-doc';
import AccountConfirmation from '../../account-confirmation/account-confirmation';
import Footer from '../../../Common/Pages/footer/footer';
import axios from 'axios';
import { setUser, setReqDoc, setListDoc } from '../../../global/features/user';
import { useDispatch, useSelector } from 'react-redux';
import { BallTriangle } from 'react-loader-spinner';
import PlusIco from '.././../../Assets/Images/plus-ico.svg';
import JPGIco from '.././../../Assets/Images/jpg-ico.png';
import DocxIco from '.././../../Assets/Images/docx-ico.png';
import PNGIco from '.././../../Assets/Images/png-ico.png';
import PDFIco from '.././../../Assets/Images/pdf-ico.png';
import { setSideBarVal } from '../../../global/features/data';

function CompleteAccountVerification(props) {
  const [accountVerification, setaccountVerification] = 'accountVerification';
  const [currentStep, setCurrentStep] = useState(1);
  const [payVal, setPayVal] = useState('ownAccount');
  const [documentList, setDocumentList] = useState([]);
  const [loader, setLoader] = useState(false);
  const user = useSelector((state) => state.user);
  const token = user.user?.tokens?.access;
  // console.log('token: ', token);
  const [checkDocStatus, setCheckDocStatus] = useState();
  const [listDocStatus, setListDocStatus] = useState();
  const reqDocList = user.reqDocuments;
  const [listofDoc, setListofDoc] = useState(reqDocList);
  const [docButton, setDocButton] = useState(true);
  const [docStatus, setDocStatus] = useState();
  const [whoUploadDoc, setWhoUploadDoc] = useState('');
  const [docUploadData, setDocUploadData] = useState(false);
  const [senderAccountVal, setSenderAccountVal] = useState();
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();
  let axiosToken = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token,
    },
  };
  const previousButton = () => {
    setCurrentStep((prevState) => {
      return prevState - 1;
    });
  };
  const handleDocConfirmation = () => {
    setLoader(true);
    setCurrentStep((prevState) => {
      return prevState >= 3 ? 4 : prevState + 1;
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/files`, axiosToken)
      .then((res) => {
        dispatch(setListDoc(res.data.files.docs));
        setListDocStatus(res.data.status);
        setCheckDocStatus(res.data.incomplete);
        setDocumentList((prevState) => {
          return res.data.files.docs;
        });
      })
      .catch((err) => {
        // console.log('err: ', err);
      });
    setLoader(false);
  };
  const nextButton = () => {
    setCurrentStep((prevState) => {
      return prevState >= 3 ? 4 : prevState + 1;
    });
  };
  const senderApi = () => {
    setLoader(true);
    setCurrentStep((prevState) => {
      return prevState >= 3 ? 4 : prevState + 1;
    });
    let documentofList = [];
    let axiosToken = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/files/required-docs`,
        {
          who: payVal,
        },
        axiosToken,
      )
      .then((res) => {
        dispatch(setReqDoc(res.data.required));
        let newArray = [];
        res.data.required.map((data, i) => {
          let tempArr = {
            ...data,
            docName: 'No Image',
            image: null,
            uploadStatus: 0,
          };
          newArray = [
            ...newArray,
            { ...data, docName: 'No Image', image: null, uploadStatus: 0 },
          ];
        });
        documentofList = newArray;
        setListofDoc([...documentofList]);
        setLoader(false);

        axios
          .get(`${process.env.REACT_APP_API_URL}/files`, axiosToken)
          .then((res) => {
            dispatch(setListDoc(res.data?.files?.docs));
            setDocumentList((prevState) => {
              return res.data?.docs;
            });
            let listData = res.data?.docs;
            if (listData) {
              const list = listData.map((e) => e.field);
              let arr = [...documentofList];
              documentofList.map((data, i) => {
                let nameIndex = list.indexOf(data.key);
                if (nameIndex != -1) {
                  let ext = listData[nameIndex]['originalname']
                    .split('.')
                    .pop();
                  // console.log('ext: ', ext);
                  if (ext === 'doc' || ext === 'docx') {
                    let imageIcon = DocxIco;
                    arr[i] = {
                      ...data,
                      docName: listData[nameIndex]['originalname'],
                      uploadStatus: 100,
                      image: imageIcon,
                    };
                  } else if (ext === 'pdf') {
                    let imageIcon = PDFIco;
                    arr[i] = {
                      ...data,
                      docName: listData[nameIndex]['originalname'],
                      uploadStatus: 100,
                      image: imageIcon,
                    };
                  } else if (ext === 'png' || ext === 'PNG') {
                    let imageIcon = PNGIco;
                    arr[i] = {
                      ...data,
                      docName: listData[nameIndex]['originalname'],
                      uploadStatus: 100,
                      image: imageIcon,
                    };
                  } else if (ext === 'jpeg' || ext === 'jpg') {
                    let imageIcon = JPGIco;
                    arr[i] = {
                      ...data,
                      docName: listData[nameIndex]['originalname'],
                      uploadStatus: 100,
                      image: imageIcon,
                    };
                  }
                }
              });
              setListofDoc([...arr]);
              setLoader(false);
              return arr;
            } else {
              setLoader(false);
            }
          })
          .catch((err) => {
            // console.log('err: ', err);
          });
      })
      .catch((err) => {
        // console.log('err: ', err);
      });
    // Get All Doc API
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/files`, axiosToken)
      .then((res) => {
        if (!res.data.incomplete.own) {
          setPayVal('');
        }
      })
      .catch((err) => {
        // console.log('err: ', err);
      });
  }, []);
  const handleGoToDashboard = () => {
    dispatch(setSideBarVal('home'));
  };
  return (
    <>
      <Header />
      <div className="container pb-sm-5" id="account-verification-wrapper">
        <div className="row">
          {currentStep == 2 ? (
            <button
              onClick={previousButton}
              className="back-btn pb-3 text-start d-block d-sm-none pt-3 pt-sm-0">
              {' '}
              <img src={BackIco} alt="back-ico" className="me-2" />
              Back{' '}
            </button>
          ) : (
            ''
          )}
          <div className="col-12 pt-sm-5 text-start text-sm-center">
            <h1 className="px-3 px-sm-0 pt-4 pt-sm-0">
              {' '}
              Complete One-Time KYC{' '}
            </h1>
            <p className="px-3 px-sm-0">
              {' '}
              You can transfer any number of times once your documents are
              complete. You might have to update the details as and when they
              change.{' '}
            </p>
            <MultiProgressBar
              currentStep={currentStep}
              accountVerification={accountVerification}
            />
          </div>
          {currentStep == 2 ? (
            <button
              onClick={previousButton}
              className="back-btn pb-3 text-start d-none d-sm-block">
              {' '}
              <img src={BackIco} alt="back-ico" className="me-2" />
              Back{' '}
            </button>
          ) : (
            ''
          )}
          {(() => {
            switch (currentStep) {
              case 1:
                return (
                  <Sender
                    payVal={payVal}
                    setPayVal={setPayVal}
                    setDocStatus={setDocStatus}
                    listDocStatus={listDocStatus}
                    setListDocStatus={setListDocStatus}
                    checkDocStatus={checkDocStatus}
                    setCheckDocStatus={setCheckDocStatus}
                    whoUploadDoc={whoUploadDoc}
                    setWhoUploadDoc={setWhoUploadDoc}
                    docUploadData={docUploadData}
                    setDocUploadData={setDocUploadData}
                    setSenderAccountVal={setSenderAccountVal}
                    senderAccountVal={senderAccountVal}
                    selected={selected}
                    setSelected={setSelected}
                  />
                );
              case 2:
                return (
                  <UploadDoc
                    listofDoc={listofDoc}
                    setListofDoc={setListofDoc}
                    docButton={docButton}
                    setDocButton={setDocButton}
                  />
                );
              case 3:
                return <ThankYou accountVerification={accountVerification} />;
              // case 4:
              //   return <ThankYou accountVerification={accountVerification} />;
              default:
                return 'No Page Found';
            }
          })()}
          <div className="row mx-0 justify-content-center">
            <div className="col-12 col-sm-6 btn-wrapper d-flex align-items-center justify-content-center upload-doc-btns">
              {(() => {
                switch (currentStep) {
                  case 1:
                    return (
                      <>
                        {docStatus?.father === true ||
                        docStatus?.mother === true ||
                        docStatus?.own === true ||
                        docStatus?.spouse === true ? (
                          <button
                            className="mt-5 p-4 d-flex align-items-center justify-content-center next-btn login-btn"
                            onClick={senderApi}>
                            {' '}
                            Next{' '}
                          </button>
                        ) : (
                          ''
                        )}
                      </>
                    );
                  case 2:
                    return (
                      <>
                        <button
                          className="mt-4 p-4 d-flex align-items-center justify-content-center prev-btn login-btn"
                          onClick={previousButton}>
                          {' '}
                          Previous{' '}
                        </button>
                        <button
                          disabled={docButton}
                          className={
                            docButton
                              ? 'btn-disable mt-4 p-4 d-flex align-items-center justify-content-center'
                              : 'mx-auto p-4 d-flex align-items-center justify-content-center next-btn login-btn'
                          }
                          onClick={handleDocConfirmation}>
                          {' '}
                          Next{' '}
                        </button>
                      </>
                    );
                  // case 3:
                  //   return (
                  //     (
                  //       <button
                  //         className="mt-4 p-4 d-flex align-items-center justify-content-center prev-btn"
                  //         onClick={previousButton}>
                  //         {' '}
                  //         Previous{' '}
                  //       </button>
                  //     ),
                  //     (
                  //       <button
                  //         className="mt-4 p-4 d-flex align-items-center justify-content-center next-btn login-btn"
                  //         onClick={nextButton}>
                  //         {' '}
                  //         Complete{' '}
                  //       </button>
                  //     )
                  //   );
                  case 3:
                    return (
                      <div className="btn-wrapper">
                        <Link
                          to="/dashboard"
                          onClick={handleGoToDashboard}
                          className="mt-4 p-4 d-flex align-items-center justify-content-center login-btn link-btn"
                          style={{ display: 'inline-block' }}>
                          Go to Dashboard
                        </Link>
                      </div>
                    );
                  default:
                    return 'No Page Found';
                }
              })()}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mt-5">
            <Footer />
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader-wrapper d-flex align-items-center justify-content-center">
          <BallTriangle
            height="100"
            width="100"
            color="#45C29A"
            ariaLabel="loading"
          />
        </div>
      )}
    </>
  );
}

export default CompleteAccountVerification;
