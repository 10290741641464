import React from 'react';
import Tick from '../../../Assets/Images/tick.svg';
import './terms-conditions.scss';

function TermsConditions() {
  return (
    <>
      <div className="row mt-sm-4 mx-0" id="terms-conditions-wrapper">
        <div className="col-12 pt-5 px-sm-5">
          <h1 className="text-center">
            This is the important bit, please read it carefully
          </h1>
          <p className="my-4 py-2">
            We at Remigos handle your data with care. We understand the
            importance of your privacy. We only share it with Central Identities
            Data Repository, just to check that you are who you say you are. We
            will never use it for anything else
          </p>
          <div className="col-sm-7 col-12">
            <h2 className="mb-4">Please make sure of the points below</h2>
            <ul>
              <li className="d-flex align-items-start">
                <img src={Tick} alt="tick-ico" />
                <p>
                  I am an Indian tax-resident, and have a valid Permanent
                  Account Number (PAN)
                </p>
              </li>
              <li className="d-flex align-items-start">
                <img src={Tick} alt="tick-ico" />
                <p>
                  I agree to pay for my transfer using a resident bank account.
                  Remigos is unable to accept payments from NRE or NRO accounts
                </p>
              </li>
              <li className="d-flex align-items-start">
                <img src={Tick} alt="tick-ico" />
                <p>
                  I have a valid Indian Driving License or an Indian Passport,
                  and my PAN is linked to my Aadhar.
                </p>
              </li>
              <li className="d-flex align-items-start">
                <img src={Tick} alt="tick-ico" />
                <p>I have a valid Indian contact number.</p>
              </li>
              <li className="d-flex align-items-start">
                <img src={Tick} alt="tick-ico" />
                <p>
                  I haven’t sent more than 250,000 US Dollars abroad from India
                  this tax year - or the equivalent in another currency.
                </p>
              </li>
              <li className="d-flex align-items-start">
                <img src={Tick} alt="tick-ico" />
                <p>
                  I agree to the terms of the{' '}
                  <a
                    href="https://rbi.org.in/scripts/Fema.aspx"
                    target="_blank"
                    style={{ whiteSpace: 'nowrap' }}
                    rel="noreferrer">
                    Foreign Exchange Management Act.
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsConditions;
