export const ListofDoc = [
  {
    id: 1,
    name: 'Self attested students Passport',
    docName: 'no image',
    image: null,
    uploadStatus: 0,
  },
  {
    id: 2,
    name: 'College Offer letter (optional)',
    docName: 'no image',
    image: null,
    uploadStatus: 0,
  },
  {
    id: 3,
    name: 'Student Aadhar',
    docName: 'no image',
    image: null,
    uploadStatus: 0,
  },
  {
    id: 4,
    name: 'Self attested Student PAN Card',
    docName: 'no image',
    image: null,
    uploadStatus: 0,
  },
  {
    id: 5,
    name: 'Self attested One Parent’s Aadhar',
    docName: 'no image',
    image: null,
    uploadStatus: 0,
  },
  {
    id: 6,
    name: 'Self attested One Parent’s PAN Card ',
    docName: 'no image',
    image: null,
    uploadStatus: 0,
  },
  {
    id: 7,
    name: 'Self attested Rent Agreement (Optional)',
    docName: 'no image',
    image: null,
    uploadStatus: 0,
  },
  {
    id: 8,
    name: 'Student’s BRP or Visa Signature',
    docName: 'no image',
    image: null,
    uploadStatus: 0,
  },
];
