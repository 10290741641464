import React, { useState, useContext, useEffect } from 'react';
import RegisterCompleteIco from '../../../Assets/Images/thumbs-up.svg';
import JPGIco from '../../../Assets/Images/jpg-ico.png';
import DocxIco from '../../../Assets/Images/docx-ico.png';
import PNGIco from '../../../Assets/Images/png-ico.png';
import PDFIco from '../../../Assets/Images/pdf-ico.png';
import { ProgressBar } from 'react-bootstrap';
import CheckIco from '../../../Assets/Images/verified.svg';
import DeleteIco from '../../../Assets/Images/delete.png';
import RemoveIco from '../../../Assets/Images/remove.png';
import Header from '../../../Common/Pages/header/header';
import Footer from '../../../Common/Pages/footer/footer';
import './reupload-success-doc.scss';
import RejectedIco from '../../../Assets/Images/rejected.svg';
import ParentsIco from '../../../Assets/Images/parents.svg';
import { useNavigate } from 'react-router-dom';
import BackIco from '../../../Assets/Images/back-ico.svg';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

function ReuploadSuccess(props) {
  const user = useSelector((state) => state.user);
  const selecteDoc = user.whoSelRejectedDoc;
  const token = user.user.tokens.access;
  const [seconds, setSeconds] = useState(5);
  const navigate = useNavigate();

  const previousButton = () => {
    navigate('/rejected-doc-list');
  };
  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 5000);
  });
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Header />
      <div className="container reupload-success-doc">
        {/* <button onClick={previousButton} className="back-btn pb-3 text-start">
          {' '}
          <img src={BackIco} alt="back-ico" className="me-2" />
          Back{' '}
        </button> */}
        <div className="inner-reupload-success">
          <div className="verification-doc-status">
            {/* <ul className="ps-0">
              <li
                className={
                  listDocStatus?.own === 'rejected' ||
                  listDocStatus?.father === 'rejected' ||
                  listDocStatus?.mother === 'rejected' ||
                  listDocStatus?.spouse === 'rejected'
                    ? 'rejectedDoc p-2'
                    : 'p-2'
                }>
                <div className="d-flex align-items-center">
                  {' '}
                  <span className="d-flex align-items-center justify-content-center">
                    <img src={ParentsIco} alt="ico-parent" />{' '}
                  </span>
                  <div className="ps-2 col-10 text-start">
                    <h6 className="text-start mb-0"> {selecteDoc} Account </h6>
                    <h2> D. Prakash </h2>
                  </div>
                  <img
                    src={
                      listDocStatus?.own === 'rejected' ||
                      listDocStatus?.father === 'rejected' ||
                      listDocStatus?.mother === 'rejected' ||
                      listDocStatus?.spouse === 'rejected'
                        ? RejectedIco
                        : 'p-2'
                    }
                    alt="pendingIco"
                    className="ms-3 statusIco ms-4"
                  />
                </div>
              </li>
            </ul> */}
          </div>
          <div className="text-center heading-wrapper">
            <img
              src={RegisterCompleteIco}
              alt="thumb-ico"
              style={{ width: '130px' }}
              className="mb-3 mt-4"
            />
            <h2> Re-upload Successful.... </h2>
            <h3> Re-upload of the documents done </h3>
            <p className="pt-3 redirect-timer">
              {' '}
              Redirect to Dashboard in <strong>{seconds}</strong>sec{' '}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default ReuploadSuccess;
